import React, { useState, useEffect, useCallback } from "react"; // Import React and necessary hooks
import Box from '@mui/material/Box'; // Import Box component from Material-UI
import Collapse from '@mui/material/Collapse'; // Import Collapse component from Material-UI
import IconButton from '@mui/material/IconButton'; // Import IconButton component from Material-UI
import Table from '@mui/material/Table'; // Import Table component from Material-UI
import TableBody from '@mui/material/TableBody'; // Import TableBody component from Material-UI
import TableCell from '@mui/material/TableCell'; // Import TableCell component from Material-UI
import TableHead from '@mui/material/TableHead'; // Import TableHead component from Material-UI
import TableRow from '@mui/material/TableRow'; // Import TableRow component from Material-UI
import Typography from '@mui/material/Typography'; // Import Typography component from Material-UI
import {
  TextField,
  Stack,
  MenuItem,
  InputAdornment,
  InputLabel,
  FormControl,
  Select,
  Divider,
  Chip,
  Checkbox,
  FormGroup,
  FormControlLabel,Paper,CircularProgress,Snackbar
} from '@mui/material'; // Import various Material-UI components
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; // Import KeyboardArrowDownIcon from Material-UI
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'; // Import KeyboardArrowUpIcon from Material-UI
import { Button, Avatar, Tooltip } from '@mui/material'; // Import Button, Avatar, Tooltip components from Material-UI
import { blue, blueGrey, cyan, green, red, teal, yellow } from '@mui/material/colors'; // Import color palettes from Material-UI
import { createTheme } from '@mui/material/styles'; // Import createTheme function from Material-UI
import PdfDocument from "../PDF/Main"; // Import PdfDocument component from "../PDF/Main"
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'; // Import PDFDownloadLink and PDFViewer components from '@react-pdf/renderer'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Import PictureAsPdfIcon from Material-UI
import { orange, indigo } from '@mui/material/colors'; // Import color palettes from Material-UI
import Dialog from '@mui/material/Dialog'; // Import Dialog component from Material-UI
import DialogActions from '@mui/material/DialogActions'; // Import DialogActions component from Material-UI
import DialogContent from '@mui/material/DialogContent'; // Import DialogContent component from Material-UI
import DialogContentText from '@mui/material/DialogContentText'; // Import DialogContentText component from Material-UI
import DialogTitle from '@mui/material/DialogTitle'; // Import DialogTitle component from Material-UI
import { Alert } from '@mui/material'; // Import Alert component from Material-UI
import Stepper from '@mui/material/Stepper'; // Import Stepper component from Material-UI
import Step from '@mui/material/Step'; // Import Step component from Material-UI
import StepLabel from '@mui/material/StepLabel'; // Import StepLabel component from Material-UI
import ModeEditIcon from '@mui/icons-material/ModeEdit'; // Import ModeEditIcon from Material-UI
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'; // Import DisabledByDefaultIcon from Material-UI
import { DatePicker } from "@mui/x-date-pickers"; // Import DatePicker component from "@mui/x-date-pickers"
import LoadingDialog from '../Loading'; // Import LoadingDialog component from "../Loading"
import AxiosFunction from "../../axiosCustomInstance"; // Import AxiosFunction from "../../axiosCustomInstance"
import UserAuth from "../ProtectedRoute/userAuth"; // Import UserAuth component from "../ProtectedRoute/userAuth"
import InfoIcon from '@mui/icons-material/Info'; // Import InfoIcon from Material-UI
import { useDrag, useDrop } from 'react-dnd'; // Import useDrag and useDrop hooks from 'react-dnd'
import { DndProvider } from 'react-dnd'; // Import DndProvider component from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'; // Import HTML5Backend from 'react-dnd-html5-backend'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import CheckCircleIcon from Material-UI
import StepIcon from '@mui/material/StepIcon'; // Import StepIcon component from Material-UI
import ForwardIcon from '@mui/icons-material/Forward'; // Import ForwardIcon from Material-UI
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'; // Import StepConnector and stepConnectorClasses from Material-UI
import { styled,keyframes } from '@mui/system'; // Import styled function from Material-UI
import OrderIcon from '@mui/icons-material/ShoppingCart'; // Import OrderIcon from Material-UI
import PaidIcon from '@mui/icons-material/MonetizationOn'; // Import PaidIcon from Material-UI
import ShippingIcon from '@mui/icons-material/LocalShipping'; // Import ShippingIcon from Material-UI
import InvoiceIcon from '@mui/icons-material/Description'; // Import InvoiceIcon from Material-UI
import CompletedIcon from '@mui/icons-material/Done'; // Import CompletedIcon from Material-UI
import QuoteGeneratedIcon from '@mui/icons-material/Description'; // Import QuoteGeneratedIcon from Material-UI
import { Link, createSearchParams, useNavigate } from 'react-router-dom'; // Import Link, createSearchParams, and useNavigate from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import VisibilityIcon from Material-UI
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShopIcon from '@mui/icons-material/Shop';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DiscountIcon from '@mui/icons-material/Discount';
import PersonIcon from '@mui/icons-material/Person';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PhoneIcon from '@mui/icons-material/Phone'; 
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TodayIcon from '@mui/icons-material/Today';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';    
import CloseIcon from '@mui/icons-material/Close'; 
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { RowingOutlined } from "@mui/icons-material";
import PackingSlipPdfDocument from '../PackageSlip/Main'; 
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Circle } from '@mui/icons-material';
import { pdf } from '@react-pdf/renderer';
import formatNameFromEmail from '../HelperFunctions/EmaiToNameHelper'
import PackingSlipComponent from './PackingSlip/PackingSlip';
import EmailDialog from "../Email/KOorCustomerEmail";


const iconMapping = {
  'QUOTE GENERATED': <QuoteGeneratedIcon  />,
  'ORDERED': <OrderIcon />,
  'ORDERED & ALREADY PAID': <PaidIcon />,
  'SHIPPED': <ShippingIcon />,
  'INVOICED': <InvoiceIcon />,
  'ORDER COMPLETE': <CheckCircleIcon />,
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    display: 'none',
  },
  [`&.${stepConnectorClasses.alternativeLabel}.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    display: 'none',
  },
  margin: 'auto',
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  height: 22,
  display: 'flex',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
}));

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const color1 = blueGrey[500];
const color2 = blueGrey[50];

const DraggableStep = ({ label, isActive, isCompleted, onClick }) => {
  return (
    <Step>
      <StepLabel
        sx={{
          color: 'white',
          height: '50px',
          borderRadius: '10px',
          width: '85px',
          backgroundColor: 'white',
          boxShadow: isActive ? '0px 0px 5px blueGrey' : 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          mx: '0px',
          baseline: 'middle',
          marginTop: '5px',
        }}
        onClick={onClick}
      >
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress
            variant="determinate"
            value={100}
            sx={{
              color: isActive || isCompleted ? orange[500] : orange[200],
              position: 'absolute',
              zIndex: 1,
              height: '40px !important',
              width: '40px !important',
              animation: isActive ? `${rotateAnimation} 2s linear infinite` : 'none', // Add rotating animation if active
            }}
          />
          {iconMapping[label] && React.cloneElement(iconMapping[label], { sx: { color: blueGrey[800], height: '25px', width: '25px', zIndex: 2 } })}
        </Box>
        <Typography variant="subtitle1" sx={{ fontSize: '9px', fontWeight: 'bold', color: blueGrey[800], marginTop: '10px' }}>
          {label}
        </Typography>
        {isActive && (
          <Typography variant="caption" sx={{ fontSize: '8px', color: blue[900], marginTop: '2px' }}>
            <b>Current Stage</b>
          </Typography>
        )}
      </StepLabel>
    </Step>
  );
};

const FilteredSteps = ({ row }) => {
  const existingStages = row?.Stage_Sequence ? row?.Stage_Sequence.split('-').filter(stage => stage !== '') : [];

  return (
    <Box sx={{}}>
      <Stepper alternativeLabel sx={{ padding: '0' }} connector={<QontoConnector />}>
        {existingStages.map((label, index) => (
          <React.Fragment key={label}>
            <DraggableStep
              label={label}
              isActive={label === row?.Status}
              isCompleted={index < existingStages.indexOf(row?.Status)}
              icon={iconMapping[label]}
            />
            {index < existingStages.length - 1 && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', top: '-30px' }}>
                <Circle sx={{ color: blueGrey[500], fontSize: '32px', position: 'absolute', zIndex: 1 }} />
                <ArrowForwardIcon sx={{ color: 'white', fontSize: '22px', zIndex: 2 }} />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Stepper>
    </Box>
  );
};

function MasterTableHelper(props) {
  // Extract UserName and UserEmail from UserAuth() hook
  const { UserName, UserEmail } = UserAuth();

  // Get current date formatted as 'medium'
  const PD = new Date();
  const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD);

  // Destructure props to get row data and refreshRowData function
  const { row, refreshRowData, usersData } = props;

  // Initialize various state variables for managing the component's state
  const [status, setStatus] = useState(row?.Status || ''); // Manages status
  const [areYouSureDialog, setAreYouSureDialog] = useState(false); // Controls "Are you sure?" dialog visibility
  const [updateQuoteDialog, setUpdateQuoteDialog] = useState(false); // Controls "Update quote" dialog visibility
  const [invoiceDate, setInvoiceDate] = useState(row?.Invoice_Date || ''); // Manages invoice date
  const [paymentDate, setPaymentDate] = useState(row?.Payment_Date || ''); // Manages payment date
  const [actualdelivery, setActualDelivery] = useState(row?.Actual_Delivery || ''); // Manages actual delivery date
  const [invoiceNumber, setInvoiceNumber] = useState(row?.Invoice_Number || ''); // Manages invoice number
  const [purchaseorderNumber, setPurchaseOrderNumber] = useState(row?.Purchase_Order || ''); // Manages purchase order number
  const [rfqrfqID, setRFQRFQID] = useState(row?.RFQRFQID || ''); // Manages RFQ ID
  const AxiosAPIInstance = AxiosFunction(); // Axios instance for making API requests
  const [loading, setLoading] = useState(false); // Manages loading state
  const [updateError, setUpdateerror] = useState(false); // Manages update error state
  const [updateErrorMessage, setUpdateErrorMessage] = useState(""); // Manages update error message
  const [updateSuccess, setUpdateSuccess] = useState(false); // Manages update success state
  const [getMoreInfo, setGetMoreInfo] = useState(false); // Controls "Get more info" visibility
  const [rFQData, setRFQData] = useState(row?.RFQ); // Manages purchase orders data
  const [alreadyPaidChecked, setAlreadyPaidChecked] = useState(row?.Already_Paid || ''); // Manages "Already Paid" checkbox state
  const [addCommentChecked, setAddCommentChecked] = useState(row?.Add_Comment_Checked || ''); // Manages "Add Comment" checkbox state
  const [addComment, setAddComment] = useState(row?.Add_Comment || ''); // Manages additional comments
  const [salesHeadError, setSalesHeadError] = useState(false); // Manages sales head error state
  const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState(""); // Manages sales head error message
  const [MasterLifeCycleRowData, setMasterLifeCycleRowData] = useState({}); // Manages lifecycle row data
  const [userDefinedStages, setUserDefinedStages] = useState([ // Stores user-defined stages
    'QUOTE GENERATED',
    'ORDERED',
    'INVOICED',
    'SHIPPED',
    'ORDER COMPLETE',
  ]);
  const [customerOrKOEmailDialogOpen, setCustomerOrKOEmailDialogOpen] = useState(false); // Manages customer email dialog visibility
  const [attachments, setAttachments] = useState([]); // Manages file attachments
  const [getKOAndCustomerEmailHistory, setGetKOAndCustomerEmailHistory] = useState(false); // Controls fetching email history
  const [koCustomerEMailHistory, setKOCustomerEMailHistory] = useState([{}]); // Manages email history data
  const [attachFilesChecked, setAttachFilesChecked] = useState(false); // Manages the "Attach Files" checkbox state
  const [attachSKUDetailsInTheBody, setAttachSKUDetailsInTheBody] = useState(false); // Manages the "Attach SKU Details" checkbox state
  const [allSKUsCost, setAllSKUsCost] = useState(''); // Manages the total cost of all SKUs

  const [packageDetailsChecked, setPackageDetailsChecked] = useState(row?.packageDetailsChecked || ''); // Manages package details checkbox state
  const [packingSlipDownloadlicked, setPackingSlipDownloadClicked] = useState(false); // Manages packing slip download click state


  // State management
  const [isPackageDetailsDialogOpen, setIsPackageDetailsDialogOpen] = useState(false); // State to control the visibility of the Packing Slip Dialog
  const [isKOorCustomerDialogOpen, setIsKOorCustomerDialogOpen] = React.useState(false);
  const [toEmail, setToEmail] = React.useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const defaultSubject=`${row?.RFQQuoteNumber}----(${row?.Customer_Name})`
  // Function to handle the dialog success event
  const handleKoOrCustomerEmailSuccess = () => {
    setAlertMessage('Email sent successfully');
    setAlertSeverity('success');
    setIsSnackbarOpen(true);
    setIsKOorCustomerDialogOpen(false);
  };

  // Function to handle dialog errors
  const handleDialogError = (error) => {
    setAlertMessage(`Error: ${error.message}`);
    setAlertSeverity('error');
    setIsSnackbarOpen(true);
  };

  // Function to open the KO or Customer Email dialog
  const handleOpenKOorCustomerEmailOpenDialog = (e) => {
    e.preventDefault();
    console.log("Opening dialog..."); // Debug log
    setIsKOorCustomerDialogOpen(true);
  };

  // Function to close the Snackbar
  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };


  // Function to open the packing slip dialog
  const handleOpenPackingSlipDialog = (e) => {
    e.preventDefault();
    setIsPackageDetailsDialogOpen(true); // Open the dialog when the button is clicked
  };




const defaultContent = `Hope you're doing great!

This order has been shipped. Please see attached order spreadsheet, order data sheet and packing list with tracking information`;



// Initialize InvoiceData state object
const [InvoiceData, setInvoiceData] = useState({
  invoice_no: "",
  Contract_Vehicle: "",
  KO_name: "",
  KO_email: "",
  KO_phone: "",
  KO_address: "",
  Print_Date: String(today),
  Valid_For: "30 Days",
  trans_date: "",
  due_date: "",
  Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
  items: [{}],
  Tax: 0,
  Shipping_Cost: 0,
  Total_Cost: 0,
  Shipping_Details: {
    Organization_Name: "",
    KO_name: "",
    Sales_Head_Info: {},
    Customer_Name: "",
    Customer_Email: "",
    Customer_Phone: "",
    Customer_Address: "",
    Shipping_Method: "",
    Shipping_Terms: "",
    Estimated_Delivery: "",
    Payment_Terms: "",
    Notes: ""
  }
});


//-------------------------------------------------------------------------

// Import useNavigate hook from react-router-dom
const navigate = useNavigate();
  // Function to handle year change

// Function to navigate to the UpdateRFQ page with parameters
const RFQID_Search = () => {
  navigate({
    pathname: "/Dashboard/UpdateRFQ",
    search: createSearchParams({
      RID: row?.RFQRFQID,
      RStatus: row?.RFQ_Status,
      VNo: 'LifeCycle',
    }).toString()
  });
};



let File_Opportunity_Name = '';
const OP_arr = String(row?.RFQ[0]?.Opportunity_Name).split('.');
for (var i = 0; i < OP_arr.length; i++) {
  OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
}
File_Opportunity_Name=OP_arr.join(" ")

const HandlePDFClick = async (row) => {
  setLoading(true); 
  await AxiosAPIInstance.get("RFQsSearch/RFQID",{params:{RFQ_ID:row?.RFQRFQID}}).then(async(response)=>{
    const arr = String(response?.data[0].Sales_Head_Name).toLowerCase().split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    
    }
    const KO_arr = String(response?.data[0].KO_name).toLowerCase().split(" ");
    for (var i = 0; i < KO_arr.length; i++) {
      KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
    
    }
   
    const CO_arr = String(response?.data[0].Customer_Name).toLowerCase().split(" ");
    for (var i = 0; i < CO_arr.length; i++) {
      CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
    
    }
  const OP_arr = String(response?.data[0].Opportunity_Name).split('.');
  for (var i = 0; i < OP_arr.length; i++) {
    OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
  }

  const updatedInvoiceData = {
    ...InvoiceData,
    invoice_num: String(response?.data[0].Invoice_No),
    Contract_Vehicle: response?.data[0].Contract_Vehicle,
    KO_name: KO_arr.join(' '),
    KO_email: response?.data[0].KO_email,
    KO_phone: response?.data[0].KO_phone,
    KO_address: response?.data[0].KO_address,
    Print_Date: String(today),
    trans_date: String(response?.data[0].Last_Updated_Date).split('T')[0],
    due_date: response?.data[0].Due_date,
    Valid_For: response?.data[0].Valid_For,
    Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: response?.data[0]['Purchase_Orders'],
    Tax: response?.data[0].Tax,
    Shipping_Cost: response?.data[0].Shipping_Cost,
    Total_Cost: response?.data[0].Actual_Total_Cost_After_Margin,
    Other_Cost_1: response?.data[0].Other_Cost_1,
    Other_Cost_2: response?.data[0].Other_Cost_2,
    Other_Cost_3: response?.data[0].Other_Cost_3,
    Other_Cost_Name1: response?.data[0].Other_Cost_Name1,
    Other_Cost_Name2: response?.data[0].Other_Cost_Name2,
    Other_Cost_Name3: response?.data[0].Other_Cost_Name3,
    Shipping_Details: {
      Organization_Name: response?.data[0].Organization_Name,
      KO_name: response?.data[0].KO_name,
      Sales_Head_Info: { Name: arr.join(' '), Email: response?.data[0].Sales_Head_Email, Phone: response?.data[0].Sales_Head_Ph },
      Customer_Name: CO_arr.join(' '),
      Customer_Email: response?.data[0].Customer_Email,
      Customer_Phone: response?.data[0].Customer_Phone,
      Customer_Address: response?.data[0].Customer_Address,
      Shipping_Method: response?.data[0].Shipping_Method,
      Shipping_Terms: response?.data[0].Shipping_Terms,
      Estimated_Delivery: response?.data[0].Estimated_Delivery,
      Payment_Terms: response?.data[0].Payment_Terms,
      Notes: response?.data[0].Notes
    }
  };

  setInvoiceData(updatedInvoiceData);

  // Format Opportunity Name for file name
  File_Opportunity_Name = OP_arr.join(" ")
  setTimeout(async () => {
    setLoading(true);
    const pdfBlob = await pdf(<PdfDocument invoicedata={updatedInvoiceData} />).toBlob();
    const url = URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${File_Opportunity_Name} ${String(response?.data[0].Invoice_No).split('.')[0]}${String(response?.data[0].Invoice_No).split('.')[1]}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setLoading(false);
  }, 0);
}).catch(async(error)=>{
  setLoading(false); // Set loading state to false
  setUpdateerror(true); // Set update error state to true
  setUpdateSuccess(false); // Set update success state to false
  setUpdateErrorMessage(error.response?.data?.message || "Cannot Process the request"); // Set update error message
})
};


    const handleAreYouSureDialogClose=(e,row)=>{
      e.preventDefault();
      setAreYouSureDialog(false)
      setRFQRFQID(row?.RFQRFQID)
     }

     const updateDialogClose=async(e)=>{
      e?.preventDefault()
      setUpdateQuoteDialog(false)
      setInvoiceNumber('')
      setPurchaseOrderNumber('')
      setAddComment('')
      setInvoiceDate('')
      setRFQRFQID('')
      setActualDelivery('')
      setAlreadyPaidChecked(false)
      setAddCommentChecked(false)
      setPackageDetailsChecked(false)
     }

     const updateDialogOpen=async(e)=>{
      e?.preventDefault()
      setUpdateQuoteDialog(true)
      setInvoiceNumber(row?.Invoice_Number)
      setPurchaseOrderNumber(row?.Purchase_Order)
      setAddComment(row?.Add_Comment)
      setInvoiceDate(row?.Invoice_Date)
      setRFQRFQID(row?.RFQRFQID)
      setActualDelivery(row?.Actual_Delivery)
      setAlreadyPaidChecked(row?.Already_Paid)
      setAddCommentChecked(row?.Add_Comment_Checked)
      setPackageDetailsChecked(row?.Package_Details_Checked) 
     }



//-----------------------------------
     const handleClose=async(e)=>{
      e.preventDefault()
      setUpdateerror(false)
      setUpdateErrorMessage("")
      setUpdateSuccess(false)
      setGetMoreInfo(false)
      setGetKOAndCustomerEmailHistory(false)
      setKOCustomerEMailHistory([{}])
      setCustomerOrKOEmailDialogOpen(false);
      setAttachments(null)
      setAttachFilesChecked(false)
      setAttachSKUDetailsInTheBody(false)
      setAllSKUsCost('')
      setRFQRFQID()
    

     }

     const handleSuccessClose=async(e)=>{
      e.preventDefault()
      setUpdateSuccess(false)
      setRFQRFQID()

     }
     const handleFullHistory=async(e)=>{
      e.preventDefault()
      setRFQRFQID()
      setGetMoreInfo(true);
     }

     const handleAlreadyPaidChange=async(e)=>{
      setAlreadyPaidChecked(e.target.checked)
     }

     const handleCommentCheckedChange=async(e)=>{
      setAddCommentChecked(e.target.checked)
      if(e.target.checked===false)
      {
        setAddComment('N/A')
      }
     }


// Function to handle updating the master lifecycle
const handleMasterLifeCycleUpdate = async (e) => {
  setPackingSlipDownloadClicked(false)
  e.preventDefault(); // Prevent default form submission behavior
  setLoading(true); // Set loading state to true
  setRFQRFQID(MasterLifeCycleRowData?.RFQRFQID)
  try {
    // Define body data for the update request
    const body = {
      RFQRFQID:rfqrfqID,
      Invoice_Date: invoiceDate,
      Invoice_Number: invoiceNumber,
      Purchase_Order: purchaseorderNumber,
      Payment_Date: paymentDate,
      Status: status,
      Updated_By: UserEmail,
      Last_Updated_Date: today,
      Actual_Delivery: actualdelivery,
      Already_Paid: alreadyPaidChecked,
      Add_Comment_Checked: addCommentChecked,
      Add_Comment: addComment
    };

    // Send PATCH request to update master lifecycle
    await AxiosAPIInstance.patch("/MasterLifeCycle/MasterLifeCycleUpdate", body)
      .then(async (response) => {
        setLoading(false); // Set loading state to false
        refreshRowData(); // Refresh row data
        setUpdateerror(false); // Set update error state to false
        setUpdateSuccess(true); // Set update success state to true
        setUpdateErrorMessage(response?.data); // Set update success message
        setUpdateQuoteDialog(false); // Close update quote dialog
      })
      .catch(async (error) => {
        setLoading(false); // Set loading state to false
        setUpdateerror(true); // Set update error state to true
        setUpdateSuccess(false); // Set update success state to false
        setUpdateErrorMessage(error.response?.data); // Set update error message
      });
  } catch {
    setLoading(false); // Set loading state to false
    setUpdateerror(true); // Set update error state to true
    setUpdateErrorMessage("Cannot Process the request"); // Set update error message
  }
};






    return (
      <DndProvider backend={HTML5Backend}>
      <React.Fragment>
      <LoadingDialog open={loading} />
      {updateError===true&&(<Dialog
      onClose={async(e)=>{
        handleClose(e)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' severity="error">ERROR</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
      {updateErrorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={async(e)=>{
        handleClose(e)}} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {updateSuccess===true&&(<Dialog
      open={updateSuccess}
      onClose={async(e)=>{
        handleClose(e)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' severity="success">success</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
      {updateErrorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={async(e)=>{
          handleSuccessClose(e)}} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {getMoreInfo===true&&(<Dialog
    key={row?.RFQQuoteNumber}
    
    open={getMoreInfo}
    onClose={async(e)=>{
      handleClose(e)}}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description" >
    <DialogTitle id="alert-dialog-title">
    <Alert variant='filled' sx={{bgcolor:indigo[800]}}>FULL UPDATE HISTORY: {row?.Customer_Name}</Alert>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      <Stack sx={{textAlign:'left' ,alignItems:"center",color:'black'}}>
      <span style={{ whiteSpace: 'pre-wrap' }} >
      <Divider sx={{backgroundColor:red[900]}}></Divider>{row?.History}<Divider sx={{backgroundColor:red[900]}}></Divider>
</span>
      </Stack>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button onClick={async(e)=>{
      handleClose(e)}} sx={{width:"200px",backgroundColor:indigo[900],color:"white",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:blue[600],color:'white'}}} >OK</Button>
    </DialogActions>
  </Dialog>)}   

  {updateQuoteDialog===true&&(<Dialog
    key={row}
    open={updateQuoteDialog}
    maxWidth='x-lg'
    sx={{justifyContent:"stretch"}}
    onClose={
      async(e,row)=>
        {handleAreYouSureDialogClose(e,row)}}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description" >
    <DialogTitle id="alert-dialog-title">
    <Alert variant='filled' sx={{bgcolor:orange[200],color:blueGrey[900]}}>{row.Customer_Name+' ('+ row?.RFQQuoteNumber+')'}</Alert>
    </DialogTitle>
    <DialogContent sx={{flexGrow:'inherit',justifyContent:'inherit'}} >
      <DialogContentText id="alert-dialog-description">
      <Stack sx={{textAlign:"center",alignItems:"center",marginTop:'20px'}} justifyContent='center' direction='column' spacing={3}>
        <Stack sx={{textAlign:"center",alignItems:"center"}} direction='row' justifyContent='center' spacing={4}>
        <TextField  size='small' value={invoiceNumber} label='Invoice #'  name='Invoice #' onChange={(e)=>setInvoiceNumber(e.target.value)}  sx={{width:"200px"}}></TextField>
         <TextField  size='small' value={purchaseorderNumber} label='Purchase Order#' name='Purchase Order#' onChange={(e)=>setPurchaseOrderNumber(e.target.value)}  sx={{width:"200px"}}></TextField>
        <DatePicker label="Payment Date" sx={{width:"200px"}} value={paymentDate}  renderInput={(params)=><TextField size="small" variant="standard" label='Payment Date'
        name='Payment Date' sx={{width:"200px"}} {...params}/>} onChange={(value)=>setPaymentDate(value)}>
        </DatePicker>
        <DatePicker label="Invoice Date" sx={{width:"200px"}} value={invoiceDate}  renderInput={(params)=><TextField size="small" label='Invoice Date' name='Invoice Date' variant="standard"  
        sx={{width:"200px"}} {...params}/>} onChange={(value)=>setInvoiceDate(value)}>
        </DatePicker>
        </Stack>
        <Stack sx={{textAlign:"center",alignItems:"center"}} justifyContent='center' direction='row' spacing={4}>
         <DatePicker label="Actual Delivery" sx={{width:"200px"}} value={actualdelivery}  renderInput={(params)=><TextField size="small" variant="standard"  
      label='Actual Delivery' name='Actual Delivery' sx={{width:"200px"}} {...params}/>} onChange={(value)=>setActualDelivery(value)}>
        </DatePicker>
      <FormControl>
            <InputLabel id="Status-id">
               Select Status
            </InputLabel>
            <Select
                labelId="Status-id"
                value={status}
                variant='standard'
                style={{
                    width: 200,
                }}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}>
      
          <MenuItem size="small" value={"QUOTE GENERATED"}>QUOTE GENERATED</MenuItem>
          <MenuItem size="small" value={"SHIPPED"}>SHIPPED</MenuItem>
          <MenuItem size="small" value={"ORDERED"}>ORDERED</MenuItem>
          <MenuItem size="small" value={"INVOICED"}>INVOICED</MenuItem>
          <MenuItem size="small" value={"ORDER COMPLETE"}>ORDER COMPLETE</MenuItem>
            </Select>
        </FormControl>

      </Stack>
      <Stack sx={{textAlign:"center",alignItems:"center"}} direction='row' justifyContent='center' spacing={4}>
      <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="Already Paid" checked={alreadyPaidChecked} onChange={(e)=>{handleAlreadyPaidChange(e)}}/>} label="Already Paid" />
       </FormGroup>
       <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="Add Comment" checked={addCommentChecked} onChange={(e)=>{handleCommentCheckedChange(e)}}/>} label="Add Comment" />
       </FormGroup>
       <TextField  size='small' label='Add Comment' value={addComment} disabled={!addCommentChecked}  onChange={(e)=>
        {setAddComment(e.target.value)
        }}  sx={{width:"300px"}}></TextField>
     </Stack>
     <Stack>
<Divider sx={{color:orange[900],bgcolor:red[900],width:"100%",height:"1px"}} orientation='horizontal'></Divider>
</Stack>

      </Stack>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button name="Update" variant='contained' size='small'
    onClick={(e)=>{
    setRFQRFQID(row?.RFQRFQID);
    setMasterLifeCycleRowData(row)
    handleMasterLifeCycleUpdate(e)}}> 
Update </Button>
     <Button name="Cancel" variant='outlined' color='warning' size='small'
     onClick={(e,row)=>{
      setMasterLifeCycleRowData(row)
      updateDialogClose(e);}}> Cancel </Button>
    </DialogActions>
  </Dialog>)}
        
  {isKOorCustomerDialogOpen && (
        <>
          {console.log("Dialog is rendering...")} {/* Debug log */}
          <EmailDialog
            row={row}
            userName={UserName}
            userEmail={UserEmail}
            onSuccess={handleKoOrCustomerEmailSuccess}
            onError={handleDialogError}
            usersData={usersData}
            toEmail={toEmail}
            skuData={row?.Purchase_Orders}
            loading={loading}
            setLoading={setLoading}
            setIsKOorCustomerDialogOpen={setIsKOorCustomerDialogOpen}
            isKOorCustomerDialogOpen={isKOorCustomerDialogOpen}
            defaultSubject={defaultSubject}
            Quote_No={row?.RFQ[0]?.RFQQuoteNumber}
            RFQID={row?.RFQRFQID}
          />
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleSnackbarClose} severity={alertSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
        </>
      )}
 {/* Conditional rendering of PackingSlipComponent */}
 {isPackageDetailsDialogOpen && (
                <PackingSlipComponent
                    row={row}
                    UserName={UserName}
                    today={today}
                    refreshRowData={refreshRowData}
                    File_Opportunity_Name={File_Opportunity_Name}
                    PackingSlipPdfDocument={PackingSlipPdfDocument}
                    invoiceNumber={row?.Invoice_Number}
                    isPackageDetailsDialogOpen={isPackageDetailsDialogOpen}
                    onUpdateSuccess={setUpdateSuccess}
                    onUpdateError={setUpdateerror}
                    onUpdateErrorMessage={setUpdateErrorMessage}
                    setIsPackageDetailsDialogOpen={setIsPackageDetailsDialogOpen}
                />
            )}

            
      {getKOAndCustomerEmailHistory===true&&(<Dialog
    maxWidth='x-lg'
    key={row}
    open={getKOAndCustomerEmailHistory}
    sx={{justifyContent:"stretch"}}
    onClose={async(e)=>{
      handleClose(e)}}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description" >
    <DialogTitle id="alert-dialog-title">
    <Alert variant='filled' sx={{bgcolor:blueGrey[800],fontSize:'11px'}}>EMAIL HISTORY: {row.Opportunity_Name}</Alert>
    </DialogTitle>
    <DialogContent sx={{flexGrow:'inherit',justifyContent:'inherit'}} >
      <DialogContentText id="alert-dialog-description">

          {koCustomerEMailHistory.length!==0 && koCustomerEMailHistory?.map((P,index)=>{
          return(
            <>
<Paper elevation={3} sx={{ borderRadius: 1, padding: 1,border:1 }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>Date</Typography>
      <Typography variant="body1" sx={{ fontSize: '13px' }}>{String(P.Latest_Email_Date).split('T')[0]}</Typography>
    </div>
    <div>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>Quote No:</Typography>
      <Typography variant="body1" sx={{ fontSize: '13px' }}>{String(P.Quote_No)}</Typography>
    </div>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>Sent To</Typography>
      <Typography variant="body1" sx={{ fontSize: '13px' }}>{P.To_Name}<br />{P.To_Email}</Typography>
    </div>
    <div>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>Sent By</Typography>
      <Typography variant="body1" sx={{ fontSize: '13px' }}>{P.Email_SentBy_Name}<br />{P.Email_SentBy_Email}</Typography>
    </div>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>Body</Typography>
      <Typography variant="body1" sx={{ fontSize: '13px' }}><span style={{ whiteSpace: 'pre-wrap' }}>{P.Body}</span></Typography>
    </div>
  </div>
  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>ORDER DETAILS:</Typography>
  {P?.AttachSKUDataInTheEmailBody && (
      <div>
   
            <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
              <TableHead>
                <TableRow>
                    <TableCell sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}} align="center">Vendor/Partner</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}} align="center">SKU ID</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}} align="center">SKU Desc</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}} align="center">QTY</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}}align="center">Unit Cost($)</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}} align="center">Total Cost($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {JSON.parse(P?.SKU_DATA).map((skurow,index)=>{
        return(
          <>
          <TableRow>   
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">{skurow.Vendor_Partner_name}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">{skurow.SKU_ID}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">{skurow.SKU_Description}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">{skurow.QTY}</TableCell>
                      <TableCell  sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">{Number(skurow.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell  sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">{Number(skurow.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>              
          </TableRow>
        </>
        )
         })}
          <TableRow>   
                      <TableCell colSpan={5} sx={{border:1,borderColor:'text.primary',color:"black",backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'11px',fontWeight:'bold'}} align="center">SUB TOTAL</TableCell>
                      <TableCell  sx={{border:1,borderColor:'text.primary',fontSize:'11px'}} align="center">${JSON.parse(P?.SKU_DATA).reduce((total, current) => Number(total) + Number(current.Total_SKU_Cost), 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>              
          </TableRow>
              </TableBody>
            </Table>
              </div>
          )
          }
</Paper>

<br></br>
<Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
<br></br>
          </>
          )
           })}
    
      </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button onClick={async(e)=>{
      handleClose(e)}} sx={{width:"200px",backgroundColor:orange[600],color:"white",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:orange[400],color:'white'}}} >CLOSE</Button>
    </DialogActions>
  </Dialog>)}  
  

        <TableRow  sx={{ '& > *': { borderBottom: 'unset' }}}>
          <TableCell sx={{width:'15%'}} align="center" scope="row"><b  style={{color:'red'}}>{row?.Customer_Name}</b><br></br>
           Division: <b>{row?.Division}</b><br></br>
           Contact Vehicle: <b>{row?.Contract_Vehicle}</b><br/>
           Quote: <b>{row?.RFQQuoteNumber}</b><br></br><br></br>

           <Typography sx={{ fontSize: "12px", fontWeight: 'bold',padding:1,color:'white', fontFamily: 'Verdana (sans-serif)',backgroundColor:green[800]}} variant="success">{row?.RFQ_Status}</Typography>
           <Paper elevation={1} sx={{ borderRadius: 1, padding: 3,border:1}}>
    <Typography variant="body1" sx={{ fontSize: '12.5px', width:"100%",fontWeight: 'bold',textAlign:'center' }}>QUOTE DETAILS</Typography>
    <div style={{ display: 'flex', alignItems: 'center',  justifyContent: 'space-between', marginTop: '8px' }}> 
    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Created By</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{formatNameFromEmail(row?.Sales_Head_Email)}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><CalendarTodayIcon sx={{color:orange[900],height:'20px',width:'20px'}} s/> Created</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row?.Created_Date).split('T')[0]}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Updated By</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{formatNameFromEmail(row?.Updated_By)}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><CalendarTodayIcon sx={{color:orange[900],height:'20px',width:'20px'}} s/> Updated</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row?.Last_Updated_Date).split('T')[0]}</Typography>
  </div>
  {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}><HourglassTopIcon sx={{color:orange[900]}}/> Re-Open Count</Typography>
      <Typography variant="body1" sx={{ fontSize: '13px' }}>{String(row?.Reopen_Count === undefined || null ? 0 : row?.Reopen_Count)}</Typography>
  </div> */}
</Paper>
           </TableCell>

          <TableCell sx={{width:'15%'}} align="center" scope="row">
          <Paper elevation={1} sx={{ borderRadius: 1, padding: 2,border:1,borderColor:blueGrey[900]}}>
  <Typography variant="body1" sx={{ fontSize: '12.5px', width:"100%",fontWeight: 'bold',textAlign:'center' }}>KO DETAILS</Typography>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px',width:'100%' }}> 
    <PersonIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row?.RFQ?.[0]?.KO_name}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    <ContactMailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}} />
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row?.RFQ?.[0]?.KO_address}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
      <AlternateEmailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
        <Typography variant="body1" sx={{ fontSize: '12.5px',color:blue[900] }}>{row?.RFQ?.[0]?.KO_email}</Typography> <Tooltip title="Send Email">
          <IconButton
            variant="text"
            sx={{
              color: 'white',
              borderRadius: 1,
              transition: 'transform 0.2s ease-in-out',
              "&:hover": {
                backgroundColor: indigo[100],
                transform: 'scale(1.1)',
              },
            }}
            onClick={(e) => 
              { 
                handleOpenKOorCustomerEmailOpenDialog(e)
                setToEmail(row?.RFQ?.[0]?.KO_email)  
              }
            
            }
          >
            <Avatar
              sx={{
                backgroundColor: indigo[800],
                width: "25px",
                height: "25px",
                transition: 'transform 0.2s ease-in-out',
                "&:hover": {
                  backgroundColor: indigo[800],
                  transform: 'scale(1.1)',
                },
              }}
              variant='rounded'
            >
              <EmailIcon sx={{ color: "white", backgroundColor: indigo[800], width: "20px", height: "20px" }} />
            </Avatar>
          </IconButton>
        </Tooltip>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    <PhoneIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row?.RFQ?.[0]?.KO_phone}</Typography>
  </div>
</Paper>
<Paper elevation={1} sx={{ borderRadius: 1, padding: 2,border:1,borderColor:blueGrey[900]}}>
<Typography variant="body1" sx={{ fontSize: '12.5px', width:"100%",fontWeight: 'bold',textAlign:'center' }}>CUSTOMER DETAILS</Typography>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px',width:'100%' }}> 
    <PersonIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row?.RFQ?.[0]?.Customer_Name}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    <ContactMailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}} />
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row?.RFQ?.[0]?.Customer_Address}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
      <AlternateEmailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
        <Typography variant="body1" sx={{ fontSize: '12.5px',color:blue[900] }}>{row?.RFQ?.[0]?.Customer_Email}</Typography> <Tooltip title="Send Email">
          <IconButton
            variant="text"
            sx={{
              color: 'white',
              borderRadius: 1,
              transition: 'transform 0.2s ease-in-out',
              "&:hover": {
                backgroundColor: indigo[100],
                transform: 'scale(1.1)',
              },
            }}
            onClick={(e) =>{
              setToEmail(row?.RFQ?.[0]?.Customer_Email)
              handleOpenKOorCustomerEmailOpenDialog(e)
            
            }}
          > 
            <Avatar
              sx={{
                backgroundColor: indigo[800],
                width: "25px",
                height: "25px",
                transition: 'transform 0.2s ease-in-out',
                "&:hover": {
                  backgroundColor: indigo[800],
                  transform: 'scale(1.1)',
                },
              }}
              variant='rounded'
            >
              <EmailIcon sx={{ color: "white", backgroundColor: indigo[800], width: "20px", height: "20px" }} />
            </Avatar>
          </IconButton>
        </Tooltip>  
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    <PhoneIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row?.RFQ?.[0]?.Customer_Phone}</Typography>
  </div>
</Paper>
            </TableCell>     
          <TableCell sx={{}} align="center"> 
   

    <Paper elevation={1} sx={{ borderRadius: 1, padding: 2,border:1}}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><ShopIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Purchase Order#</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row?.Purchase_Order}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><ReceiptIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Invoice#</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row?.Invoice_Number}</Typography>
    </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><CalendarTodayIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Payment Date</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row?.Payment_Date).split('T')[0]}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><CalendarTodayIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Invoice Date</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row?.Invoice_Date).split('T')[0]}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><ShippingIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Actual Delivery</Typography>
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row?.Actual_Delivery).split('T')[0]}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> 
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}><ShippingIcon sx={{color:orange[900],height:'20px',width:'20px',}}/> Expected Delivery</Typography>
   
      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row?.Expected_Delivery)}</Typography>
  </div>
  <br></br>  
  <Stack sx={{}} justifyContent='center' direction='row' spacing={2}> 
  <Tooltip title='Update'>
    {row?.RFQ_Status === 'LOST' ? (
      <IconButton sx={{ width: "120px", height: "30px" }} size='small' type="submit" variant="contained" onClick={(e) => {
        setUpdateErrorMessage("Editing this contract is not possible since it is currently in the 'LOST' status. To make edits, navigate to the RFQs Search page and update the status.")
        setUpdateerror(true)
      }}>
        <Avatar sx={{ backgroundColor: red[800], width: "120px", fontSize: '12px', height: "30px" }} size='small' variant='rounded'>LOST</Avatar>
      </IconButton>
    ) : (
      <IconButton sx={{ width: "80px", height: "25px",
      transition: 'transform 0.2s ease-in-out',
       }} type="submit" variant="outlined" size='small' onClick={(e,row) => {
        e.preventDefault(); 
        setMasterLifeCycleRowData(row)
        updateDialogOpen(e)
    
      }}>
        <Avatar sx={{ 
          backgroundColor: orange[800], 
          width: "80px", fontSize: '12.5px', height: "25px",
          transition: 'transform 0.2s ease-in-out',
         }} variant='rounded'>
          <ModeEditIcon sx={{ width: "17px", height: "17px", }} />
          {row?.Status === "ORDER COMPLETE" ? " Re-Open" : " Edit"}
        </Avatar>
      </IconButton>
    )}
  </Tooltip>


  </Stack>
</Paper>
</TableCell>

  <TableCell sx={{}}align="center">{'$'+Number(row?.Aspetto_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
    <TableCell align="center" sx={{padding: "10px",alignContent:"center",justifyContent:"center"}}>
    <Paper  sx={{ borderRadius: 1, padding: 3,border:1}}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div>
    <FilteredSteps 
      userDefinedStages={userDefinedStages}
      row={row}/>
    </div>
  </div>
</Paper>
      <br></br>
  {row?.Already_Paid ?<Stack sx={{backgroundColor:yellow[700],border:1,borderColor:'black'}}>Already Paid: YES</Stack>:''} <br></br>
  {row?.Add_Comment_Checked?<Stack sx={{backgroundColor:blue[500],border:1}}>{row?.Add_Comment}</Stack>:''}
  <br></br>
     {/* Tooltip and IconButton for Packing Slip */}
     <Tooltip title='Update Packing Slip Details'>
        {row?.RFQ_Status === 'LOST' ? (
          <IconButton
            sx={{ width: "120px", height: "30px" }}
            size='small'
            type="submit"
            variant="contained"
            onClick={() => {
              setUpdateErrorMessage("Editing this contract is not possible since it is currently in the 'LOST' status. To make edits, navigate to the RFQs Search page and update the status.");
              setUpdateerror(true);
            }}
          >
            <Avatar
              sx={{ backgroundColor: red[800], width: "120px", fontSize: '12px', height: "30px" }}
              size='small'
              variant='rounded'
            >
              LOST
            </Avatar>
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "120px",
              height: "25px",
              fontSize: '12.5px',
              transition: 'transform 0.2s ease-in-out',
            }}
            type="submit"
            variant="outlined"
            size='small'
            onClick={handleOpenPackingSlipDialog}
          >
            <Avatar
              sx={{
                backgroundColor: orange[800],
                width: "120px",
                fontSize: '12.5px',
                height: "25px",
                transition: 'transform 0.2s ease-in-out',
              }}
              variant='rounded'
            >
              <ReceiptIcon sx={{ width: "17px", height: "17px" }} />
              Packing Slip
            </Avatar>
          </IconButton>
        )}
      </Tooltip>
         <Tooltip title="Get Life Cyle History">
         <IconButton name="Get Full History" variant='contained' color='info'  onClick={(e)=>{handleFullHistory(e)}}
          sx={{color:'white', padding:'8px' ,borderRadius: 1,transition: 'transform 0.2s ease-in-out', }}> 
         <Avatar variant="rounded" 
         sx={{
                backgroundColor: indigo[800],
                width: "25px",
                height: "25px",
                transition: 'transform 0.2s ease-in-out',
              }}>
                <InfoIcon sx={{ color: "white", backgroundColor: indigo[800], width: "20px", height: "20px" }}/></Avatar> </IconButton>
         </Tooltip>



      <Tooltip title="View Quote"><IconButton variant="text" sx={{color:'white', padding:'8px' ,borderRadius: 1,transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
}} onClick={(e)=>{
          e.preventDefault();
          RFQID_Search()}}>

      <Avatar sx={{ 
        backgroundColor:indigo[800],
        width:"25px",height:"25px",transition: 'transform 0.2s ease-in-out', }} variant='rounded'>
      <VisibilityIcon sx={{color:"white",backgroundColor:indigo[800],width:"25px",height:"25px" }} /></Avatar></IconButton></Tooltip>

    {/* <Tooltip title="Download Packing Slip">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: orange[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); HandleGeneratePackingSlipClick(row);}}>
                <Avatar sx={{ backgroundColor: orange[800], width: '30px', height: '30px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: orange[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <ReceiptIcon sx={{ color: 'white', backgroundColor: orange[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip> */}

        <Tooltip title="Generate PDF">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out',}} onClick={(e) => { e.preventDefault(); HandlePDFClick(row) }}>
                <Avatar sx={{ backgroundColor: red[800], width: '25px', height: '25px', 
                  transition: 'transform 0.2s ease-in-out', }} variant="rounded">
                  <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>

  <br></br>
</TableCell>
        </TableRow>
      </React.Fragment>
      </DndProvider>
    );
  }

  export default MasterTableHelper