import React, { Component } from 'react';
import ApplicationBar from './AppBar/ApplicationBar';
import DHSTrackerExcelUploader from './DHSTracker/ExcelUpload'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, info) {
    console.error("ErrorBoundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ApplicationBar />
          <div style={{ padding: '20px' }}>
           <DHSTrackerExcelUploader/>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
