import React, { useState } from 'react';
import {
  TableRow, TableCell, Paper, Grid, Typography, IconButton, Avatar, Tooltip,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert,TextField,Stack,Snackbar
} from '@mui/material';
import { blueGrey, cyan, indigo, orange, red } from '@mui/material/colors';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DescriptionIcon from '@mui/icons-material/Description';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EmailIcon from '@mui/icons-material/Email';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ComputerIcon from '@mui/icons-material/Computer';
import WorkIcon from '@mui/icons-material/Work';
import InventoryIcon from '@mui/icons-material/Inventory';
import CommentIcon from '@mui/icons-material/Comment';
import ConversationDialog from '../Comments/Conversation';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import AxiosFunction from '../../axiosCustomInstance';
import NewDHSTrackerRow from './NewDHSTrackerRow';
import GeneralEmailDialog from '../Email/KOorCustomerGeneralEmail';

const StyledTableRowComponent = ({ row,index,onUpdateRow,refreshRowData,usersData}) => {
  const [error, setError] = useState(null); // Track error state
  const [commentsOpen, setCommentsOpen] = useState(false);
  const conversationId = row.trackerID; // Replace with the actual conversation ID
  const title = row.contractType+'-'+'Tracker ID:'+row.trackerID; // Replace with the actual title
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [formData, setFormData] = useState(row);  // Start with current row data
  const [loading, setLoading] = useState(false);
  const AxiosAPIInstance = AxiosFunction();
  const [isKOorCustomerGeneralDialogOpen, setIsKOorCustomerGeneralDialogOpen] = React.useState(false);
  const [toEmail, setToEmail] = React.useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState('success');
  const defaultSubject=`${row?.contractType}----(Submitted By:${row?.submittedBy})---Order Number: ${row.orderNumber}`
  const DialogBoxTitle=`${row?.contractType}----(Submitted By:${row?.submittedBy})---Order Number: ${row.orderNumber}`
  const DialogBoxTitleID=row.RMA_ID
 // Function to handle the dialog success event
 const handleKoOrCustomerGeneralEmailSuccess = () => {
  setAlertMessage('Email sent successfully');
  setAlertSeverity('success');
  setIsSnackbarOpen(true);
  setIsKOorCustomerGeneralDialogOpen(false);
};

// Function to handle dialog errors
const handleDialogError = (error) => {
  setAlertMessage(`Error: ${error.message}`);
  setAlertSeverity('error');
  setIsSnackbarOpen(true);
};

// Function to open the KO or Customer Email dialog
const handleOpenKOorCustomerGeneralEmailOpenDialog = (e) => {
  e.preventDefault();
  console.log("Opening dialog..."); // Debug log
  setIsKOorCustomerGeneralDialogOpen(true);
};

// Function to close the Snackbar
const handleSnackbarClose = () => {
  setIsSnackbarOpen(false);
};
// Open and close the edit dialog
const handleEditOpen = () => setOpenEditDialog(true);
const handleEditClose = () => setOpenEditDialog(false);

// Function to handle form submission and update the record
const handleUpdate = async (updatedData) => {
  setLoading(true);
  try {
    const response = await AxiosAPIInstance.patch('/Tracker/DHS/update', updatedData);
    if (response.status === 200) {
      // Update the current row immediately in the table
      onUpdateRow(index, response.data);

      // Refresh the entire table data from the server
      await refreshRowData();

      handleEditClose();  // Close dialog on successful update
    }
  } catch (error) {
    console.error("Failed to update record:", error);
  } finally {
    setLoading(false);
  }
};

  // Handle error dialog close
  const handleCloseErrorDialog = () => {
    setError(null);
  };
  const handleCommentsOpen = () => {
    setCommentsOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsOpen(false);
  };
  // Wrap risky code in a try-catch block to catch any rendering errors
  try {
    return (
      <>
            {isKOorCustomerGeneralDialogOpen && (
        <>
          {console.log("Dialog is rendering...")} {/* Debug log */}
          <GeneralEmailDialog
            row={row}
            onSuccess={handleKoOrCustomerGeneralEmailSuccess}
            onError={handleDialogError}
            usersData={usersData}
            toEmail={toEmail}
            loading={loading}
            setLoading={setLoading}
            setIsKOorCustomerGeneralDialogOpen={setIsKOorCustomerGeneralDialogOpen}
            isKOorCustomerGeneralDialogOpen={isKOorCustomerGeneralDialogOpen}
            defaultSubject={defaultSubject}
            DialogBoxTitle={DialogBoxTitle}
            DialogBoxTitleID={DialogBoxTitleID}
          />
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleSnackbarClose} severity={alertSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
        </>
      )}
        <TableRow>
          <TableCell>
            <Paper elevation={1} sx={{ borderRadius: 1, padding: 2, borderColor: blueGrey[900] }}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Submitted By</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.submittedBy}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ReceiptIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Order Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.orderNumber}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ComputerIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>System</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.system}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <WorkIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Position</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.position}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InventoryIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Qty</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.qty}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </TableCell>

          <TableCell>{String(row.orderDate).split('T')[0]}</TableCell>
          <TableCell>{String(row.dueDate).split('T')[0]}</TableCell>
          <TableCell>{String(row.shipped).split('T')[0]}</TableCell>

          <TableCell>
            <Paper elevation={1} sx={{ borderRadius: 1, padding: 2, borderColor: blueGrey[900] }}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Days to Ship</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.daysToShip}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <EventAvailableIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Shipment Booked</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.shipmentBooked? 'Yes' : row.shipmentBooked==='NA'?'NA':'No'}</Typography>  
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <EmailIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Order Shipment Email Sent</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.orderShipmentEmailSent? 'Yes' : row.orderShipmentEmailSent==='NA'?'NA':'No'}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <EmailIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Invoice Request Sent</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.invoiceRequestSent? 'Yes' : row.invoiceRequestSent==='NA'?'NA':'No'}</Typography>
                </Grid>
              </Grid>

            </Paper>
          </TableCell>

          <TableCell>{row.poNumber}</TableCell>
          <TableCell>{String(row.poDate).split('T')[0]}</TableCell>
          <TableCell>{String(row.poDueDate).split('T')[0]}</TableCell>
          <TableCell>{String(row.shippedToSLW).split('T')[0]}</TableCell>
          <TableCell>
            <Paper elevation={1} sx={{ borderRadius: 1, padding: 2, borderColor: blueGrey[900] }}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <UploadFileIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Order Uploaded</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.orderUploaded? 'Yes' : row.orderUploaded==='NA'?'NA':'No'}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ConfirmationNumberIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Serial Numbers Received</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.serialNumbersReceived? 'Yes' : row.serialNumbersReceived==='NA'?'NA':'No'}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <DescriptionIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Contract Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.contractType}</Typography>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ScheduleIcon sx={{ marginRight: 1, color: orange[800] }} />
                  <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Processing Days</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontSize: '12px', textAlign: 'right' }}>{row.processingDays}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </TableCell>

          <TableCell>
          <Tooltip title="Add Comments">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1,fontSize:'12px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={handleCommentsOpen}>
                
                <Avatar sx={{ backgroundColor: red[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', fontSize:'12px',":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                {/* Add Comments */}
                <CommentIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} /> 
                
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1,fontSize:'12px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }}  onClick={handleEditOpen}>
                
                <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', fontSize:'12px',":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                {/* Add Comments */}
                <EditIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} /> 
                
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Email Customer">
                <IconButton variant="text" sx={{ color: 'white', borderRadius: 1, transition: 'transform 0.2s ease-in-out',
           
                  }} 
                           onClick={(e) => 
                            { 
                              handleOpenKOorCustomerGeneralEmailOpenDialog(e)
                              setToEmail('')  
                            }
                          }>
                  <Avatar  sx={{
                  backgroundColor: cyan[800],
                  ":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: cyan[700],
                  },
                  width: "80px",
                  fontSize: '12.5px',
                  height: '25px'
                }} variant="rounded">
                    <EmailIcon sx={{ color: 'white', backgroundColor: cyan[800], width: '20px', height: '20px',":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: cyan[700],
                  }}} />Email
                  </Avatar>
                </IconButton>
              </Tooltip>
          <ConversationDialog
           open={commentsOpen}
           onClose={handleCommentsClose}
           conversationId={conversationId}
           title={title}
           />
          </TableCell>
        </TableRow>

        {/* Error dialog */}
        <Dialog open={!!error} onClose={handleCloseErrorDialog}>
          <DialogTitle>
            <Alert severity="error">Error</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{error}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>OK</Button>
          </DialogActions>
        </Dialog>
              {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleEditClose} fullWidth  maxWidth='lg'
    sx={{justifyContent:"stretch",marginTop:'20px'}}    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description" >
    <DialogTitle id="alert-dialog-title">
    <Alert variant='filled' sx={{bgcolor:orange[200],color:blueGrey[900]}}>{row.orderNumber+' (Submitted By:'+ row?.submittedBy+')'}</Alert>
    </DialogTitle>
        <DialogContent  sx={{flexGrow:'inherit',justifyContent:'inherit'}}>
          <NewDHSTrackerRow
          
            initialData={row}
            onSubmit={handleUpdate}
            buttonText="Update"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>

      </>
    );
  } catch (err) {
    setError("An error occurred while rendering the table row. Please try again.");
    console.error("Error rendering table row:", err); // Log the error for debugging
    return null;
  }
};

export default StyledTableRowComponent;
