import React, { useState, useEffect } from 'react';
import {
  Container, Box, Paper, Typography, Divider, Grid, IconButton, TextField, Alert,
  Toolbar, Tooltip, Dialog, DialogTitle, DialogContent,Stack,
  DialogActions, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { blue, blueGrey, green, orange, red, yellow } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import BugReportIcon from '@mui/icons-material/BugReport';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import HistoryIcon from '@mui/icons-material/History';
import CommentIcon from '@mui/icons-material/Comment';
import { Stepper, Step, StepLabel } from '@mui/material';
import moment from 'moment';
import AxiosFunction from '../../axiosCustomInstance';
import LoadingDialog from '../Loading';
import ApplicationBar from '../AppBar/ApplicationBar';
import UserAuth from '../ProtectedRoute/userAuth';
import ConversationDialog from '../Comments/Conversation';
import { styled } from '@mui/material/styles';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { selectIsAdmin, selectUserRole } from "../Login_Registration/authSlice";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import UpdateBugDialog from './Dialogs/UpdateDialog'; // Import the dialog component
import formatNameFromEmail from '../HelperFunctions/EmaiToNameHelper';

const stages = {
  open: { label: 'Open', color: blue[700], icon: <BugReportIcon /> },
  inprogress: { label: 'InProgress', color: orange[700], icon: <HourglassTopIcon /> },
  resolved: { label: 'Resolved', color: green[700], icon: <HourglassBottomIcon /> },
  closed: { label: 'Closed', color: red[700], icon: <CheckCircleIcon /> }
};

const priorities = {
  low: { label: 'Low', color: blue[700], icon: <LowPriorityIcon /> },
  medium: { label: 'Medium', color: yellow[700], icon: <WarningIcon /> },
  high: { label: 'High', color: orange[700], icon: <PriorityHighIcon /> },
  critical: { label: 'Critical', color: red[900], icon: <ErrorIcon /> }
};

const BugList = () => {
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedBug, setSelectedBug] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filterStage, setFilterStage] = useState('');
  const [filterPriority, setFilterPriority] = useState('');
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(''); 
  const [title, setTitle] = useState('New Title' + new Date()); 
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectUserRole);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const AxiosAPIInstance = AxiosFunction();
  const { UserName, UserEmail } = UserAuth();

  useEffect(() => {
    fetchBugs();
  }, []);

  const fetchBugs = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await AxiosAPIInstance.get('/Bugs/BugSearch');
      console.log('Fetched bugs:', response.data.bugs); 
      setBugs(response.data.bugs); 
    } catch (error) {
      setErrorMessage('Error fetching bugs');
      console.error('Error fetching bugs', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCommentsOpen = (bug) => {
    setCommentsOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsOpen(false);
    setTitle('');
    setConversationId('');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleHistoryDialogOpen = (bug) => {
    setSelectedBug(bug);
    setHistoryDialogOpen(true);
  };

  const handleHistoryDialogClose = () => {
    setHistoryDialogOpen(false);
    setSelectedBug(null);
  };

  const handleStageFilterChange = (event) => {
    setFilterStage(event.target.value);
    setPage(0);
  };

  const handlePriorityFilterChange = (event) => {
    setFilterPriority(event.target.value);
    setPage(0);
  };

  const filteredBugs = bugs.filter((bug) => {
    const matchesSearchTerm =
      (bug.Title && bug.Title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Description && bug.Description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Resolution_Stage && bug.Resolution_Stage.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Priority && bug.Priority.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Created_date && new Date(bug.Created_date).toLocaleString().toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Updated_date && new Date(bug.Updated_date).toLocaleString().toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Updated_By && bug.Updated_By.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (bug.Created_By && bug.Created_By.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesStageFilter = filterStage ? bug.Resolution_Stage?.toLowerCase() === filterStage.toLowerCase() : true;
    const matchesPriorityFilter = filterPriority ? bug.Priority?.toLowerCase() === filterPriority.toLowerCase() : true;

    return matchesSearchTerm && matchesStageFilter && matchesPriorityFilter;
  });

  const sortedBugs = filteredBugs.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const parseUpdateHistory = (history) => {
    try {
      const parsedHistory = JSON.parse(history || '[]');
      if (!Array.isArray(parsedHistory)) {
        console.error('Parsed history is not an array:', parsedHistory);
        return [];
      }
      return parsedHistory;
    } catch (error) {
      console.error('Failed to parse history:', error);
      return [];
    }
  };

  const updateBugStatus = async (bugId, newStatus, closingComments = '') => {
    setLoading(true);
    setErrorMessage('');
  
    try {
      const requestBody = {
        Bug_REQ_ID: bugId,
        status: newStatus,
        updatedBy: UserEmail,
        closingComments: newStatus.toLowerCase() === 'closed' ? closingComments : ''
      };
      console.log(bugId)
      const response = await AxiosAPIInstance.patch('/Bugs/UpdateStatus', requestBody);
  
      if (response.status === 200) {
        setBugs((prevBugs) =>
          prevBugs.map((bug) =>
            bug.Bug_REQ_ID === bugId ? { ...bug, Resolution_Stage: newStatus, Closing_Comments: closingComments } : bug
          )
        );
        setErrorMessage('Bug status updated successfully.');
      } else {
        throw new Error('Failed to update bug status');
      }
    } catch (error) {
      console.error('Error updating bug status:', error);
      setErrorMessage('An error occurred while updating the bug status. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDialogOpen = (bug) => {
    setSelectedBug(bug);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
    setSelectedBug(null);
  };
  
  const renderStageWithStepper = (stage) => {
    const steps = ['Open', 'InProgress', 'Resolved', 'Closed'];
    const activeStep = steps.indexOf(stage);
  
    const stages = {
      open: { label: 'Open', color: blue[700], icon: <BugReportIcon /> },
      inprogress: { label: 'InProgress', color: orange[700], icon: <HourglassTopIcon /> },
      resolved: { label: 'Resolved', color: green[700], icon: <HourglassBottomIcon /> },
      closed: { label: 'Closed', color: red[700], icon: <CheckCircleIcon /> }
    };
  
    const CustomStepIcon = styled('div')(({ theme, active, completed }) => ({
      backgroundColor: active || completed ? orange[300] : 'rgba(0, 0, 0, 0.26)',
      color: blueGrey[900],
      display: 'flex',
      padding:4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: 24,
      height: 24,
      fontSize: 12,
    }));
  
    return (
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stageKey = label.toLowerCase().replace(' ', '');
          const stageIcon = stages[stageKey]?.icon;
  
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props}>
                    {stageIcon} {/* Render the icon */}
                  </CustomStepIcon>
                )}
                sx={{
                  color: 'white',
                  padding: '20px',
                  height: '30px',
                  borderRadius: '5px',
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };
  return (
    <>
      <ApplicationBar />
      {/* Update dialog */}
      {selectedBug && (
        <UpdateBugDialog
          open={updateDialogOpen}
          onClose={handleUpdateDialogClose}
          bug={selectedBug}
          onUpdate={updateBugStatus}
        />
      )}
      <Container maxWidth="x-lg" sx={{ marginTop: '80px' }}>
        <Box sx={{ mt: 4 }}>
          {/* <Paper elevation={3} sx={{ p: 4, backgroundColor: '#f9f9f9', margin: '0 0px' }}> */}
          <Stack justifyContent="center">
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana, sans-serif', marginTop: '0px' }} >
              Bug List
            </Typography>
          </Stack>

            <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px", marginTop: '20px' }} orientation='horizontal' />
            {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <TextField size='small'
                label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                sx={{ width: '40%' }}
              />
              <FormControl sx={{ width: '20%' }} size='small'>
                <InputLabel id="filter-stage-label">Filter by Stage</InputLabel>
                <Select 
                  labelId="filter-stage-label"
                  value={filterStage}
                  onChange={handleStageFilterChange}
                  label="Filter by Stage"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {Object.keys(stages).map((stageKey) => (
                    <MenuItem key={stageKey} value={stages[stageKey].label}>{stages[stageKey].label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '20%' }} size='small'>
                <InputLabel id="filter-priority-label">Filter by Priority</InputLabel>
                <Select
                  labelId="filter-priority-label"
                  value={filterPriority}
                  onChange={handlePriorityFilterChange}
                  label="Filter by Priority"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {Object.keys(priorities).map((priorityKey) => (
                    <MenuItem key={priorityKey} value={priorities[priorityKey].label}>{priorities[priorityKey].label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Toolbar>
            <TableContainer component={Paper} sx={{ marginTop: '20px', margin: '0 20px' }}>
              <Table sx={{ width:'100%' }}>
                <TableHead sx={{ bgcolor: blueGrey[900], alignContent: 'center',color:'whitesmoke' }}>
                  <TableRow>
                    <TableCell sx={{ color:'whitesmoke' ,width:'5%',fontWeight:'bold' }}>
                      <TableSortLabel
                        active={sortConfig.key === 'Title'}
                        direction={sortConfig.key === 'Title' ? sortConfig.direction : 'asc'}
                        onClick={() => handleSortChange('Title')}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color:'whitesmoke' ,width:'25%',fontWeight:'bold' }}>
                      <TableSortLabel
                        active={sortConfig.key === 'Resolution_Stage'}
                        direction={sortConfig.key === 'Resolution_Stage' ? sortConfig.direction : 'asc'}
                        onClick={() => handleSortChange('Resolution_Stage')}
                      >
                        Stage
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color:'whitesmoke' ,width:'5%',fontWeight:'bold' }}>
                      <TableSortLabel
                        active={sortConfig.key === 'Priority'}
                        direction={sortConfig.key === 'Priority' ? sortConfig.direction : 'asc'}
                        onClick={() => handleSortChange('Priority')}
                      >
                        Priority
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color:'whitesmoke',width:'5%',fontWeight:'bold' }}>
                      <TableSortLabel
                        active={sortConfig.key === 'Created_date'}
                        direction={sortConfig.key === 'Created_date' ? sortConfig.direction : 'asc'}
                        onClick={() => handleSortChange('Created_date')}
                      >
                        Created Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color:'whitesmoke' ,width:'10%',fontWeight:'bold'}}>Description</TableCell>
                    <TableCell sx={{ color:'whitesmoke' ,width:'5%' ,fontWeight:'bold'}}>Created By</TableCell>
                    <TableCell sx={{ color:'whitesmoke' ,width:'20%',fontWeight:'bold'}}>Steps to Reproduce</TableCell>
                    <TableCell sx={{ color:'whitesmoke' ,width:'5%' ,fontWeight:'bold'}}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedBugs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bug) => (
                    <TableRow key={bug.Bug_REQ_ID}>
                      <TableCell sx={{width:'5%'}}>{bug.Title}</TableCell>
                      <TableCell sx={{width:'25%'}}>{renderStageWithStepper(bug.Resolution_Stage)}</TableCell>
                      <TableCell sx={{width:'5%' }}>{priorities[bug.Priority?.toLowerCase()]?.label}</TableCell>
                      <TableCell sx={{width:'5%' }}>{moment(bug.Created_date).format('YYYY-MM-DD')}</TableCell>
                      <TableCell sx={{width:'10%'}}>{bug.Description}</TableCell>
                      <TableCell sx={{width:'5%'}}>{formatNameFromEmail(bug.Created_By_Email)}</TableCell>
                      <TableCell sx={{width:'20%'}}>{bug.Steps_To_Produce}</TableCell>
                      <TableCell sx={{width:'5%'}}>
                        <Tooltip title="Resolution Progress Comments">
                          <IconButton onClick={() => {
                            setTitle(bug?.Title);
                            setConversationId(bug?.Bug_REQ_ID);
                            handleCommentsOpen();
                          }}>
                            <CommentIcon sx={{ color: red[700] }} />
                          </IconButton>
                        </Tooltip>
                        {isAdmin && (<Tooltip title="Edit">
                     {/* Render bug details */}
                       <IconButton onClick={() => handleUpdateDialogOpen(bug)}>
                      <EditIcon />
                      </IconButton>
                       </Tooltip>)}
          
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={sortedBugs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          {/* </Paper> */}
        </Box>
      </Container>
      {loading && <LoadingDialog />}

      <Dialog open={historyDialogOpen} onClose={handleHistoryDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Update History</DialogTitle>
        <DialogContent>
          <List>
            {selectedBug && parseUpdateHistory(selectedBug.Update_History).length > 0 ? (
              parseUpdateHistory(selectedBug.Update_History).map((history, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar>{history.updatedBy && history.updatedBy[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={history.updatedBy}
                    secondary={
                      <>
                        <Typography variant="body2" component="span">
                          {new Date(history.updatedDate).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" color="textPrimary" component="p">
                          {history.message}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Typography>No update history available</Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHistoryDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <ConversationDialog
        open={commentsOpen}
        onClose={handleCommentsClose}
        conversationId={conversationId}
        title={title}
      />
    </>
  );
};

export default BugList;
