import {Button,TextField,Paper, Stack,Typography,Tooltip,IconButton,Avatar,Grid,Divider,Box,Tab,Tabs} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState,useEffect} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import { PDFDownloadLink} from '@react-pdf/renderer';
import { DatePicker } from "@mui/x-date-pickers";
import PdfDocument from "../PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey,red,green,indigo, lime, orange,teal,cyan, blue} from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import { Form, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import ApplicationBar from "../AppBar/ApplicationBar";
import UserAuth from '../ProtectedRoute/userAuth';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { color } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import AxiosFunction from "../../axiosCustomInstance"
import isEqual from 'lodash/isEqual';
import LoadingDialog from '../Loading'
import DHS_SKUs_ARRAY from '../SKUs/DHS_SKUs_ARRAY';
import FBOP_SKUs_ARRAY from '../SKUs/FBOP_SKUs_ARRAY';
import { isWithinInterval, format } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import GenerateInvoicePDF from '../PDF/PDFStyle2';
import SwipeableViews from 'react-swipeable-views';

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});


function  RFQ_Copy()
{

  const PD = new Date(); // Creating a new Date object to get current date and time
  const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD); // Formatting today's date
  
  const { UserName, UserEmail } = UserAuth(); // Destructuring user authentication data
  const AxiosAPIInstance = AxiosFunction(); // Creating an Axios instance for API calls
  
  const formRef = React.useRef(); // Creating a reference for the form element
  
  // Arrays containing DHS and FBOP SKUs
  var DHSSKUsARRAY = DHS_SKUs_ARRAY();
  var FBOPSKUsARRAY = FBOP_SKUs_ARRAY();
  
  //----------------------------------------------------------------------------------------------
  // State for holding FBOP SKUs array
  var [SKUIDs, setSKUIDs] = useState(FBOPSKUsARRAY);
  // State for holding DHS SKUs array
  var [DHSSKUIDs, setDHSSKUIDs] = useState(DHSSKUsARRAY);
  //---------------------------------------------------------------------------------------------------------
  
  // State for toggling other cost 1 option
  const [otherCost1, setOtherCost1] = useState(false);
  // State for toggling other cost 2 option
  const [otherCost2, setOtherCost2] = useState(false);
  // State for toggling other cost 3 option
  const [otherCost3, setOtherCost3] = useState(false);
  
  // State for holding filtered DHS SKUs based on search input
  const [filteredDHSSKUIDs, setFilteredDHSSKUIDs] = useState([]);
  // State for holding search input value
  const [searchInput, setSearchInput] = useState('');
  // State for controlling visibility of SKU dialog
  const [skusDialog, setSKUsDialog] = useState(false);
  // State for controlling visibility of "Get SKUs" button click
  const [getSKUClicked, setGetSKUClicked] = useState(false);
  // State for holding retrieved SKUs
  const [getSKUs, setGetSKUs] = useState([]);


  React.useEffect(() => {
    // Filter DHS SKUs based on search input
    const filteredSKUs = DHSSKUIDs.filter((SKU) => {
      const searchTerm = searchInput.toLowerCase(); // Convert search input to lowercase for case-insensitive matching
      // Check if SKU ID or description contains search term
      return (
        SKU.ID.toLowerCase().includes(searchTerm) ||
        SKU.Desc.toLowerCase().includes(searchTerm)
      );
    });
    // Update filtered DHS SKUs state
    setFilteredDHSSKUIDs(filteredSKUs);
  }, [searchInput, DHSSKUIDs]); // Trigger effect whenever search input or DHS SKUs array changes
  
  // Function to handle search input change
  const handleSearchSKU = (input) => {
    setSearchInput(input); // Set search input state to the input value
  };
  
  const today1 = new Date(); // Creating a new Date object to get current date and time
  

const dateRanges = [
  { start: new Date('06/09/23'), end: new Date('06/08/24'), year: 'Year1' },
  { start: new Date('06/09/24'), end: new Date('06/08/25'), year: 'Year2' },
  { start: new Date('06/09/25'), end: new Date('06/08/26'), year: 'Year3' },
  { start: new Date('06/09/26'), end: new Date('06/08/27'), year: 'Year4' },
  { start: new Date('06/09/27'), end: new Date('06/08/28'), year: 'Year5' },
  { start: new Date('06/09/28'), end: new Date('06/08/29'), year: 'Year6' },
  { start: new Date('06/09/29'), end: new Date('06/08/30'), year: 'Year7' },
];

//---------------------------Defining Form State Variable--------------------------------------
const [Form_Value, Set_Form_Value] = useState({
  Opportunity_Name: "",
  RFQ_ID: '',
  Contract_Vehicle: 'GSA',
  KO_name: '',
  KO_email: '',
  Notes_For_PO: "",
  Valid_For: "30 Days",
  KO_phone: '',
  KO_address: '',
  Notes_For_KO:'',
  Published_date: null,
  Due_date: null,
  Brief: 'N/A',
  Organization_Name: '',
  Customer_Name: '',
  Customer_Email: '',
  Customer_Phone: '',
  Customer_Address: '',
  Shipping_Method: 'N/A',
  Sales_Head_Name: '',
  Sales_Head_Email: '',
  Sales_Head_Ph: "540-547-8487",
  Shipping_Terms: 'FOB Origin',
  Estimated_Delivery: '45 Days ARO',
  Payment_Terms: 'NET30',
  Notes: 'N/A',
  Created_Date: '',
  Shipping_Cost: parseFloat(0).toFixed(2),
  Tax: parseFloat(0).toFixed(2),
  Other_Cost_1: parseFloat(0).toFixed(2),
  Other_Cost_2: parseFloat(0).toFixed(2),
  Other_Cost_3: parseFloat(0).toFixed(2),
  Other_Cost_Name1: "NA",
  Other_Cost_Name2: "NA",
  Other_Cost_Name3: "NA",
  Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
  RFQ_Status: 'PENDING',
  Working_Sales_Head: String(UserEmail).toLowerCase(),
  Updated_By: String(UserEmail).toLowerCase(),
  Version_No: parseInt(0),
  Updating_Comments: 'N/A'
});

//------------Defining SKU State Variable------------------------------------------------------
const [New_SKUT, Set_New_SKU] = useState([{
  Vendor_Partner_name: '',
  SKU_ID: '',
  SKU_Description: '',
  QTY: parseInt(0),
  Aspetto_Unit_Cost: parseFloat(0).toFixed(2),
  Unit_Of_Measure: 'ea',
  RFQRFQID: '',
  Margin_Percent: parseFloat(0).toFixed(2),
  Customer_Unit_Cost: parseFloat(0).toFixed(2),
  Total_SKU_Cost: parseFloat(0).toFixed(2),
  RFQ_Created_Date: Form_Value.Created_Date,
  RFQ_Last_Updated_Date: Form_Value.Last_Updated_Date,
  SKU_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
  SKU_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
  SKU_Created_By: 'TBD',
  SKU_Updated_By: 'TBD',
  Version_No: Form_Value.Version_No
}]);

//----------------------Defining Cost State Variable------------------------------------------
const [TotalCost,SetTotalCost]=useState({Actual_Total_Cost_Before_Margin:parseFloat(0.00).toFixed(2),
  Actual_Total_Cost_After_Margin:parseFloat(0.00 ).toFixed(2),Discount_Applied:parseFloat(0).toFixed(2),
  Final_Total_Cost_After_Dicscount:parseFloat(0).toFixed(2)})


  const [clicked, setClicked] = useState(false); // State for controlling click behavior
  const [shipp, setShipp] = useState(false); // State for controlling shipping option
  const [tax, setTax] = useState(false); // State for controlling tax option
  const [fileName, setFileName] = useState(""); // State for storing file name
  
  const [searchParams] = useSearchParams(); // Hook for accessing URL search parameters
  const [draftSaveButtonRequiredFieldDisable, setDraftSaveButtonRequiredFieldDisable] = useState(false); // State for disabling draft save button if required fields are not filled
  const [dis, setDis] = useState(false); // State for disabling elements
  const navigate = useNavigate(); // Hook for navigation
  const [rfqMssge, setRFQMssge] = useState(''); // State for RFQ message
  const [successDialog, setSuccessDialog] = useState(false); // State for success dialog visibility
  const [errorDialog, setErrorDialog] = useState(false); // State for error dialog visibility
  const [open, setOpen] = useState(false); // State for controlling opening and closing of a dialog
  const [areYouSureDialog, setAreYouSureDialog] = useState(false); // State for "Are you sure?" dialog visibility
  const [draftSubmitRFQIDDisable, setDraftSubmitRFQIDDisable] = useState(false); // State for disabling draft submit RFQ ID
  const [KOsNameChecked, setKOsNameChecked] = useState(false); // State for checking KO's name
  const [KOsEmailChecked, setKOsEmailChecked] = useState(false); // State for checking KO's email
  const [KOsPhoneChecked, setKOsPhoneChecked] = useState(false); // State for checking KO's phone
  const [KOsAddressChecked, setKOsAddressChecked] = useState(false); // State for checking KO's address
  const [BillToKOsNameChecked, setBillToKOsNameChecked] = useState(false)  // State variable for KO's name checked status
  const [BillToKOsEmailChecked, setBillToKOsEmailChecked] = useState(false)  // State variable for KO's email checked status
  const [BillToKOsPhoneChecked, setBillToKOsPhoneChecked] = useState(false)  // State variable for KO's phone checked status
  const [BillToKOsAddressChecked, setBillToKOsAddressChecked] = useState(false)  // State variable for KO's address checked status
  const [loading, setLoading] = useState(false); // State for controlling loading indicator
  const [preview, setPreview] = useState(false); // State for controlling preview
  const [dhsCBPDialogClicked, setDHSCBPDialogClicked] = useState(false); // State for controlling DHS CBP dialog click
  const [FBOPDialogClicked, setFBOPDialogClicked] = useState(false); // State for controlling FBOP dialog click
  const [pricingYear, setPricingYear] = useState(''); // State for holding pricing year
  const [searchByVendorNameInput, setSearchByVendorNameInput] = useState(''); // State for searching by vendor name input
  const [skuIdInput, setSkuIdInput] = useState(''); // State for SKU ID input
  
  const [tabIndex, setTabIndex] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSwipeChange = (index) => {
    setTabIndex(index);
  };


  React.useEffect(() => {
    async function fetchData() {
        setLoading(true)
        var EndPoint=''
        if(searchParams.get("QuoteType")==='Internal' )
        {
          EndPoint='/RFQsSearch/RFQIDWithVersonNo'
        }
        else
        {
          EndPoint='/DHSCustomer/DHSOutSideSearch/RFQIDWithVersonNo'
        }
        await AxiosAPIInstance.get(EndPoint, {
            params: {
                RFQ_ID: searchParams.get("RID"),
                RFQ_Status: searchParams.get("RStatus"),
                Version_No: searchParams.get("VNo")
            }
        }).then((result) => {

            function isEqual(obj1, obj2) {
                // Check if IDs and descriptions of two objects are equal
                return obj1.ID === obj2.ID && obj1.Desc === obj2.Desc;
            }

            function subtractArrays(arr1, arr2) {
                const result = []; // Initialize an empty array to store the difference between the arrays

                // Iterate through the elements of the first array
                arr1.forEach(obj1 => {
                    // Find an object in the second array that matches the current object from the first array
                    const matchingObj = arr2.find(obj2 => isEqual(obj1, obj2));

                    // If no matching object is found, push the current object to the result array
                    if (!matchingObj) {
                        result.push(obj1);
                    }
                });

                // Return the result array containing the elements present in the first array but not in the second array
                return result;
            }

            setLoading(false); // Set loading state to false


            try {
                // Convert and format sales head name to title case
                const arr = String(result?.data[0]['Sales_Head_Name']).toLowerCase().split(" ");
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }

                // Convert and format KO name to title case
                const KO_arr = String(result?.data[0]['KO_name']).toLowerCase().split(" ");
                for (var i = 0; i < KO_arr.length; i++) {
                    KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
                }

                // Convert and format customer name to title case
                const CO_arr = String(result?.data[0]['Customer_Name']).toLowerCase().split(" ");
                for (var i = 0; i < CO_arr.length; i++) {
                    CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
                }

                // Convert and format user name to title case
                const User_arr = String(UserName).toLowerCase().split(" ");
                for (var i = 0; i < User_arr.length; i++) {
                    User_arr[i] = User_arr[i].charAt(0).toUpperCase() + User_arr[i].slice(1);
                }


                //--------------------------------Setting Pricing Year for DHS-----------------------------------------------------------------------
                if (result?.data[0]['Contract_Vehicle'] === 'DHS-CBP') {
                    let pricingYearString = '';

                    for (const range of dateRanges) {
                        if (isWithinInterval(today1, {
                                start: range.start,
                                end: range.end
                            })) {
                            pricingYearString = `${format(range.start, 'MM/dd/yy')}-${format(
                            range.end,
                            'MM/dd/yy'
                            )}(${range.year})`;
                            break; // Exit the loop once a matching range is found
                        }
                    }

                    if (pricingYearString) {
                        setPricingYear(pricingYearString);
                    } else {
                      setErrorDialog(true)
                      setRFQMssge('DHS Pricing year is not within the range')
                    }

                }
                //----------------------------------------------Handle Full Quote Copied--------------------------------------------------------------------------------
                if (searchParams.get("CheckedDetails") === "FULL QUOTE") {
                    Set_Form_Value({
                        ...Form_Value,
                        Opportunity_Name: result?.data[0]['Opportunity_Name'],
                        RFQ_ID: "NA",
                        Invoice_No: "NA",
                        Contract_Vehicle: result?.data[0]['Contract_Vehicle'],
                        KO_name: KO_arr.join(" "),
                        KO_email: result?.data[0]['KO_email'],
                        KO_phone: result?.data[0]['KO_phone'],
                        BillTo_Name: result?.data[0]['BillTo_Name'],
                        BillTo_Email: result?.data[0]['BillTo_Email'],
                        BillTo_Phone: result?.data[0]['BillTo_Phone'],
                        BillTo_Address: result?.data[0]['BillTo_Address'],
                        Notes_For_KO:result?.data[0]['Notes_For_KO'],
                        KO_address: result?.data[0]['KO_address'],
                        Published_date: result?.data[0]['Published_date'],
                        Due_date: result?.data[0]['Due_date'],
                        Brief: result?.data[0]['Brief'],
                        Notes_For_PO: result?.data[0]['Notes_For_PO'],
                        Organization_Name: result?.data[0]['Organization_Name'],
                        Customer_Name: CO_arr.join(" "),
                        Valid_For: result?.data[0]['Valid_For'],
                        Customer_Email: result?.data[0]['Customer_Email'],
                        Customer_Phone: result?.data[0]['Customer_Phone'],
                        Customer_Address: result?.data[0]['Customer_Address'],
                        Shipping_Method: result?.data[0]['Shipping_Method'],
                        Sales_Head_Name: User_arr.join(" "),
                        Sales_Head_Email: String(UserEmail).toLowerCase() || "sales@aspettoinc.com",
                        Sales_Head_Ph: result?.data[0]['Sales_Head_Ph'],
                        Other_Cost_1: result?.data[0]['Other_Cost_1'],
                        Other_Cost_2: result?.data[0]['Other_Cost_2'],
                        Other_Cost_3: result?.data[0]['Other_Cost_3'],
                        Other_Cost_Name1: result?.data[0]['Other_Cost_Name1'],
                        Other_Cost_Name2: result?.data[0]['Other_Cost_Name2'],
                        Other_Cost_Name3: result?.data[0]['Other_Cost_Name3'],
                        Shipping_Terms: result?.data[0]['Shipping_Terms'],
                        Estimated_Delivery: result?.data[0]['Estimated_Delivery'],
                        Payment_Terms: result?.data[0]['Payment_Terms'],
                        Notes: result?.data[0]['Notes'],
                        Shipping_Cost: result?.data[0]['Shipping_Cost'],
                        Tax: result?.data[0]['Tax'],
                        Created_Date: String(today),
                        Last_Updated_Date: String(today),
                        RFQ_Status: result?.data[0]['RFQ_Status'],
                        Updated_By: String(UserEmail).toLowerCase() || "sales@aspettoinc.com",
                        Version_No: Number(0),
                        Updating_Comments: (result?.data[0]['Updating_Comments'] + `\nQUOTE WAS COPIED FROM ` + result?.data[0]['RFQ_ID'])
                    })

                    // Check if shipping cost is non-zero
                    if (result?.data[0]["Shipping_Cost"] !== 0) {
                        setShipp(true); // Set shipping state to true
                    }

                    // Check if tax is non-zero
                    if (result?.data[0]["Tax"] !== 0) {
                        setTax(true); // Set tax state to true
                    }

                    // Check if other cost 1 is non-zero and not null
                    if (result?.data[0]["Other_Cost_1"] !== 0.00 && result?.data[0]["Other_Cost_1"] !== null && !isNaN(result?.data[0]["Other_Cost_1"])) {
                        setOtherCost1(true); // Set other cost 1 state to true
                    }

                    // Check if other cost 2 is non-zero and not null
                    if (result?.data[0]["Other_Cost_2"] !== 0.00 && result?.data[0]["Other_Cost_2"] !== null && !isNaN(result?.data[0]["Other_Cost_2"])) {
                        setOtherCost2(true); // Set other cost 2 state to true
                    }

                    // Check if other cost 3 is non-zero and not null
                    if (result?.data[0]["Other_Cost_3"] !== 0.00 && result?.data[0]["Other_Cost_3"] !== null && !isNaN(result?.data[0]["Other_Cost_3"])) {
                        setOtherCost3(true); // Set other cost 3 state to true
                    }

                    //--------------------------------------------------------------------------

                    // Check if KO name matches customer name
                    if (result?.data[0]["KO_name"] === result?.data[0]["Customer_Name"]) {
                        setKOsNameChecked(true); // Set KO's name checked state to true
                    }

                    // Check if KO email matches customer email
                    if (result?.data[0]["KO_email"] === result?.data[0]["Customer_Email"]) {
                        setKOsEmailChecked(true); // Set KO's email checked state to true
                    }

                    // Check if KO phone matches customer phone
                    if (result?.data[0]["KO_phone"] === result?.data[0]["Customer_Phone"]) {
                        setKOsPhoneChecked(true); // Set KO's phone checked state to true
                    }

                    // Check if KO address matches customer address
                    if (result?.data[0]["KO_address"] === result?.data[0]["Customer_Address"]) {
                        setKOsAddressChecked(true); // Set KO's address checked state to true
                    }

                    // Check if KO name matches customer name
                    if (result?.data[0]["KO_name"] === result?.data[0]["BillTo_Name"]) {
                        setBillToKOsNameChecked(true); // Set KO's name checked state to true
                     }
                  
                    // Check if KO email matches customer email
                    if (result?.data[0]["KO_email"] === result?.data[0]["BillTo_Email"]) {
                          setBillToKOsEmailChecked(true); // Set KO's email checked state to true
                    }
                  
                    // Check if KO phone matches customer phone
                    if (result?.data[0]["KO_phone"] === result?.data[0]["BillTo_Phone"]) {
                           setBillToKOsPhoneChecked(true); // Set KO's phone checked state to true
                    }
                  
                    // Check if KO address matches customer address
                    if (result?.data[0]["KO_address"] === result?.data[0]["BillTo_Address"]) {
                            setBillToKOsAddressChecked(true); // Set KO's address checked state to true
                    }
                    //----------------------------------------------------

                    // Initialize other cost variables
                    var OC1 = 0.00;
                    var OC2 = 0.00;
                    var OC3 = 0.00;

                    //---------------------------------------------------

                    // Parse and assign other cost variables if not null
                    if (result?.data[0]["Other_Cost_1"] == null) {
                        OC1 = parseFloat(0.00);
                    }
                    if (result?.data[0]["Other_Cost_2"] == null) {
                        OC2 = parseFloat(0.00);
                    }
                    if (result?.data[0]["Other_Cost_3"] == null) {
                        OC3 = parseFloat(0.00);
                    }
                    if (result?.data[0]["Other_Cost_1"] != null) {
                        OC1 = parseFloat(result?.data[0]["Other_Cost_1"]);
                    }
                    if (result?.data[0]["Other_Cost_2"] != null) {
                        OC2 = parseFloat(result?.data[0]["Other_Cost_2"]);
                    }
                    if (result?.data[0]["Other_Cost_3"] != null) {
                        OC3 = parseFloat(result?.data[0]["Other_Cost_3"]);
                    }


                    //------------------------------Calculating the Total Cost-----------------------------------------------------------------------------
                     var skusl 
                    if(searchParams.get("QuoteType")==='DHS-External') 
                    {    
                       Set_New_SKU(result?.data[0]["DHS_CBP_Purchase_Orders"])
                       skusl = [...result?.data[0]["DHS_CBP_Purchase_Orders"]];
                    }
                    else if(searchParams.get("QuoteType")==='Internal')
                    {
                       Set_New_SKU(result?.data[0]["Purchase_Orders"])
                       skusl = [...result?.data[0]["Purchase_Orders"]];
                    }

                    let cal = TotalCost.Actual_Total_Cost_Before_Margin === 0;
                    let cal3 = TotalCost.Actual_Total_Cost_After_Margin === 0;
                    skusl.map((sku, index) => {
                        cal = cal + (parseInt(sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost).toFixed(2));
                        cal3 = cal3 + parseInt(sku.QTY) * parseFloat(sku.Customer_Unit_Cost).toFixed(2);
                    })
                    cal3 = (cal3 + parseFloat(result?.data[0]["Shipping_Cost"]) + parseFloat(result?.data[0]["Tax"]) + OC1 + OC2 + OC3)
                    cal = (cal + parseFloat(result?.data[0]["Shipping_Cost"]) + parseFloat(result?.data[0]["Tax"]) + OC1 + OC2 + OC3)
                    SetTotalCost({
                        ...TotalCost,
                        Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2),
                        Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2),
                    });
                    setDis(false)
                }

                //-------------------------------------------------Handling If Only Customer Details Copy field is checked---------------------------------------------------------------------------------
                else if (searchParams.get("CheckedDetails") === "QUOTE") {
                    if (result?.data[0]["KO_name"] === result?.data[0]["Customer_Name"]) {
                        setKOsNameChecked(true)
                    }
                    if (result?.data[0]["KO_email"] === result?.data[0]["Customer_Email"]) {
                        setKOsEmailChecked(true)
                    }
                    if (result?.data[0]["KO_phone"] === result?.data[0]["Customer_Phone"]) {
                        setKOsPhoneChecked(true)
                    }
                    if (result?.data[0]["KO_address"] === result?.data[0]["Customer_Address"]) {
                        setKOsAddressChecked(true)
                    }
                    
                    // Check if KO name matches customer name
                    if (result?.data[0]["KO_name"] === result?.data[0]["BillTo_Name"]) {
                      setBillToKOsNameChecked(true); // Set KO's name checked state to true
                   }
                
                  // Check if KO email matches customer email
                  if (result?.data[0]["KO_email"] === result?.data[0]["BillTo_Email"]) {
                        setBillToKOsEmailChecked(true); // Set KO's email checked state to true
                  }
                
                  // Check if KO phone matches customer phone
                  if (result?.data[0]["KO_phone"] === result?.data[0]["BillTo_Phone"]) {
                         setBillToKOsPhoneChecked(true); // Set KO's phone checked state to true
                  }
                
                  // Check if KO address matches customer address
                  if (result?.data[0]["KO_address"] === result?.data[0]["BillTo_Address"]) {
                          setBillToKOsAddressChecked(true); // Set KO's address checked state to true
                  }
                  //----------------------------------------------------
                    Set_Form_Value({
                        ...Form_Value,
                        Opportunity_Name: result?.data[0]['Opportunity_Name'],
                        RFQ_ID: "NA",
                        Invoice_No: "NA",
                        Contract_Vehicle: result?.data[0]['Contract_Vehicle'],
                        KO_name: KO_arr.join(" "),
                        KO_email: result?.data[0]['KO_email'],
                        KO_phone: result?.data[0]['KO_phone'],
                        BillTo_Name: result?.data[0]['BillTo_Name'],
                        BillTo_Email: result?.data[0]['BillTo_Email'],
                        BillTo_Phone: result?.data[0]['BillTo_Phone'],
                        BillTo_Address: result?.data[0]['BillTo_Address'],
                        Notes_For_KO:result?.data[0]['Notes_For_KO'],
                        KO_address: result?.data[0]['KO_address'],
                        Published_date: result?.data[0]['Published_date'],
                        Due_date: result?.data[0]['Due_date'],
                        Brief: result?.data[0]['Brief'],
                        Notes_For_PO: result?.data[0]['Notes_For_PO'],
                        Organization_Name: result?.data[0]['Organization_Name'],
                        Customer_Name: CO_arr.join(" "),
                        Customer_Email: result?.data[0]['Customer_Email'],
                        Customer_Phone: result?.data[0]['Customer_Phone'],
                        Customer_Address: result?.data[0]['Customer_Address'],
                        Shipping_Method: result?.data[0]['Shipping_Method'],
                        Sales_Head_Name: User_arr.join(" "),
                        Valid_For: result?.data[0]['Valid_For'],
                        Sales_Head_Email: String(UserEmail).toLowerCase() || "sales@aspettoinc.com",
                        Sales_Head_Ph: "540-547-8487",
                        Other_Cost_1: result?.data[0]['Other_Cost_1'],
                        Other_Cost_2: result?.data[0]['Other_Cost_2'],
                        Other_Cost_3: result?.data[0]['Other_Cost_3'],
                        Other_Cost_Name1: result?.data[0]['Other_Cost_Name1'],
                        Other_Cost_Name2: result?.data[0]['Other_Cost_Name2'],
                        Other_Cost_Name3: result?.data[0]['Other_Cost_Name3'],
                        Shipping_Terms: result?.data[0]['Shipping_Terms'],
                        Estimated_Delivery: result?.data[0]['Estimated_Delivery'],
                        Payment_Terms: result?.data[0]['Payment_Terms'],
                        Notes: result?.data[0]['Notes'],
                        Shipping_Cost: result?.data[0]['Shipping_Cost'],
                        Tax: result?.data[0]['Tax'],
                        Created_Date: String(today),
                        Last_Updated_Date: String(today),
                        RFQ_Status: result?.data[0]['RFQ_Status'],
                        Updated_By: String(UserEmail).toLowerCase() || "sales@aspettoinc.com",
                        Version_No: Number(0),
                        Updating_Comments: (result?.data[0]['Updating_Comments'] + "QUOTE WAS COPIED FROM " + result?.data[0]['RFQ_ID'])
                    })
                }
                //-------------------------------------------------Handling If Only SKU Details Copy field is checked---------------------------------------------------------------------------------
                else if (searchParams.get("CheckedDetails") === "SKU") {
                    Set_Form_Value({
                        ...Form_Value,
                        Shipping_Cost: result?.data[0]['Shipping_Cost'],
                        Tax: result?.data[0]['Tax'],
                        Other_Cost_1: result?.data[0]['Other_Cost_1'],
                        Other_Cost_2: result?.data[0]['Other_Cost_2'],
                        Other_Cost_3: result?.data[0]['Other_Cost_3'],
                        Other_Cost_Name1: result?.data[0]['Other_Cost_Name1'],
                        Other_Cost_Name2: result?.data[0]['Other_Cost_Name2'],
                        Other_Cost_Name3: result?.data[0]['Other_Cost_Name3'],
                    })
                    if (result?.data[0]["Shipping_Cost"] != 0) {
                        setShipp(true)
                    }
                    if (result?.data[0]["Tax"] != 0) {
                        setTax(true)
                    }
                    if (parseFloat(result?.data[0]["Other_Cost_1"]).toFixed(2) != 0.00 && result?.data[0]["Other_Cost_1"] != null && result?.data[0]["Other_Cost_1"] != NaN) {
                        setOtherCost1(true)
                    }
                    if (parseFloat(result?.data[0]["Other_Cost_2"]).toFixed(2) != 0.00 && result?.data[0]["Other_Cost_2"] != null && result?.data[0]["Other_Cost_2"] != NaN) {
                        setOtherCost2(true)
                    }
                    if (parseFloat(result?.data[0]["Other_Cost_3"]).toFixed(2) != 0.00 && result?.data[0]["Other_Cost_3"] != null && result?.data[0]["Other_Cost_3"] !== NaN) {
                        setOtherCost3(true)
                    }

                    //----------------------------------------------------
                    var OC1 = 0.00
                    var OC2 = 0.00
                    var OC3 = 0.00

                    //---------------------------------------------------
                    if (result?.data[0]["Other_Cost_1"] == null) {
                        OC1 = parseFloat(0.00)
                    }
                    if (result?.data[0]["Other_Cost_2"] == null) {
                        OC2 = parseFloat(0.00)
                    }
                    if (result?.data[0]["Other_Cost_3"] == null) {
                        OC3 = parseFloat(0.00)
                    }
                    if (result?.data[0]["Other_Cost_1"] != null) {
                        OC1 = parseFloat(result?.data[0]["Other_Cost_1"])
                    }
                    if (result?.data[0]["Other_Cost_2"] != null) {
                        OC2 = parseFloat(result?.data[0]["Other_Cost_2"])
                    }
                    if (result?.data[0]["Other_Cost_3"] != null) {
                        OC3 = parseFloat(result?.data[0]["Other_Cost_3"])
                    }
                    //--------------------------------------------------------
                    var skusl 
                    if(searchParams.get("QuoteType")==='DHS-External') 
                    {    
                       Set_New_SKU(result?.data[0]["DHS_CBP_Purchase_Orders"])
                       skusl = [...result?.data[0]["DHS_CBP_Purchase_Orders"]];
                    }
                    else if(searchParams.get("QuoteType")==='Internal')
                    {
                       Set_New_SKU(result?.data[0]["Purchase_Orders"])
                       skusl = [...result?.data[0]["Purchase_Orders"]];
                    }

                    let cal = TotalCost.Actual_Total_Cost_Before_Margin === 0;
                    let cal3 = TotalCost.Actual_Total_Cost_After_Margin === 0;
                    skusl.map((sku, index) => {
                        cal = cal + (parseInt(sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost)).toFixed(2);
                        cal3 = cal3 + parseInt(sku.QTY) * parseFloat(sku.Customer_Unit_Cost).toFixed(2);
                    })
                    cal3 = (cal3 + parseFloat(result?.data[0]["Shipping_Cost"]) + parseFloat(result?.data[0]["Tax"]) + OC1 + OC2 + OC3)
                    cal = (cal + parseFloat(result?.data[0]["Shipping_Cost"]) + parseFloat(result?.data[0]["Tax"]) + OC1 + OC2 + OC3)
                    SetTotalCost({
                        ...TotalCost,
                        Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2),
                        Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2),
                    });
                    setDis(false)
                }
            } catch {
                setRFQMssge("Something went wrong while loading the quote")
                setErrorDialog(true)
            }
        }).catch((error) => {
            setLoading(false)
            setRFQMssge(error?.response?.data)
            setErrorDialog(true)
            navigate("/RFQsSearch")
        })

    }
    fetchData();

}, [searchParams])


const handleClose = (e) => {
  // Close error and success dialogs
  setErrorDialog(false);
  setSuccessDialog(false);
  
  // Reset loading state
  setLoading(false);
  
  // Reset form-related states
  setDraftSaveButtonRequiredFieldDisable(false);
  setDis(false);
  setDHSCBPDialogClicked(false);
  setFBOPDialogClicked(false);
  setSKUsDialog(false);
}

const handleSubmitOrSaveDecision = (e) => {
  e.preventDefault();
  
  // Open confirmation dialog
  setAreYouSureDialog(true);
}

const handleAreYouSureDialogClose = (e) => {
  // Close confirmation dialog
  setAreYouSureDialog(false);
}


{/*-----------------------------------------------------------------*/}
const handleDHSSKUs = async (e) => {
  function isEqual(obj1, obj2) {
      return obj1.ID === obj2.ID && obj1.Desc === obj2.Desc;
  }
  //-----------------------Code For Adjusting the SKUs if the Contract Vehicle is DHS------------------------------------
  function subtractArrays(arr1, arr2) {
      const result = [];

      arr1.forEach(obj1 => {
          const matchingObj = arr2.find(obj2 => isEqual(obj1, obj2));

          if (!matchingObj) {
              result.push(obj1);
          }
      });

      return result;
  }
  //-------------------------------------------Setting Pricing Year for DHS----------------------------------------------------------------------
  var array3 = DHSSKUsARRAY
  var array4 = [{}]
  New_SKUT.map((s, i) => {

      array4.push({
          ID: s.SKU_ID,
          Desc: s.SKU_Description
      })
  })

  const subtractedDHSArray = subtractArrays(array3, array4);
  setDHSSKUIDs(subtractedDHSArray)
  setDHSCBPDialogClicked(true);

}
  //-----------------------Code For Adjusting the SKUs if the Contract Vehicle is FBOP------------------------------------
  const handleFBOPSKUs = async (e) => {
  
    // Function to check if two objects are equal based on their ID and Desc properties
    function isEqual(obj1, obj2) {
        return obj1.ID === obj2.ID && obj1.Desc === obj2.Desc;
    }
  
    // Function to subtract elements of arr2 from arr1 based on custom equality function
    function subtractArrays(arr1, arr2) {
        const result = [];
  
        // Iterate over elements in arr1
        arr1.forEach(obj1 => {
            // Find matching object in arr2
            const matchingObj = arr2.find(obj2 => isEqual(obj1, obj2));
  
            // If no matching object found, push obj1 to result array
            if (!matchingObj) {
                result.push(obj1);
            }
        });
  
        return result;
    }
    
    //-----------------------Code For Adjusting the SKUs if the Contract Vehicle is FBOP------------------------------------
  
    // Initialize array1 with FBOPSKUsARRAY
    var array1 = FBOPSKUsARRAY;
    
    // Initialize array2 as an array with an empty object
    var array2 = [{}];
  
    // Map New_SKUT array to array2 with required properties
    New_SKUT.map((s, i) => {
        array2.push({
            ID: s.SKU_ID,
            Desc: s.SKU_Description
        });
    });
  
    // Get subtracted array by subtracting array2 from array1
    const subtractedArray = subtractArrays(array1, array2);
    
    // Set the subtractedArray as SKUIDs state
    setSKUIDs(subtractedArray);
    
    // Set FBOPDialogClicked state to true
    setFBOPDialogClicked(true);
  }
  

{/*---------------------Adding SKUs-----------------*/}
const HandleAddSKU=()=>{
  Set_New_SKU([...New_SKUT,{Vendor_Partner_name:'',
  SKU_ID:'',
  SKU_Description:'',
  QTY:parseInt(0),
  Aspetto_Unit_Cost:parseFloat(0).toFixed(2),
  Unit_Of_Measure:'ea',
  RFQRFQID:Form_Value.RFQ_ID,
  Margin_Percent:parseFloat(0).toFixed(2),Customer_Unit_Cost:parseFloat(0).toFixed(2),
  Total_SKU_Cost:parseFloat(0).toFixed(2),
  RFQ_Created_Date:Form_Value.Created_Date,
  RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
  SKU_Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  SKU_Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  SKU_Created_By:String(UserEmail).toLowerCase(),
  SKU_Updated_By:String(UserEmail).toLowerCase(),                                                     
  Version_No:Form_Value.Version_No
}])
};

{/*-----------------------------------------------------------------*/}
const HandleRemoveSKU=(index)=>{
  const skusl=[...New_SKUT];
  if(Form_Value.Contract_Vehicle==="FBOP")
  {
     setSKUIDs([...SKUIDs,{ID:New_SKUT[index].SKU_ID,Desc:New_SKUT[index].SKU_Description}])
  }
  else if (Form_Value.Contract_Vehicle==="DHS-CBP")
  {
    setDHSSKUIDs([...DHSSKUIDs,{ID:New_SKUT[index].SKU_ID,Desc:New_SKUT[index].SKU_Description}])
  }
    skusl.splice(index,1)
    Set_New_SKU(skusl)
    let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
    let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
    skusl.map((sku, index)=>{
      cal=cal + (parseInt(sku.QTY===""?parseFloat(0):sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost===""?parseFloat(0.00):sku.Aspetto_Unit_Cost));
      cal3= cal3+ parseInt(sku.QTY===""?parseFloat(0):sku.QTY)*parseFloat(sku.Customer_Unit_Cost===""?parseFloat(0.00):sku.Customer_Unit_Cost);
    skusl[index]['RFQRFQID']=Form_Value.RFQ_ID
    skusl[index]['Sales_Head_Email']=String(Form_Value.Sales_Head_Email).toLowerCase();
    skusl[index]['Opportunity_Name']=Form_Value.Opportunity_Name;
    })
    cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3))
    cal=(cal+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3))
    SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3 ).toFixed(2),});


  }


//-----------------------------------------------------------------------
// Function to handle contract vehicle change
const handleContractVehicleChange = async (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  setPreview(false); // Set preview state to false to hide preview
  Set_Form_Value({ ...Form_Value, Contract_Vehicle: e.target.value }); // Update contract vehicle in form value state
  Set_New_SKU([...New_SKUT]); // Update new SKUs array

  // If the selected contract vehicle is DHS-CBP
  if (e.target.value === 'DHS-CBP') {
    //-------------------------------------------Setting Pricing Year for DHS----------------------------------------------------------------------
    let pricingYearString = ''; // Initialize pricing year string

    // Iterate through date ranges to find the matching range for today's date
    for (const range of dateRanges) {
      if (isWithinInterval(today1, { start: range.start, end: range.end })) {
        pricingYearString = `${format(range.start, 'MM/dd/yy')}-${format(
          range.end,
          'MM/dd/yy'
        )}(${range.year})`; // Format pricing year string based on date range
        break; // Exit the loop once a matching range is found
      }
    }

    // If pricing year string is found, update state with the found string
    if (pricingYearString) {
      setPricingYear(pricingYearString);
    } else {
      setErrorDialog(true)
      setRFQMssge('DHS Pricing year is not within the range')
    }
  }
};

//---------------------------------------------------------------------------------------------
// Function to fetch all SKUs by SKU ID
const getAllSKUs = async (SKUID) => {
  setLoading(true); // Set loading state to true
  
  try {
    // Send GET request to fetch SKUs by SKU ID
    await AxiosAPIInstance.get("/SKUsSearch/getSKUsBySKUID", { params: { SKU_ID: SKUID } }).then(async (result) => {
      // If SKUs are found
      if (result?.data?.length !== 0) {
        setGetSKUClicked(true); // Set get SKU clicked state to true
        const New_SKUs = result?.data; // Extract fetched SKUs
        setGetSKUs(New_SKUs); // Set fetched SKUs
        setLoading(false); // Set loading state to false
        setSKUsDialog(true); // Open SKUs dialog
      } else { // If no records found
        setSuccessDialog(true); // Show success dialog
        setErrorDialog(false); // Hide error dialog
        setRFQMssge('No Records Found'); // Set message
        setLoading(false); // Set loading state to false
      }
    }).catch(async (error) => { // Catch any errors in fetching SKUs
      setSuccessDialog(false); // Hide success dialog
      setErrorDialog(true); // Show error dialog
      setRFQMssge(error?.response?.data); // Set message from error response
      setLoading(false); // Set loading state to false
    });
  } catch { // Catch any unexpected errors
    setSuccessDialog(false); // Hide success dialog
    setErrorDialog(true); // Show error dialog
    setRFQMssge('Something Went Wrong'); // Set generic error message
    setLoading(false); // Set loading state to false
  }
}

  //-----------------------------------------------------------------------------------------------------------------------------------------------
  const filteredSearchAllSKUs = getSKUs.filter((SKU) =>
  SKU.Vendor_Partner_name.toLowerCase().includes(searchByVendorNameInput.toLowerCase()) &&
  SKU.SKU_ID.toLowerCase().includes(skuIdInput.toLowerCase())
  );
//-------------------------------------------------------------------------------------------------------------------------------------------------------
// Function to handle change in SKU details.

// Function to handle changes in SKU fields
const HandleSKUChange = (e, index) => {
  const { name, value } = e.target;
  const skusl = [...New_SKUT];
  skusl[index][name] = value;

  if (name === "Customer_Unit_Cost") {
    // Calculate Margin_Percent and Total_SKU_Cost based on Customer_Unit_Cost
    const aspettoUnitCost = parseFloat(skusl[index]['Aspetto_Unit_Cost']) || 0;
    const customerUnitCost = parseFloat(value) || 0;
    
    // Calculate margin percentage
    skusl[index]['Margin_Percent'] = (customerUnitCost.toFixed(2) === aspettoUnitCost.toFixed(2)) 
      ? "0.00"
      : (((customerUnitCost - aspettoUnitCost) / customerUnitCost) * 100).toFixed(2);

    // Calculate total SKU cost
    skusl[index]['Total_SKU_Cost'] = (parseFloat(skusl[index]['QTY']) * customerUnitCost).toFixed(2);
  } else if (!["Unit_Of_Measure", "SKU_Description", "SKU_ID", "Vendor_Partner_name"].includes(name)) {
    // Calculate Customer_Unit_Cost and Total_SKU_Cost based on other fields
    const aspettoUnitCost = parseFloat(skusl[index]['Aspetto_Unit_Cost']) || 0;
    const marginPercent = parseFloat(skusl[index]['Margin_Percent']) / 100 || 0;
    
    // Calculate customer unit cost
    skusl[index]['Customer_Unit_Cost'] = (aspettoUnitCost / (1 - marginPercent)).toFixed(2);
    // Calculate total SKU cost
    skusl[index]['Total_SKU_Cost'] = (parseFloat(skusl[index]['QTY']) * parseFloat(skusl[index]['Customer_Unit_Cost'])).toFixed(2);
  }
  else if (name === "QTY") {
    // Calculate Customer_Unit_Cost and Total_SKU_Cost based on other fields
    const qTY = parseFloat(skusl[index]['QTY']) || 0;
    const customerUnitCost = parseFloat(skusl[index]['Customer_Unit_Cost']) || 0;
    
    // Calculate total SKU cost
    skusl[index]['Total_SKU_Cost'] = (qTY * customerUnitCost).toFixed(2);
  }

  // Update SKU_Last_Updated_Date and SKU_Updated_By fields
  skusl[index]['SKU_Last_Updated_Date'] = String(today);
  skusl[index]['SKU_Updated_By'] = String(UserEmail).toLowerCase();
  
  // Update state with new SKU list
  Set_New_SKU(skusl);

  // Recalculate total costs
  let cal = 0;
  let cal3 = 0;
  // Calculate total costs without shipping cost
  skusl.forEach((sku, index) => {
    cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
    cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
  });
  cal3 = (cal3 + parseFloat(e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00)) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
  cal = (cal + parseFloat(e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00)) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
  // Update total costs
 SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
};

// Function to handle shipping cost change.
const handleShipping = (e) => {
  // Check if shipping cost is enabled
  if (shipp === true) {
    const skusl = [...New_SKUT];
    // Update shipping cost in form values
    Set_Form_Value({ ...Form_Value, Shipping_Cost: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with shipping cost
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal3 = (cal3 + parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal = (cal + parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if shipping cost is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If shipping cost is disabled, set it to 0
    const skusl = [...New_SKUT];
    Set_Form_Value({ ...Form_Value, Shipping_Cost: parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without shipping cost
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal3 = (cal3 + parseFloat(e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00)) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal = (cal + parseFloat(e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00)) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if shipping cost is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle tax change.
const handleTax = (e) => {
  // Check if tax is enabled
  if (tax === true) {
    const skusl = [...New_SKUT];
    // Update tax in form values
    Set_Form_Value({ ...Form_Value, Tax: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with tax
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if tax is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If tax is disabled, set it to 0
    const skusl = [...New_SKUT];
    Set_Form_Value({ ...Form_Value, Tax: e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without tax
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal3 = cal3 + (parseFloat(e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if tax is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle change in Other Cost 1.
const handleOtherCost1 = async (e) => {
  // Check if Other Cost 1 is enabled
  if (otherCost1 == true) {
    const skusl = [...New_SKUT];
    // Update Other Cost 1 in form values
    Set_Form_Value({ ...Form_Value, Other_Cost_1: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with Other Cost 1
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 1 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If Other Cost 1 is disabled, set it to 0
    const skusl = [...New_SKUT];
    Set_Form_Value({ ...Form_Value, Other_Cost_1: e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without Other Cost 1
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 1 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle change in Other Cost 2.
const handleOtherCost2 = async (e) => {
  // Check if Other Cost 2 is enabled
  if (otherCost2 == true) {
    const skusl = [...New_SKUT];
    // Update Other Cost 2 in form values
    Set_Form_Value({ ...Form_Value, Other_Cost_2: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with Other Cost 2
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 2 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If Other Cost 2 is disabled, set it to 0
    const skusl = [...New_SKUT];
    Set_Form_Value({ ...Form_Value, Other_Cost_2: e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without Other Cost 2
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 2 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle change in Other Cost 3.
const handleOtherCost3 = async (e) => {
  // Check if Other Cost 3 is enabled
  if (otherCost3 == true) {
    const skusl = [...New_SKUT];
    // Update Other Cost 3 in form values
    Set_Form_Value({ ...Form_Value, Other_Cost_3: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with Other Cost 3
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 3 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If Other Cost 3 is disabled, set it to 0
    const skusl = [...New_SKUT];
    Set_Form_Value({ ...Form_Value, Other_Cost_3: parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without Other Cost 3
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(parseFloat(0.00)) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(parseFloat(0.00)) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 3 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}


//-----------------------------------------------------------------------------
const onDragEnd = (result) => {
  if (!result.destination) return;

  const items = Array.from(New_SKUT);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  // Update the sequence ID of each SKU
  items.forEach((item, index) => {
    item.sequence_id = index + 1;
  });

  Set_New_SKU(items);
};


//-----------------------------Declaring PDF Data Variables-------------------------------------------------
  const [InvoiceData, setInvoiceData] = useState({
      invoice_no: '',
      Contract_Vehicle: '',
      KO_name: "",
      KO_email: "",
      KO_phone: "",
      KO_address: "",
      BillTo_Name: '',
      BillTo_Email: '',
      BillTo_Phone: '',
      BillTo_Address:'',
      Print_Date:String(today),
      Valid_For:"30 Days",
      trans_date: String(Form_Value.Last_Updated_Date).split('T')[0],
      due_date: "",
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: [{}],
      Tax: 0,
      Shipping_Cost: 0,
      Other_Cost_1:0,Other_Cost_2:0,Other_Cost_3:0,Other_Cost_Name1:"",Other_Cost_Name2:"",Other_Cost_Name3:"",
      Total_Cost: 0,
      Shipping_Details: {
          Organization_Name: "",
          KO_name: "",
          Sales_Head_Info: {},
          Customer_Name: "",
          Customer_Email: "",
          Customer_Phone: "",
          Customer_Address: "",
          Shipping_Method: "",
          Shipping_Terms: "",
          Estimated_Delivery: "",
          Payment_Terms: "",
          Notes: ""
      }
  })

  const previewQuote = async (e) => {
    // Set preview mode to true
    setPreview(true);
    
    // Prevent default form submission behavior
    e.preventDefault();
    
    // Set invoice data with updated form values
    setInvoiceData({
      ...InvoiceData,
      invoice_num: '',
      Contract_Vehicle: Form_Value.Contract_Vehicle,
      KO_name: Form_Value.KO_name,
      KO_email: Form_Value.KO_email,
      KO_phone: Form_Value.KO_phone,
      KO_address: Form_Value.KO_address,
      BillTo_Name: Form_Value.BillTo_Name,
      BillTo_Email: Form_Value.BillTo_Email,
      BillTo_Phone: Form_Value.BillTo_Phone,
      BillTo_Address:Form_Value.BillTo_Address ,
      Notes_For_KO:Form_Value.Notes_For_KO,
      Print_Date: String(today),
      trans_date: String(today),
      due_date: Form_Value.Due_date,
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: [...New_SKUT],
      Tax: Form_Value.Tax,
      Valid_For: Form_Value.Valid_For,
      Shipping_Cost: Form_Value.Shipping_Cost,
      Total_Cost: TotalCost.Actual_Total_Cost_After_Margin,
      Other_Cost_1: Form_Value.Other_Cost_1,
      Other_Cost_2: Form_Value.Other_Cost_2,
      Other_Cost_3: Form_Value.Other_Cost_3,
      Other_Cost_Name1: Form_Value.Other_Cost_Name1,
      Other_Cost_Name2: Form_Value.Other_Cost_Name2,
      Other_Cost_Name3: Form_Value.Other_Cost_Name3,
      Shipping_Details: {
        Organization_Name: Form_Value.Organization_Name,
        KO_name: Form_Value.KO_name,
        Sales_Head_Info: {
          Name: Form_Value.Sales_Head_Name,
          Email: Form_Value.Sales_Head_Email,
          Phone: "540-547-8487"
        },
        Customer_Name: Form_Value.Customer_Name,
        Customer_Email: Form_Value.Customer_Email,
        Customer_Phone: Form_Value.Customer_Phone,
        Customer_Address: Form_Value.Customer_Address,
        Shipping_Method: Form_Value.Shipping_Method,
        Shipping_Terms: Form_Value.Shipping_Terms,
        Estimated_Delivery: Form_Value.Estimated_Delivery,
        Payment_Terms: Form_Value.Payment_Terms,
        Notes: Form_Value.Notes
      }
    });
  }
  

  const HandleSubmit = async (e) => {
    //--------------------------------------------------------------------------------
    // Prevent default form submission behavior
    e.preventDefault();

    // Set loading state to indicate processing
    setLoading(true);

    // Set dis state to indicate disabled state
    setDis(true);

    // Updating RFQ Last Updated Date in Form_Value
    Set_Form_Value({
        ...Form_Value,
        Last_Updated_Date: String(today)
    });

    // Combine Form_Value and TotalCost into Form_Table
    const Form_Table = {
        ...Form_Value,
        ...TotalCost
    };

    // Copy SKUs array and update each SKU
    const SKUs = [...New_SKUT];
    SKUs.map(async (row, index) => {
        // Set common properties for each SKU
        SKUs[index]['RFQRFQID'] = Form_Value.RFQ_ID;
        SKUs[index]['Sales_Head_Email'] = String(Form_Value.Sales_Head_Email).toLowerCase();
        SKUs[index]['Opportunity_Name'] = Form_Value.Opportunity_Name;
        SKUs[index]['RFQ_Created_Date'] = Form_Value.Created_Date;
        SKUs[index]['RFQ_Last_Updated_Date'] = Form_Value.Last_Updated_Date;
    });

    // Update New_SKU state with modified SKUs
    Set_New_SKU(SKUs);
    // Check if draft save button required fields are disabled
    if (draftSaveButtonRequiredFieldDisable === true) {
        // Check if RFQ_ID and Opportunity_Name are not empty
        if (Form_Value.RFQ_ID !== "" && Form_Value.Opportunity_Name !== "") {
            // Send a POST request to create and update RFQ drafts
            await AxiosAPIInstance.post("/RFQDrafts/CreateAndUpdateRFQDrafts", [Form_Table, New_SKUT, String(UserEmail).toLowerCase()])
                .then(async (response) => {
                    // Set loading state to false after successful response
                    setLoading(false);
                    // Reset clicked state
                    setClicked(false);
                    // Reset dis state
                    setDis(false);
                    // Set RFQ message with server response data
                    setRFQMssge(response?.data);
                    // Open success dialog
                    setSuccessDialog(true);
                    // Reset states related to shipping, tax, and other costs
                    setShipp(false);
                    setTax(false);
                    setOtherCost1(false);
                    setOtherCost2(false);
                    setOtherCost3(false);
                    // Extract RFQID from server response and update Form_Value state
                    const Response_RFQID = String(response?.data).split(' ')[1];
                    Set_Form_Value({
                        ...Form_Value,
                        RFQ_ID: Response_RFQID
                    });
                })
                .catch((err) => {
                    // Set loading state to false after catching an error
                    setLoading(false);
                    // Set RFQ message with error response data
                    setRFQMssge(err?.response?.data);
                    // Open error dialog
                    setErrorDialog(true);
                    // Reset dis state
                    setDis(false);
                });
        } else {
            // Open error dialog if RFQ ID and Opportunity Name are empty
            setLoading(false);
            setErrorDialog(true);
            setRFQMssge("Cannot Save As a Draft: RFQ ID and Opportunity Name are required fields ");
            setDis(false);
        }
    } else {
        Set_Form_Value({
            ...Form_Value,
            Created_Date: String(today)
        })
        var No_Of_Null_Values = 0;
        New_SKUT.map(async (row, index) => {
            if (row.SKU_ID === '' || row.SKU_Description === '' || row.Vendor_Partner_name === '') {
                No_Of_Null_Values = No_Of_Null_Values + 1;
            }
        })
        /*--------------------------------Updating the database---------------*/
        if (No_Of_Null_Values > 0 || Form_Value.Other_Cost_1 === "" || Form_Value.Other_Cost_2 === "" || Form_Value.Other_Cost_3 === "" || Form_Value.Shipping_Cost === "" ||
            Form_Value.Tax === "") {
            setRFQMssge('All the fields from the order details section are required')
            setErrorDialog(true)
        } else {
            //--------------------------------------------------------------------------------
            await AxiosAPIInstance.post("/RFQs/CreateRFQ", [Form_Table, New_SKUT])
                .then(
                    async (response) => {
                        setLoading(false)
                        setShipp(false)
                        setTax(false)
                        setOtherCost1(false)
                        setOtherCost2(false)
                        setOtherCost3(false)
                        //------------------Setting PDF Data-------------------------

                        const invoice_num = response?.data
                        setInvoiceData({
                            ...InvoiceData,
                            invoice_num: invoice_num,
                            Contract_Vehicle: Form_Value.Contract_Vehicle,
                            KO_name: Form_Value.KO_name,
                            KO_email: Form_Value.KO_email,
                            KO_phone: Form_Value.KO_phone,
                            KO_address: Form_Value.KO_address,
                            BillTo_Name: Form_Value.BillTo_Name,
                            BillTo_Email: Form_Value.BillTo_Email,
                            BillTo_Phone: Form_Value.BillTo_Phone,
                            BillTo_Address:Form_Value.BillTo_Address ,
                            Valid_For: Form_Value.Valid_For,
                            trans_date: String(today),
                            due_date: Form_Value.Due_date,
                            Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
                            items: [...New_SKUT],
                            Tax: Form_Value.Tax,
                            Notes_For_KO:Form_Value.Notes_For_KO,
                            Shipping_Cost: Form_Value.Shipping_Cost,
                            Total_Cost: TotalCost.Actual_Total_Cost_After_Margin,
                            Other_Cost_1: Form_Value.Other_Cost_1,
                            Other_Cost_2: Form_Value.Other_Cost_2,
                            Other_Cost_3: Form_Value.Other_Cost_3,
                            Other_Cost_Name1: Form_Value.Other_Cost_Name1,
                            Other_Cost_Name2: Form_Value.Other_Cost_Name2,
                            Other_Cost_Name3: Form_Value.Other_Cost_Name3,
                            Shipping_Details: {
                                Organization_Name: Form_Value.Organization_Name,
                                KO_name: Form_Value.KO_name,
                                Sales_Head_Info: {
                                    Name: Form_Value.Sales_Head_Name,
                                    Email: Form_Value.Sales_Head_Email,
                                    Phone: "540-547-8487"
                                },
                                Customer_Name: Form_Value.Customer_Name,
                                Customer_Email: Form_Value.Customer_Email,
                                Customer_Phone: Form_Value.Customer_Phone,
                                Customer_Address: Form_Value.Customer_Address,
                                Shipping_Method: Form_Value.Shipping_Method,
                                Shipping_Terms: Form_Value.Shipping_Terms,
                                Estimated_Delivery: Form_Value.Estimated_Delivery,
                                Payment_Terms: Form_Value.Payment_Terms,
                                Notes: Form_Value.Notes
                            }
                        })
                        //----------Removing DOts from the Opportunity name
                        const arr = String(Form_Value.Opportunity_Name).split(".");
                        for (var i = 0; i < arr.length; i++) {
                            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

                        }
                        var File_Opportunity_Name = arr.join(" ")

                        setFileName(File_Opportunity_Name + " " + String(invoice_num).split('.')[0] + String(invoice_num).split('.')[1])
                        setClicked(true)
                        setDis(false)
                        setRFQMssge('A new quote with QUOTE NUMBER :' + invoice_num + ' created sucessfully')
                        setSuccessDialog(true)
                        searchParams.delete("RID")
                        setDraftSubmitRFQIDDisable(false)
                        //---------------------------------------------------------------------------------------
                        Set_Form_Value({
                            ...Form_Value,
                            Opportunity_Name: '',
                            RFQ_ID: '',
                            Contract_Vehicle: 'GSA',
                            KO_name: '',
                            KO_email: '',
                            KO_phone: '',
                            KO_address: '',
                            BillTo_Name:'',
                            BillTo_Email: '',
                            BillTo_Phone: '',
                            BillTo_Address:'',
                            Valid_For: '30 Days',
                            Published_date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            Due_date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            Brief: 'N/A',
                            Organization_Name: '',
                            Notes_For_PO: "NA",
                            Customer_Name: '',
                            Notes_For_KO:'NA',
                            Customer_Email: '',
                            Customer_Phone: '',
                            Customer_Address: '',
                            Shipping_Method: 'N/A',
                            Sales_Head_Name: UserName,
                            Sales_Head_Ph: "540-547-8487",
                            Sales_Head_Email: UserEmail,
                            Shipping_Terms: 'FOB Origin',
                            Estimated_Delivery: 'N/A',
                            Payment_Terms: 'NET30',
                            Other_Cost_1: Number(parseFloat(0).toFixed(2)),
                            Other_Cost_2: Number(parseFloat(0).toFixed(2)),
                            Other_Cost_3: Number(parseFloat(0).toFixed(2)),
                            Other_Cost_Name1: "NA",
                            Other_Cost_Name2: "NA",
                            Other_Cost_Name3: "NA",
                            Notes: 'N/A',
                            Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            RFQ_Status: 'PENDING',
                            Shipping_Cost: parseFloat(0.00).toFixed(2),
                            Tax: parseFloat(0.00).toFixed(2),
                            Updated_By: 'TBD',
                            Version_No: parseInt(0),
                            Updating_Comments: 'RFQ Created under PENDING Status'
                        })
                        SetTotalCost({
                            ...TotalCost,
                            Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2),
                            Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
                            Discount_Applied: parseFloat(0).toFixed(2),
                            Final_Total_Cost_After_Dicscount: parseFloat(0).toFixed(2)
                        })
                        //-----------------------------------------------------------------------------------------
                        Set_New_SKU([{
                            Vendor_Partner_name: '',
                            SKU_ID: '',
                            SKU_Description: '',
                            QTY: parseInt(0),
                            Aspetto_Unit_Cost: parseFloat(0.00).toFixed(2),
                            Unit_Of_Measure: 'ea',
                            RFQRFQID: '',
                            Margin_Percent: parseFloat(0.00).toFixed(2),
                            Customer_Unit_Cost: parseFloat(0.00).toFixed(2),
                            Total_SKU_Cost: parseFloat(0.00).toFixed(2),
                            RFQ_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            RFQ_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            SKU_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            SKU_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            SKU_Created_By: 'TBD',
                            SKU_Updated_By: 'TBD',
                        }])

                    })
                .catch((err) => {
                    setLoading(false)
                    setRFQMssge(err.response?.data)
                    setErrorDialog(true)
                    setDis(false)
                })
        }
    }

}
  return(
    <>
     <LoadingDialog open={loading} />
    {<ApplicationBar></ApplicationBar>}
        {successDialog===true&&(<Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rfqMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
            {rfqMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e)=>{
            e.preventDefault();
            handleClose();
            setKOsAddressChecked(false)
            setKOsNameChecked(false)
            setKOsNameChecked(false)
            setKOsPhoneChecked(false)
            setSKUIDs(FBOPSKUsARRAY)
            setDHSSKUIDs(DHSSKUsARRAY)
            setDis(false)
            }} href="/Dashboard/RFQInput">OK</Button>
          </DialogActions>
        </Dialog>)}
        {errorDialog===true&&(<Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rfqMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
            {rfqMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK"  onClick={(e)=>{
            handleClose();
            e.preventDefault();
            setDis(false);}} href="/Dashboard/RFQInput">OK</Button>
          </DialogActions>
        </Dialog>)}
        {areYouSureDialog===true&&(<Dialog
        open={areYouSureDialog}
        onClose={handleAreYouSureDialogClose}
        aria-labelledby="alert-dialog-title"variant="standard"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
       <Alert variant='filled' severity='info'> {draftSaveButtonRequiredFieldDisable?"Are You Sure you want to save it as a Draft? ":"Are You Sure, You want to Submit?"}</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
          {draftSaveButtonRequiredFieldDisable?"Once Saved, You Can Only Access it from the Drafts Tab ":"Once Submited You Can Access it from the Retrieve/Update RFQs Tab"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="YES" type='submit' onClick={async(e)=>{ setAreYouSureDialog(false);HandleSubmit(e)}} >YES</Button>
          <Button name="NO" onClick={(e)=>{e.preventDefault();setAreYouSureDialog(false)}}>
           NO
          </Button>
        </DialogActions>
      </Dialog>)}
        {/* ---------------------------------DHS-SKUs Dialog--------------------------------------------- */}
        {dhsCBPDialogClicked === true && (
  <Dialog
    open={dhsCBPDialogClicked}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' sx={{ bgcolor: blueGrey[700] }}>DHS-CBP SKUs</Alert>
    </DialogTitle>
    <DialogContent>
      <TextField
        size="small"
        label="Search SKU"
        variant="standard"
        sx={{ width: "100%", marginBottom: 2 }}
        onChange={(e) => handleSearchSKU(e.target.value)}
      />
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Display SKUs based on search results */}
        {filteredDHSSKUIDs.map((SKU, index) => {
          return (
            <FormGroup>
             <Stack direction="row">
          <FormControlLabel size="small" control={<IconButton  variant="contained"  onClick={async(e,)=>
          {
            e.preventDefault()
            setDis(true)
            var DHS_Pricing_Year=String(pricingYear).split('(')[0]
            await AxiosAPIInstance.get("/DHSPricing/PartNo/PricingYear",{params:{Part_No:SKU.ID}}).then(
             async(result)=>{
               setDis(false)
               var CustomerCost=''
                 if(DHS_Pricing_Year==='06/09/23-06/08/24')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year1).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/24-06/08/25')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year2).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/25-06/08/26')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year3).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/26-06/08/27')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year4).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/27-06/08/28')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year5).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/28-06/08/29')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year6).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/28-06/08/30')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year7).replace("$",'').replace(",",'').replace(' ','')
                 }

                 Set_New_SKU([...New_SKUT,{
                   Vendor_Partner_name:result?.data.Part_No,
                   SKU_ID:result?.data.Part_No,
                   SKU_Description:SKU.Desc,
                   QTY:parseInt(0),
                   Aspetto_Unit_Cost:parseFloat(CustomerCost).toFixed(2),
                   Unit_Of_Measure:'ea',
                   RFQRFQID:Form_Value.RFQ_ID,
                   Margin_Percent:parseFloat(0.00).toFixed(2),
                   Customer_Unit_Cost:parseFloat(CustomerCost).toFixed(2),
                   Total_SKU_Cost:parseFloat(0).toFixed(2),
                   RFQ_Created_Date:Form_Value.Created_Date,
                   RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
                   SKU_Created_Date:String(today),  
                   SKU_Last_Updated_Date:String(today),
                   SKU_Created_By:String(UserEmail).toLowerCase(),
                   SKU_Updated_By:String(UserEmail).toLowerCase(),
                   Version_No:parseInt(Form_Value.Version_No)
               }])
        const skus=[...DHSSKUIDs]
         skus.splice(index,1)
         setDHSSKUIDs(skus)
             }
            ).catch((error)=>{
             setRFQMssge(error?.response?.data)
             setErrorDialog(true)
             setDis(false)
            }
              
            )

          }}><Avatar variant='rounded' sx={{bgcolor:[orange[800]],marginLeft:"10px",width:"25px",height:"25px",}}>
            <Tooltip title={`Add SKU# ${SKU.ID}`}><AddBoxIcon/></Tooltip></Avatar></IconButton>  } label={<span style={{ whiteSpace: 'pre-wrap' }}><b>{SKU.ID} :</b><br/>
 {SKU.Desc} 
</span>} />
           </Stack>
           <Divider sx={{color:red[900],bgcolor:red[900]}}></Divider>
            </FormGroup>
          );
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button name="OK" onClick={(e) => {
        e.preventDefault();
        setSearchInput("")
        handleClose();
      }}>OK</Button>
    </DialogActions>
  </Dialog>
)}
      {FBOPDialogClicked===true&&(<Dialog
        open={FBOPDialogClicked}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        <Alert variant='filled' sx={{bgcolor:indigo[700]}}>FBOP SKUs</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
          {SKUIDs.map((SKU,index)=>{
          return(<FormGroup>
            <Stack direction="row">
          <FormControlLabel size="small" control={<IconButton  variant="contained"  onClick={async(e,)=>
          {
            e.preventDefault()
            setDis(true)
            await AxiosAPIInstance.get("/SpecialContracts/FBOPSKUs",{params:{Agency:"FBOP",SKUIDs:SKU.ID,Pricing_Year:PD.getFullYear()}}).then(
             async(result)=>{
               setDis(false)
               result?.data.map(async(row,index)=>{
                 Set_New_SKU([...New_SKUT,{
                   Vendor_Partner_name:row.SKU_ID,
                   SKU_ID:row.SKU_ID,
                   SKU_Description:row.SKU_Description,
                   QTY:parseInt(0),
                   Aspetto_Unit_Cost:parseFloat(row.Aspetto_Unit_Cost).toFixed(2),
                   Unit_Of_Measure:'ea',
                   RFQRFQID:Form_Value.RFQ_ID,
                   Margin_Percent:parseFloat(row.Margin_Percent).toFixed(2),
                   Customer_Unit_Cost:parseFloat(row.Customer_Unit_Cost).toFixed(2),
                   Total_SKU_Cost:parseFloat(0).toFixed(2),
                   RFQ_Created_Date:Form_Value.Created_Date,
                   RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
                   SKU_Created_Date:String(today),  
                   SKU_Last_Updated_Date:String(today),
                   SKU_Created_By:String(UserEmail).toLowerCase(),
                   SKU_Updated_By:String(UserEmail).toLowerCase(),
                   Version_No:parseInt(Form_Value.Version_No)
                 
                 }])
               })
        const skus=[...SKUIDs]
         skus.splice(index,1)
         setSKUIDs(skus)
             }
            ).catch((error)=>{
             setRFQMssge(error?.response?.data)
             setErrorDialog(true)
             setDis(false)
            }
              
            )

          }}><Avatar variant='rounded' sx={{bgcolor:[indigo[800]],marginLeft:"10px",width:"30px",height:"30px",}}>
            <Tooltip title={`Add SKU# ${SKU.ID}`}><AddBoxIcon/></Tooltip></Avatar></IconButton>  } label={SKU.Desc} />
           </Stack>
           </FormGroup>)
        }) 
      }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          e.preventDefault();
          handleClose();
          }} >OK</Button>
        </DialogActions>
      </Dialog>)}

      {getSKUClicked === true && (
        <Dialog
          maxWidth="lg"
          open={skusDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Alert variant="filled"  sx={{ bgcolor: orange[300],color:blueGrey[900]}}>
              SKU Details
            </Alert>
            <br></br>
            <Stack direction='row' spacing={2} justifyContent='center'> 
            <TextField
                label="Search by SKU ID"
                value={skuIdInput}
                onChange={(e) => setSkuIdInput(e.target.value)}
                variant="outlined"
                size="small"
              />
            <TextField
                label="Search Vendor/Partner"
                value={searchByVendorNameInput}
                onChange={(e) => setSearchByVendorNameInput(e.target.value)}
                variant="outlined"
                size="small"
              />
            </Stack>

          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
              <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
                <TableHead>
                <TableRow>
                        <TableCell sx={{border:1,borderColor:'black',color:"black",backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Add</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Vendor/Partner</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">SKU ID</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,width:'20%',fontWeight:'bold'}} align="center">SKU Desc</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Aspetto's Cost($)</TableCell>
                        {/* <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5}} align="center">Margin(%)</TableCell> */}
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Customer's Cost($)</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Used in the past for</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Quote Dated</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Created By</TableCell>
            </TableRow>
                </TableHead>
                {filteredSearchAllSKUs.map((SKU, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">
                    <FormControlLabel
                      size="small"
                      control={
                        <IconButton
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            Set_New_SKU([...New_SKUT,{
                              Vendor_Partner_name:SKU.SKU_ID,
                              SKU_ID:SKU.SKU_ID,
                              SKU_Description:SKU.SKU_Description,
                              QTY:parseInt(0),
                              Aspetto_Unit_Cost:parseFloat(SKU.Aspetto_Unit_Cost).toFixed(2),
                              Unit_Of_Measure:'ea',
                              RFQRFQID:Form_Value.RFQ_ID,
                              Margin_Percent:parseFloat(SKU.Margin_Percent).toFixed(2),
                              Customer_Unit_Cost:parseFloat(SKU.Customer_Unit_Cost).toFixed(2),
                              Total_SKU_Cost:parseFloat(0).toFixed(2),
                              RFQ_Created_Date:Form_Value.Created_Date,
                              RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
                              SKU_Created_Date:String(today),  
                              SKU_Last_Updated_Date:String(today),
                              SKU_Created_By:String(UserEmail).toLowerCase(),
                              SKU_Updated_By:String(UserEmail).toLowerCase(),
                              Version_No:parseInt(Form_Value.Version_No)
                            
                            }])
                          }}
                        >
                          <Avatar variant="rounded" sx={{ bgcolor: [orange[800]], marginLeft: '10px', width: '25px', height: '25px' }}>
                            <Tooltip title={`Add SKU# ${SKU.Vendor_Partner_name}`}>
                              <AddBoxIcon />
                            </Tooltip>
                          </Avatar>
                        </IconButton>
                      }
                    /></TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{SKU.Vendor_Partner_name}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{SKU.SKU_ID}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{SKU.SKU_Description}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{Number(SKU.Aspetto_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                        {/* <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{Number(SKU.Margin_Percent)}</TableCell> */}
                        <TableCell  sx={{border:1,borderColor:'text.primary'}} align="center">{Number(SKU.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell> 
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{SKU.Opportunity_Name}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{String(SKU.RFQ_Created_Date).split('T')[0]}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" variant='contained' color='warning' size='small' onClick={(e) => 
              { e.preventDefault();
                setLoading(true)
                setSearchByVendorNameInput('')
                setSkuIdInput('')
               handleClose(); }}>
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      )}

    <Stack direction="row" spacing={2} justifyContent="center" sx={{marginTop:"60px"}} >
        <Stack direction="column" spacing={3}  justifyContent="center"  >
        <Stack direction="row"  justifyContent="center" >
        <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"25px", fontWeight:"bold",padding:1}}>Request For Quotation(RFQ)-Copy</Typography>
        </Stack>
        <Stack justifyContent="center" direction="row">
        <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center"> 
          <FormControl size="small" sx={{width:"250px"}}>
           <InputLabel id="STATUS">STATUS</InputLabel>
           <Select size="small"
          labelId="STATUS"
          id="STATUS"
          value={Form_Value.RFQ_Status}
          label="STATUS"
          onChange={(e)=>{Set_Form_Value({...Form_Value,RFQ_Status:e.target.value})}}
          sx={{width:"250px"}}>
          <MenuItem size="small" name="PENDING" value={"PENDING"}>PENDING</MenuItem>
          <MenuItem size="small" name="WON" value={"WON"}>WON</MenuItem>
          <MenuItem size="small" name="LOST" value={"LOST"}>LOST</MenuItem>
        </Select>
      </FormControl>

      <TextField size="small" required sx={{width:"550px"}} label="Updating Comments" multiline={true} value={Form_Value.Updating_Comments} onChange={(e)=>Set_Form_Value({...Form_Value,Updating_Comments:e.target.value})} minRows={2} 
      helperText="Ex: Status change from PENDING TO WON/LOST or Added/Removed/Updated SKUs. Minimum 2 lines required" ></TextField>
            <Stack sx={{width:"250px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
          </Stack>
        <Stack direction="row"  spacing={2} justifyContent="center">
        <TextField size="small"  required variant="outlined" sx={{width:"250px"}} label="Opportunity Name" value={Form_Value.Opportunity_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Opportunity_Name:e.target.value})}></TextField>
        <TextField size="small" required sx={{width:"250px"}} label="RFQ ID" helperText="Enter NA if you don't have one" value={Form_Value.RFQ_ID} onChange={(e)=>Set_Form_Value({...Form_Value,RFQ_ID:e.target.value})} InputProps={{endAdornment:(
            <InputAdornment>
            </InputAdornment>
          )}}></TextField>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
        <Select size="small"
          labelId="Contract-Vehicle"
          id="Contract-Vehicle-id"
          value={Form_Value.Contract_Vehicle}
          label="Contract Vehicle"
          onChange={(e)=>{handleContractVehicleChange(e)}}
          sx={{width:"250px"}}>
          <MenuItem size="small" name="FE" value={"Federal"}>Federal</MenuItem>
          <MenuItem size="small" name="MI" value={"Military"}>Military</MenuItem>
          <MenuItem size="small" name="TA" value={"Tactical"}>Tactical</MenuItem>
          <MenuItem size="small" name="G" value={"GSA"}>GSA</MenuItem>
          <MenuItem size="small" name="SO" value={"SAM/Open Market"}>SAM/Open Market</MenuItem>
          <MenuItem size="small" name="FB" value={"FBOP"}>FBOP</MenuItem>
          <MenuItem size="small" name="DH-CB" value={"DHS-CBP"}>DHS-CBP</MenuItem>
          <MenuItem size="small" name="UN" value={"Unison"}>Unison</MenuItem>
          <MenuItem size="small" name="DI" value={"Direct Inquiry"}>Direct Inquiry</MenuItem>
          <MenuItem size="small" name="CO" value={"Commercial"}>Commercial</MenuItem>
          <MenuItem size="small" name="EN" value={"Engineering"}>Engineering</MenuItem>
        </Select>
      </FormControl>
      <DatePicker label="Published date" sx={{width:"250px"}} value={Form_Value.Published_date} maxDate={new Date()} renderInput={(params)=><TextField size="small" required sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Published_date:value})}>
        </DatePicker>
        <DatePicker label="Due date" sx={{width:"250px"}} value={Form_Value.Due_date} minDate={new Date()} renderInput={(params)=><TextField size="small" required variant="outlined" sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Due_date:value})}>
        </DatePicker>
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, border: 1,width:'1250px'}}>
      <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="KO's Details" sx={{backgroundColor:orange[200],color:blueGrey[900],fontWeight: 'bold'}} />
          <Tab label="Ship To" sx={{backgroundColor:orange[200],color:blueGrey[900],fontWeight: 'bold'}}/>
          <Tab label="Bill To" sx={{backgroundColor:orange[200],color:blueGrey[900],fontWeight: 'bold'}}/>
        </Tabs>

        <SwipeableViews index={tabIndex} onChangeIndex={handleSwipeChange}>
          {/* Tab 1: KO's Details */}
          <Box>
            <Stack required direction="row" spacing={2} justifyContent="center" sx={{marginTop:'15px'}}>
              <TextField
                variant="standard"
                size="small"
                sx={{ width: "325px" }}
                label="Contracting Officer's Name"
                value={Form_Value.KO_name}
                onChange={(e) => {
          
                  Set_Form_Value({ ...Form_Value, KO_name: e.target.value });
                  setKOsNameChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                variant="standard"
                type="email"
                sx={{ width: "325px" }}
                label="Contracting Officer's Email"
                value={Form_Value.KO_email}
                onChange={(e) => {
             
                  Set_Form_Value({ ...Form_Value, KO_email: e.target.value });
                  setKOsEmailChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                variant="standard"
                sx={{ width: "325px" }}
                label="Contracting Officer's Phone"
                value={Form_Value.KO_phone}
                onChange={(e) => {
                  setPreview(false);
                  Set_Form_Value({ ...Form_Value, KO_phone: e.target.value });
                  setKOsPhoneChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                variant="standard"
                multiline
                sx={{ width: "325px" }}
                label="Contracting Officer's Address"
                value={Form_Value.KO_address}
                onChange={(e) => {
           
                  Set_Form_Value({ ...Form_Value, KO_address: e.target.value });
                  setKOsAddressChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactMailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>

          {/* Tab 2: Ship To */}
          <Box>
            <Stack direction="row" spacing={1} justifyContent="center" sx={{marginTop:'15px'}}>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Customer's Name"
                  value={Form_Value.Customer_Name}
                  disabled={KOsNameChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, Customer_Name: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px', fontSize: '10px' }}
                        checked={KOsNameChecked}
                        onChange={(e) => {
                          setKOsNameChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Name: Form_Value.KO_name });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Name: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Name"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  type="email"
                  sx={{ width: "300px" }}
                  label="Customer's Email"
                  value={Form_Value.Customer_Email}
                  disabled={KOsEmailChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, Customer_Email: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={KOsEmailChecked}
                        onChange={(e) => {
                          setKOsEmailChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Email: Form_Value.KO_email });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Email: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Email"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Customer's Phone"
                  value={Form_Value.Customer_Phone}
                  disabled={KOsPhoneChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, Customer_Phone: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={KOsPhoneChecked}
                        sx={{ width: '20px' }}
                        onChange={(e) => {
                          setKOsPhoneChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Phone: Form_Value.KO_phone });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Phone: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Phone"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  multiline
                  sx={{ width: "300px" }}
                  label="Customer's Address"
                  value={Form_Value.Customer_Address}
                  disabled={KOsAddressChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, Customer_Address: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px', fontSize: '10px' }}
                        checked={KOsAddressChecked}
                        onChange={(e) => {
                          setKOsAddressChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Address: Form_Value.KO_address });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Address: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Address"
                  />
                </FormGroup>
              </Stack>
            </Stack>
          </Box>

          {/* Tab 3: Bill To */}
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: blueGrey[900] }}>
              Bill To
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Name"
                  value={Form_Value.BillTo_Name}
                  disabled={BillToKOsNameChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, BillTo_Name: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={BillToKOsNameChecked}
                        onChange={(e) => {
                          setBillToKOsNameChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Name: Form_Value.KO_name });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Name: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Name"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  type="email"
                  sx={{ width: "300px" }}
                  label="Email"
                  value={Form_Value.BillTo_Email}
                  disabled={BillToKOsEmailChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, BillTo_Email: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={BillToKOsEmailChecked}
                        onChange={(e) => {
                          setBillToKOsEmailChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Email: Form_Value.KO_email });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Email: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Email"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Phone"
                  value={Form_Value.BillTo_Phone}
                  disabled={BillToKOsPhoneChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, BillTo_Phone: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={BillToKOsPhoneChecked}
                        sx={{ width: '20px' }}
                        onChange={(e) => {
                          setBillToKOsPhoneChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Phone: Form_Value.KO_phone });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Phone: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Phone"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  multiline
                  sx={{ width: "300px" }}
                  label="Address"
                  value={Form_Value.BillTo_Address}
                  disabled={BillToKOsAddressChecked}
                  onChange={(e) => {
                    Set_Form_Value({ ...Form_Value, BillTo_Address: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={BillToKOsAddressChecked}
                        onChange={(e) => {
                          setBillToKOsAddressChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Address: Form_Value.KO_address });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Address: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Address"
                  />
                </FormGroup>
              </Stack>
            </Stack>
          </Box>
        </SwipeableViews>
      </Paper>
    </Box>
        <Stack direction='row' spacing={2} justifyContent="center">
        <TextField size="small" variant="standard"   sx={{width:"250px"}} label="Organization/Agency's Name" value={Form_Value.Organization_Name} onChange={(e)=>{ setPreview(false);Set_Form_Value({...Form_Value,Organization_Name:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}></TextField>
                <TextField
         size="small"
         variant="standard"
         sx={{ width: "250px" }}
         multiline={true}
         rowsMax={20}
         label="Brief"
         value={Form_Value.Brief}
         onChange={(e) => {
           Set_Form_Value({ ...Form_Value, Brief: e.target.value });
         }}
         InputProps={{
           startAdornment: (
             <InputAdornment position="start">
               <DescriptionIcon />
             </InputAdornment>
           ),
         }}
       />
        <TextField size="small"   sx={{width:"250px"}} label="Shipping Method"  value={Form_Value.Shipping_Method} onChange={(e)=>Set_Form_Value({...Form_Value,Shipping_Method:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocalShippingIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Shipping-Terms">Shipping Terms</InputLabel>
        <Select size="small"
          labelId="Shipping-Terms"
          id="Shipping-Terms-id"
          label="Shipping Terms"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Shipping_Terms:e.target.value})}}
          sx={{width:"250px"}}
          value={Form_Value.Shipping_Terms}>
          <MenuItem  size="small" value={"FOB Origin"}>FOB Origin</MenuItem>
          <MenuItem  size="small" value={"FOB Destination"}>FOB Destination</MenuItem>
        </Select>
      </FormControl>
      
      <TextField size="small" multiline={true} rowsMax={10} sx={{width:"250px"}} label="Estimated Delivery" value={Form_Value.Estimated_Delivery} onChange={(e)=>Set_Form_Value({...Form_Value,Estimated_Delivery:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
          </TextField>
    
        </Stack>
        <Stack direction='row' spacing={2} justifyContent="center">
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Payment_Terms">Payment Terms</InputLabel>
        <Select size="small"
          labelId="Payment_Terms"
          id="Payment_Terms-id"
          value={Form_Value.Payment_Terms}
          label="Payment Terms"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Payment_Terms:e.target.value})}}
          sx={{width:"250px"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"NET30"}>NET30</MenuItem>
          <MenuItem size="small" value={"NET40"}>NET45</MenuItem>
          <MenuItem size="small" value={"NET50"}>NET60</MenuItem>
          <MenuItem size="small" value={"Due On Receipt"}>Due On Receipt</MenuItem>
        </Select>
      </FormControl>
        <TextField size="small"  sx={{width:"250px"}} name="Notes" label="Shipping Notes" multiline={true} rowsMax={20} value={Form_Value.Notes} onChange={(e)=>Set_Form_Value({...Form_Value,Notes:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" variant="standard"  sx={{width:"250px"}} multiline={true} rowsMax={20} helperText="Use this field if you have some special rates/notes/info for Purchase Orders deptartment." label="Notes For PO" value={Form_Value.Notes_For_PO} onChange={(e)=>Set_Form_Value({...Form_Value,Notes_For_PO:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <  TextField size="small" variant="standard"  sx={{width:"250px"}} multiline={true} rowsMax={20} helperText="30,60,90,120 Days. Default Value is 30 Days" label="Valid For" value={Form_Value.Valid_For} 
        onChange={(e)=>{
          Set_Form_Value({...Form_Value,Valid_For:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
          <TextField size="small" required sx={{width:"250px"}}  label="Sales Lead Name" value={Form_Value.Sales_Head_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          )
        }}></TextField>
     

        </Stack>
        <Stack direction='row' spacing={2} justifyContent="center">
        <TextField size="small"  required type="email" sx={{width:"250px"}} label="Sales Lead Email" value={Form_Value.Sales_Head_Email} onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Email:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          )
        }}>
        </TextField>
        <TextField size="small" required  sx={{width:"250px"}}  label="Sales Lead Phone" value={Form_Value.Sales_Head_Ph} onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Ph:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" variant="outlined"  sx={{width:"350px"}} multiline={true} rowsMax={20} helperText="Use this field if you have some special info for KO." label="Notes For KO" value={Form_Value.Notes_For_KO} 
        onChange={(e)=>{
          setPreview(false);
          Set_Form_Value({...Form_Value,Notes_For_KO:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>

                <Stack sx={{width:"250px"}} ></Stack>
                <Stack sx={{width:"150px"}} ></Stack>
        </Stack>
  {/*--------------------------ORDER DETAILS-----------------------------------------------*/}
        <Stack direction="column" spacing={2}>
        <Stack direction="row"  justifyContent="center">
        <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"19px", fontWeight:"bold",padding:1}}>Order Details</Typography>
        </Stack>
        <Stack justifyContent="center" direction="row">
        <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
          {Form_Value.Contract_Vehicle==="DHS-CBP" &&(
          <Stack direction='column' spacing={2} >
<Stack direction='row' spacing={2} justifyContent="center">
 <FormControl size="small" sx={{width:"250px",marginBottom:"20px"}} >
 <InputLabel id="Pricing-Year">Select Pricing Year</InputLabel>
 <Select size="small"
   required
   labelId="Pricing-Year"
   id="Pricing-Year-id"
   value={pricingYear}
   variant="standard"
   label="Pricing-Year"
   onChange={(e)=>
    { 
      setDHSSKUIDs(DHSSKUsARRAY)
      setPricingYear(e.target.value)
    }}
   sx={{width:"250px"}}>
   <MenuItem size="small" name="Yr1" value={"06/09/23-06/08/24(Year1)"}>06/09/23-06/08/24(Year1)</MenuItem>
   <MenuItem size="small" name="Yr2" value={"06/09/24-06/08/25(Year2)"}>06/09/24-06/08/25(Year2)</MenuItem>
   <MenuItem size="small" name="Yr3" value={"06/09/25-06/08/26(Year3)"}>06/09/25-06/08/26(Year3)</MenuItem>
   <MenuItem size="small" name="Yr4" value={"06/09/26-06/08/27(Year4)"}>06/09/26-06/08/27(Year4)</MenuItem>
   <MenuItem size="small" name="Yr5" value={"06/09/27-06/08/28(Year5)"}>06/09/27-06/08/28(Year5)</MenuItem>
   <MenuItem size="small" name="Yr6" value={"06/09/28-06/08/29(Year6)"}>06/09/28-06/08/29(Year6)</MenuItem>
   <MenuItem size="small" name="Yr7" value={"06/09/28-06/08/30(Year7)"}>06/09/28-06/08/30(Year7)</MenuItem>
 </Select>
</FormControl>
<Button  variant="contained" size='small' onClick={(e)=>{setSearchInput("");handleDHSSKUs(e)}} sx={{bgcolor:[orange[900]],width:"200px",height:"35px","&:hover": {
    bgcolor: orange[600], // Zoom in effect on hover
  }}} disabled={pricingYear===''?true:false}>ADD DHS-CBP SKUs</Button>
</Stack>
{/* <Stack>
<Divider sx={{color:blueGrey[900],bgcolor:orange[700],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
</Stack> */}
</Stack>
         )}
        {Form_Value.Contract_Vehicle==="FBOP" &&(
          <Stack direction='column' spacing={2} >
          <Stack direction='row' spacing={2} justifyContent="center" >
<Button   variant="contained" size='small' onClick={(e)=>handleFBOPSKUs(e)} sx={{bgcolor:[orange[900]],width:"200px",height:"35px","&:hover": {
    bgcolor: orange[600], // Zoom in effect on hover
  }}} disabled={dis}>ADD FBOP SKUs</Button>
</Stack>
{/* <Stack>
<Divider sx={{color:blueGrey[900],bgcolor:orange[700],width:"100%",height:"1px"}} orientation='horizontal'></Divider>
</Stack> */}
</Stack> )}

<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="skus">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {New_SKUT.map((New_Sku, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Stack direction="column" spacing={1} justifyContent="center">
                      <Stack direction="row" spacing={1} justifyContent="center">
                        <IconButton {...provided.dragHandleProps}>
                        <DragIndicatorIcon sx={{color:orange[900]}} />
                        </IconButton>
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="Vendor_Partner_name"
                          sx={{ width: "150px" }}
                          label="Vendor's Partner Name"
                          value={New_Sku.Vendor_Partner_name}
                          onChange={(e) => HandleSKUChange(e, index)}
                        />
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="SKU_ID"
                          sx={{ width: "150px" }}
                          label="SKU ID"
                          value={New_Sku.SKU_ID}
                          onChange={(e) => HandleSKUChange(e, index)}
                        />
                        <TextField
                          size="small"
                          required
                          sx={{ width: "250px" }}
                          name="SKU_Description"
                          label="SKU Description"
                          multiline
                          rowsMax={20}
                          value={New_Sku.SKU_Description}
                          onChange={(e) => HandleSKUChange(e, index)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          name="QTY"
                          required
                          variant="outlined"
                          sx={{ width: "100px" }}
                          label="QTY"
                          value={String(New_Sku.QTY).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                        />
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="Unit_Of_Measure"
                          sx={{ width: "70px" }}
                          label="UM"
                          value={String(New_Sku.Unit_Of_Measure).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                        />
                        <TextField
                          size="small"
                          name="Aspetto_Unit_Cost"
                          required
                          sx={{ width: "130px" }}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          label="Aspetto's Cost"
                          value={New_Sku.Aspetto_Unit_Cost === "NaN" ? parseFloat(0.00) : String(New_Sku.Aspetto_Unit_Cost).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="Margin_Percent"
                          sx={{ width: "110px" }}
                          label="Margin"
                          value={String(New_Sku.Margin_Percent).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <PercentIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          name="Customer_Unit_Cost"
                          required
                          sx={{ width: "130px" }}
                          label="Customer's Cost"
                          value={New_Sku.Customer_Unit_Cost === "NaN" ? parseFloat(0.00) : String(New_Sku.Customer_Unit_Cost).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          name="Total_SKU_Cost"
                          required
                          sx={{ width: "180px" }}
                          label="Total SKU Cost"
                          value={New_Sku.Total_SKU_Cost === "NaN" ? parseFloat(0.00) : String(New_Sku.Total_SKU_Cost).trim()}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} sx={{ marginLeft: "120px" }} justifyContent="center">
                        <Stack></Stack>
                        {New_SKUT.length > 1 && (
                          <IconButton
                            name="Cancel"
                            sx={{
                              borderRadius: '5px',
                              color: 'white',
                              transition: 'transform 0.2s ease-in-out',
                              "&:hover": {
                                transform: 'scale(1.1)',
                              },
                            }}
                            variant='contained'
                            color='warning'
                            onClick={() => HandleRemoveSKU(index)}
                          >
                            <Avatar variant="rounded" sx={{ backgroundColor: red[700], height: '30px', width: '30px', ":hover": { boxShadow: "10px 10px 20px #ccc" } }}>
                              <Tooltip title="Remove SKU"><DisabledByDefaultIcon /></Tooltip>
                            </Avatar>
                          </IconButton>
                        )}
                        {New_SKUT.length - 1 === index && (
                          <>
                            <IconButton
                              variant="contained"
                              sx={{
                                borderRadius: '5px',
                                color: 'white',
                                transition: 'transform 0.2s ease-in-out',
                                "&:hover": {
                                  transform: 'scale(1.1)',
                                },
                              }}
                              color="success"
                              onClick={() => HandleAddSKU(index)}
                            >
                              <Avatar variant='rounded' sx={{ bgcolor: green[800], height: '30px', width: '30px' }}>
                                <Tooltip title="Add SKU"><AddBoxIcon /></Tooltip>
                              </Avatar>
                            </IconButton>
                            <IconButton
                              size='small'
                              variant="contained"
                              onClick={(e) => {
                                e?.preventDefault();
                                getAllSKUs(New_Sku.SKU_ID);
                              }}
                              sx={{
                                borderRadius: '5px',
                                color: 'white',
                                transition: 'transform 0.2s ease-in-out',
                                "&:hover": {
                                  transform: 'scale(1.1)',
                                },
                              }}
                              disabled={dis}
                            >
                              <Avatar variant='rounded' sx={{ bgcolor: blue[800], height: '30px', width: '30px' }}>
                                <Tooltip title="Search from Existing SKUs"><SearchIcon /></Tooltip>
                              </Avatar>
                            </IconButton>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
   
        <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"205px"}}></Stack>
        <FormGroup>
        
      <FormControlLabel size="small" control={<Checkbox size="small" checked={shipp} onChange={(e)=>{
        setShipp(e.target.checked)
       if(e.target.checked===false)
       {
         Set_Form_Value({...Form_Value,Shipping_Cost:parseFloat(0).toFixed(2)})
         const skusl=[...New_SKUT];
         let cal=0.00;
         let cal3=0.00;
           skusl.map((sku, index)=>{
           cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
           cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
           })
           cal3=(cal3+parseFloat(0)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
           cal=(cal+parseFloat(0)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
           SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
       }
      }}/>} label="Add Shipping Cost" />
       </FormGroup>
      <TextField required sx={{width:"200px"}} size="small" inputProps={{min:"0",step:"any",inputMode:"numeric"}}  disabled={!shipp} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Shipping cost" value={Form_Value.Shipping_Cost}  onChange={(e)=>{handleShipping(e)}}></TextField>
    
          </Stack>
          
          <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"280px"}}></Stack>
        <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" checked={tax} onChange={(e)=>{
        setTax(e.target.checked);
        if(e.target.checked===false)
        {
          Set_Form_Value({...Form_Value,Tax:parseFloat(0).toFixed(2)})
          const skusl=[...New_SKUT];
          let cal=0.00;
          let cal3=0.00;
            skusl.map((sku, index)=>{
            cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
            cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
            })
            cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
            cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
            SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});

        }
      }}/>} label="Add Tax" />
       </FormGroup>
        <TextField required sx={{width:"200px"}}  size="small" disabled={!tax} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Tax" value={Form_Value.Tax} inputProps={{min:"0",step:"any",inputMode:"numeric"}}  onChange={(e)=>handleTax(e)}></TextField>
          </Stack>
            {/*------------------------------------------------------------Other Costs--------------------------------------------- */}
          {/* ------------------------------------------------------------------------------------------------------------------ */}
          <Stack direction="row" justifyContent="center" spacing={3}>
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"80px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={otherCost1} onChange={(e)=>{
          setOtherCost1(e.target.checked)
         if(e.target.checked===false)
         {
           Set_Form_Value({...Form_Value,Other_Cost_1:parseFloat(0).toFixed(2),Other_Cost_Name1:"NA"})
           const skusl=[...New_SKUT];
           let cal=0.00;
           let cal3=0.00;
             skusl.map((sku, index)=>{
             cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
             cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
             })
             cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
             cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
             SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
         }
        }}/>} label="Other Cost 1" />
         </FormGroup>
        <TextField  variant="standard" required  sx={{width:"200px"}} size="small" type ="text" disabled={!otherCost1}   value={Form_Value.Other_Cost_Name1} label="Enter Cost Name" onChange={(e)=>{Set_Form_Value({...Form_Value,Other_Cost_Name1:e.target.value})}}></TextField>
                    <TextField inputProps={{min:"0",step:"any"}} variant="standard" required sx={{width:"200px"}} size="small" disabled={!otherCost1} InputProps={{startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),}}  value={Form_Value.Other_Cost_1} label="Amount" onChange={(e)=>handleOtherCost1(e)}></TextField>
      
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={3}>
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"80px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={otherCost2} onChange={(e)=>{
          setOtherCost2(e.target.checked)
         if(e.target.checked===false)
         {
          console.log(Form_Value.Other_Cost_2)
           Set_Form_Value({...Form_Value,Other_Cost_2:parseFloat(0).toFixed(2),Other_Cost_Name2:"NA"})
           const skusl=[...New_SKUT];
           let cal=0.00;
           let cal3=0.00;
             skusl.map((sku, index)=>{
             cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
             cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
             })
             cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
             cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
             SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
         }
        }}/>} label="Other Cost 2" />
         </FormGroup>
        <TextField  variant="standard" required sx={{width:"200px"}} size="small" type ="text" disabled={!otherCost2}  value={Form_Value.Other_Cost_Name2} label="Enter Cost Name" onChange={(e)=>{Set_Form_Value({...Form_Value,Other_Cost_Name2:e.target.value})}}></TextField>
                    <TextField inputProps={{min:"0",step:"any"}} variant="standard" required  sx={{width:"200px"}} size="small" disabled={!otherCost2} InputProps={{startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),}}  value={Form_Value.Other_Cost_2} label="Amount" onChange={(e)=>handleOtherCost2(e)}></TextField>
      
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={3}>
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"80px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={otherCost3} onChange={(e)=>{
          setOtherCost3(e.target.checked)
         if(e.target.checked===false)
         {
           Set_Form_Value({...Form_Value,Other_Cost_3:parseFloat(0).toFixed(2),Other_Cost_Name3:"NA"})
           const skusl=[...New_SKUT];
           let cal=0.00;
           let cal3=0.00;
             skusl.map((sku, index)=>{
             cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
             cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
             })
             cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_1))
             cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_1))
             SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
         }
        }}/>} label="Other Cost 3" />
         </FormGroup>
        <TextField  variant="standard" required sx={{width:"200px"}} size="small" type ="text" disabled={!otherCost3} value={Form_Value.Other_Cost_Name3} label="Enter Cost Name" onChange={(e)=>{Set_Form_Value({...Form_Value,Other_Cost_Name3:e.target.value})}}></TextField>
                    <TextField inputProps={{min:"0",step:"any"}} variant="standard" required  sx={{width:"200px"}} size="small"  disabled={!otherCost3} InputProps={{startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),}}  value={Form_Value.Other_Cost_3} label="Amount" onChange={(e)=>handleOtherCost3(e)}></TextField>
      
            </Stack>
     <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <TextField size="small"  required sx={{width:"300px"}} type="number" label="Total Cost Before Margin" value={TotalCost.Actual_Total_Cost_Before_Margin==="NaN"?parseFloat(0.00):TotalCost.Actual_Total_Cost_Before_Margin} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} ></TextField>
        <TextField size="small" required sx={{width:"300px"}} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Total Cost After Margin" value={TotalCost.Actual_Total_Cost_After_Margin==="NaN"?parseFloat(0.00):Number(TotalCost.Actual_Total_Cost_After_Margin).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ></TextField>
        </Stack>
            
        </Stack>
          <Stack direction="row" spacing={2} justifyContent="center">
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"220px"}}></Stack><Stack sx={{width:"220px"}}></Stack>
          <FormGroup>
          
        <FormControlLabel size="small" control={<Checkbox size="small" checked={draftSaveButtonRequiredFieldDisable} onChange={(e)=>{
          setDraftSaveButtonRequiredFieldDisable(e.target.checked)
        
        }}/>} label="Save as a Draft & Submit Later?" />
         </FormGroup>
          <Button size='small'  variant="contained" onClick={(e)=>handleSubmitOrSaveDecision(e)} sx={{bgcolor:draftSaveButtonRequiredFieldDisable?[orange[900]]:[indigo[500]],width:"200px",height:"35px"}} disabled={dis}> {draftSaveButtonRequiredFieldDisable?"Save as Draft":"Create Quote"}</Button>
          {draftSaveButtonRequiredFieldDisable!==true &&(<Button size='small' variant="contained" onClick={(e)=>previewQuote(e)} sx={{bgcolor:[orange[800]],width:"200px",height:"35px",":hover":{boxShadow:"10px 10px 20px #ccc",bgcolor:orange[500]}}} disabled={preview}>Pre-view Quote </Button>)}
          <Stack sx={{width:"200px"}}>
            { 
           clicked===true && draftSaveButtonRequiredFieldDisable===false &&
              (<><PDFDownloadLink component="button" color="secondary"
                document={<PdfDocument invoicedata={InvoiceData}/>}
                fileName={fileName}>
                {({ blob, url, loading, error }) =>
                  loading ? "Loading..." : "Download Your Quote"
                }
              </PDFDownloadLink></>)
          }
      {preview===true && clicked===false &&
              (<><PDFDownloadLink component="button" color="secondary"
                document={<PdfDocument invoicedata={InvoiceData}/>}
                fileName={fileName}>
                {({ blob, url, loading, error }) =>
                  loading ? "Loading..." : "Preview Your Quote"
                }
              </PDFDownloadLink></>)
          }
          </Stack>
          </Stack>
        </Stack>
     
          </Stack>
  </>
  )
}
export default RFQ_Copy