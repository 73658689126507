import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, Box, IconButton, Typography, Stack, Button,
  MenuItem, FormControl, Select, InputLabel, Dialog, DialogActions, DialogContent,
  DialogContentText, Alert, DialogTitle,FormControlLabel,Checkbox,TablePagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { blueGrey, indigo, orange } from '@mui/material/colors';
import ApplicationBar from '../AppBar/ApplicationBar';
import AxiosFunction from '../../axiosCustomInstance';
import LoadingDialog from '../Loading';
import UserAuth from '../ProtectedRoute/userAuth';
import StyledTableRowComponent from './TrackerRow';


// Styled components for custom table styling
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxWidth: '100%',
  margin: '20px auto',
  boxShadow: theme.shadows[3],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '& th': {
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
  },
}));

// Table headers with corresponding data keys
const tableHeaders = [
  { label: 'Order Info', key: 'orderInfo' },
  { label: 'Order Date', key: 'orderDate', isDate: true },
  { label: 'Due Date', key: 'dueDate', isDate: true },
  { label: 'Shipped', key: 'shipped', isDate: true },
  { label: 'Shipping Info', key: 'shippingInfo' },
  { label: 'PO Number', key: 'poNumber' },
  { label: 'PO Date', key: 'poDate', isDate: true },
  { label: 'PO Due Date', key: 'poDueDate', isDate: true },
  { label: 'Shipped to SLW', key: 'shippedToSLW', isDate: true },
  { label: 'Additional Info', key: 'additionalInfo' },
  { label: 'Actions', key: 'actions' }
];

const TrackerTable = () => {
  const [rows, setRows] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const { UserEmail } = UserAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const AxiosAPIInstance = AxiosFunction();
  const [loading, setLoading] = useState(false);
  const [salesHeadError, setSalesHeadError] = useState(false);
  const [salesHeadErrorMsg, setSalesHeadErrorMsg] = useState('');
  const [totalData, setTotalData] = useState([]);
  const [salesHeadName, setSalesHeadName] = useState(UserEmail.toLowerCase().trim());
  const [usersData, setUsersData] = useState([]);
  const [trackerMssge, setTrackerMssge] = useState('')
  const [successDialog, setSuccessDialog] = useState(false)
  const [errorDialog, setErrorDialog] = useState(false)
  const [dis, setDis] = useState(false)
  const [contractTypes, setContractTypes] = useState([]);
  const [contractType, setContractType] = useState('all');
  const [dateFilters, setDateFilters] = useState({
    dueDateFrom: '',
    dueDateTo: '',
    poDateFrom: '',
    poDateTo: '',
    shippedDateFrom: '',
    shippedDateTo: '',
  });
    // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
    // Pagination handlers
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
  const handleClose = (e) => {
    setErrorDialog(false)
    setSuccessDialog(false)
  };
  // Parent Component
const [status, setStatus] = useState({
  shipmentBooked: false,
  orderShipmentEmailSent: false,
  invoiceRequestSent: false,
  serialNumbersReceived: false,
  orderUploaded: false,
});

const handleStatusChange = (field, value) => {
  setStatus(prev => ({ ...prev, [field]: value }));
};

// Fetch contract types from the backend
useEffect(() => {
  AxiosAPIInstance.get('/Tracker/DHS/TrackerContractTypes/getByTrackerType', {
    params: { trackerType: 'DHS' }
  })
    .then(response => {
      // Check if data is an array and map to extract contractTypeName
      const contractTypeNames = Array.isArray(response?.data)
        ? response?.data.map(item => item.contractTypeName)
        : [];

      // Set the state with the extracted contractTypeNames
      setContractTypes(contractTypeNames);
      setErrorDialog(false);
    })
    .catch(error => {
      console.error(error);
      setTrackerMssge(error?.response?.data);
      setSuccessDialog(false);
      setErrorDialog(true);
    });
}, []);
  useEffect(() => {
    setLoading(true);
    fetchUsersData();
    fetchData()
  }, [salesHeadName,
    dateFilters.dueDateFrom,
    dateFilters.dueDateTo,
    dateFilters.poDateFrom,
    dateFilters.poDateTo,
    dateFilters.shippedDateFrom,
    dateFilters.shippedDateTo]);

  const fetchUsersData = async () => {
    try {
      const result = await AxiosAPIInstance.get("/UserAuth/users");
      setUsersData(result?.data || []);
      setSalesHeadError(false);
    } catch (error) {
      setSalesHeadError(true);
      setSalesHeadErrorMsg("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseErrorDialog = () => {
    setSalesHeadError(false);
    setSalesHeadErrorMsg('');
  };

  // Clear Filters function
  const handleClearFilters = () => {
    setPage(0)
    setDateFilters({
      dueDateFrom: '',
      dueDateTo: '',
      poDateFrom: '',
      poDateTo: '',
      shippedDateFrom: '',
      shippedDateTo: '',
    });
    setStatus({
      shipmentBooked: false,
      orderShipmentEmailSent: false,
      invoiceRequestSent: false,
      serialNumbersReceived: false,
      orderUploaded: false,
    });
    setSearchTerm('');
    setContractType('All'); // Reset contract type selection if applicable
    fetchData(); // Fetch data after clearing filters to update the view
  };



  const fetchData = async () => {
    setPage(0)
    setLoading(true);
    try {
      console.log(contractType)
      const response = await AxiosAPIInstance.get('Tracker/DHS/Search', {
        params: {
          createdByEmail: salesHeadName,
          searchTerm,
          contractType:contractType,
          dueDateFrom: dateFilters.dueDateFrom,
          dueDateTo: dateFilters.dueDateTo,
          poDateFrom: dateFilters.poDateFrom,
          poDateTo: dateFilters.poDateTo,
          shippedDateFrom: dateFilters.shippedDateFrom,
          shippedDateTo: dateFilters.shippedDateTo,
        },
      });
      
      if (response.data.length > 0) {
        setTotalData(response?.data);
        console.log(response?.data)
        setSalesHeadError(false);
      } else {
        setTotalData([]);
        setSalesHeadError(true);
        setSalesHeadErrorMsg("No Records Found");
      }
    } catch (error) {
      setSalesHeadError(true);
      setTotalData([]);
      // Check if error.response.data is an object or string, and handle accordingly
      setSalesHeadErrorMsg(typeof error.response?.data === 'string' ? error.response.data : "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setPage(0)
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    const sortedRows = [...rows].sort((a, b) => {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);
      return direction === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortConfig({ key, direction });
    setRows(sortedRows);
  };

  const handleDateFilterChange = (field, value) => {
    setDateFilters({
      ...dateFilters,
      [field]: value,
    });
  };
// Apply filtering and sorting to totalData
const applyFiltersAndSorting = () => {
  // Filter rows based on search term, date filters, and checkbox filters
  const filtered = totalData.filter((row) => {
    // Search term filter
    const matchesSearchTerm = row?.submittedBy?.toLowerCase().includes(searchTerm) || 
                              row?.orderNumber?.toLowerCase().includes(searchTerm) || 
                              row?.poNumber?.toLowerCase().includes(searchTerm)||
                              row?.position?.toLowerCase().includes(searchTerm)||
                              row?.system?.toLowerCase().includes(searchTerm)

    // Date range filter
    const withinDateRange = (field, from, to) => {
      if (!from && !to) return true;
      const date = new Date(row[field]);
      const fromDate = from ? new Date(from) : null;
      const toDate = to ? new Date(to) : null;
      return (!fromDate || date >= fromDate) && (!toDate || date <= toDate);
    };
    const matchesDateFilters = withinDateRange("dueDate", dateFilters.dueDateFrom, dateFilters.dueDateTo) &&
                               withinDateRange("poDate", dateFilters.poDateFrom, dateFilters.poDateTo) &&
                               withinDateRange("shipped", dateFilters.shippedDateFrom, dateFilters.shippedDateTo);

    // Checkbox filters
    const matchesCheckboxFilters = (!status.shipmentBooked || row.shipmentBooked === status.shipmentBooked) &&
                                   (!status.orderShipmentEmailSent || row.orderShipmentEmailSent === status.orderShipmentEmailSent) &&
                                   (!status.invoiceRequestSent || row.invoiceRequestSent === status.invoiceRequestSent) &&
                                   (!status.serialNumbersReceived || row.serialNumbersReceived === status.serialNumbersReceived) &&
                                   (!status.orderUploaded || row.orderUploaded === status.orderUploaded);

    // Combine all filters
    return matchesSearchTerm && matchesDateFilters && matchesCheckboxFilters;
  });

  // Sort filtered rows
  const sorted = filtered.sort((a, b) => {
    const key = sortConfig.key;
    if (!key) return 0; // No sorting if no key
    const dateA = new Date(a[key]);
    const dateB = new Date(b[key]);
    return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
  });

  return sorted;
};

var filteredData = applyFiltersAndSorting();
//send this refreshed row back to styled component.
const refreshRowData = async () => {
  setLoading(true);
  try {
    const params = {
      createdByEmail: salesHeadName,
      searchTerm,
      contractType:contractType,
      dueDateFrom: dateFilters.dueDateFrom,
      dueDateTo: dateFilters.dueDateTo,
      poDateFrom: dateFilters.poDateFrom,
      poDateTo: dateFilters.poDateTo,
      shippedDateFrom: dateFilters.shippedDateFrom,
      shippedDateTo: dateFilters.shippedDateTo,
    };

    const response = await AxiosAPIInstance.get("/Tracker/DHS/Search", { params });
    setLoading(false);

    if (response?.data.length > 0) {
      setTotalData(response.data);
      filteredData = applyFiltersAndSorting();
      setSalesHeadError(false);
    } else {
      setTotalData([]);
      setSalesHeadError(true);
      setSalesHeadErrorMsg("No Records Found");
    }
  } catch (error) {
    setLoading(false);
    setSalesHeadError(true);
    setSalesHeadErrorMsg("Something went wrong while refreshing data.");
    console.error("Failed to refresh row data:", error);
  }
};
const onUpdateRow = (index, updatedRowData) => {
  setTotalData(prevData => {
    const newData = [...prevData];
    newData[index] = updatedRowData;  // Update the specific row in the local state
    return newData;
  });
};

  return (
    <>
      <ApplicationBar />
      {/* Error dialog for error messages */}
      <Dialog open={salesHeadError} onClose={handleCloseErrorDialog} aria-labelledby="error-dialog-title" aria-describedby="error-dialog-description">
        <DialogTitle id="error-dialog-title">
          <Alert variant='filled' severity={salesHeadErrorMsg === "No Records Found" ? "warning" : "error"}>
            {salesHeadErrorMsg === "No Records Found" ? "Status" : "Error"}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
            {salesHeadErrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog}>OK</Button>
        </DialogActions>
      </Dialog>
      {successDialog === true && (<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {trackerMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {trackerMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false)
          }
          } href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog === true && (<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {trackerMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {trackerMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false);
          }} href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
      <StyledTableContainer component={Paper}>
        <Stack sx={{ marginTop: "60px" }} direction="row" justifyContent="center">
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>Tracker Search (DHS)</Typography>
        </Stack>

        <Box margin="20px" display="flex" justifyContent="center">
          <TextField
            variant="outlined"
            placeholder="Search Submitted By, Order Number, System, Position, PO #"
            
            sx={{ flex:0.5,marginRight: '20px' }}
            onChange={handleSearchChange}
          />
          <FormControl sx={{ minWidth: '350px' }}>
            <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
            <Select labelId="Sales-Head-id" value={salesHeadName}  sx={{marginRight: '20px'}} size='small' variant='standard' onChange={(e) => setSalesHeadName(e.target.value)}>
              <MenuItem value='All'>All</MenuItem>
              {usersData.map((user, index) => (
                <MenuItem key={index} value={user.User_email.toLowerCase()}>{user.User_email.toLowerCase()}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Contract Type</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="contractType"
                  value={contractType}
                  onChange={(e)=>setContractType(e.target.value)}
                  variant='standard'
                >
                    <MenuItem value='all'>All</MenuItem>
                  {contractTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
           
              </FormControl>
        </Box>

        <Stack direction='row' spacing={4} justifyContent='center'>
  {/* Due Date Filters */}
  <Box display="flex" flexDirection="column" alignItems="center" padding="10px" border="1px solid #ccc" borderRadius="8px" marginRight="10px">
    <Typography variant="subtitle1" marginBottom="10px">Due Date</Typography>
    <TextField label="From" type="date" value={dateFilters.dueDateFrom} onChange={(e) => handleDateFilterChange('dueDateFrom', e.target.value)} InputLabelProps={{ shrink: true }} />
    <TextField label="To" type="date" value={dateFilters.dueDateTo} onChange={(e) => handleDateFilterChange('dueDateTo', e.target.value)} InputLabelProps={{ shrink: true }} />
  </Box>

  {/* PO Date Filters */}
  <Box display="flex" flexDirection="column" alignItems="center" padding="10px" border="1px solid #ccc" borderRadius="8px" marginRight="10px" >
    <Typography variant="subtitle1" marginBottom="10px">PO Date</Typography>
    <TextField label="From" type="date" value={dateFilters.poDateFrom} onChange={(e) => handleDateFilterChange('poDateFrom', e.target.value)} InputLabelProps={{ shrink: true }} />
    <TextField label="To" type="date"  value={dateFilters.poDateTo} onChange={(e) => handleDateFilterChange('poDateTo', e.target.value)} InputLabelProps={{ shrink: true }} />
  </Box>

  {/* Shipped Date Filters */}
  <Box display="flex" flexDirection="column" alignItems="center" padding="10px" border="1px solid #ccc" borderRadius="8px">
    <Typography variant="subtitle1" marginBottom="10px">Shipped Date</Typography>
    <TextField label="From" type="date" value={dateFilters.shippedDateFrom} onChange={(e) => handleDateFilterChange('shippedDateFrom', e.target.value)} InputLabelProps={{ shrink: true }} />
    <TextField label="To" type="date" value={dateFilters.shippedDateTo} onChange={(e) => handleDateFilterChange('shippedDateTo', e.target.value)} InputLabelProps={{ shrink: true }} />
  </Box>
  <Box margin="20px" display="flex" flexDirection="column">

      {/* Shipment Booked */}
      <FormControlLabel sx={{fontSize:'12px'}}
        control={
          <Checkbox
            checked={status.shipmentBooked} sx={{fontSize:'12px'}}
            size='small'
            onChange={(e) => handleStatusChange('shipmentBooked', e.target.checked)}
          />
        }
        label="Shipment Booked"
      />

      {/* Order Shipment Email Sent */}
      <FormControlLabel
        control={
          <Checkbox
            checked={status.orderShipmentEmailSent}sx={{fontSize:'12px'}}
            size='small'
            onChange={(e) => handleStatusChange('orderShipmentEmailSent', e.target.checked)}
          />
        }
        label="Order Shipment Email Sent"
      />

      {/* Invoice Request Sent */}
      <FormControlLabel
        control={
          <Checkbox
            checked={status.invoiceRequestSent}sx={{fontSize:'12px'}}
            size='small'
            onChange={(e) => handleStatusChange('invoiceRequestSent', e.target.checked)}
          />
        }
        label="Invoice Request Sent"
      />

    </Box>
    <Box margin="20px" display="flex" flexDirection="column">     
      {/* Serial Numbers Received */}
      <FormControlLabel
        control={
          <Checkbox
            checked={status.serialNumbersReceived}sx={{fontSize:'12px'}}
            size='small'
            onChange={(e) => handleStatusChange('serialNumbersReceived', e.target.checked)}
          />
        }
        label="Serial Numbers Received"
      />

      {/* Order Uploaded */}
      <FormControlLabel
        control={
          <Checkbox
            checked={status.orderUploaded}sx={{fontSize:'12px'}}
            size='small'  
            onChange={(e) => handleStatusChange('orderUploaded', e.target.checked)}
          />
        }
        label="Order Uploaded"
      />
          <Stack sx={{ marginTop: "10px", marginBottom: "10px" }} spacing={1} direction="row" justifyContent="center">
          <Button sx={{ width: "200px",backgroundColor:indigo[900],":hover": { backgroundColor: indigo[500],}  }} size="small" variant="contained"  onClick={handleClearFilters}>Clear Filters</Button>
        </Stack>
      </Box>
</Stack>

        <Stack sx={{ marginTop: "10px", marginBottom: "10px" }} spacing={1} direction="row" justifyContent="center">
          <Button sx={{ width: "200px" }} size="small" variant="contained" color='success' onClick={fetchData}>Get</Button>
        </Stack>

        {loading ? <LoadingDialog open={loading} /> : (
          <Table stickyHeader>
            <StyledTableHead>
              <TableRow>
                {tableHeaders.map(({ label, key, isDate }) => (
                  <TableCell key={key} sx={{ bgcolor: blueGrey[800] }}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <strong>{label}</strong>
                      {isDate && (
                        <IconButton size="small" onClick={() => handleSort(key)} aria-label={`sort ${label}`}>
                          {sortConfig.key === key && sortConfig.direction === 'asc' ? (
                            <ArrowUpwardIcon sx={{color:orange[900]}} />
                          ) : (
                            <ArrowDownwardIcon sx={{color:orange[900]}} />
                          )}
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
             <StyledTableRowComponent key={rowIndex} index={rowIndex} refreshRowData={refreshRowData} onUpdateRow={onUpdateRow}  usersData={usersData} row={row} />
              ))}
              {!filteredData.length && (
                <TableRow>
                  <TableCell colSpan={tableHeaders.length} align="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      No data available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledTableContainer>
    </>
  );
};

export default TrackerTable;
