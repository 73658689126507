import React, { useState, useEffect } from 'react';
import {
  Container, TextField, Typography, Box, Paper, FormControl, InputLabel,
  Select, MenuItem, Stack, Divider, Grid, Button, Card, CardContent,
  CardActionArea, CardActions, Slider, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, IconButton, Avatar,
  Table, TableBody, TableHead, TableCell, TableRow
} from '@mui/material';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer,
  PieChart, Pie, Cell, LabelList, Line, ReferenceLine, RadialBarChart, RadialBar
} from 'recharts';
import ApplicationBar from '../../../AppBar/ApplicationBar';
import UserAuth from "../../../ProtectedRoute/userAuth";
import { orange, green, red, blueGrey, blue, indigo } from '@mui/material/colors';
import LoadingDialog from '../../../Loading';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import AxiosFunction from '../../../../axiosCustomInstance';
import { Email, Phone } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import { DatePicker } from "@mui/x-date-pickers";
import PdfDocument from "../../../PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MergeIcon from '@mui/icons-material/Merge';
import HistoryIcon from '@mui/icons-material/History';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-router-dom'; // Import Link
import RFQ_CLIENT_INPUT_UPDATE from '../../rfqUpdate';
// import RfqMerge from './rfqMerge';

// Main component for RFQ Merge Search functionality
const RFQs_Merge_Search = ({row}) => {
  // States to hold various data
  const [chartData, setChartData] = useState([]); // Data for charts by count
  const [chartDataByDollarValue, setChartDataByDollarValue] = useState([]); // Data for charts by dollar value
  const [chartDataByProfitMargin, setChartDataByProfitMargin] = useState([]); // Data for charts by profit margin
  const { UserName, UserEmail } = UserAuth(); // Get user information
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // State for selected year
  const [selectedContractVehicle, setSelectedContractVehicle] = useState('All'); // State for selected contract vehicle
  const [dialogMssge, setDialogMssge] = useState(''); // Message for dialog
  const [successDialog, setSuccessDialog] = useState(false); // State for success dialog
  const [errorDialog, setErrorDialog] = useState(false); // State for error dialog
  const [loading, setLoading] = useState(false); // Loading state
  const [dis, setDis] = useState(false); // Disable state for actions
  const AxiosAPIInstance = AxiosFunction(); // Axios instance for API calls
  let [usersData, setUsersData] = useState([{ User_email: "" }]); // State for users data
  const [salesHeadError, setSalesHeadError] = useState(false); // Error state for Sales Head
  const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState(""); // Error message for Sales Head
  const [barHeights, setBarHeights] = useState([]); // Bar heights for charts
  const [averageValue, setAverageValue] = useState(0); // Average value for profit margin
  const [pieChartData, setPieChartData] = useState([
    { name: 'PENDING', value: 1000, dollarValue: 0 },
    { name: 'WON', value: 2000, dollarValue: 0 },
    { name: 'LOST', value: 500, dollarValue: 0 },
  ]); // Data for pie chart by status

  const [pieChartDataProfitMargin, setPieChartDataProfitMargin] = useState([
    { name: 'PENDING', value: 1000, dollarValue: 0 },
    { name: 'WON', value: 2000, dollarValue: 0 },
    { name: 'LOST', value: 500, dollarValue: 0 },
  ]); // Data for pie chart by profit margin
  const [totalQuotes, setTotalQuotes] = useState(0); // Total quotes count
  const [conversionRate, setConversionRate] = useState(0); // Conversion rate
  const [averageDealSize, setAverageDealSize] = useState(0); // Average deal size
  const [totalRevenue, setTotalRevenue] = useState(0); // Total revenue
  const [totalProfit, setTotalProfit] = useState(0); // Total profit
  const [totalProfitPercent, setTotalProfitPercent] = useState(0); // Total profit percentage
  const [wonQuotes, setWonQuotes] = useState([]); // Won quotes
  const [lostQuotes, setLostQuotes] = useState([]); // Lost quotes
  const [pendingQuotes, setPendingQuotes] = useState([]); // Pending quotes
  const [allQuotes, setAllQuotes] = useState([]); // All quotes
  const [searchTerm, setSearchTerm] = useState(''); // Search term
  const [clicked, setClicked] = useState(false); // State for button click
  const [mergedQuotes, setMergedQuotes] = useState([]); // Merged quotes
  const [viewQuote, setViewQuote] = useState(false); // State for viewing quote details
  const [selectedQuote, setSelectedQuote] = useState(null); // State for selected quote

  const PD = new Date(); // Current date
  const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium'
  }).format(PD); // Formatted current date

  // State for form values
  const [Form_Value, Set_Form_Value] = useState([{
    Opportunity_Name: '', RFQ_ID: '', Invoice_No: '', Contract_Vehicle: '', KO_name: '', KO_email: '',
    KO_phone: '', KO_address: '', Published_date: null, Due_date: null, Brief: '', Organization_Name: '',
    Customer_Name: '', Customer_Email: '', Customer_Phone: '', Customer_Address: '', Shipping_Method: '', Sales_Head_Name: '', Sales_Head_Email: String(UserEmail).toLowerCase() || "sales@aspettoinc.com", Sales_Head_Ph: "",
    Shipping_Terms: 'FOB Origin', Estimated_Delivery: '', Payment_Terms: 'NET30', Notes: '', Shipping_Cost: Number(parseFloat(0).toFixed(2)),
    Tax: Number(parseFloat(0).toFixed(2)), Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
    Created_Date: today,
    Last_Updated_Date: today, RFQ_Status: 'PENDING',
    Updated_By: 'TBD', Version_No: parseInt(0), Updating_Comments: 'RFQ Created under PENDING Status'
  }]);

  const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
  const [sortField, setSortField] = useState('Created_Date'); // State for sorting field
  const [dateRange, setDateRange] = useState([null, null]); // State for date range filter
  const [marginRange, setMarginRange] = useState([0, 100]); // State for margin range filter

  // Fetch users data on component mount
  useEffect(() => {
    const fetchUsersData = async () => {
      setLoading(true);
      setClicked(false);
      try {
        const result = await AxiosAPIInstance.get("/UserAuth/users");
        setLoading(false);
        const users = [...result?.data];
        Set_Form_Value({ ...Form_Value, Sales_Head_Email: String(UserEmail).toLowerCase() });
        setUsersData(users);
        setSalesHeadError(false);
        setSalesHeadErrorMsge("");
      } catch (error) {
        setLoading(false);
        setSalesHeadError(true);
        setSalesHeadErrorMsge(error?.response.data);
      }
    };

    fetchUsersData();
  }, []);


  // Fetch chart data on component mount or when selected year/contract vehicle changes
  useEffect(() => {
    const fetchData = async () => {
      setDis(true);
      setLoading(true);

      try {
        const response = await AxiosAPIInstance.get('/GetYourPerformance', {
          params: {
            Sales_Head_Email: String(Form_Value.Sales_Head_Email).toLowerCase(),
            Contract_Vehicle: selectedContractVehicle,
            Year: selectedYear
          },
        });

        const fetchedData = response.data;

        // Initialize data for all months
        const allMonths = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
            PENDING_DOLLAR: 0,
            WON_DOLLAR: 0,
            LOST_DOLLAR: 0,
          };
        });

        // Update chart data based on fetched data
        const updatedChartData = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex(item => item.name === monthYear);

          if (monthIndex !== -1) {
            if (entry.RFQ_Status === 'PENDING') {
              acc[monthIndex].PENDING += 1;
              acc[monthIndex].PENDING_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'WON') {
              acc[monthIndex].WON += 1;
              acc[monthIndex].WON_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'LOST') {
              acc[monthIndex].LOST += 1;
              acc[monthIndex].LOST_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            }
          }

          return acc;
        }, allMonths);

        // Sort chart data by date
        updatedChartData.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartData(updatedChartData);

        const allMonthsByDollarValue = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
          };
        });

        const updatedChartDataByDollarValue = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex(item => item.name === monthYear);

          if (monthIndex !== -1) {
            if (entry.RFQ_Status === 'PENDING') {
              acc[monthIndex].PENDING += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'WON') {
              acc[monthIndex].WON += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'LOST') {
              acc[monthIndex].LOST += parseFloat(entry.Actual_Total_Cost_After_Margin);
            }
          }

          return acc;
        }, allMonthsByDollarValue);

        updatedChartDataByDollarValue.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartDataByDollarValue(updatedChartDataByDollarValue);

        const allMonthsByProfitMargin = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
            height: 0,
          };
        });

        const updatedMonthsByProfitMargin = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex((item) => item.name === monthYear);

          if (monthIndex !== -1) {
            const height = parseFloat(entry.Actual_Total_Cost_After_Margin) - parseFloat(entry.Actual_Total_Cost_Before_Margin);

            acc[monthIndex].PENDING += entry.RFQ_Status === 'PENDING' ? height : 0;
            acc[monthIndex].WON += entry.RFQ_Status === 'WON' ? height : 0;
            acc[monthIndex].LOST += entry.RFQ_Status === 'LOST' ? height : 0;
            acc[monthIndex].height += height;
          }

          return acc;
        }, allMonthsByProfitMargin);

        updatedMonthsByProfitMargin.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartDataByProfitMargin(updatedMonthsByProfitMargin);
        setBarHeights(updatedMonthsByProfitMargin.map((entry) => entry.height));

        const calculateAverageHeight = () => {
          const averageHeight = updatedMonthsByProfitMargin.reduce((acc, entry) => acc + entry.height, 0) / updatedMonthsByProfitMargin.length;
          return averageHeight;
        };

        setAverageValue(calculateAverageHeight());

        const pieData = [
          { name: 'PENDING', value: 0, dollarValue: 0 },
          { name: 'WON', value: 0, dollarValue: 0 },
          { name: 'LOST', value: 0, dollarValue: 0 },
        ];

        updatedChartDataByDollarValue.forEach(entry => {
          pieData[0].value += entry.PENDING;
          pieData[0].dollarValue += parseFloat(entry.PENDING_DOLLAR);
          pieData[1].value += entry.WON;
          pieData[1].dollarValue += parseFloat(entry.WON_DOLLAR);
          pieData[2].value += entry.LOST;
          pieData[2].dollarValue += parseFloat(entry.LOST_DOLLAR);
        });

        setPieChartData(pieData);

        const pieDataProfitMargin = [
          { name: 'PENDING', value: 0, dollarValue: 0 },
          { name: 'WON', value: 0, dollarValue: 0 },
          { name: 'LOST', value: 0, dollarValue: 0 },
        ];

        updatedMonthsByProfitMargin.forEach(entry => {
          pieDataProfitMargin[0].value += entry.PENDING;
          pieDataProfitMargin[0].dollarValue += parseFloat(entry.PENDING_DOLLAR);
          pieDataProfitMargin[1].value += entry.WON;
          pieDataProfitMargin[1].dollarValue += parseFloat(entry.WON_DOLLAR);
          pieDataProfitMargin[2].value += entry.LOST;
          pieDataProfitMargin[2].dollarValue += parseFloat(entry.LOST_DOLLAR);
        });

        setPieChartDataProfitMargin(pieDataProfitMargin);

        const totalQuotes = fetchedData.length;
        const allQuotes = fetchedData;
        const totalWonQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'WON').length;
        const totalPendingQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'PENDING').length;
        const totalLostQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'LOST').length;

        const totalRevenue = fetchedData.filter(entry => entry.RFQ_Status === 'WON').reduce((acc, entry) => acc + parseFloat(entry.Actual_Total_Cost_After_Margin), 0);
        const averageDealSize = totalWonQuotes > 0 ? totalRevenue / totalWonQuotes : 0;
        const conversionRate = totalQuotes > 0 ? (totalWonQuotes / totalQuotes) * 100 : 0;

        const totalProfit = fetchedData.reduce((acc, entry) => acc + (parseFloat(entry.Actual_Total_Cost_After_Margin) - parseFloat(entry.Actual_Total_Cost_Before_Margin)), 0);
        const totalProfitPercent = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0;

        setTotalQuotes(totalQuotes);

        setConversionRate(conversionRate);
        setAverageDealSize(averageDealSize);
        setTotalRevenue(totalRevenue);
        setTotalProfit(totalProfit);
        setTotalProfitPercent(totalProfitPercent);

        const wonQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'WON');
        setWonQuotes(wonQuotes);
        const pendingQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'PENDING');
        setPendingQuotes(pendingQuotes);
        const lostQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'LOST');
        setLostQuotes(lostQuotes);

        setAllQuotes(allQuotes)

      } catch (error) {
        setErrorDialog(true);
        setSuccessDialog(false);
        setDialogMssge(error?.response?.data);
      } finally {
        setDis(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedYear, selectedContractVehicle, Form_Value?.Sales_Head_Email]);

  const COLORS = [orange[600], green[600], red[600]]; // Colors for pie chart



  // Close dialog handler
  const handleClose = async () => {
    setDis(false);
    setErrorDialog(false);
    setSuccessDialog(false);
    setDialogMssge('');
    setClicked(false);
    setViewQuote(false); // Close quote details dialog
    setSelectedQuote(null); // Reset selected quote
  };

  // Handle change in year selection
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setClicked(false);
  };

  // Handle change in contract vehicle selection
  const handleContractVehicleChange = (event) => {
    setSelectedContractVehicle(event.target.value);
    setClicked(false);
  };

  // Format currency values for display
  const formatCurrency = (value) => `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

  // Calculate average profit margin per status
  const calculateAverageProfitMarginPerStatus = (status) => {
    const filteredData = chartDataByProfitMargin.filter(entry => entry[status] > 0);
    const totalProfitMargin = filteredData.reduce((acc, entry) => acc + entry[status], 0);
    const averageProfitMarginDollar = filteredData.length > 0 ? totalProfitMargin / filteredData.length : 0;
    const totalDollarValue = chartDataByDollarValue.reduce((acc, entry) => acc + entry[status], 0);
    const averageProfitMarginPercent = totalDollarValue > 0 ? (totalProfitMargin / totalDollarValue) * 100 : 0;

    return { averageProfitMarginDollar, averageProfitMarginPercent };
  };

  const pendingProfitMargin = calculateAverageProfitMarginPerStatus('PENDING');
  const wonProfitMargin = calculateAverageProfitMarginPerStatus('WON');
  const lostProfitMargin = calculateAverageProfitMarginPerStatus('LOST');

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filter quotes based on search term
  const filteredQuotes = allQuotes.filter((quote) =>
    quote.Opportunity_Name.toLowerCase().includes(searchTerm) ||
    quote.Customer_Email.toLowerCase().includes(searchTerm) ||
    quote.Customer_Phone.includes(searchTerm) ||
    quote.Contract_Vehicle.toLowerCase().includes(searchTerm) ||
    quote.Invoice_No.toLowerCase().includes(searchTerm) ||
    quote.RFQ_Status.toLowerCase().includes(searchTerm) ||
    quote.Customer_Name.toLowerCase().includes(searchTerm) ||
    quote.KO_name.toLowerCase().includes(searchTerm) ||
    quote.Organization_Name.toLowerCase().includes(searchTerm)
  );

  // Handle change in sort field
  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  // Handle change in sort order
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  // Handle date range change
  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange);
  };

  // Handle margin range change
  const handleMarginRangeChange = (event, newRange) => {
    setMarginRange(newRange);
  };

  // Sort quotes based on selected field and order
  const sortedQuotes = [...filteredQuotes].sort((a, b) => {
    if (sortField === 'Created_Date') {
      const dateA = new Date(a.Created_Date);
      const dateB = new Date(b.Created_Date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    } else if (sortField === 'Revenue') {
      const revenueA = parseFloat(a.Actual_Total_Cost_After_Margin);
      const revenueB = parseFloat(b.Actual_Total_Cost_After_Margin);
      return sortOrder === 'asc' ? revenueA - revenueB : revenueB - revenueA;
    }
    return 0;
  });

  // Filter and sort quotes based on date and margin ranges
  const filteredAndSortedQuotes = sortedQuotes.filter((quote) => {
    const quoteDate = new Date(quote.Created_Date);
    const margin = ((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin)) * 100;

    return (
      (dateRange[0] === null || quoteDate >= dateRange[0]) &&
      (dateRange[1] === null || quoteDate <= dateRange[1]) &&
      margin >= marginRange[0] &&
      margin <= marginRange[1]
    );
  })

  // Generate PDF file name
  var File_Opportunity_Name = ''
  const [InvoiceData, setInvoiceData] = useState({
    invoice_no: "",
    Contract_Vehicle: "",
    KO_name: "",
    KO_email: "",
    KO_phone: "",
    KO_address: "",
    Print_Date: String(today),
    Valid_For: "30 Days",
    trans_date: "",
    due_date: "",
    Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax: 0,
    Shipping_Cost: 0,
    Total_Cost: 0,
    Shipping_Details: {
      Organization_Name: "",
      KO_name: "",
      Sales_Head_Info: {},
      Customer_Name: "",
      Customer_Email: "",
      Customer_Phone: "",
      Customer_Address: "",
      Shipping_Method: "",
      Shipping_Terms: "",
      Estimated_Delivery: "",
      Payment_Terms: "",
      Notes: ""
    }
  })

  const navigate = useNavigate();
  // Function to navigate to the UpdateRFQ page with parameters
  const RFQID_Search = (quote) => {
    setSelectedQuote(quote); // Set the selected quote for the dialog
    setViewQuote(true); // Open the dialog
  };

  // Handle PDF generation and download
  const HandlePDFClick = async (quote) => {
    // Capitalizing KOs, COs, and customers' names
    const arr = String(quote.Sales_Head_Name).toLowerCase().split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const KO_arr = String(quote.KO_name).toLowerCase().split(" ");
    for (var i = 0; i < KO_arr.length; i++) {
      KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
    }

    const CO_arr = String(quote.Customer_Name).toLowerCase().split(" ");
    for (var i = 0; i < CO_arr.length; i++) {
      CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
    }

    // Prepare invoice data for PDF generation
    const updatedInvoiceData = {
      ...InvoiceData,
      invoice_num: String(quote.Invoice_No),
      Contract_Vehicle: quote.Contract_Vehicle,
      KO_name: KO_arr.join(" "),
      KO_email: quote.KO_email,
      KO_phone: quote.KO_phone,
      KO_address: quote.KO_address,
      Print_Date: String(today),
      trans_date: String(quote.Last_Updated_Date).split('T')[0],
      due_date: quote.Due_date,
      Valid_For: quote.Valid_For,
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: quote.Purchase_Orders,
      Tax: quote.Tax,
      Shipping_Cost: quote.Shipping_Cost,
      Total_Cost: quote.Actual_Total_Cost_After_Margin,
      Other_Cost_1: quote.Other_Cost_1,
      Other_Cost_2: quote.Other_Cost_2,
      Other_Cost_3: quote.Other_Cost_3,
      Other_Cost_Name1: quote.Other_Cost_Name1,
      Other_Cost_Name2: quote.Other_Cost_Name2,
      Other_Cost_Name3: quote.Other_Cost_Name3,
      Shipping_Details: {
        Organization_Name: quote.Organization_Name,
        KO_name: quote.KO_name,
        Sales_Head_Info: {
          Name: arr.join(" "),
          Email: quote.Sales_Head_Email,
          Phone: quote.Sales_Head_Ph
        },
        Customer_Name: CO_arr.join(" "),
        Customer_Email: quote.Customer_Email,
        Customer_Phone: quote.Customer_Phone,
        Customer_Address: quote.Customer_Address,
        Shipping_Method: quote.Shipping_Method,
        Shipping_Terms: quote.Shipping_Terms,
        Estimated_Delivery: quote.Estimated_Delivery,
        Payment_Terms: quote.Payment_Terms,
        Notes: quote.Notes
      }
    };

    // Generate file name for PDF
    const OP_arr = String(quote.Opportunity_Name).split(".");
    for (var i = 0; i < OP_arr.length; i++) {
      OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
    }
    File_Opportunity_Name = OP_arr.join(" ")

    setInvoiceData(updatedInvoiceData);  // Set state

    setTimeout(async () => {  // Use a timeout to ensure state is updated before generating PDF
      const pdfBlob = await pdf(<PdfDocument invoicedata={updatedInvoiceData} />).toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${File_Opportunity_Name} ${String(quote.Invoice_No).split('.')[0]}${String(quote.Invoice_No).split('.')[1]}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);  // Delay the PDF generation to allow state update
  };

  // Handle view quote click
  const handleViewQuoteClick = (quote) => {
    setSelectedQuote(quote); // Set the selected quote details
    setViewQuote(true); // Open the dialog
  };

  // Handle adding a quote to merge
  const handleAddToMerge = (quote) => {
    setMergedQuotes((prevQuotes) => [...prevQuotes, quote]);
    setAllQuotes((prevQuotes) => prevQuotes.filter(q => q.RFQ_ID !== quote.RFQ_ID));
  };

  // Handle removing a quote from merge
  const handleRemoveFromMerge = (quote) => {
    setMergedQuotes((prevQuotes) => prevQuotes.filter(q => q.RFQ_ID !== quote.RFQ_ID));
    setAllQuotes((prevQuotes) => [...prevQuotes, quote]);
  };

  // Check if a quote is added to merge
  const isAddedToMerge = (quote) => {
    return mergedQuotes.some(q => q.RFQ_ID === quote.RFQ_ID);
  };

  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {successDialog && (
        <Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogMssge && <Alert variant="filled" severity="success">SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
              {dialogMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => {
              e.preventDefault();
              handleClose();
              setDis(false);
            }} href="/Dashboard/RFQInput">OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorDialog && (
        <Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogMssge && <Alert variant="filled" severity="error">ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
              {dialogMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => {
              e.preventDefault();
              handleClose();
              setDis(false);
            }} href="/Dashboard/RFQInput">OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {viewQuote && selectedQuote && (
        <Dialog
          open={viewQuote}
          onClose={handleClose}
          aria-labelledby="quote-dialog-title"
          aria-describedby="quote-dialog-description"
          maxWidth='x-md'
        >
          <DialogTitle id="quote-dialog-title">
            <Alert variant="filled" sx={{ bgcolor: blueGrey[400] }}>RFQ Details</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="quote-dialog-description" sx={{ color: 'black' }}>
              <Table aria-label="purchases" sx={{ border: 1, borderColor: 'text.main' }}>
                <TableHead sx={{ backgroundColor: blueGrey[400] }}>
                  <TableRow>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', fontWeight: 'bold', bgcolor: orange[200], fontWeight: 'bold' }}>Vendor/Partner Name</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold',fontSize:'12.5px' }}>SKU ID</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">SKU Description</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">QTY</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">UM</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">Aspetto's Unit Cost($)</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">Margin(%)</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">Customer's Unit Cost($)</TableCell>
                    <TableCell sx={{ color: "black", fontFamily: 'Verdana (sans-serif)', bgcolor: orange[200], fontWeight: 'bold' ,fontSize:'12.5px' }} align="center">Total SKU Cost($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedQuote["Purchase_Orders"]?.map((Purchase_OrderROW, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Purchase_OrderROW.Vendor_Partner_name}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Purchase_OrderROW.SKU_ID}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Purchase_OrderROW.SKU_Description}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Purchase_OrderROW.QTY}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Purchase_OrderROW.Unit_Of_Measure}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Number(Purchase_OrderROW.Aspetto_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Purchase_OrderROW.Margin_Percent}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Number(Purchase_OrderROW.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary' ,fontSize:'12.5px' }} align="center">{Number(Purchase_OrderROW.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <Container>
        <Stack direction="column" justifyContent="center" sx={{ width: '100%' }} spacing={2}>
          {/* <RfqMerge row={row}/> */}
          <Box display="flex" justifyContent="center" mt={2} mb={4}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <FormControl size="small" sx={{ width: '250px' }}>
                <InputLabel id="Select-Year">Select Year</InputLabel>
                <Select value={selectedYear} onChange={handleYearChange} variant="standard">
                  {[...Array(5)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ width: '250px' }}>
                <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
                <Select
                  size="small"
                  labelId="Contract-Vehicle"
                  id="Contract-Vehicle-id"
                  value={selectedContractVehicle}
                  variant="standard"
                  label="Contract Vehicle"
                  onChange={handleContractVehicleChange}
                  sx={{ width: '250px' }}
                >
                  <MenuItem size="small" value="All">All</MenuItem>
                  <MenuItem size="small" value="Federal">Federal</MenuItem>
                  <MenuItem size="small" value="Military">Military</MenuItem>
                  <MenuItem size="small" value="Tactical">Tactical</MenuItem>
                  <MenuItem size="small" value="GSA">GSA</MenuItem>
                  <MenuItem size="small" value="FBOP">FBOP</MenuItem>
                  <MenuItem size="small" value="DHS-CBP">DHS-CBP</MenuItem>
                  <MenuItem size="small" value="SAM/Open Market">SAM/Open Market</MenuItem>
                  <MenuItem size="small" value="Unison">Unison</MenuItem>
                  <MenuItem size="small" value="Direct Inquiry">Direct Inquiry</MenuItem>
                  <MenuItem size="small" value="Commercial">Commercial</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
                <Select
                  sx={{ width: '300px' }}
                  variant="standard"
                  label="Sales Head Email ID"
                  labelId="Sales-Head-id"
                  value={String(Form_Value.Sales_Head_Email).toLowerCase()}
                  size="small"
                  onChange={(e) => Set_Form_Value({ ...Form_Value, Sales_Head_Email: e.target.value })}
                >
                  <MenuItem value="all">All</MenuItem>
                  {usersData?.map((row, index) => (
                    <MenuItem key={index} value={String(row?.User_email).toLowerCase()}>
                      {String(row?.User_email).toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
          {/* Key Metrics */}
          <Grid container spacing={2}>
            <Divider sx={{ bgcolor: 'orange', width: '100%', height: '1.5px', marginTop: '20px' }} orientation="horizontal" />
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} alignItems="center">
                <TextField
                  helperText="Search By Customer Email/Quote Number/Quote Status/Opportunity Name/Organization Name/Contract Vehicle/Phone Number/KO & Customer Names"
                  label="Search"
                  size='small'
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ flex: 1 }}
                ></TextField>
              </Stack>
              <Box mt={2}>
                <Typography gutterBottom sx={{fontSize:'13px',fontWeight:'bold'}}>Filter by Margin Percentage Range</Typography>
                <Slider
                  value={marginRange}
                  onChange={handleMarginRangeChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={1}
                  marks
                />
              </Box>
              <Stack direction='row'>
                <Grid item xs={6}>
                  <Typography variant="h4" gutterBottom>
                    Quotes
                  </Typography>
                  <Grid container spacing={2}>
                    {filteredAndSortedQuotes.map((quote, index) => (
                      <Grid item xs={6} key={index}>
                        <Card sx={{ border: 1, borderColor: blueGrey[800], padding: 1.5 }}>
                          <CardActionArea>
                            <CardContent>
                              <Typography variant="h5" component="div" gutterBottom sx={{ color: blueGrey[900], fontWeight: 'bold' }}>
                                {quote.Opportunity_Name}
                              </Typography>
                              {quote?.RFQ_Status === 'WON' && (
                                <Button color='success' variant='contained' size='small'  sx={{fontSize:'12.5px'}}>WON</Button>
                              )}
                              {quote?.RFQ_Status === 'LOST' && (
                                <Button color='error' variant='contained' size='small'  sx={{fontSize:'12.5px'}}>LOST</Button>
                              )}
                              {quote?.RFQ_Status === 'CANCELLED' && (
                                <Button color='info' variant='contained' size='small' sx={{fontSize:'12.5px'}} >CANCELLED</Button>
                              )}
                              {quote?.RFQ_Status === 'PENDING' && (
                                <Button color='warning' variant='contained' size='small'>PENDING</Button>
                              )}
                              <Typography variant="body2" sx={{ marginTop: "20px" }}></Typography>
                              <Typography variant="body2" sx={{fontSize:'12.5px'}} color="text.secondary">
                                <strong>Quote #: </strong> {quote?.Invoice_No}
                              </Typography>
                              <Typography variant="body2"  sx={{fontSize:'12.5px'}} color="text.secondary">
                                <strong>Cost Before Margin: </strong> {formatCurrency(quote.Actual_Total_Cost_Before_Margin)}
                              </Typography>
                              <Typography variant="body2"  sx={{fontSize:'12.5px'}} color="text.secondary">
                                <strong>Cost After Margin: </strong> {formatCurrency(quote.Actual_Total_Cost_After_Margin)}
                              </Typography>
                              <Typography variant="body2"  sx={{fontSize:'12.5px'}} color="text.secondary">
                                <strong>Profit Margin: </strong>{' '}
                                {((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin) * 100).toFixed(2)}
                                %
                              </Typography>
                              <Typography variant="body2"   sx={{fontSize:'12.5px'}} color="text.secondary">
                                <strong>Contract Vehicle: </strong> {quote.Contract_Vehicle}
                              </Typography>
                              <Box display="flex" alignItems="center" mt={1}>
                                <Email color="primary" />
                                <Typography variant="body2"  sx={{fontSize:'12.5px'}} color="text.secondary" ml={1}>
                                  {quote.Customer_Email}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center" mt={1}>
                                <Phone color="primary" />
                                <Typography variant="body2"  sx={{fontSize:'12.5px'}} color="text.secondary" ml={1}>
                                  {quote.Customer_Phone}
                                </Typography>
                              </Box>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <IconButton
                              name="View Quote"
                              variant="contained"
                              sx={{
                                color: 'white',
                                padding: '10px',
                                borderRadius: 1,
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  backgroundColor: indigo[100],
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewQuoteClick(quote);
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: indigo[800],
                                  width: '25px',
                                  height: '25px',
                                  transition: 'transform 0.2s ease-in-out',
                                  width:'70px',
                                  fontSize:'12.5px',
                                  '&:hover': {
                                    backgroundColor: indigo[800],
                                    transform: 'scale(1.1)',
                                  },
                                }}
                                variant="rounded"
                              >
                                {/* <VisibilityIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} /> */}
                                 SKUs
                              </Avatar>
                            </IconButton>
                            <IconButton
                              variant="text"
                              sx={{
                                color: 'white',
                                padding: '10px',
                                borderRadius: 1,
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  backgroundColor: red[100],
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                HandlePDFClick(quote);
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: red[800],
                                  width: '25px',
                                  height: '25px',
                                  transition: 'transform 0.2s ease-in-out',
                                  '&:hover': {
                                    backgroundColor: red[800],
                                    transform: 'scale(1.1)',
                                  },
                                }}
                                variant="rounded"
                              >
                                <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                            <IconButton
                              variant="text"
                              sx={{
                                color: 'white',
                                padding: '10px',
                                borderRadius: 1,
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  backgroundColor: green[100],
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                isAddedToMerge(quote) ? handleRemoveFromMerge(quote) : handleAddToMerge(quote);
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: green[800],
                                  width: '25px',
                                  height: '25px',
                                  transition: 'transform 0.2s ease-in-out',
                                  '&:hover': {
                                    backgroundColor: green[800],
                                    transform: 'scale(1.1)',
                                  },
                                }}
                                variant="rounded"
                              >
                                {isAddedToMerge(quote) ? (
                                  <RemoveIcon sx={{ color: 'white', backgroundColor: green[800], width: '20px', height: '20px' }} />
                                ) : (
                                  <AddIcon sx={{ color: 'white', backgroundColor: green[800], width: '20px', height: '20px' }} />
                                )}
                              </Avatar>
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ color: 'red', fontSize: "2px", mx: 2 }} />
                <Grid item xs={6}>
                  <Typography variant="h4" gutterBottom>
                    Merged Quotes
                  </Typography>
                  <Grid container spacing={2}>
                    {mergedQuotes.map((quote, index) => (
                      <Grid item xs={6} key={index}>
                        <Card sx={{ border: 1, borderColor: blueGrey[800], padding: 1.5 }}>
                          <CardActionArea>
                            <CardContent>
                              <Typography variant="h6" component="div" gutterBottom sx={{ color: blueGrey[900], fontWeight: 'bold' }}>
                                {quote.Opportunity_Name}
                              </Typography>
                              {quote?.RFQ_Status === 'WON' && (
                                <Button color='success' variant='contained' size='small' sx={{fontSize:'13px'}}>WON</Button>
                              )}
                              {quote?.RFQ_Status === 'LOST' && (
                                <Button color='error' variant='contained' size='small' sx={{fontSize:'13px'}}>LOST</Button>
                              )}
                              {quote?.RFQ_Status === 'CANCELLED' && (
                                <Button color='info' variant='contained' size='small' sx={{fontSize:'13px'}}>CANCELLED</Button>
                              )}
                              {quote?.RFQ_Status === 'PENDING' && (
                                <Button color='warning' variant='contained' size='small' sx={{fontSize:'13px'}}>PENDING</Button>
                              )}
                              <Typography variant="body2" sx={{ marginTop: "20px" }}></Typography>
                              <Typography variant="body2" color="text.secondary">
                                <strong>Quote #: </strong> {quote?.Invoice_No}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                <strong>Cost Before Margin: </strong> {formatCurrency(quote.Actual_Total_Cost_Before_Margin)}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                <strong>Cost After Margin: </strong> {formatCurrency(quote.Actual_Total_Cost_After_Margin)}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                <strong>Profit Margin: </strong>{' '}
                                {((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin) * 100).toFixed(2)}
                                %
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                <strong>Contract Vehicle: </strong> {quote.Contract_Vehicle}
                              </Typography>
                              <Box display="flex" alignItems="center" mt={1}>
                                <Email color="primary" />
                                <Typography variant="body2" color="text.secondary" ml={1}>
                                  {quote.Customer_Email}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center" mt={1}>
                                <Phone color="primary" />
                                <Typography variant="body2" color="text.secondary" ml={1}>
                                  {quote.Customer_Phone}
                                </Typography>
                              </Box>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                          <IconButton
                              name="View Quote"
                              variant="contained"
                              sx={{
                                color: 'white',
                                padding: '10px',
                                borderRadius: 1,
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  backgroundColor: indigo[100],
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleViewQuoteClick(quote);
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: indigo[800],
                                  width: '25px',
                                  height: '25px',
                                  transition: 'transform 0.2s ease-in-out',
                                  width:'70px',
                                  fontSize:'12.5px',
                                  '&:hover': {
                                    backgroundColor: indigo[800],
                                    transform: 'scale(1.1)',
                                  },
                                }}
                                variant="rounded"
                              >
                                {/* <VisibilityIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} /> */}
                                SKUs
                              </Avatar>
                            </IconButton>
                            <IconButton
                              variant="text"
                              sx={{
                                color: 'white',
                                padding: '10px',
                                borderRadius: 1,
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  backgroundColor: red[100],
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                HandlePDFClick(quote);
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: red[800],
                                  width: '25px',
                                  height: '25px',
                                  transition: 'transform 0.2s ease-in-out',
                                  '&:hover': {
                                    backgroundColor: red[800],
                                    transform: 'scale(1.1)',
                                  },
                                }}
                                variant="rounded"
                              >
                                <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                            <IconButton
                              variant="text"
                              sx={{
                                color: 'white',
                                padding: '10px',
                                borderRadius: 1,
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  backgroundColor: red[100],
                                  transform: 'scale(1.1)',
                                },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveFromMerge(quote);
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: red[800],
                                  width: '25px',
                                  height: '25px',
                                  transition: 'transform 0.2s ease-in-out',
                                  '&:hover': {
                                    backgroundColor: red[800],
                                    transform: 'scale(1.1)',
                                  },
                                }}
                                variant="rounded"
                              >
                                <RemoveIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Stack>
              <Grid container spacing={2}>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default RFQs_Merge_Search;
