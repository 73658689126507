import React, { useEffect, useState } from "react";
import {
Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel,
MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, Alert
} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import { blueGrey, yellow, orange, blue } from '@mui/material/colors';
import { createTheme, useTheme } from '@mui/material/styles';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { SaveAlt, Search as SearchIcon, Visibility as VisibilityIcon, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import ApplicationBar from "../../AppBar/ApplicationBar";
import SalesRow from "./TableHelpers/salesTableRowHelper";
import AxiosFunction from "../../../axiosCustomInstance";
import LoadingDialog from '../../Loading';
import UserAuth from "../../ProtectedRoute/userAuth";
import TablePaginationActions from "./TablePaginationActions";
import excel from '../../Images/excel.png'
import PdfDocument from "../../PDF/Main";
import { pdf } from '@react-pdf/renderer';

const theme = createTheme({
palette: {
primary: { main: blueGrey[900] },
secondary: { main: blueGrey[500] },
},
});

const RFQs_Search_Update = () => {
// Importing necessary hooks and components from React and other libraries
const [GetClick, SetGetClick] = useState(false); // State to track if the search button is clicked
const [totalData, setTotalData] = useState([]); // State to store the fetched data
const [KOorCustomerName, setKOorCustomerName] = useState(""); // State to store the Sales Head's name
const [salesHeadName, setSalesHeadName] = useState(""); // State to store the Sales Head's name
let [usersData, setUsersData] = useState([{ User_email: "" }]); // State to store user data
const [opportunityName, setOpportunityName] = useState(""); // State to store the Opportunity Name
const { UserName, UserEmail } = UserAuth(); // Extracting UserName and UserEmail from the UserAuth hook
const [getInvoiceNum, setgetInvoiceNum] = useState(""); // State to store the Invoice Number
const currentYear = new Date().getFullYear(); // Getting the current year
const [DateValue, SetDateValue] = useState({
  From_Date: new Date(currentYear, 0, 1), // January 1st of the current year
  To_Date: new Date(currentYear, 11, 31) // December 31st of the current year
}); // State to store the date range
const [open1, setOpen1] = useState(false); // State to handle the opening of a modal or dialog
const [stus, setStus] = useState("ALL"); // State to store the current status filter
const [dis, setDis] = useState(false); // State to handle the disabled state of a button
const [oppError, setOppError] = useState(false); // State to track if there's an error with the Opportunity Name
const [dateError, setDateError] = useState(false); // State to track if there's a date-related error
const [salesHeadError, setSalesHeadError] = useState(false); // State to track if there's an error with the Sales Head field
const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState(""); // State to store the Sales Head error message
const [invoiceNumError, setInvoiceNumError] = useState(false); // State to track if there's an error with the Invoice Number
const [searchFilterError, setSearchFilterError] = useState(false); // State to track if there's an error with the search filter
const [oppErrorMsge, setOppErrorMsge] = useState(""); // State to store the Opportunity error message
const [dateErrorMsge, setDateErrorMsge] = useState(""); // State to store the Date error message
const [invoiceNumErrorMsge, setInvoiceNumErrorMsge] = useState(""); // State to store the Invoice Number error message
const [searchFilterErrorMsge, setSearchFilterErrorMsge] = useState(""); // State to store the search filter error message
const [contractVehicle, setContractVehicle] = useState('All'); // State to store the selected Contract Vehicle
const [orderBy, setOrderBy] = useState(''); // State to handle sorting column
const [order, setOrder] = useState('asc'); // State to handle sorting order (ascending or descending)
const [searchTerm, setSearchTerm] = useState(''); // State to store the search term for filtering results
const PD = new Date(); // Current date object
const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD); // Formatting the current date
const navigate = useNavigate(); // React Router hook for navigation
const [page, setPage] = useState(0); // State to track the current page in pagination
const [rowsPerPage, setRowsPerPage] = useState(5); // State to track the number of rows per page in pagination

const AxiosAPIInstance = AxiosFunction(); // Custom Axios instance for making API requests
const [loading, setLoading] = useState(false); // State to track loading state

// Calculate empty rows for pagination when data does not fill a page
const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

// Handler to change the current page in pagination
const handleChangePage = (event, newPage) => { setPage(newPage); };

// Handler to change the number of rows per page in pagination
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10)); // Parse and set the number of rows per page
  setPage(0); // Reset to the first page when rows per page changes
};

// Function to perform a stable sort on the data array
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]); // Map elements with their original index
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]); // Compare elements
    if (order !== 0) return order;
    return a[1] - b[1]; // Preserve original order in case of a tie
  });
  return stabilizedThis.map((el) => el[0]); // Return sorted array
};

// Function to get a comparator function based on the sort order and column
const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// Comparator function for descending sort order
const descendingComparator = (a, b, orderBy) => {
  if (orderBy === 'Actual_Total_Cost_After_Margin') {
    const decimalA = new Number(a[orderBy]); // Convert value to number
    const decimalB = new Number(b[orderBy]);
    if (decimalB < decimalA) return -1;
    if (decimalB > decimalA) return 1;
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }
};

// Handler for sorting requests in table headers
const handleRequestSort = (property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc'); // Toggle sorting order
  setOrderBy(property); // Set the column to sort by
};

// Handler for search term input change
const handleSearchChange = (event) => {
  setSearchTerm(event.target.value.toLowerCase()); // Convert search term to lowercase
};

// useEffect hook to fetch users' data when the component is mounted
useEffect(() => {
  setLoading(true); // Start loading spinner
  const fetchUsersData = async () => {
    try {
      const result = await AxiosAPIInstance.get("/UserAuth/users"); // Fetch users data from API
      setLoading(false); // Stop loading spinner
      const users = [...result?.data]; // Store the fetched data
      setUsersData(users); // Update state with user data
      setSalesHeadError(false); // Reset any Sales Head error
    } catch (error) {
      setLoading(false); // Stop loading spinner on error
      setSalesHeadError(true); // Set Sales Head error state
    }
    setSalesHeadName(String(UserEmail).toLowerCase().trim()); // Set the Sales Head name from user email
    await AxiosAPIInstance.get("/RFQsSearch/SalesHead", { params: { Sales_Head_Email: String(UserEmail).trim().toLowerCase(), RFQ_Status: stus, ContractVehicle: contractVehicle } })
      .then(async (response) => {
        setLoading(false); // Stop loading spinner
        if (response?.data.length > 0) {
          SetGetClick(true); // Indicate that data was successfully fetched
          await setTotalData(response?.data); // Update state with the fetched data
          setDis(false); // Enable buttons or inputs
        } else {
          SetGetClick(false); // No data was found
          setSalesHeadError(true); // Set Sales Head error state
          setTotalData([]); // Clear data
          setSalesHeadErrorMsge("No Records Found"); // Set error message
        }
      })
      .catch((err) => {
        setLoading(false); // Stop loading spinner on error
        SetGetClick(false); // Indicate that the fetch was unsuccessful
        setSalesHeadError(true); // Set Sales Head error state
        setSalesHeadErrorMsge(err.response?.data); // Set error message from response
        setTotalData([]); // Clear data
        setDis(false); // Enable buttons or inputs
      });
  };
  fetchUsersData(); // Call the function to fetch users data
}, []);

// useEffect hook to reset data when the status changes
useEffect(() => {
  setTotalData([]); // Clear the data
  SetGetClick(false); // Indicate that no data is being displayed
}, [stus]);

// Handler to close various error dialogs
const handleClose = (e) => {
  setOpen1(false); // Close any open modals or dialogs
  setInvoiceNumError(false); // Reset Invoice Number error
  setOppError(false); // Reset Opportunity Name error
  setSalesHeadError(false); // Reset Sales Head error
  setSearchFilterError(false); // Reset search filter error
  setDateError(false); // Reset date error
  setOppErrorMsge(""); // Clear Opportunity error message
  setSalesHeadError(""); // Clear Sales Head error message
  setInvoiceNumErrorMsge(""); // Clear Invoice Number error message
  setDateErrorMsge(""); // Clear Date error message
};

// Handler to fetch data based on the selected filters
const Get_Data_Click = async (e) => {
  e.preventDefault(); // Prevent default form submission
  setDis(true); // Disable buttons or inputs
  setLoading(true); // Start loading spinner
  setTotalData([]); // Clear the data
  setPage(0); // Reset pagination to the first page

  // Building search parameters object
  const searchParams = {
    Sales_Head_Email: salesHeadName ? String(salesHeadName).trim().toLowerCase() : undefined,
    Opportunity_Name: opportunityName ? String(opportunityName).trim() : undefined,
    Invoice_No: getInvoiceNum ? getInvoiceNum : undefined,
    From: DateValue.From_Date,
    To: DateValue.To_Date,
    RFQ_Status: stus,
    ContractVehicle: contractVehicle,
    KOorCustomerName:KOorCustomerName
  };

  // Determine the endpoint based on the provided search parameters
  let selectedEndpoint = "/RFQsSearch/StatusOnly";
  if (searchParams.Invoice_No) {
    selectedEndpoint = "/RFQsSearch/QuoteNumber";
  } else if (searchParams.Sales_Head_Email && searchParams.Opportunity_Name && searchParams.From && searchParams.To) {
    selectedEndpoint = "/RFQsSearch/OpportunityName/SalesHead/DateRange";
  } else if (searchParams.Opportunity_Name && searchParams.From && searchParams.To) {
    selectedEndpoint = "/RFQsSearch/OpportunityName/DateRange";
  } else if (searchParams.Sales_Head_Email && searchParams.From && searchParams.To) {
    selectedEndpoint = "/RFQsSearch/SalesHead/DateRange";
  } else if (searchParams.Sales_Head_Email && searchParams.Opportunity_Name) {
    selectedEndpoint = "/RFQsSearch/OpportunityName/SalesHead";
  } else if (searchParams.Sales_Head_Email) {
    selectedEndpoint = "/RFQsSearch/SalesHead";
  } else if (searchParams.Opportunity_Name) {
    selectedEndpoint = "/RFQsSearch/OpportunityName";
  } else if (searchParams.From && searchParams.To) {
    selectedEndpoint = "/RFQsSearch/DateSearch";
  }

  // Make the API request with the selected endpoint and search parameters
  await AxiosAPIInstance.get(selectedEndpoint, { params: searchParams })
    .then(async (response) => {
      setLoading(false); // Stop loading spinner
      if (response?.data.length > 0) {
        await setTotalData(response?.data); // Update state with the fetched data
        setDis(false); // Enable buttons or inputs
        SetGetClick(true); // Indicate that data was successfully fetched
      } else {
        SetGetClick(false); // No data was found
        setDateError(true); // Set date error state
        setDateErrorMsge("No Records Found"); // Set error message
        setTotalData([]); // Clear data
      }
    })
    .catch((err) => {
      setLoading(false); // Stop loading spinner on error
      SetGetClick(false); // Indicate that the fetch was unsuccessful
      setDateError(true); // Set date error state
      setDateErrorMsge(err.response?.data); // Set error message from response
      setTotalData([]); // Clear data
      setDis(false); // Enable buttons or inputs
    });
  setLoading(false); // Stop loading spinner
  setDis(false); // Enable buttons or inputs
};

// Handler to generate an Excel file from the displayed data
const handleGenerateExcel = async (e) => {
  e.preventDefault(); // Prevent default form submission
  setLoading(true); // Start loading spinner
  try {
    if (totalData?.length > 0) {
      // Map and format data for Excel export
      const formattedData = totalData?.map((row) => ({
        OpportunityName: row.Opportunity_Name,
        QuoteNo: row.Invoice_No,
        ContractVehicle: row.Contract_Vehicle,
        KOname: row.KO_name,
        KOemail: row.KO_email,
        KOphone: row.KO_phone,
        KOaddress: row.KO_address,
        PublishedDate: String(row.Published_date).split('T')[0],
        DueDate: String(row.Due_date).split('T')[0],
        Brief: row.Brief,
        OrganizationName: row.Organization_Name,
        NotesForPO: row.Notes_For_PO,
        CustomerName: row.Customer_Name,
        CustomerEmail: row.Customer_Email,
        CustomerPhone: row.Customer_Phone,
        CustomerAddress: row.Customer_Address,
        ShippingMethod: row.Shipping_Method,
        SalesHeadName: row.Sales_Head_Name,
        SalesHeadEmail: row.Sales_Head_Email,
        SalesHeadPh: row.Sales_Head_Ph,
        ShippingTerms: row.Shipping_Terms,
        EstimatedDelivery: row.Estimated_Delivery,
        PaymentTerms: row.Payment_Terms,
        Notes: row.Notes,
        ShippingCost: row.Shipping_Cost,
        Tax: row.Tax,
        OtherCost1: row.Other_Cost_1,
        OtherCost2: row.Other_Cost_2,
        OtherCost3: row.Other_Cost_3,
        OtherCostName1: row.Other_Cost_Name1,
        OtherCostName2: row.Other_Cost_Name2,
        OtherCostName3: row.Other_Cost_Name3,
        TotalCostBeforeMargin: row.Actual_Total_Cost_Before_Margin,
        TotalCostAfterMargin: row.Actual_Total_Cost_After_Margin,
        CreatedDate: String(row.Created_Date).split('T')[0],
        LastUpdatedDate: String(row.Last_Updated_Date).split('T')[0],
        Status: row.RFQ_Status,
        UpdatedBy: row.Updated_By,
        UpdatingComments: row.Updating_Comments,
      }));
      setDis(false); // Enable buttons or inputs
      setLoading(false); // Stop loading spinner
      const wb = XLSX.utils.book_new(); // Create a new workbook
      const ws = XLSX.utils.json_to_sheet(formattedData); // Convert data to Excel sheet
      const headers = [
        'Opportunity Name', 'Quote No', 'Contract Vehicle', 'KO name', 'KO email', 'KO phone', 'KO address', 'Published Date', 'Due Date', 'Brief', 'Organization Name', 'Notes For PO',
        'Customer Name', 'Customer Email', 'Customer Phone', 'Customer Address', 'Shipping Method', 'Sales Head Name', 'Sales Head Email', 'Sales Head Ph', 'Shipping Terms',
        'Estimated Delivery', 'Payment Terms', 'Notes', 'Shipping Cost($)', 'Tax($)', 'Other Cost1($)', 'Other Cost2($)', 'Other Cost3($)', 'Other Cost Name1', 'Other Cost Name2',
        'Other Cost Name3', 'Total Cost Before Margin($)', 'Total Cost After Margin($)', 'Created Date', 'Last Updated Date', 'Status', 'Updated By', 'Updating Comments',
      ];
      // Set headers in the Excel sheet
      headers.forEach((header, index) => {
        const cellAddress = { r: 0, c: index };
        ws[XLSX.utils.encode_cell(cellAddress)] = { v: header, s: { font: { bold: true } } };
      });
      XLSX.utils.book_append_sheet(wb, ws, 'ASPETTO SALES'); // Append sheet to workbook
      const today = new Date(); // Get current date
      const excelFileName = 'ASPETTO SALES-' + String(today).split('T')[0] + '(' + 'STATUS:' + stus + ')' + '.xlsx'; // Generate file name
      XLSX.writeFile(wb, excelFileName); // Write Excel file to disk
    } else {
      SetGetClick(false); // No data was found
      setDateError(true); // Set date error state
      setDateErrorMsge("No Records Found"); // Set error message
    }
  } catch (err) {
    setLoading(false); // Stop loading spinner on error
    SetGetClick(false); // Indicate that the fetch was unsuccessful
    console.error(err); // Log error to console
    setTotalData([]); // Clear data
    setDis(false); // Enable buttons or inputs
    setSearchFilterError(true); // Set search filter error state
    setSearchFilterErrorMsge("Something went wrong"); // Set error message
  }
};

// Handler to navigate to the RFQ update page when a specific quote is clicked
const RFQID_Search = (quote) => {
  navigate({
    pathname: "/Dashboard/UpdateRFQ",
    search: createSearchParams({
      RID: quote?.RFQ_ID,
      RStatus: quote?.RFQ_Status,
      VNo: 'LifeCycle',
    }).toString()
  });
};

// Filter the quotes based on the search term
const filteredQuotes = totalData.filter((quote) =>
  quote.Customer_Email.toLowerCase().includes(searchTerm) ||
  quote.Customer_Name.toLowerCase().includes(searchTerm) ||
  quote.KO_name.toLowerCase().includes(searchTerm) ||
  quote.KO_email.toLowerCase().includes(searchTerm) ||
  quote.Organization_Name.toLowerCase().includes(searchTerm)||
  quote.Opportunity_Name.toLowerCase().includes(searchTerm)
);


  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {invoiceNumError && (
        <Dialog open={invoiceNumError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={invoiceNumErrorMsge === "No Records Found" ? "warning" : "error"}>
              {invoiceNumErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {invoiceNumErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {oppError && (
        <Dialog open={oppError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={oppErrorMsge === "No Records Found" ? "warning" : "error"}>
              {oppErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {oppErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {salesHeadError && (
        <Dialog open={salesHeadError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={salesHeadErrorMsge === "No Records Found" ? "warning" : "error"}>
              {salesHeadErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {salesHeadErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {dateError && (
        <Dialog open={dateError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={dateErrorMsge === "No Records Found" ? "warning" : "error"}>
              {dateErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {dateErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {searchFilterError && (
        <Dialog open={searchFilterError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity="error">ERROR</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {searchFilterErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Stack direction='column' spacing={0} justifyContent="center">
        <Stack sx={{ marginTop: "60px" }} direction="row" justifyContent="center">
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }} justifyContent="center">Request For Quotes (RFQs)</Typography>
        </Stack>
        <Stack direction='row' justifyContent="center" spacing={2} sx={{marginTop:'20px'}}>
          <TextField size="small" label="Quote Number" value={getInvoiceNum} onChange={(e) => { setgetInvoiceNum(e.target.value); SetGetClick(false); }} />
          <TextField
                  helperText="Search By Customer's/KO's Names & Emails/Organization Name/Opportunity Name"
                  label="Search"
                  size='small'
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ flex: 0.5 }}
                ></TextField>
          <FormControl sx={{minWidth:'150px'}}>
            <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
            <Select label="Sales Head Email ID" labelId="Sales-Head-id" value={String(salesHeadName).toLowerCase()} size='small' onChange={(e) => { SetGetClick(false); setSalesHeadName(e.target.value); setTotalData([]); }}>
              <MenuItem value='all'>All</MenuItem>
              {usersData?.map((row, index) => (
                <MenuItem key={index} value={String(row?.User_email).toLowerCase()}>{String(row?.User_email).toLowerCase()}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack direction='row' spacing={1}>
            <DatePicker size="small" label="Created Date (From)" maxDate={new Date()} value={DateValue.From_Date} onChange={(value) => { SetDateValue({ ...DateValue, From_Date: value }); SetGetClick(false); }} renderInput={(params) => <TextField size="small" sx={{ width: '200px' }} {...params} />} />
            <DatePicker label="Created Date (To)" minDate={DateValue.From_Date} value={DateValue.To_Date} onChange={(value) => { SetDateValue({ ...DateValue, To_Date: value }); SetGetClick(false); }} renderInput={(params) => <TextField sx={{ width: '200px' }} variant="outlined" size="small" {...params} />} />
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "17px" }} spacing={1} direction="row" justifyContent="center">
          <FormControl size="small">
            <InputLabel id="Quote_Status">Status</InputLabel>
            <Select size="small" labelId="Quote_Status" id="Quote_Status-id" value={stus} label="Quote Status" onChange={(e) => { setStus(e.target.value); }} sx={{ width: "150px" }}>
              <MenuItem size="small" value={"ALL"}>ALL</MenuItem>
              <MenuItem size="small" value={"PENDING"}>PENDING</MenuItem>
              <MenuItem size="small" value={"WON"}>WON</MenuItem>
              <MenuItem size="small" value={"LOST"}>LOST</MenuItem>
              <MenuItem size="small" name="CANCELLED" value={"CANCELLED"}>CANCELLED</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ width: "250px" }}>
            <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
            <Select size="small" labelId="Contract-Vehicle" id="Contract-Vehicle-id" value={contractVehicle} variant="outlined" label="Contract Vehicle" onChange={(e) => {
              setTotalData([]);
              setPage(0);
              SetGetClick(false);
              setContractVehicle(e.target.value);
            }} sx={{ width: "250px" }}>
              <MenuItem size="small" name="ALL" value={"All"}>All</MenuItem>
              <MenuItem size="small" name="FE" value={"Federal"}>Federal</MenuItem>
              <MenuItem size="small" name="MI" value={"Military"}>Military</MenuItem>
              <MenuItem size="small" name="TA" value={"Tactical"}>Tactical</MenuItem>
              <MenuItem size="small" name="G" value={"GSA"}>GSA</MenuItem>
              <MenuItem size="small" name="FB" value={"FBOP"}>FBOP</MenuItem>
              <MenuItem size="small" name="DH-CB" value={"DHS-CBP"}>DHS-CBP</MenuItem>
              <MenuItem size="small" name="SO" value={"SAM/Open Market"}>SAM/Open Market</MenuItem>
              <MenuItem size="small" name="UN" value={"Unison"}>Unison</MenuItem>
              <MenuItem size="small" name="DI" value={"Direct Inquiry"}>Direct Inquiry</MenuItem>
              <MenuItem size="small" name="CO" value={"Commercial"}>Commercial</MenuItem>
              <MenuItem size="small" name="CO" value={"Engineering"}>Engineering</MenuItem>
            </Select>
          </FormControl>
          <Button
            disabled={!GetClick}
            variant="contained"
            sx={{ backgroundColor: yellow[900], fontSize: "10px", ":hover": { backgroundColor: yellow[800] }, height: '35px' }}
            startIcon={<SaveAlt />}
            onClick={handleGenerateExcel}
            size="small">Download
            <img src={excel} alt="ASPETTO" height="35px" width="35px"></img>
          </Button>
        </Stack>
        <Stack sx={{ marginTop: "10px", marginBottom: "10px" }} spacing={1} direction="row" justifyContent="center">
          <Button sx={{ width: "200px" }} size="small" variant="contained" color='success' disabled={dis} onClick={Get_Data_Click}>Get</Button>
        </Stack>
        <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal'></Divider>
        <br />
        {GetClick === true && totalData.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', width: "16%", padding: 0.5 }} align="center">Contract Info</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 0.5, width: "23%" }} align="center">Contracting Officer's Info</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 0.5, width: "23%" }} align="center">Customer's Info</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", backgroundColor: orange[600], fontFamily: 'Verdana (sans-serif)', cursor: 'pointer', padding: 0.5, width: "30%", "&:hover": { backgroundColor: orange[300], color: orange[800] } }} align="center">
                    <TableSortLabel active direction={orderBy === 'Created_Date' ? order : 'asc'} onClick={() => handleRequestSort('Created_Date')}>
                      <b>Sort by Created Date</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', padding: 0.5, color: 'white', width: "10%", fontFamily: 'Verdana, sans-serif', backgroundColor: blue[600], cursor: 'pointer', "&:hover": { backgroundColor: blue[300], color: orange[800] } }} align="center">
                    <TableSortLabel active direction={orderBy === 'Actual_Total_Cost_After_Margin' ? order : 'asc'} onClick={() => handleRequestSort('Actual_Total_Cost_After_Margin')}>
                      <b>Sale($)</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 0.5 }} align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stableSort(filteredQuotes, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : filteredQuotes
                ).map((row, index) => (
                  <SalesRow key={index} row={row} usersData={usersData} />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={filteredQuotes?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
}
export default RFQs_Search_Update;



