import React, { useState, useEffect } from 'react';
import {
  Button, Container, Dialog, DialogActions, DialogContent, DialogTitle,
  Typography, Alert, Select, MenuItem, FormControl, InputLabel, Stack, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Box
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import * as XLSX from 'xlsx';
import AxiosFunction from '../../axiosCustomInstance';
import ApplicationBar from '../AppBar/ApplicationBar';
import LoadingDialog from '../Loading';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UserAuth from '../ProtectedRoute/userAuth';
import ErrorBoundary
 from '../ErrorBoundary';
const DHSTrackerExcelUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [contractType, setContractType] = useState('');
  const [fields, setFields] = useState([]);
  const [mappingDialog, setMappingDialog] = useState(false);
  const [databaseFields] = useState([
    'submittedBy', 'orderNumber', 'system', 'position', 'qty', 'fieldOrAcademy',
    'orderDate', 'dueDate', 'shipped', 'daysToShip', 'shipmentBooked',
    'orderShipmentEmailSent', 'invoiceRequestSent', 'poNumber', 'poDate',
    'poDueDate', 'shippedToSLW', 'processingDays', 'serialNumbersReceived', 
    'orderUploaded', 'comments'
  ]);
  const [fieldMappings, setFieldMappings] = useState({});
  const [contractTypes, setContractTypes] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [apiError, setApiError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [extractedData, setExtractedData] = useState([]);
  const [isExtractEnabled, setIsExtractEnabled] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [savedMappings, setSavedMappings] = useState({});
  const AxiosAPIInstance = AxiosFunction();
  const {UserEmail,UserName}=UserAuth()
  // Fetch contract types from the backend
  useEffect(() => {
    AxiosAPIInstance.get('/Tracker/DHS/TrackerContractTypes/getByTrackerType', {
      params: { trackerType: 'DHS' }
    })
      .then(response => {
        const contractTypeNames = Array.isArray(response?.data)
          ? response?.data.map(item => item.contractTypeName)
          : [];
        setContractTypes(contractTypeNames);
      })
      .catch(error => {
        setApiError('Failed to load contract types');
        setErrorDialog(true);
      });
  }, []);

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setExtractedData([]);
      setIsExtractEnabled(true);
      setIsUploadEnabled(false);
      loadPreviousMappings(); // Load saved mappings when a new file is chosen
      previewExcelHeaders(file);
    }
  };

  // Load previous mappings if they exist
  const loadPreviousMappings = () => {
    if (savedMappings[contractType]) {
      setFieldMappings(savedMappings[contractType]);
    }
    setMappingDialog(true);
  };

  // Preview Excel headers and open mapping dialog
  const previewExcelHeaders = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = jsonData[0].filter(header => header !== 'trackerID' && header !== 'trackerType');
        
        setFields(headers);
        setMappingDialog(true);
      } catch (error) {
        setApiError('Failed to parse Excel file. Please check the format.');
        setErrorDialog(true);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  // Handle contract type selection
  const handleContractTypeChange = (e) => {
    setContractType(e.target.value);
    setExtractedData([]);
    if(selectedFile!==null) 
      {
        setIsExtractEnabled(true)
        loadPreviousMappings();
      };
    setIsUploadEnabled(false);
   // Show previously saved mappings on contract type change
  };

  // Handle field mapping selection
  const handleMappingChange = (dbField, excelHeader) => {
    setFieldMappings(prevMappings => ({
      ...prevMappings,
      [dbField]: excelHeader
    }));
  };

  // Check if all database fields are mapped
  const allFieldsMapped = () => databaseFields.every(field => fieldMappings[field]);

  // Extract data based on mappings
  const handleExtractData = () => {
    if (!selectedFile) return;
    setLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[contractType];//searching for contract types
        if (!worksheet) {
          throw new Error(`Sheet name '${contractType}' not found in file`);
        }

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headerRow = jsonData[0];
        const rowData = jsonData.slice(1)
          .map(row => {
            const mappedRow = {};
            Object.entries(fieldMappings).forEach(([dbField, excelHeader]) => {
              const index = headerRow.indexOf(excelHeader);
              if (index !== -1) {
                mappedRow[dbField] = dbField.toLowerCase().includes("date") || dbField.toLowerCase().includes('shippedtoslw') || dbField.toLowerCase().includes('shipped') 
                  ? parseDate(row[index])
                  : row[index];
              }
            });
            return mappedRow;
          })
          .filter(row => row.submittedBy);  // Only include rows where submittedBy is not empty

        setExtractedData(rowData);
        setIsUploadEnabled(true);

      } catch (error) {
        setApiError(error.message);
        setErrorDialog(true);
      } finally {
        setLoading(false);
      }
    };
    fileReader.readAsArrayBuffer(selectedFile);
  };

  const parseDate = (dateValue) => {
    // Check if the value is a number, which likely indicates an Excel serial date
    if (typeof dateValue === 'number') {
      // Excel serial date conversion
      const excelEpoch = new Date(Date.UTC(1900, 0, 1)); // Excel epoch start (January 1, 1900)
      const parsedDate = new Date(excelEpoch.getTime() + (dateValue - 1) * 24 * 60 * 60 * 1000); // Convert days to ms
  
      // Format as 'YYYY-MM-DD'
      return parsedDate.toISOString().split('T')[0];
    }
  
    // If it's a string, we can parse using standard formats
    if (typeof dateValue === 'string') {
      const dateFormats = ['MM-DD-YY', 'MM-DD-YYYY', 'MM/DD/YY', 'MM/DD/YYYY'];
      const parsedDate = dateFormats
        .map(format => {
          const parts = dateValue.split(/[-/]/);
          if (format === 'MM-DD-YY' && parts.length === 3) {
            return new Date(`20${parts[2]}-${parts[0]}-${parts[1]}`);
          } else if (format === 'MM-DD-YYYY' && parts.length === 3) {
            return new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
          } else if (format === 'MM/DD/YY' && parts.length === 3) {
            return new Date(`20${parts[2]}-${parts[0]}-${parts[1]}`);
          } else if (format === 'MM/DD/YYYY' && parts.length === 3) {
            return new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
          }
          return null;
        })
        .find(date => date && !isNaN(date.getTime()));
  
      return parsedDate ? parsedDate.toISOString().split('T')[0] : dateValue; // Format as YYYY-MM-DD or return original
    }
  
    // If neither string nor number, return as is
    return dateValue;
  };
  
// Function to calculate daysToDueDate based on orderDate and dueDate
const calculateDaysToDueDate = (orderDate, dueDate) => {
  if (!orderDate || !dueDate) return 0;  // Return 0 if either date is missing
  
  // Convert to Date objects if they are strings
  const order = new Date(orderDate);
  const due = new Date(dueDate);

  // Calculate difference in milliseconds and convert to days
  const differenceInTime = due - order;
  const daysToDueDate = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

  return daysToDueDate;
};

const handleFileUpload = async () => {
  setLoading(true); // Start loading indicator
  try {
    // Validate necessary data before proceeding
    if (!contractType || extractedData.length === 0 || !allFieldsMapped()) {
      setApiError('Please complete all selections and ensure data extraction before uploading');
      setErrorDialog(true);
      return;
    }

    // Prepare orders with metadata
    const ordersWithMetaData = extractedData.map(order => ({
      ...order,
      createdByEmail: UserEmail,       // Replace with actual user email
      createdByName: UserName,         // Replace with actual user name
      daysToDueDate: calculateDaysToDueDate(order.orderDate, order.dueDate),
    }));

    // Attempt to send the POST request with JSON payload
    const response = await AxiosAPIInstance.post(
      '/Tracker/DHS/bulkUpload',
      {
        contractType,
        orders: ordersWithMetaData,
        mappings: fieldMappings
      },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );

    // Handle success response
    setSuccessMessage(response?.data || 'Data uploaded successfully');
    setSuccessDialog(true);

  } catch (error) {
    // Extract the error message from the response, with fallback
    const errorMessage = 
      typeof error?.response?.data === 'string'
        ? error.response.data
        : error?.response?.data?.message || 'An unexpected error occurred. Please try again later.';

    // Display error message in dialog
    setApiError(errorMessage);
    setErrorDialog(true);
    console.error('File upload failed:', error);

  } finally {
    // Reset state regardless of success or failure
    setSelectedFile(null);
    setExtractedData([]);
    setIsExtractEnabled(false);
    setIsUploadEnabled(false);
    setLoading(false); // Stop loading indicator
  }
};

  

  // Search and filter data
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Sorting function
  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    const sortedRows = [...extractedData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setSortConfig({ key, direction });
    setExtractedData(sortedRows);
  };

  const filteredData = extractedData.filter(row =>
    Object.values(row).some(value => 
      (value || '').toString().toLowerCase().includes(searchTerm)
    )
  );

  const handleCloseDialog = () => {
    setErrorDialog(false);
    setSuccessDialog(false);
  };

  return (
    <>
    <ErrorBoundary>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 10 }}>
        <Stack justifyContent="center">
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>
            Bulk Upload (DHS-Tracker)
          </Typography>
        </Stack>

        <FormControl sx={{ width: '100%', marginBottom: 2 }}>
          <InputLabel>Contract Type</InputLabel>
          <Select value={contractType} onChange={handleContractTypeChange} label="Contract Type">
            {contractTypes.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', width: '100%', justifyContent: 'center', mt: 2 }}>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} disabled={!contractType} />
          <Button variant="contained" color="primary" onClick={handleExtractData} disabled={!isExtractEnabled}>Extract</Button>
          <Button variant="contained" color="secondary" onClick={handleFileUpload} disabled={!isUploadEnabled}>Upload To Database</Button>
        </Stack>

        <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px", mt: 2 }} orientation="horizontal" />

        <Dialog open={mappingDialog} onClose={() => setMappingDialog(false)}>
          <DialogTitle><Alert variant='filled' sx={{ bgcolor: orange[200], color: blueGrey[900] }}>Map Excel Fields to Database Fields</Alert></DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>Map fields from your Excel file:</Typography>
            {databaseFields.map((dbField, index) => (
              <Stack direction="row" spacing={2} alignItems="center" key={index} sx={{ marginBottom: 2 }}>
                <Typography variant="body2" sx={{ width: '150px' }}>{dbField}:</Typography>
                <FormControl sx={{ width: '200px' }}>
                  <Select value={fieldMappings[dbField] || ''} onChange={(e) => handleMappingChange(dbField, e.target.value)} displayEmpty>
                    <MenuItem value="">Select Excel Field</MenuItem>
                    {fields.filter(header => !Object.values(fieldMappings).includes(header) || fieldMappings[dbField] === header).map((header, idx) => (
                      <MenuItem key={idx} value={header}>{header}</MenuItem>
                    ))}
                  </Select>
                  {dbField.toLowerCase().includes("date") && (
                    <Typography variant="caption" color="textSecondary">Format: MM-DD-YY or MM-DD-YYYY</Typography>
                  )}
                </FormControl>
              </Stack>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setMappingDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={() => setMappingDialog(false)} color="primary" disabled={!allFieldsMapped()}>Save Mappings</Button>
          </DialogActions>
        </Dialog>

        <Box display="flex" justifyContent="center" sx={{ marginTop: 2 }}>
          <TextField variant="outlined" placeholder="Search Submitted By, Order Number, System, Position, PO #" onChange={handleSearchChange} sx={{ width: '600px' }} />
        </Box>

        <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>Rows Extracted: {filteredData.length}</Typography>

        {filteredData.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 4, maxWidth: '100%', overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {Object.keys(filteredData[0]).map((header, index) => (
                    <TableCell key={index} sx={{ bgcolor: blueGrey[800], color: 'white' }}>
                      <Box display="flex" alignItems="center">
                        {header}
                        <IconButton size="small" onClick={() => handleSort(header)}>
                          {sortConfig.key === header && sortConfig.direction === 'asc' ? (
                            <ArrowUpwardIcon sx={{ color: orange[900] }} />
                          ) : (
                            <ArrowDownwardIcon sx={{ color: orange[900] }} />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Success and Error Dialogs */}
        {successDialog && (
          <Dialog open={successDialog} onClose={handleCloseDialog}>
            <DialogTitle><Alert severity="success">Upload Successful</Alert></DialogTitle>
            <DialogContent><Typography variant="body1" align="center">{successMessage}</Typography></DialogContent>
            <DialogActions><Button onClick={handleCloseDialog} color="primary">OK</Button></DialogActions>
          </Dialog>
        )}

        {errorDialog && (
          <Dialog open={errorDialog} onClose={handleCloseDialog}>
            <DialogTitle><Alert severity="error">Error</Alert></DialogTitle>
            <DialogContent><Typography variant="body1" align="center" color="error">{apiError}</Typography></DialogContent>
            <DialogActions><Button onClick={handleCloseDialog} color="primary">OK</Button></DialogActions>
          </Dialog>
        )}
      </Container>
      </ErrorBoundary>
    </>
  );
};

export default DHSTrackerExcelUploader;
