import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Select, MenuItem, IconButton, InputLabel, FormControl, Grid, Container, Box,Typography,Chip,Divider,Stack,Alert,Avatar,Tooltip,
  Paper,DialogContentText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import UserAuth from '../ProtectedRoute/userAuth';
import LoadingDialog from '../Loading';
import ApplicationBar from '../AppBar/ApplicationBar';
import AxiosFunction from '../../axiosCustomInstance';
import { blueGrey, indigo, orange } from '@mui/material/colors';
import { Form } from 'react-router-dom';
import {
    DatePicker
  } from "@mui/x-date-pickers";
import TrackerTable from './TrackerTable';

const NewDHSTrackerRow = ({ initialData = {}, onSubmit, buttonText}) => {
    // Contract Types and new contract type management

    const [newContractType, setNewContractType] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const[loading,setLoading]=useState(false)
    // Error handling
    const [formError, setFormError] = useState({});
    const [apiError, setApiError] = useState('');
    const [trackerMssge, setTrackerMssge] = useState('')
    const [successDialog, setSuccessDialog] = useState(false)
    const [errorDialog, setErrorDialog] = useState(false)
    const [dis, setDis] = useState(false)
    const [contractTypes, setContractTypes] = useState([]);
    const AxiosAPIInstance=AxiosFunction()
    const {UserName,UserEmail}=UserAuth()
    const [contractTypeDescription,setContractTypeDescription]=useState('')
  // State for form fields
  const [formData, setFormData] = useState({
    trackerType:'DHS',
    submittedBy: '',
    orderNumber: '',
    system: '',
    position: '',
    qty: '',
    fieldOrAcademy: '',
    orderDate: '',
    dueDate: '',
    daysToDueDate:0,
    shipped: '',
    daysToShip: '',
    shipmentBooked: '',
    orderShipmentEmailSent: '',
    invoiceRequestSent: '',
    poNumber: '',
    poDate: '',
    poDueDate: '',
    shippedToSLW: '',
    processingDays: '',
    serialNumbersReceived: '',
    orderUploaded: '',
    contractType: '',
    createdByEmail:UserEmail,
    createdByName:UserName,
    updatedBy:UserEmail,
    ...initialData  // Override defaults with initial data if provided
  });


  // Handles input changes for form fields
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

// Fetch contract types from the backend
useEffect(() => {
  AxiosAPIInstance.get('/Tracker/DHS/TrackerContractTypes/getByTrackerType', {
    params: { trackerType: 'DHS' }
  })
    .then(response => {
      // Check if data is an array and map to extract contractTypeName
      const contractTypeNames = Array.isArray(response?.data)
        ? response?.data.map(item => item.contractTypeName)
        : [];

      // Set the state with the extracted contractTypeNames
      setContractTypes(contractTypeNames);
      setErrorDialog(false);
    })
    .catch(error => {
      setApiError('Failed to load contract types');
      console.error(error);
      setTrackerMssge(error?.response?.data);
      setSuccessDialog(false);
      setErrorDialog(true);
    });
}, []);

  // Open dialog for new contract type
  const handleOpenDialog = () => {
    setNewContractType('');
    setOpenDialog(true);
    setLoading(false)
  };

  // Close dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = (e) => {
    setErrorDialog(false)
    setSuccessDialog(false)
  };
  // Save new contract type
  const handleSaveNewContractType = async() => {
    setLoading(true)
    if (!newContractType) {
      setApiError('Contract type cannot be empty');
      return;
    }
    var trackerData=
    console.log(trackerData)
    await AxiosAPIInstance.post('/Tracker/DHS/TrackerContractTypes/New', 
      { 
        trackerType:'DHS', 
        contractTypeDescription:contractTypeDescription,
        contractTypeName:newContractType, 
        createdByName:UserName, 
        createdByEmail:String(UserEmail).trim().toLocaleLowerCase()
        })
      .then(
        async(response) => {
        setContractTypes([...contractTypes, newContractType]);
        setOpenDialog(false);
        setTrackerMssge(response?.data)
        setSuccessDialog(true)
        setErrorDialog(false)
        setLoading(false)
        
      })
      .catch(
        async(error) => {
        setApiError('Failed to save new contract type');
        setTrackerMssge(error?.response?.data)
        setSuccessDialog(false)
        setErrorDialog(true)
        setLoading(false)
        console.error(error);
      });
  };
// Calculate the due date based on the number of days to ship
const handleDaysToDueDateChange = (e) => {
  const daysToDueDate = parseInt(e.target.value); // Parse the input as a base-10 integer

  if (formData.orderDate && !isNaN(daysToDueDate)) {
    // Create a new Date object to avoid mutating the original orderDate
    var newDueDate = new Date(formData.orderDate);
    newDueDate.setDate(newDueDate.getDate() + daysToDueDate); // Add the number of days

    setFormData({
      ...formData,
      daysToDueDate: e.target.value,
      dueDate: newDueDate, // Set the new due date
    });
  } else {
    setFormData({ ...formData, daysToDueDate: e.target.value });
  }
};

// Calculate the processing days by subtracting the order date from the shipped date
const handleShippedDateChange = (value) => {
  if (formData.orderDate && value) {
    const orderDate = new Date(formData.orderDate);
    const shippedDate = new Date(value);
    const daysToShip = Math.ceil((shippedDate - orderDate) / (1000 * 60 * 60 * 24)); // Difference in days
    setFormData({
      ...formData,
      shipped: value,
      daysToShip: daysToShip,
    });
  } else {
    setFormData({ ...formData, shipped: value });
  }
};

// Calculate the processing days by subtracting the order date from the shipped date
const handleOrderDateChange = (value) => {
  if (formData.daysToDueDate && value && formData.shipped) {
    const orderDate = new Date(value);
    // Create a new Date object to avoid mutating the original orderDate
    const newDueDate = new Date(orderDate);
    newDueDate.setDate(newDueDate.getDate() + formData.daysToDueDate); // Add the number of days
    const daysToShip = Math.ceil((formData.shipped - orderDate) / (1000 * 60 * 60 * 24)); // Difference in days
    setFormData({
      ...formData,
      orderDate: value,
      dueDate:newDueDate,
      daysToShip:daysToShip
    });
  } else {
    setFormData({ ...formData, orderDate: value, dueDate:value});
  }
};

// Calculate the processing days by subtracting the order date from the shipped date
const handlePoDateChange = (value) => {
  if (formData.shippedToSLW && value) {
    const poDate = new Date(value);
    const shippedToSLW = new Date(formData.shippedToSLW);
    const processingDays = Math.ceil((shippedToSLW - poDate) / (1000 * 60 * 60 * 24)); // Difference in days
    setFormData({
      ...formData,
      poDate: value,
      processingDays: processingDays,
    });
  } else {
    setFormData({ ...formData, poDate: value });
  }
};

// Calculate the processing days by subtracting the order date from the shipped date
const handleShipToSLWChange = (value) => {
  if (formData.poDate && value) {
    const poDate = new Date(formData.poDate);
    const shippedToSLW = new Date(value);
    const processingDays = Math.ceil((shippedToSLW - poDate) / (1000 * 60 * 60 * 24)); // Difference in days
    setFormData({
      ...formData,
      shippedToSLW: value,
      processingDays: processingDays,
    });
  } else {
    setFormData({ ...formData, shippedToSLW: value });
  }
};
//Form submission handler with basic validation
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setLoading(true)
    // Example validation
    if (!formData.submittedBy || !formData.orderNumber || !formData.contractType) {
      setFormError({
        submittedBy: !formData.submittedBy ? 'Submitted By is required' : '',
        orderNumber: !formData.orderNumber ? 'Order Number is required' : '',
        contractType: !formData.contractType ? 'Contract Type is required' : ''
      });
      return;
    }
    AxiosAPIInstance.post('/Tracker/DHS/newRecord', formData)
      .then(response => {
        setLoading(false)
        console.log('Order submitted successfully', response?.data);
        setTrackerMssge('Order submitted successfully', response?.data)
        setSuccessDialog(true)
        setErrorDialog(false)
        setFormData({...formData,
          submittedBy: '',
          orderNumber: '',
          system: '',
          position: '',
          qty: '',
          fieldOrAcademy: '',
          orderDate: '',
          dueDate: '',
          daysToDueDate: 0,
          shipped: '',
          daysToShip: '',
          shipmentBooked: '',
          orderShipmentEmailSent: '',
          invoiceRequestSent: '',
          poNumber: '',
          poDate: '',
          poDueDate: '',
          shippedToSLW: '',
          processingDays: '',
          serialNumbersReceived: '',
          orderUploaded: '',
          contractType: '',
          createdByEmail:UserEmail,
          createdByName:UserName,
          updatedBy:UserEmail});
      
      })
      .catch(error => {
        setApiError('Failed to submit order');
        setLoading(false)
        setTrackerMssge('Failed to create new record')
        setSuccessDialog(false)
        setErrorDialog(true)
        console.error(error);
      });
  };
  //Form submission handler with basic validation
  const handleFormUpdate = async(e) => {
    e.preventDefault();
    setFormError({});
    setLoading(true)
    // Example validation
    if (!formData.submittedBy || !formData.orderNumber || !formData.contractType) {
      setFormError({
        submittedBy: !formData.submittedBy ? 'Submitted By is required' : '',
        orderNumber: !formData.orderNumber ? 'Order Number is required' : '',
        contractType: !formData.contractType ? 'Contract Type is required' : ''
      });
      return;
    }
    try {
    await onSubmit(formData);  // Call the parent component's submit function
  } catch (error) {
    setApiError('Failed to submit order');
    setLoading(false)
    setTrackerMssge('Failed to create new record')
    setSuccessDialog(false)
    setErrorDialog(true)
    console.error(error);
  } finally {
    setLoading(false);
  }

  };
  return (
    <>
    <Container>
        <ApplicationBar/>
        <LoadingDialog open={loading}/>
        {successDialog === true && (<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {trackerMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {trackerMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false)
          }
          } href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog === true && (<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {trackerMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {trackerMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false);
          }} href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
        <Paper  sx={{padding:2,marginTop: "50px"}}>
        <Stack
        justifyContent="center"
        alignItems="center"
        direction='column'
        spacing={3}
      >
        <Stack  justifyContent="center" >
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>
            Tracker (DHS)
          </Typography>
        </Stack>
        <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation="horizontal" />
         
            {/* Form fields */}
            <Stack spacing={3} direction='row'>
              <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Submitted by"
                name="submittedBy"
                value={formData.submittedBy}
                onChange={handleInputChange}
                error={!!formError.submittedBy}
                helperText={formError.submittedBy}
              />
             <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Order Number"
                name="orderNumber"
                value={formData.orderNumber}
                onChange={handleInputChange}
                error={!!formError.orderNumber}
                helperText={formError.orderNumber}
              />
                <TextField
                sx={{width:"250px"}} 
                size='small'
                label="System"
                name="system"
                value={formData.system}
                onChange={handleInputChange}
              />
               <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Position"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
              />
            </Stack>
          
            <Stack spacing={3} direction='row'>
               <TextField
                sx={{width:"150px"}} 
                size='small'
                label="Quantity"
                name="qty"
                value={formData.qty}
                onChange={handleInputChange}
              />
               <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Field or Academy"
                name="fieldOrAcademy"
                value={formData.fieldOrAcademy}
                onChange={handleInputChange}
              />
          <DatePicker
            label="Order Date"
            sx={{ width: "200px" }}
            value={formData.orderDate}
            maxDate={new Date()}
             renderInput={(params) => (
               <TextField
               variant="outlined"
               sx={{ width: "200px" }}
               size="small"
      name="orderDate"
      {...params}
    />
  )}
  onChange={(value) => {
    setFormData({ ...formData, orderDate: value })
    handleOrderDateChange(value);
  }}
/>

<TextField
  sx={{ width: "170px" }}
  size="small"
  label="Days to Due Date"
  name="daysToDueDate"
  value={formData.daysToDueDate}
  onChange={(e)=>handleDaysToDueDateChange(e)}
/>

<DatePicker
  label="Due Date"
  sx={{ width: "200px" }}
  value={formData.dueDate}
  minDate={formData.orderDate}
  renderInput={(params) => (
    <TextField
      helperText='Order Date + Days to Due Date'
      variant="outlined"
      sx={{ width: "200px" }}
      size="small"
      name="dueDate"
      {...params}
    />
  )}
  disabled
/>
            </Stack>

            <Stack spacing={3} direction='row'>
            <DatePicker label="Shipped Date"sx={{width:"250px"}} 
            value={formData.shipped} 
            minDate={formData.orderDate}
            renderInput={(params)=><TextField variant="outlined" sx={{width:"250px"}}  size="small"  name="shipped"
            {...params}/>}   onChange={(value)=>{
              setFormData({...formData,shipped:value})
              handleShippedDateChange(value)}}>
           </DatePicker>
           <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Days to Ship"
                name="daysToShip"
                value={formData.daysToShip}
                // onChange={handleInputChange}
                disabled
              />
           </Stack>
           <Stack spacing={3} direction='row'>
                <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Shipment Booked</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="shipmentBooked"
                  value={
                    formData.shipmentBooked === true || formData.shipmentBooked === 'YES'
                      ? 'YES'
                      : formData.shipmentBooked === false || formData.shipmentBooked === 'NO'
                      ? 'NO'
                      : 'NA'
                  }
                  onChange={handleInputChange}
                  variant='standard'
                >
                    <MenuItem  value='YES'>
                      YES
                    </MenuItem>
                    <MenuItem  value='NO'>
                      NO
                    </MenuItem>
                    <MenuItem  value='NA'>
                      NA
                    </MenuItem>
               
                </Select>
              </FormControl>

              <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Order Shipment Email Sent</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="orderShipmentEmailSent"
                  value={
                    formData.orderShipmentEmailSent === true || formData.orderShipmentEmailSent === 'YES'
                      ? 'YES'
                      : formData.orderShipmentEmailSent === false || formData.orderShipmentEmailSent === 'NO'
                      ? 'NO'
                      : 'NA'
                  }
                  onChange={handleInputChange}
                  variant='standard'
                >
                    <MenuItem  value='YES'>
                      YES
                    </MenuItem>
                    <MenuItem  value='NO'>
                      NO
                    </MenuItem>
                    <MenuItem  value='NA'>
                      NA
                    </MenuItem>
               
                </Select>
           
              </FormControl>
              <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Invoice Request Sent</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="invoiceRequestSent"
                  value={
                    formData.invoiceRequestSent === true || formData.invoiceRequestSent === 'YES'
                      ? 'YES'
                      : formData.invoiceRequestSent === false || formData.invoiceRequestSent === 'NO'
                      ? 'NO'
                      : 'NA'
                  }
                  onChange={handleInputChange}
                  variant='standard'
                >
                    <MenuItem  value='YES'>
                      YES
                    </MenuItem>
                    <MenuItem  value='NO'>
                      NO
                    </MenuItem>
                    <MenuItem  value='NA'>
                      NA
                    </MenuItem>
               
                </Select>
              </FormControl>
           </Stack>
           <Stack spacing={3} direction='row'>

              <TextField
                sx={{width:"250px"}} 
                size='small'
                label="PO Number"
                name="poNumber"
                value={formData.poNumber}
                onChange={handleInputChange}
              />
            <DatePicker   label="PO Date" sx={{width:"250px"}} 
            value={formData.poDate}  minDate={formData.orderDate} 
            renderInput={(params)=><TextField variant="outlined"  size="small"  sx={{width:"250px"}}  name="poDate"
             {...params}/>}   onChange={(value)=>{
              setFormData({...formData,poDate:value})
              handlePoDateChange(value)}}>
            </DatePicker>
            <DatePicker label="PO Due Date" sx={{width:"250px"}} 
            value={formData.poDueDate} minDate={formData.poDate}
            renderInput={(params)=><TextField variant="outlined"  size="small"  sx={{width:"250px"}}   name="poDueDate"
             {...params}/>}   
             onChange={(value)=>{setFormData({...formData,poDueDate:value})}}
             >
           </DatePicker>
            </Stack>

            <Stack spacing={3} direction='row'>
            <DatePicker   label="Shipped to SLW" sx={{width:"250px"}} 
            value={formData.shippedToSLW} minDate={formData.poDate} 
            renderInput={(params)=><TextField variant="outlined"  size="small"  sx={{width:"250px"}}  name="shippedToSLW"
             {...params}/>}   onChange={(value)=>{
              setFormData({...formData,shippedToSLW:value})
              handleShipToSLWChange(value)}}>
            </DatePicker>
              <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Processing Days"
                name="processingDays"
                value={formData.processingDays}
                // onChange={handleInputChange}
                disabled
              />
                  <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Order Uploaded</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="orderUploaded"
                  value={
                    formData.orderUploaded === true || formData.orderUploaded === 'YES'
                      ? 'YES'
                      : formData.orderUploaded === false || formData.orderUploaded === 'NO'
                      ? 'NO'
                      : 'NA'
                  }
                  onChange={handleInputChange}
                  variant='standard'
                >
                    <MenuItem  value='YES'>
                      YES
                    </MenuItem>
                    <MenuItem  value='NO'>
                      NO
                    </MenuItem>
                    <MenuItem  value='NA'>
                      NA
                    </MenuItem>
               
                </Select>
           
              </FormControl>
              <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Serial Numbers Received</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="serialNumbersReceived"
                  value={
                    formData.serialNumbersReceived === true || formData.serialNumbersReceived === 'YES'
                      ? 'YES'
                      : formData.serialNumbersReceived === false || formData.serialNumbersReceived === 'NO'
                      ? 'NO'
                      : 'NA'
                  }
                  onChange={handleInputChange}
                  variant='standard'
                >
                    <MenuItem  value='YES'>
                      YES
                    </MenuItem>
                    <MenuItem  value='NO'>
                      NO
                    </MenuItem>
                    <MenuItem  value='NA'>
                      NA
                    </MenuItem>
               
                </Select>
              </FormControl>
             </Stack>
      

            {/* Contract Type dropdown with add option */}
            <Stack spacing={3} direction='row'>
              <FormControl sx={{width:'250px'}}>
                <InputLabel sx={{width:'250px'}}>Contract Type</InputLabel>
                <Select
                  sx={{width:'250px'}}
                  size='small'
                  name="contractType"
                  value={formData.contractType}
                  onChange={handleInputChange}
                  error={!!formError.contractType}
                  variant='standard'
                >
                  {contractTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
           
              </FormControl>
              <Tooltip title="Add New Contract Type">
                <IconButton name="Add New Contract Type" aria-label="add"  variant='contained' color='info'  onClick={handleOpenDialog}
                sx={{ padding:'8px' ,borderRadius: 1,transition: 'transform 0.2s ease-in-out', fontSize:'13px'}}> 
                <Avatar variant="rounded" 
                sx={{
                backgroundColor: indigo[800],
                width: "185px",
                height: "30px",
                fontSize:'13px',
                transition: 'transform 0.2s ease-in-out',
                 }}>
                <AddIcon  sx={{ color: "white", backgroundColor: indigo[800],width: "20px",height: "20px", }}/>Add New Contract Type</Avatar></IconButton>
                </Tooltip>
              {formError.contractType && <p style={{ color: 'red' }}>{formError.contractType}</p>}
            </Stack>

     
            <Grid item xs={12}>
           {/* Conditionally render the button based on buttonText */}
            {buttonText === 'Update' ? (
                  <Button type="button" variant="contained" color="warning" onClick={handleFormUpdate}>
                Update
              </Button>
             ) : (
              <Button type="button" variant="contained" color="warning" onClick={handleSubmit}>
                 Create
               </Button>
             )}
             </Grid>

            </Stack>
          {/* Error messages */}
          {/* {apiError && <p style={{ color: 'red' }}>{apiError}</p>} */}
        </Paper>
     
        {/* Dialog for adding new contract type */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
         <Alert variant='filled' sx={{bgcolor:orange[200],color:blueGrey[900]}}>Add Contract Type</Alert>
         </DialogTitle>
          <DialogContent>
            <Stack direction='column' spacing={4} sx={{marginTop:'20px'}}>
            <TextField
              sx={{width:"250px",marginTop:'20px'}} 
              size='small'
              label="New Contract Type"
              value={newContractType}
              onChange={(e) => setNewContractType(e.target.value)}
            />
                <TextField
                sx={{width:"250px"}} 
                size='small'
                label="Description"
                multiline
                minRows={3}
                name="description"
                value={contractTypeDescription}
                onChange={(e) => setContractTypeDescription(e.target.value)}
              />
            </Stack>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
            <Button onClick={handleSaveNewContractType} color="primary">Save</Button>
          </DialogActions>
        </Dialog>

    </Container>
    {/* <Stack sx={{width:'100%'}}>
     <TrackerTable/>
     </Stack> */}
    </>
  );
};

export default NewDHSTrackerRow;
