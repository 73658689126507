import {
  Box,
  Button,
  TextField,
  Paper,
  Stack,
  Typography,
  IconButton,
  Avatar,
  Grid,
  Tooltip,
  useScrollTrigger,
  Divider,Tab, Tabs
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {
  useState
} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import {
  PDFDownloadLink,
  PDFViewer
} from '@react-pdf/renderer';
import {
  DatePicker
} from "@mui/x-date-pickers";
import PdfDocument from "../PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  blue,
  blueGrey,
  cyan
} from '@mui/material/colors'
import {
  createTheme
} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  useNavigate
} from 'react-router-dom';
import {
  Alert
} from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar";
import userAuth from "../ProtectedRoute/userAuth"
import {
  orange,
  indigo,
  green,
  red,lightBlue
} from '@mui/material/colors';
import {
  useSearchParams
} from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {
  color,
  margin
} from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import AxiosFunction from "../../axiosCustomInstance"
import LoadingDialog from '../Loading'
import FBOP from '../Images/FBOP.jpg'
import DHS_SKUs_ARRAY from '../SKUs/DHS_SKUs_ARRAY';
import FBOP_SKUs_ARRAY from '../SKUs/FBOP_SKUs_ARRAY';
import isEqual from 'date-fns/isEqual';
import { isWithinInterval, format } from 'date-fns';
import GetSKUs from './GetSKUs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import renderVariants from './rfqMultiVariantQuote';
// import RFQClientInputMultiVariant from '../RFQ/RFQClientInputMultiVariant';
import SwipeableViews from 'react-swipeable-views';

const theme = createTheme({
  palette: {
      primary: {
          main: blueGrey[900],
      },
      secondary: {
          main: blueGrey[300],
      },
  },
});


//---------------------------------------------------------------------------------
function RFQ_CLIENT_INPUT() {

// Import AxiosFunction to get AxiosAPIInstance
const AxiosAPIInstance = AxiosFunction()

//--------------------------------------------------------------------------------------------------------------------

// useState hooks for managing various state variables
const [clicked, setClicked] = useState(false)  // State variable for click status
const [shipp, setShipp] = useState(false)  // State variable for shipping status
const [tax, setTax] = useState(false)  // State variable for tax status
const [otherCost1, setOtherCost1] = useState(false)  // State variable for other cost 1 status
const [otherCost2, setOtherCost2] = useState(false)  // State variable for other cost 2 status
const [otherCost3, setOtherCost3] = useState(false)  // State variable for other cost 3 status
const [fileName, setFileName] = useState("")  // State variable for file name
const [dis, setDis] = useState(false)  // State variable for disable status
const navigate = useNavigate();  // Navigation hook
const [rfqMssge, setRFQMssge] = useState('')  // State variable for RFQ message
const [successDialog, setSuccessDialog] = useState(false)  // State variable for success dialog status
const [errorDialog, setErrorDialog] = useState(false)  // State variable for error dialog status
const { UserName, UserEmail } = userAuth()  // Destructuring userAuth() hook
const [draftSaveButtonRequiredFieldDisable, setDraftSaveButtonRequiredFieldDisable] = useState(false)  // State variable for draft save button disable status
const [searchParams] = useSearchParams();  // Hook for managing search parameters
const [draftSubmitRFQIDDisable, setDraftSubmitRFQIDDisable] = useState(false)  // State variable for draft submit RFQ ID disable status
const [areYouSureDialog, setAreYouSureDialog] = useState(false)  // State variable for "Are you sure" dialog status
const [KOsNameChecked, setKOsNameChecked] = useState(false)  // State variable for KO's name checked status
const [KOsEmailChecked, setKOsEmailChecked] = useState(false)  // State variable for KO's email checked status
const [KOsPhoneChecked, setKOsPhoneChecked] = useState(false)  // State variable for KO's phone checked status
const [KOsAddressChecked, setKOsAddressChecked] = useState(false)  // State variable for KO's address checked status

const [BillToKOsNameChecked, setBillToKOsNameChecked] = useState(false)  // State variable for KO's name checked status
const [BillToKOsEmailChecked, setBillToKOsEmailChecked] = useState(false)  // State variable for KO's email checked status
const [BillToKOsPhoneChecked, setBillToKOsPhoneChecked] = useState(false)  // State variable for KO's phone checked status
const [BillToKOsAddressChecked, setBillToKOsAddressChecked] = useState(false)  // State variable for KO's address checked status

const [preview,setPreview]=useState(false);  // State variable for preview status
const [dhsCBPDialogClicked,setDHSCBPDialogClicked]=useState(false)  // State variable for DHS CBP dialog clicked status
const [FBOPDialogClicked,setFBOPDialogClicked]=useState(false)  // State variable for FBOP dialog clicked status
const [getSKUClicked,setGetSKUClicked]=useState(false)  // State variable for get SKU clicked status
const [getSKUs,setGetSKUs]=useState([])  // State variable for getting SKUs
const [success, setSuccess] = useState(false)  // State variable for success status
const [successErrorMssge, setSuccessErrorMssge] = useState('')  // State variable for success error message
const [successErrorDialog, setSuccessErrorDialog] = useState(false)  // State variable for success error dialog status
const [loading, setLoading] = useState(false);  // State variable for loading status
const [skusDialog,setSKUsDialog]=useState(false)  // State variable for SKUs dialog status
const [searchByVendorNameInput, setSearchByVendorNameInput] = useState('');  // State variable for search by vendor name input
const [skuIdInput, setSkuIdInput] = useState('');  // State variable for SKU ID input
var SKUID=''  // Variable for SKU ID
var DHSSKUsARRAY=DHS_SKUs_ARRAY()  // Variable for DHS SKUs array
var FBOPSKUsARRAY=FBOP_SKUs_ARRAY()  // Variable for FBOP SKUs array
const[pricingYear,setPricingYear]=useState('')  // State variable for pricing year
const[skuVariantButon,setSKUVariantButton]=useState(false)
const [tabIndex, setTabIndex] = useState(0);
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
const [firstName, lastName] = UserName.split(' ').map(capitalize);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSwipeChange = (index) => {
    setTabIndex(index);
  };


const [variants, setVariants] = useState([
  {
    variantName: '',
    skus: [
      {
        Vendor_Partner_name: '',
        SKU_ID: '',
        SKU_Description: '',
        QTY: 0,
        Aspetto_Unit_Cost: 0.0,
        Margin_Percent: 0.0,
        Customer_Unit_Cost: 0.0,
        Total_SKU_Cost: 0.0,
      },
    ],
    shippingCost: 0.0,
    tax: 0.0,
    otherCosts: [{ name: '', amount: 0.0 }],
    totalCostBeforeMargin: 0.0,
    totalCostAfterMargin: 0.0,
  },
]);

//----------------------------------------------------------------------------------------------------------------------------
const PD = new Date()
const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium'
}).format(PD)

//--------------------------------------------------------------------------------------------------------------------
var [SKUIDs,setSKUIDs]=useState(FBOPSKUsARRAY)

var [DHSSKUIDs,setDHSSKUIDs]=useState(DHSSKUsARRAY)
const [searchInput, setSearchInput] = useState('');
const [filteredDHSSKUIDs, setFilteredDHSSKUIDs] = useState([]);


React.useEffect(() => {
// This function filters DHSSKUIDs based on a search input and updates the state with the filtered results.
const filteredSKUs = DHSSKUIDs.filter((SKU) => {
  // Convert the search input to lowercase for case-insensitive comparison.
  const searchTerm = searchInput.toLowerCase();
  // Check if either the SKU ID or Description contains the search term.
  return (
    SKU.ID.toLowerCase().includes(searchTerm) ||
    SKU.Desc.toLowerCase().includes(searchTerm)
  );
});
// Update the state with the filtered SKUs.
setFilteredDHSSKUIDs(filteredSKUs);
// This effect runs whenever there is a change in the search input or DHSSKUIDs.
}, [searchInput, DHSSKUIDs]);

// This function updates the search input state based on user input.
const handleSearchSKU = (input) => {
  setSearchInput(input);
};


//---------------------------------------------------------------------------------------------------------------------
// State variable to hold form values for creating a new RFQ (Request for Quotation).
const [Form_Value, Set_Form_Value] = useState({
  // Initial values for various fields of the RFQ form.
  Opportunity_Name: '',
  RFQ_ID: '',
  Invoice_No: '',
  Contract_Vehicle: 'GSA',
  KO_name: '',
  KO_email: '',
  KO_phone: '',
  KO_address: '',
  Published_date: null,
  Due_date: null,
  Brief: '',
  Organization_Name: '',
  Notes_For_PO: 'NA',
  Notes_For_KO:'NA',
  Customer_Name: '',
  Customer_Email: '',
  Customer_Phone: '',
  Customer_Address: '',
  BillTo_Name: '',
  BillTo_Email: '',
  BillTo_Phone: '',
  BillTo_Address: '',
  Shipping_Method: 'N/A',
  Sales_Head_Name: String(firstName+' '+lastName),
  Valid_For: '30 Days',
  Sales_Head_Email: String(UserEmail).toLowerCase() || 'sales@aspettoinc.com',
  Sales_Head_Ph: '540-547-8487',
  Shipping_Terms: 'FOB Origin',
  Estimated_Delivery: '45 Days ARO',
  Payment_Terms: 'NET30',
  Notes: 'N/A',
  Shipping_Cost: parseFloat(0).toFixed(2),
  Tax: parseFloat(0).toFixed(2),
  Other_Cost_1: parseFloat(0).toFixed(2),
  Other_Cost_2: parseFloat(0).toFixed(2),
  Other_Cost_3: parseFloat(0).toFixed(2),
  Other_Cost_Name1: 'NA',
  Other_Cost_Name2: 'NA',
  Other_Cost_Name3: 'NA',
  Created_Date: String(today), // Initializing creation date to today's date.
  Last_Updated_Date: String(today), // Initializing last updated date to today's date.
  RFQ_Status: 'PENDING',
  Updated_By: String(UserEmail).toLowerCase(),
  Version_No: parseInt(0),
  Updating_Comments: 'RFQ Created under PENDING Status',
  Working_Sales_Head: String(UserEmail).toLowerCase(),
  Tranfer_History: ''
});

// State variable to hold information about newly added SKUs (Stock Keeping Units) for the RFQ.
const [New_SKU, Set_New_SKU] = useState([{
  // Initial values for various fields of a new SKU entry.
  Vendor_Partner_name: '',
  SKU_ID: '',
  SKU_Description: '',
  QTY: parseInt(0),
  Aspetto_Unit_Cost: parseFloat(0).toFixed(2),
  Unit_Of_Measure: 'ea',
  RFQRFQID: '',
  Margin_Percent: parseFloat(0).toFixed(2),
  Customer_Unit_Cost: parseFloat(0).toFixed(2),
  Total_SKU_Cost: parseFloat(0).toFixed(2),
  RFQ_Created_Date: Form_Value.Created_Date, // Assigning RFQ creation date to the SKU.
  RFQ_Last_Updated_Date: Form_Value.Last_Updated_Date, // Assigning last updated date to the SKU.
  SKU_Created_Date: String(today), // Initializing SKU creation date to today's date.
  SKU_Last_Updated_Date: String(today), // Initializing last updated date to today's date.
  SKU_Created_By: Form_Value.Sales_Head_Email, // Assigning the Sales Head's email as the creator of the SKU.
  SKU_Updated_By: Form_Value.Sales_Head_Email, // Assigning the Sales Head's email as the last updater of the SKU.
  Version_No: Form_Value.Version_No // Assigning the RFQ version number to the SKU.
}]);

// State variable to hold total cost information related to the RFQ.
const [TotalCost, SetTotalCost] = useState({
  // Initial values for various total cost metrics.
  Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2),
  Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
  Discount_Applied: parseFloat(0).toFixed(2),
  Final_Total_Cost_After_Dicscount: parseFloat(0).toFixed(2)
});

// Date range configuration for different years for DHS contract.
const today1 = new Date(); // Current date.
const dateRanges = [
  { start: new Date('06/09/23'), end: new Date('06/08/24'), year: 'Year1' },
  { start: new Date('06/09/24'), end: new Date('06/08/25'), year: 'Year2' },
  { start: new Date('06/09/25'), end: new Date('06/08/26'), year: 'Year3' },
  { start: new Date('06/09/26'), end: new Date('06/08/27'), year: 'Year4' },
  { start: new Date('06/09/27'), end: new Date('06/08/28'), year: 'Year5' },
  { start: new Date('06/09/28'), end: new Date('06/08/29'), year: 'Year6' },
  { start: new Date('06/09/29'), end: new Date('06/08/30'), year: 'Year7' },
];


// Effect to fetch and populate the RFQ draft details when the RFQ ID is provided as a search parameter.
React.useEffect(()=>{
  setPreview(false)
  const fetchRFQDraft=async()=>{
    setDis(true)
    setLoading(true)
    await AxiosAPIInstance.get("/RFQDrafts/GetDraftByRFQID",{params:{RFQ_ID:String(searchParams.get("RID")).trim(),Sales_Head_Email:String(searchParams.get("SHEm")).trim().toLowerCase()}}).then(async(result)=>{
      setLoading(false)
      Set_Form_Value({...Form_Value,Opportunity_Name:result?.data[0]['Opportunity_Name'],
      RFQ_ID:result?.data[0]['RFQ_ID'],
      Invoice_No:'',
      Contract_Vehicle:result?.data[0]['Contract_Vehicle'],
      KO_name:result?.data[0]['KO_name'],
      KO_email:result?.data[0]['KO_email'],
      KO_phone:result?.data[0]['KO_phone'],
      KO_address:result?.data[0]['KO_address'],
      Published_date:result?.data[0]['Published_date'],
      Due_date:result?.data[0]['Due_date'],
      Brief:result?.data[0]['Brief'],
      Organization_Name:result?.data[0]['Organization_Name'],
      Customer_Name:result?.data[0]['Customer_Email'],
      Customer_Email:result?.data[0]['Customer_Email'],
      Customer_Phone:result?.data[0]['Customer_Phone'],
      Customer_Address:result?.data[0]['Customer_Address'],
      BillTo_Name: result?.data[0]['BillTo_Name'],
      BillTo_Email: result?.data[0]['BillTo_Email'],
      BillTo_Phone:result?.data[0]['BillTo_Phone'],
      BillTo_Address:result?.data[0]['BillTo_Address'],
      Shipping_Method:result?.data[0]['Shipping_Method'],
      Sales_Head_Name:result?.data[0]['Sales_Head_Name'],
      Valid_For:result?.data[0]['Valid_For'],
      Sales_Head_Email:String(result?.data[0]['Sales_Head_Email']).toLowerCase()||"sales@aspettoinc.com",
      Sales_Head_Ph:"540-547-8487",
      Shipping_Terms:result?.data[0]['Shipping_Terms'],
      Estimated_Delivery:result?.data[0]['Estimated_Delivery'],
      Notes_For_PO:result?.data[0]['Notes_For_PO'],
      Notes_For_KO:result?.data[0]['Notes_For_KO'],
      Payment_Terms:result?.data[0]['Payment_Terms'],
      Notes:result?.data[0]['Notes'],
      Other_Cost_1:result?.data[0]['Other_Cost_1'],
      Other_Cost_2:result?.data[0]['Other_Cost_2'],
      Other_Cost_3:result?.data[0]['Other_Cost_3'],
      Other_Cost_Name1:result?.data[0]['Other_Cost_Name1'],
      Other_Cost_Name2:result?.data[0]['Other_Cost_Name2'],
      Other_Cost_Name3:result?.data[0]['Other_Cost_Name3'],
      Shipping_Cost:result?.data[0]['Shipping_Cost'],
      Tax:result?.data[0]['Tax'],
      Created_Date:result?.data[0]['Created_Date'],
      Last_Updated_Date:String(today),
      RFQ_Status:'PENDING',
      Updated_By:String(result?.data[0]['Working_Sales_Head']).toLowerCase()||"sales@aspettoinc.com",
      Version_No:parseInt(0),
      Updating_Comments:result?.data[0]['Updating_Comments'],
      Working_Sales_Head:String(result?.data[0]['Working_Sales_Head']).toLowerCase(),
      Tranfer_History:result?.data[0]['Transfer_History']
      })
      setShipp(true)
      setTax(true)
      if (result?.data[0]["KO_name"] === result?.data[0]["Customer_Name"]) {
        setKOsNameChecked(true)
    }
    if (result?.data[0]["KO_email"] === result?.data[0]["Customer_Email"]) {
        setKOsEmailChecked(true)
    }
    if (result?.data[0]["KO_phone"] === result?.data[0]["Customer_Phone"]) {
        setKOsPhoneChecked(true)
    }
    if (result?.data[0]["KO_address"] === result?.data[0]["Customer_Address"]) {
        setKOsAddressChecked(true)
    }
    
    // Check if KO name matches customer name
    if (result?.data[0]["KO_name"] === result?.data[0]["BillTo_Name"]) {
      setBillToKOsNameChecked(true); // Set KO's name checked state to true
   }

  // Check if KO email matches customer email
  if (result?.data[0]["KO_email"] === result?.data[0]["BillTo_Email"]) {
        setBillToKOsEmailChecked(true); // Set KO's email checked state to true
  }

  // Check if KO phone matches customer phone
  if (result?.data[0]["KO_phone"] === result?.data[0]["BillTo_Phone"]) {
         setBillToKOsPhoneChecked(true); // Set KO's phone checked state to true
  }

  // Check if KO address matches customer address
  if (result?.data[0]["KO_address"] === result?.data[0]["BillTo_Address"]) {
          setBillToKOsAddressChecked(true); // Set KO's address checked state to true
  }
// Check if the 'Contract_Vehicle' in the first item of the 'data' array in the 'result' object is 'DHS-CBP'
if (result?.data[0]['Contract_Vehicle'] === 'DHS-CBP') {
  // Handle pricing year calculation for DHS-CBP contract vehicle.
    let pricingYearString = '';
    for (const range of dateRanges) {
      if (today1 >= range.start && today1 <= range.end) {
        pricingYearString = `${format(range.start, 'MM/dd/yy')}-${format(range.end, 'MM/dd/yy')}(${range.year})`;
        break;
      }
    }
    if (pricingYearString) {
      setPricingYear(pricingYearString); // Set pricing year
    } else {
      setErrorDialog(true);
      setRFQMssge('DHS Pricing year is not within the range');
    }
}

//--------------------------------------------------------------------------------------------------------------------------------
      Set_New_SKU(result?.data[0]["PurchaseOrdersDrafts"])
      const skusl=[...result?.data[0]["PurchaseOrdersDrafts"]];
      let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
      let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
      skusl.map((sku, index)=>{
      cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
      cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
      })
      cal3=(cal3+parseFloat(result?.data[0]["Shipping_Cost"])+parseFloat(result?.data[0]["Tax"])+parseFloat(result?.data[0]["Other_Cost_1"])+parseFloat(result?.data[0]["Other_Cost_2"])+parseFloat(result?.data[0]["Other_Cost_3"]))
      cal=(cal+parseFloat(result?.data[0]["Shipping_Cost"])+parseFloat(result?.data[0]["Tax"])+parseFloat(result?.data[0]["Other_Cost_1"])+parseFloat(result?.data[0]["Other_Cost_2"])+parseFloat(result?.data[0]["Other_Cost_3"]))
      SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
      setDis(false)
    }).catch((error)=>{
      setLoading(false)
      setErrorDialog(true)
      setRFQMssge(error?.response?.data)      
    })
  }
  if(searchParams?.get("RID")!==null)
   {  
    setDraftSubmitRFQIDDisable(true);
    fetchRFQDraft()
  }

},[Form_Value?.RFQ_ID])



{/*-----------------------------------------------------------------*/}
// Function to handle closing of various dialogs and resetting states.
const handleClose = (e) => {
  setErrorDialog(false); // Close error dialog
  setSuccessDialog(false); // Close success dialog
  setDraftSaveButtonRequiredFieldDisable(false); // Enable draft save button
  setPreview(false); // Hide preview
  setDis(false); // Disable form fields
  setDHSCBPDialogClicked(false); // Reset DHS CBP dialog flag
  setFBOPDialogClicked(false); // Reset FBOP dialog flag
  setLoading(false); // Hide loading indicator
  setSKUsDialog(false); // Close SKUs dialog
}

// Function to handle submission or saving decision with confirmation.
const handleSubmitOrSaveDecision = (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  setAreYouSureDialog(true); // Open confirmation dialog
}

// Function to handle closing of the confirmation dialog.
const handleAreYouSureDialogClose = (e) => {
  setAreYouSureDialog(false); // Close confirmation dialog
}
{/*-----------------------------------------------------------------*/}

// Function to handle selection of DHS SKUs.
const handleDHSSKUs = async (e) => {
  // Function to check if two objects are equal based on ID and Desc properties.
  function isEqual(obj1, obj2) {
      return obj1.ID === obj2.ID && obj1.Desc === obj2.Desc;
  }

  // Function to subtract arrays of objects.
  function subtractArrays(arr1, arr2) {
      const result = [];
      arr1.forEach(obj1 => {
          const matchingObj = arr2.find(obj2 => isEqual(obj1, obj2));
          if (!matchingObj) {
              result.push(obj1);
          }
      });
      return result;
  }

  // Prepare arrays for subtraction.
  var array3 = DHSSKUsARRAY;
  var array4 = [{}];
  New_SKU.map((s, i) => {
      array4.push({
          ID: s.SKU_ID,
          Desc: s.SKU_Description
      });
  });

  // Subtract arrays to get DHS SKUs.
  const subtractedDHSArray = subtractArrays(array3, array4);
  setDHSSKUIDs(subtractedDHSArray); // Set DHS SKUs
  setDHSCBPDialogClicked(true); // Set DHS CBP dialog flag
}

{/*-----------------------------------------------------------------*/}
// Function to handle selection of FBOP SKUs.
const handleFBOPSKUs = async (e) => {
  // Function to check if two objects are equal based on ID and Desc properties.
  function isEqual(obj1, obj2) {
      return obj1.ID === obj2.ID && obj1.Desc === obj2.Desc;
  }

  // Function to subtract arrays of objects.
  function subtractArrays(arr1, arr2) {
      const result = [];
      arr1.forEach(obj1 => {
          const matchingObj = arr2.find(obj2 => isEqual(obj1, obj2));
          if (!matchingObj) {
              result.push(obj1);
          }
      });
      return result;
  }

  // Prepare arrays for subtraction.
  var array1 = FBOPSKUsARRAY;
  var array2 = [{}];
  New_SKU.map((s, i) => {
      array2.push({
          ID: s.SKU_ID,
          Desc: s.SKU_Description
      });
  });

  // Subtract arrays to get FBOP SKUs.
  const subtractedArray = subtractArrays(array1, array2);
  setSKUIDs(subtractedArray); // Set FBOP SKUs
  setFBOPDialogClicked(true); // Set FBOP dialog flag
}
//-------------------------------------------------------------------------

//-------------------------------------------------------------------------
// Function to handle contract vehicle change.
const handleContractVehicleChange = async (e) => {
  setPreview(false); // Hide preview
  e.preventDefault(); // Prevent default form behavior
  Set_Form_Value({...Form_Value, Contract_Vehicle: String(e.target.value).trim()}); // Update contract vehicle in form values

  // Handle pricing year calculation for DHS-CBP contract vehicle.
  if (String(e.target.value).trim() === "DHS-CBP") {
    let pricingYearString = '';
    for (const range of dateRanges) {
      if (today1 >= range.start && today1 <= range.end) {
        pricingYearString = `${format(range.start, 'MM/dd/yy')}-${format(range.end, 'MM/dd/yy')}(${range.year})`;
        break;
      }
    }
    if (pricingYearString) 
      {
      setPricingYear(pricingYearString); // Set pricing year
      } 
      else 
      {
      setErrorDialog(true);
      setRFQMssge('DHS Pricing year is not within the range');
      }
  }
}

//-------------------------------------------------------------------------
// Function to add a new SKU to the RFQ.
const HandleAddSKU = () => {
  // Add a new SKU to the New_SKU state array.
  Set_New_SKU([
    ...New_SKU,
    {
      Vendor_Partner_name: '',
      SKU_ID: '',
      SKU_Description: '',
      QTY: parseInt(0),
      Aspetto_Unit_Cost: parseFloat(0).toFixed(2),
      Unit_Of_Measure: 'ea',
      RFQRFQID: Form_Value.RFQ_ID,
      Margin_Percent: parseFloat(0).toFixed(2),
      Customer_Unit_Cost: parseFloat(0).toFixed(2),
      Total_SKU_Cost: parseFloat(0).toFixed(2),
      RFQ_Created_Date: Form_Value.Created_Date,
      RFQ_Last_Updated_Date: Form_Value.Last_Updated_Date,
      SKU_Created_Date: String(today),
      SKU_Last_Updated_Date: String(today),
      SKU_Created_By: String(UserEmail).toLowerCase(),
      SKU_Updated_By: String(UserEmail).toLowerCase(),
      Version_No: parseInt(Form_Value.Version_No)
    }
  ]);
  setPreview(false); // Hide preview
}

{/*-----------------------------------------------------------------*/}
// Function to remove a SKU from the RFQ.
const HandleRemoveSKU = (index) => {
  const skusl = [...New_SKU];
  skusl.splice(index, 1); // Remove SKU at specified index
  Set_New_SKU(skusl)
  // Update SKUIDs based on contract vehicle.
  if (Form_Value.Contract_Vehicle === "FBOP") {
    setSKUIDs([...SKUIDs, { ID: New_SKU[index].SKU_ID, Desc: New_SKU[index].SKU_Description }]);
  } else if (Form_Value.Contract_Vehicle === "DHS-CBP") {
    setDHSSKUIDs([...DHSSKUIDs, { ID: New_SKU[index].SKU_ID, Desc: New_SKU[index].SKU_Description }]);
  }

  // Calculate total costs after removing SKU.
  let cal = TotalCost.Actual_Total_Cost_Before_Margin === 0;
  let cal3 = TotalCost.Actual_Total_Cost_After_Margin === 0;
  skusl.forEach((sku, index) => {
    cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
    cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    skusl[index]['RFQRFQID'] = Form_Value.RFQ_ID;
    skusl[index]['Sales_Head_Email'] = String(Form_Value.Sales_Head_Email).toLowerCase();
    skusl[index]['Opportunity_Name'] = Form_Value.Opportunity_Name;
  });
  cal3 = (cal3 + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
  cal = (cal + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
  SetTotalCost({
    ...TotalCost,
    Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2),
    Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2)
  });

  setPreview(false); // Hide preview
}

{/*-----------------------------------------------------------------*/}
// Function to handle change in RFQ ID.
const HandleRFQChange = (e) => {
  Set_Form_Value({ ...Form_Value, RFQ_ID: e.target.value }); // Update RFQ ID in form values

  // Update RFQRFQID in each SKU.
  const skusl = [...New_SKU];
  skusl.forEach((sku, index) => {
    skusl[index]['RFQRFQID'] = Form_Value.RFQ_ID;
  });

  setClicked(false); // Reset clicked state
  setPreview(false); // Hide preview
}

//------------------------------------------Search Existing SKUs----------------------------------------------------------------

// Function to get all SKUs based on SKU ID.
const getAllSKUs = async (SKUID) => {
  setLoading(true); // Set loading state
  try {
    await AxiosAPIInstance.get("/SKUsSearch/getSKUsBySKUID", { params: { SKU_ID: SKUID } }).then(async (result) => {
      if (result?.data?.length !== 0) {
        setGetSKUClicked(true); // Set flag for successful SKU retrieval
        const New_SKUs = result?.data;
        setGetSKUs(New_SKUs); // Set retrieved SKUs
        setLoading(false); // Hide loading indicator
        setSKUsDialog(true); // Open SKUs dialog
      } else {
        // Handle case where no records are found
        setSuccessDialog(true); // Show success dialog
        setErrorDialog(false); // Hide error dialog
        setRFQMssge('No Records Found'); // Set message
        setLoading(false); // Hide loading indicator
      }
    }).catch(async (error) => {
      // Handle errors
      setSuccessDialog(false); // Hide success dialog
      setErrorDialog(true); // Show error dialog
      setRFQMssge(error?.response?.data); // Set error message
      setLoading(false); // Hide loading indicator
    });
  } catch {
    // Handle generic error
    setSuccessDialog(false); // Hide success dialog
    setErrorDialog(true); // Show error dialog
    setRFQMssge('Something Went Wrong'); // Set error message
    setLoading(false); // Hide loading indicator
  }
}

//-----------------------------------------------------------------------------------------------------------------------------------------------

// Filter existing SKUs based on vendor name and SKU ID.
const filteredSearchAllSKUs = getSKUs.filter((SKU) =>
  SKU.Vendor_Partner_name.toLowerCase().includes(searchByVendorNameInput.toLowerCase()) &&
  SKU.SKU_ID.toLowerCase().includes(skuIdInput.toLowerCase())
);

//-------------------------------------------------------------------------------------------------------------------------------------------------------

// Function to handle change in SKU details.
const HandleSKUChange = (e, index) => {
  const { name, value } = e.target;
  const skusl = [...New_SKU];
  skusl[index][name] = value; // Update SKU details

  // Calculate customer unit cost, margin percent, and total SKU cost based on changes.
  if (name === "Customer_Unit_Cost") {
    skusl[index]['Margin_Percent'] = 
    parseFloat(e.target.value).toFixed(2) ===  parseFloat(skusl[index]['Aspetto_Unit_Cost']).toFixed(2) ? parseFloat(0.00).toFixed(2):
    parseFloat(((parseFloat(e.target.value) -parseFloat(skusl[index]['Aspetto_Unit_Cost']))/ parseFloat(e.target.value))* 100).toFixed(2);
    skusl[index]['Total_SKU_Cost'] = parseFloat(parseFloat(skusl[index]['QTY']) * parseFloat(skusl[index]['Customer_Unit_Cost'])).toFixed(2);
  } else {
    skusl[index]['Customer_Unit_Cost'] = parseFloat(skusl[index]['Aspetto_Unit_Cost'] / (1 - skusl[index]['Margin_Percent'] / 100)).toFixed(2);
    skusl[index]['Total_SKU_Cost'] = parseFloat(parseFloat(skusl[index]['QTY']) * parseFloat(skusl[index]['Customer_Unit_Cost'])).toFixed(2);
  }

  skusl[index]['SKU_Last_Updated_Date'] = String(today);
  skusl[index]['SKU_Updated_By'] = String(UserEmail).toLowerCase();

  Set_New_SKU(skusl); // Update New_SKU state
  let cal = TotalCost.Actual_Total_Cost_Before_Margin === 0;
  let cal3 = TotalCost.Actual_Total_Cost_After_Margin === 0;
  skusl.forEach((sku, index) => {
    cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
    cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
  });
  cal3 = (cal3 + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
  cal = (cal + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));

  SetTotalCost({
    ...TotalCost,
    Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2),
    Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2)
  });

  setPreview(false); // Hide preview
}


// Function to handle shipping cost change.
const handleShipping = (e) => {
  // Check if shipping cost is enabled
  if (shipp === true) {
    const skusl = [...New_SKU];
    // Update shipping cost in form values
    Set_Form_Value({ ...Form_Value, Shipping_Cost: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with shipping cost
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal3 = (cal3 + parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal = (cal + parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if shipping cost is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If shipping cost is disabled, set it to 0
    const skusl = [...New_SKU];
    Set_Form_Value({ ...Form_Value, Shipping_Cost: parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without shipping cost
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal3 = (cal3 + parseFloat(e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00)) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal = (cal + parseFloat(e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00)) + parseFloat(Form_Value.Tax) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if shipping cost is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle tax change.
const handleTax = (e) => {
  // Check if tax is enabled
  if (tax === true) {
    const skusl = [...New_SKU];
    // Update tax in form values
    Set_Form_Value({ ...Form_Value, Tax: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with tax
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if tax is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If tax is disabled, set it to 0
    const skusl = [...New_SKU];
    Set_Form_Value({ ...Form_Value, Tax: e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without tax
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    cal3 = cal3 + (parseFloat(e.target.value) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3));
    // Update total costs if tax is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle change in Other Cost 1.
const handleOtherCost1 = async (e) => {
  // Check if Other Cost 1 is enabled
  if (otherCost1 == true) {
    const skusl = [...New_SKU];
    // Update Other Cost 1 in form values
    Set_Form_Value({ ...Form_Value, Other_Cost_1: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with Other Cost 1
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 1 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If Other Cost 1 is disabled, set it to 0
    const skusl = [...New_SKU];
    Set_Form_Value({ ...Form_Value, Other_Cost_1: e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without Other Cost 1
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 1 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle change in Other Cost 2.
const handleOtherCost2 = async (e) => {
  // Check if Other Cost 2 is enabled
  if (otherCost2 == true) {
    const skusl = [...New_SKU];
    // Update Other Cost 2 in form values
    Set_Form_Value({ ...Form_Value, Other_Cost_2: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with Other Cost 2
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 2 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If Other Cost 2 is disabled, set it to 0
    const skusl = [...New_SKU];
    Set_Form_Value({ ...Form_Value, Other_Cost_2: e.target.value === "" ? parseFloat(0.00) : parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without Other Cost 2
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Other_Cost_3) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 2 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}

// Function to handle change in Other Cost 3.
const handleOtherCost3 = async (e) => {
  // Check if Other Cost 3 is enabled
  if (otherCost3 == true) {
    const skusl = [...New_SKU];
    // Update Other Cost 3 in form values
    Set_Form_Value({ ...Form_Value, Other_Cost_3: e.target.value });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs with Other Cost 3
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(e.target.value === "" ? parseFloat(0.00) : e.target.value) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 3 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  } else {
    // If Other Cost 3 is disabled, set it to 0
    const skusl = [...New_SKU];
    Set_Form_Value({ ...Form_Value, Other_Cost_3: parseFloat(0.00) });
    let cal = 0;
    let cal3 = 0;
    // Calculate total costs without Other Cost 3
    skusl.forEach((sku, index) => {
      cal = cal + (parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Aspetto_Unit_Cost === "" ? parseFloat(0.00) : sku.Aspetto_Unit_Cost));
      cal3 = cal3 + parseInt(sku.QTY === "" ? parseFloat(0) : sku.QTY) * parseFloat(sku.Customer_Unit_Cost === "" ? parseFloat(0.00) : sku.Customer_Unit_Cost);
    });
    cal = cal + (parseFloat(parseFloat(0.00)) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    cal3 = cal3 + (parseFloat(parseFloat(0.00)) + parseFloat(Form_Value.Other_Cost_2) + parseFloat(Form_Value.Other_Cost_1) + parseFloat(Form_Value.Shipping_Cost) + parseFloat(Form_Value.Tax));
    // Update total costs if Other Cost 3 is not empty
    if (e.target.value !== "") SetTotalCost({ ...TotalCost, Actual_Total_Cost_Before_Margin: parseFloat(cal).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(cal3).toFixed(2) });
  }
  setPreview(false); // Hide preview
}
//-----------------------------------------------------------------------------
const onDragEnd = (result) => {
  if (!result.destination) return;

  const items = Array.from(New_SKU);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  // Update the sequence ID of each SKU
  items.forEach((item, index) => {
    item.sequence_id = index + 1;
  });

  Set_New_SKU(items);
};

//------------------------------------------------------------------------------
  const [InvoiceData, setInvoiceData] = useState({
      invoice_no: '',
      Contract_Vehicle: '',
      KO_name: "",
      KO_email: "",
      KO_phone: "",
      KO_address: "",
      BillTo_Name: '',
      BillTo_Email: '',
      BillTo_Phone: '',
      BillTo_Address: '',
      Print_Date:String(today),
      Valid_For:"30 Days",
      trans_date: String(Form_Value.Last_Updated_Date).split('T')[0],
      due_date: "",
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: [{}],
      Tax: 0,
      Shipping_Cost: 0,
      Other_Cost_1:0,Other_Cost_2:0,Other_Cost_3:0,Other_Cost_Name1:"",Other_Cost_Name2:"",Other_Cost_Name3:"",
      Total_Cost: 0,
      Shipping_Details: {
          Organization_Name: "",
          KO_name: "",
          Sales_Head_Info: {},
          Customer_Name: "",
          Customer_Email: "",
          Customer_Phone: "",
          Customer_Address: "",
          Shipping_Method: "",
          Shipping_Terms: "",
          Estimated_Delivery: "",
          Payment_Terms: "",
          Notes: ""
      }
  })

  const previewQuote=async(e)=>{
    setPreview(true)
    e.preventDefault()
    setInvoiceData({
      ...InvoiceData,
      invoice_num: '',
      Contract_Vehicle: Form_Value.Contract_Vehicle,
      KO_name: Form_Value.KO_name,
      KO_email: Form_Value.KO_email,
      KO_phone: Form_Value.KO_phone,
      KO_address: Form_Value.KO_address,
      BillTo_Name: Form_Value.BillTo_Name,
      BillTo_Email: Form_Value.BillTo_Email,
      BillTo_Phone: Form_Value.BillTo_Phone,
      BillTo_Address: Form_Value.BillTo_Address,
      Print_Date:String(today),
      trans_date: String(Form_Value.Last_Updated_Date).split('T')[0],
      due_date: Form_Value.Due_date,
      Notes_For_KO:Form_Value.Notes_For_KO,
      Valid_For:Form_Value.Valid_For,
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: [...New_SKU],
      Tax: Form_Value.Tax,
      Shipping_Cost: Form_Value.Shipping_Cost,
      Total_Cost: TotalCost.Actual_Total_Cost_After_Margin,
      Other_Cost_1:Form_Value.Other_Cost_1,
      Other_Cost_2:Form_Value.Other_Cost_2,
      Other_Cost_3:Form_Value.Other_Cost_3,
      Other_Cost_Name1:Form_Value.Other_Cost_Name1,
      Other_Cost_Name2:Form_Value.Other_Cost_Name2,
      Other_Cost_Name3:Form_Value.Other_Cost_Name3,
      Shipping_Details: {
          Organization_Name: Form_Value.Organization_Name,
          KO_name: Form_Value.KO_name,
          Sales_Head_Info: {
              Name: Form_Value.Sales_Head_Name,
              Email: Form_Value.Sales_Head_Email,
              Phone: "540-547-8487"
          },
          Customer_Name: Form_Value.Customer_Name,
          Customer_Email: Form_Value.Customer_Email,
          Customer_Phone: Form_Value.Customer_Phone,
          Customer_Address: Form_Value.Customer_Address,
          Shipping_Method: Form_Value.Shipping_Method,
          Shipping_Terms: Form_Value.Shipping_Terms,
          Estimated_Delivery: Form_Value.Estimated_Delivery,
          Payment_Terms: Form_Value.Payment_Terms,
          Notes: Form_Value.Notes
      }
  })
  }

  // Handle Form Submit
  const HandleSubmit = async (e) => {
    // Prevent default form submission behavior
    e.preventDefault();
    // Hide preview
    setPreview(false);
    // Set loading state
    setLoading(true);
    // Disable form fields
    setDis(true);
    // Update Last Updated Date in form values
    Set_Form_Value({
        ...Form_Value,
        Last_Updated_Date: String(today)
    });
    // Combine form values and total costs into Form_Table
    const Form_Table = {
        ...Form_Value,
        ...TotalCost
    };
    // Update SKUs with additional RFQ data
    const SKUs = [...New_SKU];
    SKUs.map(async (row, index) => {
        SKUs[index]['RFQRFQID'] = Form_Value.RFQ_ID;
        SKUs[index]['Sales_Head_Email'] = String(Form_Value.Sales_Head_Email).toLowerCase();
        SKUs[index]['Opportunity_Name'] = Form_Value.Opportunity_Name;
        SKUs[index]['RFQ_Created_Date'] = Form_Value.Created_Date;
        SKUs[index]['RFQ_Last_Updated_Date'] = Form_Value.Last_Updated_Date;
    });
    // Set updated SKUs
    Set_New_SKU(SKUs);

    if (draftSaveButtonRequiredFieldDisable === true) {
        // Check if RFQ ID and Opportunity Name are provided
        if (Form_Value.RFQ_ID !== "" && Form_Value.Opportunity_Name !== "") {
            // Post data to create or update RFQ draft
            await AxiosAPIInstance.post("/RFQDrafts/CreateAndUpdateRFQDrafts", [Form_Table, New_SKU, String(UserEmail).toLowerCase()])
                .then(
                    async (response) => {
                        setLoading(false);
                        setClicked(false);
                        setDis(false);
                        setRFQMssge(response?.data);
                        setSuccessDialog(true);
                        // Reset other cost states
                        setShipp(false);
                        setTax(false);
                        setOtherCost1(false);
                        setOtherCost2(false);
                        setOtherCost3(false);
                        // Extract and set RFQ ID from server response
                        const Response_RFQID = String(response?.data).split(' ')[1];
                        Set_Form_Value({ ...Form_Value, RFQ_ID: Response_RFQID });
                    })
                .catch((err) => {
                    setLoading(false);
                    setRFQMssge(err?.response?.data);
                    setErrorDialog(true);
                    setDis(false);
                });
        } else {
            // Show error if RFQ ID and Opportunity Name are missing
            setLoading(false);
            setErrorDialog(true);
            setRFQMssge("Cannot Save As a Draft: RFQ ID and Opportunity Name are required fields ");
            setDis(false);
        }
    } else {
        // Update Created Date in form values
        Set_Form_Value({
            ...Form_Value,
            Created_Date: String(today)
        });
        // Count the number of null values in SKU details
        var No_Of_Null_Values = 0;
        New_SKU.map(async (row, index) => {
            if (row.SKU_ID === '' || row.SKU_Description === '' || row.Vendor_Partner_name === '') {
                No_Of_Null_Values = No_Of_Null_Values + 1;
            }
        });

        // Check for required fields and post data to create RFQ
        if (No_Of_Null_Values > 0 || Form_Value.Other_Cost_1 === "" || Form_Value.Other_Cost_2 === "" || Form_Value.Other_Cost_3 === "" || Form_Value.Shipping_Cost === "" ||
            Form_Value.Tax === "") {
            setRFQMssge('All the fields from the order details section are required');
            setErrorDialog(true);
        } else {
            await AxiosAPIInstance.post("/RFQs/CreateRFQ", [Form_Table, New_SKU])
                .then(
                    async (response) => {
                        setLoading(false);
                        // Reset other cost states
                        setShipp(false);
                        setTax(false);
                        setOtherCost1(false);
                        setOtherCost2(false);
                        setOtherCost3(false);
                        setPreview(false);
                        // Set PDF data
                        const invoice_num = response?.data;
                        setInvoiceData({
                            ...InvoiceData,
                            invoice_num: invoice_num,
                            Contract_Vehicle: Form_Value.Contract_Vehicle,
                            KO_name: Form_Value.KO_name,
                            KO_email: Form_Value.KO_email,
                            KO_phone: Form_Value.KO_phone,
                            BillTo_Name: Form_Value.BillTo_Name,
                            BillTo_Email: Form_Value.BillTo_Email,
                            BillTo_Phone: Form_Value.BillTo_Phone,
                            BillTo_Address: Form_Value.BillTo_Address,
                            Valid_For: Form_Value.Valid_For,
                            KO_address: Form_Value.KO_address,
                            Notes_For_KO:Form_Value.Notes_For_KO,
                            trans_date: String(Form_Value.Last_Updated_Date).split('T')[0],
                            due_date: Form_Value.Due_date,
                            Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
                            items: [...New_SKU],
                            Tax: Form_Value.Tax,
                            Shipping_Cost: Form_Value.Shipping_Cost,
                            Total_Cost: TotalCost.Actual_Total_Cost_After_Margin,
                            Other_Cost_1: Form_Value.Other_Cost_1,
                            Other_Cost_2: Form_Value.Other_Cost_2,
                            Other_Cost_3: Form_Value.Other_Cost_3,
                            Other_Cost_Name1: Form_Value.Other_Cost_Name1,
                            Other_Cost_Name2: Form_Value.Other_Cost_Name2,
                            Other_Cost_Name3: Form_Value.Other_Cost_Name3,
                            Shipping_Details: {
                                Organization_Name: Form_Value.Organization_Name,
                                KO_name: Form_Value.KO_name,
                                Sales_Head_Info: {
                                    Name: Form_Value.Sales_Head_Name,
                                    Email: Form_Value.Sales_Head_Email,
                                    Phone: "540-547-8487"
                                },
                                Customer_Name: Form_Value.Customer_Name,
                                Customer_Email: Form_Value.Customer_Email,
                                Customer_Phone: Form_Value.Customer_Phone,
                                Customer_Address: Form_Value.Customer_Address,
                                Shipping_Method: Form_Value.Shipping_Method,
                                Shipping_Terms: Form_Value.Shipping_Terms,
                                Estimated_Delivery: Form_Value.Estimated_Delivery,
                                Payment_Terms: Form_Value.Payment_Terms,
                                Notes: Form_Value.Notes
                            }
                        });
                        // Format Opportunity Name for file name
                        const arr = String(Form_Value.Opportunity_Name).split(".");
                        for (var i = 0; i < arr.length; i++) {
                            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                        }
                        var File_Opportunity_Name = arr.join(" ");
                        // Set file name
                        setFileName(File_Opportunity_Name + " " + String(invoice_num).split('.')[0] + String(invoice_num).split('.')[1]);
                        // Set success dialog and other states
                        setClicked(true);
                        setDis(false);
                        setRFQMssge('A new quote with QUOTE NUMBER :' + invoice_num + ' created successfully');
                        setSuccessDialog(true);
                        searchParams.delete("RID");
                        setDraftSubmitRFQIDDisable(false);
                        // Reset form values
                        Set_Form_Value({
                            ...Form_Value,
                            Opportunity_Name: '',
                            RFQ_ID: '',
                            Contract_Vehicle: 'GSA',
                            KO_name: '',
                            KO_email: '',
                            KO_phone: '',
                            KO_address: '',
                            BillTo_Name:'',
                            BillTo_Email: '',
                            BillTo_Phone: '',
                            BillTo_Address:'',
                            Published_date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            Due_date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            Brief: 'N/A',
                            Organization_Name: '',
                            Notes_For_PO: "NA",
                            Customer_Name: '',
                            Customer_Email: '',
                            Customer_Phone: '',
                            Customer_Address: '',
                            Valid_For: '30 Days',
                            Shipping_Method: 'N/A',
                            Sales_Head_Name: UserName,
                            Sales_Head_Ph: "540-547-8487",
                            Sales_Head_Email: UserEmail,
                            Shipping_Terms: 'FOB Origin',
                            Estimated_Delivery: 'NA',
                            Payment_Terms: 'NET30',
                            Notes_For_KO:'NA',
                            Other_Cost_1: Number(parseFloat(0).toFixed(2)),
                            Other_Cost_2: Number(parseFloat(0).toFixed(2)),
                            Other_Cost_3: Number(parseFloat(0).toFixed(2)),
                            Other_Cost_Name1: "NA",
                            Other_Cost_Name2: "NA",
                            Other_Cost_Name3: "NA",
                            Notes: 'NA',
                            Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            RFQ_Status: 'PENDING',
                            Shipping_Cost: parseFloat(0.00).toFixed(2),
                            Tax: parseFloat(0.00).toFixed(2),
                            Updated_By: 'TBD',
                            Version_No: parseInt(0),
                            Updating_Comments: 'RFQ Created under PENDING Status'
                        });
                        // Reset total cost values
                        SetTotalCost({
                            ...TotalCost,
                            Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2),
                            Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
                            Discount_Applied: parseFloat(0).toFixed(2),
                            Final_Total_Cost_After_Dicscount: parseFloat(0).toFixed(2)
                        });
                        // Reset SKU values
                        Set_New_SKU([{
                            Vendor_Partner_name: '',
                            SKU_ID: '',
                            SKU_Description: '',
                            QTY: parseInt(0),
                            Aspetto_Unit_Cost: parseFloat(0.00).toFixed(2),
                            Unit_Of_Measure: 'ea',
                            RFQRFQID: '',
                            Margin_Percent: parseFloat(0.00).toFixed(2),
                            Customer_Unit_Cost: parseFloat(0.00).toFixed(2),
                            Total_SKU_Cost: parseFloat(0.00).toFixed(2),
                            RFQ_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            RFQ_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            SKU_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            SKU_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                            SKU_Created_By: 'TBD',
                            SKU_Updated_By: 'TBD',
                        }]);
                    })
                .catch((err) => {
                    setLoading(false);
                    setRFQMssge(err.response?.data);
                    setErrorDialog(true);
                    setDis(false);
                    setPreview(false);
                });
        }
    }
};

return(
  <>
<LoadingDialog open={loading} />
{/* ApplicationBar component*/}
<ApplicationBar></ApplicationBar>
{/*Rendering success dialog if 'successDialog' state is true */}
{successDialog === true && (
  <Dialog
    open={successDialog}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying a success alert if 'rfqMssge' is available */}
      {rfqMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying message */}
        {rfqMssge}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog and reset state */}
      <Button name="OK" onClick={(e) => {
        e.preventDefault();
        handleClose();
        setKOsAddressChecked(false);
        setKOsNameChecked(false);
        setKOsPhoneChecked(false);
        setSKUIDs(FBOPSKUsARRAY);
        setDHSSKUIDs(DHSSKUsARRAY);
        setDis(false);
      }} href="/Dashboard/RFQInput">OK</Button>
    </DialogActions>
  </Dialog>
)}

{/*Rendering dialog to display SKU details if 'errorDialog' state is true */}
{errorDialog === true && (
  <Dialog
    open={errorDialog}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying an error alert if 'rfqMssge' is available */}
      {rfqMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying error message */}
        {rfqMssge}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog */}
      <Button name="OK" onClick={(e) => {
        handleClose();
        e.preventDefault();
        setDis(false);
      }} href="/Dashboard/RFQInput">OK</Button>
    </DialogActions>
  </Dialog>
)}

{/*Rendering dialog to display SKU details if  'dhsCBPDialogClicked' state is true */}
{dhsCBPDialogClicked === true && (
  <Dialog
    open={dhsCBPDialogClicked}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying DHS-CBP SKUs alert */}
      <Alert variant="filled" sx={{ bgcolor: orange[300],color:blueGrey[900]}}>DHS-CBP SKUs</Alert>
    </DialogTitle>
    <DialogContent>
      {/* Search field for filtering SKUs */}
      <TextField
        size="small"
        label="Search SKU"
        variant="standard"
        sx={{ width: "100%", marginBottom: 2 }}
        onChange={(e) => handleSearchSKU(e.target.value)}
      />
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying filtered SKUs */}
        {filteredDHSSKUIDs.map((SKU, index) => {
          return (
            <FormGroup key={SKU.ID}>
              {/* Displaying SKU information */}
              <Stack direction="row">
                {/* Adding button to add SKU */}
                <FormControlLabel
                  size="small"
                  control={
                    <IconButton
                      variant="contained"
                      onClick={async (e) => {
                        e.preventDefault();
                        // Handling SKU addition logic
                        setDis(true);
                        const DHS_Pricing_Year = String(pricingYear).split('(')[0];

                        // Fetching SKU data
                        await AxiosAPIInstance.get("/DHSPricing/PartNo/PricingYear", {
                          params: { Part_No: SKU.ID },
                        })
                          .then(async (result) => {
                            setDis(false);
                            let CustomerCost = '';
                            const pricingData = result?.data;

                            switch (DHS_Pricing_Year) {
                              case '06/09/23-06/08/24':
                                CustomerCost = pricingData.UnitPrice_Year1;
                                break;
                              case '06/09/24-06/08/25':
                                CustomerCost = pricingData.UnitPrice_Year2;
                                break;
                              case '06/09/25-06/08/26':
                                CustomerCost = pricingData.UnitPrice_Year3;
                                break;
                              case '06/09/26-06/08/27':
                                CustomerCost = pricingData.UnitPrice_Year4;
                                break;
                              case '06/09/27-06/08/28':
                                CustomerCost = pricingData.UnitPrice_Year5;
                                break;
                              case '06/09/28-06/08/29':
                                CustomerCost = pricingData.UnitPrice_Year6;
                                break;
                              case '06/09/29-06/08/30':
                                CustomerCost = pricingData.UnitPrice_Year7;
                                break;
                              default:
                                CustomerCost = '';
                            }

                            if (CustomerCost) {
                              CustomerCost = String(CustomerCost)
                                .replace("$", '')
                                .replace(",", '')
                                .replace(' ', '');

                              Set_New_SKU((prevNewSKU) => [
                                ...prevNewSKU,
                                {
                                  Vendor_Partner_name: pricingData.Part_No,
                                  SKU_ID: pricingData.Part_No,
                                  SKU_Description: SKU.Desc,
                                  QTY: 0,
                                  Aspetto_Unit_Cost: parseFloat(CustomerCost).toFixed(2),
                                  Unit_Of_Measure: 'ea',
                                  RFQRFQID: Form_Value.RFQ_ID,
                                  Margin_Percent: parseFloat(0.00).toFixed(2),
                                  Customer_Unit_Cost: parseFloat(CustomerCost).toFixed(2),
                                  Total_SKU_Cost: parseFloat(0).toFixed(2),
                                  RFQ_Created_Date: Form_Value.Created_Date,
                                  RFQ_Last_Updated_Date: Form_Value.Last_Updated_Date,
                                  SKU_Created_Date: String(today1),
                                  SKU_Last_Updated_Date: String(today1),
                                  SKU_Created_By: String(UserEmail).toLowerCase(),
                                  SKU_Updated_By: String(UserEmail).toLowerCase(),
                                  Version_No: parseInt(Form_Value.Version_No),
                                },
                              ]);

                              // Removing SKU from list
                              const updatedSKUs = [...DHSSKUIDs];
                              updatedSKUs.splice(index, 1);
                              setDHSSKUIDs(updatedSKUs);
                            } else {
                              setRFQMssge('Customer cost could not be determined for the selected pricing year.');
                              setErrorDialog(true);
                            }
                          })
                          .catch((error) => {
                            // Handling errors
                            setRFQMssge(error?.response?.data);
                            setErrorDialog(true);
                            setDis(false);
                          });
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{
                          bgcolor: orange[800],
                          marginLeft: "10px",
                          width: "25px",
                          height: "25px",
                        }}
                      >
                        <Tooltip title={`Add SKU# ${SKU.ID}`}>
                          <AddBoxIcon />
                        </Tooltip>
                      </Avatar>
                    </IconButton>
                  }
                  label={
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      <b>{SKU.ID} :</b>
                      <br />
                      {SKU.Desc}
                    </span>
                  }
                />
              </Stack>
              <Divider sx={{ color: blueGrey[900], bgcolor: blueGrey[900] }}></Divider>
            </FormGroup>
          );
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog */}
      <Button
        name="OK"
        onClick={(e) => {
          e.preventDefault();
          setSearchInput("");
          handleClose();
        }}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
)}


{/*Rendering dialog to display SKU details if  'FBOPDialogClicked' state is true*/}
{FBOPDialogClicked === true && (
  <Dialog
    open={FBOPDialogClicked}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying an alert for FBOP SKUs */}
      <Alert variant='filled' sx={{ bgcolor: orange[300],color:blueGrey[900]}}>FBOP SKUs</Alert>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Mapping through SKUIDs array and rendering each SKU */}
        {SKUIDs.map((SKU, index) => {
          return (
            <FormGroup>
              <Stack direction="row">
                {/* Adding SKU button */}
                <FormControlLabel size="small" control={<IconButton variant="contained" onClick={async (e) => {
                  e.preventDefault();
                  setDis(true);
                  // Fetching FBOP SKUs from the server
                  await AxiosAPIInstance.get("/SpecialContracts/FBOPSKUs", { params: { Agency: "FBOP", SKUIDs: SKU.ID, Pricing_Year: PD.getFullYear() } }).then(
                    async (result) => {
                      setDis(false);
                      result?.data.map(async (row, index) => {
                        // Adding fetched SKUs to New_SKU array
                        Set_New_SKU([...New_SKU, {
                          Vendor_Partner_name: row.SKU_ID,
                          SKU_ID: row.SKU_ID,
                          SKU_Description: row.SKU_Description,
                          QTY: parseInt(0),
                          Aspetto_Unit_Cost: parseFloat(row.Aspetto_Unit_Cost).toFixed(2),
                          Unit_Of_Measure: 'ea',
                          RFQRFQID: Form_Value.RFQ_ID,
                          Margin_Percent: parseFloat(row.Margin_Percent).toFixed(2),
                          Customer_Unit_Cost: parseFloat(row.Customer_Unit_Cost).toFixed(2),
                          Total_SKU_Cost: parseFloat(0).toFixed(2),
                          RFQ_Created_Date:Form_Value.Created_Date,
                          RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
                          SKU_Created_Date:String(today),  
                          SKU_Last_Updated_Date:String(today),
                          SKU_Created_By:String(UserEmail).toLowerCase(),
                          SKU_Updated_By:String(UserEmail).toLowerCase(),
                          Version_No:parseInt(Form_Value.Version_No)
                        }]);
                      })
                      // Removing added SKU from SKUIDs array
                      const skus = [...SKUIDs];
                      skus.splice(index, 1);
                      setSKUIDs(skus);
                    }
                  ).catch((error) => {
                    // Handling errors
                    setRFQMssge(error?.response?.data);
                    setErrorDialog(true);
                    setDis(false);
                  });
                }}><Avatar variant='rounded' sx={{ bgcolor: [orange[800]], marginLeft: "10px", width: "25px", height: "25px" }}>
                  <Tooltip title={`Add SKU# ${SKU.ID}`}><AddBoxIcon /></Tooltip></Avatar></IconButton>} label={SKU.Desc} />
              </Stack>
            </FormGroup>
          );
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close the dialog */}
      <Button name="OK" onClick={(e) => {
        e.preventDefault();
        handleClose();
      }} >OK</Button>
    </DialogActions>
  </Dialog>
)}

{/*Rendering dialog to display SKU details if 'getSKUClicked' state is true*/}
{getSKUClicked === true && (
  <Dialog
    maxWidth="x-lg"
    open={skusDialog}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying an alert for SKU Details */}
      <Alert variant="filled"  sx={{ bgcolor: orange[300],color:blueGrey[900]}}>
        SKU Details
      </Alert>
      <br></br>
      <Stack direction='row' spacing={2} justifyContent='center'>
        {/* Input field for searching by SKU ID */}
        <TextField
          label="Search by SKU ID"
          value={skuIdInput}
          onChange={(e) => setSkuIdInput(e.target.value)}
          variant="outlined"
          size="small"
        />
        {/* Input field for searching by Vendor/Partner */}
        <TextField
          label="Search Vendor/Partner"
          value={searchByVendorNameInput}
          onChange={(e) => setSearchByVendorNameInput(e.target.value)}
          variant="outlined"
          size="small"
        />
      </Stack>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Table to display SKU details */}
        <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
          <TableHead>
            <TableRow>
                        <TableCell sx={{border:1,borderColor:'black',color:"black",backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Add</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Vendor/Partner</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">SKU ID</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,width:'20%',fontWeight:'bold'}} align="center">SKU Desc</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Aspetto's Cost($)</TableCell>
                        {/* <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:orange[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5}} align="center">Margin(%)</TableCell> */}
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Customer's Cost($)</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Used in the past for</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Quote Dated</TableCell>
                        <TableCell sx={{border:1,color:"black",borderColor:'black',backgroundColor:blueGrey[200],fontFamily:'Verdana (sans-serif)',fontSize:'12px',padding:0.5,fontWeight:'bold'}} align="center">Created By</TableCell>
            </TableRow>
          </TableHead>
          {/* Mapping through filteredSearchAllSKUs array and rendering each SKU */}
          {filteredSearchAllSKUs.map((SKU, index) => (
   <TableRow key={index}>
   <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">
   <FormControlLabel
     size="small"
     control={
       <IconButton
         variant="contained"
         onClick={(e) => {
           e.preventDefault();
           Set_New_SKU([...New_SKU,{
             Vendor_Partner_name:SKU.SKU_ID,
             SKU_ID:SKU.SKU_ID,
             SKU_Description:SKU.SKU_Description,
             QTY:parseInt(0),
             Aspetto_Unit_Cost:parseFloat(SKU.Aspetto_Unit_Cost).toFixed(2),
             Unit_Of_Measure:'ea',
             RFQRFQID:Form_Value.RFQ_ID,
             Margin_Percent:parseFloat(SKU.Margin_Percent).toFixed(2),
             Customer_Unit_Cost:parseFloat(SKU.Customer_Unit_Cost).toFixed(2),
             Total_SKU_Cost:parseFloat(0).toFixed(2),
             RFQ_Created_Date:Form_Value.Created_Date,
             RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
             SKU_Created_Date:String(today),  
             SKU_Last_Updated_Date:String(today),
             SKU_Created_By:String(UserEmail).toLowerCase(),
             SKU_Updated_By:String(UserEmail).toLowerCase(),
             Version_No:parseInt(Form_Value.Version_No)
           
           }])
         }}
       >
         <Avatar variant="rounded" sx={{ bgcolor: [orange[900]], marginLeft: '10px', width: '25px', height: '25px' }}>
           <Tooltip title={`Add SKU# ${SKU.Vendor_Partner_name}`}>
             <AddBoxIcon />
           </Tooltip>
         </Avatar>
       </IconButton>
     }
   /></TableCell>
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{SKU.Vendor_Partner_name}</TableCell>
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{SKU.SKU_ID}</TableCell>
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{SKU.SKU_Description}</TableCell>
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{Number(SKU.Aspetto_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
       {/* <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{Number(SKU.Margin_Percent)}</TableCell> */}
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{Number(SKU.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell> 
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{SKU.Opportunity_Name}</TableCell>
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{String(SKU.RFQ_Created_Date).split('T')[0]}</TableCell>
       <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12px',padding:0}} align="center">{String(SKU?.Sales_Head_Email)}</TableCell>
 </TableRow>
          ))}
        </Table>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close the dialog */}
      <Button name="OK" variant='contained' color='warning' size='small' onClick={(e) => {
        e.preventDefault();
        setLoading(true);
        setSearchByVendorNameInput('');
        setSkuIdInput('');
        handleClose();
      }}>
        CLOSE
      </Button>
    </DialogActions>
  </Dialog>
)}
     {areYouSureDialog===true&&(<Dialog
        open={areYouSureDialog}
        onClose={handleAreYouSureDialogClose}
        aria-labelledby="alert-dialog-title"variant="standard"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
       <Alert variant='filled' severity='info'> {draftSaveButtonRequiredFieldDisable?"Are You Sure you want to save it as a Draft? ":"Are You Sure, You want to Submit?"}</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
          {draftSaveButtonRequiredFieldDisable?"Once Saved, You Can Only Access it from the Drafts Tab ":"Once Submited You Can Access it from the Retrieve/Update RFQs Tab"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="YES" type='submit' onClick={async(e)=>{ setAreYouSureDialog(false);HandleSubmit(e)}} >YES</Button>
          <Button name="NO" onClick={(e)=>{e.preventDefault();setAreYouSureDialog(false)}}>
           NO
          </Button>
        </DialogActions>
      </Dialog>)}
      
    <Stack direction="row" spacing={2} justifyContent="center" sx={{marginTop:"70px"}} >
        <Stack direction="column" justifyContent="center" spacing={1}>
          <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{alignContent:"center",fontSize:"25px", fontWeight:"bold",padding:0}}> Request For Quotation(RFQ)</Typography>
          </Stack>
          <Stack justifyContent="center" direction="row">
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px",marginBottom:'20px'}} orientation='horizontal'></Divider>
          </Stack>
        <Stack direction="row"  spacing={2} justifyContent="center">
        <TextField size="small"  required  variant="standard" sx={{width:"250px"}} label="Opportunity Name" value={Form_Value.Opportunity_Name} onChange={(e)=>{ setPreview(false);Set_Form_Value({...Form_Value,Opportunity_Name:e.target.value})}}></TextField>
        <Stack direction="row" spacing={1} justifyContent="center">
        <TextField size="small" required sx={{width:"250px"}} variant="standard" label="RFQ ID" helperText="Enter NA if you don't have one.Please don't make up your own number." readOnly={draftSubmitRFQIDDisable?true:false} InputProps={{endAdornment:(
            <InputAdornment>
            {draftSubmitRFQIDDisable?<Tooltip title='This Field is locked'><LockIcon></LockIcon></Tooltip>:undefined}
            </InputAdornment>
          )}} value={Form_Value.RFQ_ID} onChange={(e)=>{ setPreview(false);
          if (draftSubmitRFQIDDisable===true)
          {

          }
          HandleRFQChange(e);
          setRFQMssge("");
          }}></TextField>
          </Stack>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
        <Select size="small"
          labelId="Contract-Vehicle"
          id="Contract-Vehicle-id"
          value={Form_Value.Contract_Vehicle}
          variant="standard"
          label="Contract Vehicle"
          onChange={(e)=>{handleContractVehicleChange(e)}}
          sx={{width:"250px"}}>
          <MenuItem size="small" name="FE" value={"Federal"}>Federal</MenuItem>
          <MenuItem size="small" name="MI" value={"Military"}>Military</MenuItem>
          <MenuItem size="small" name="TA" value={"Tactical"}>Tactical</MenuItem>
          <MenuItem size="small" name="G"  value={"GSA"}>GSA</MenuItem>
          <MenuItem size="small" name="FB" value={"FBOP"}>FBOP</MenuItem>
          <MenuItem size="small" name="DH-CB" value={"DHS-CBP"}>DHS-CBP</MenuItem>
          <MenuItem size="small" name="SO" value={"SAM/Open Market"}>SAM/Open Market</MenuItem>
          <MenuItem size="small" name="UN" value={"Unison"}>Unison</MenuItem>
          <MenuItem size="small" name="DI" value={"Direct Inquiry"}>Direct Inquiry</MenuItem>
          <MenuItem size="small" name="CO" value={"Commercial"}>Commercial</MenuItem>
          <MenuItem size="small" name="EN" value={"Engineering"}>Engineering</MenuItem>
        </Select>
      </FormControl>
      <DatePicker label="Published date" sx={{width:"250px"}} value={Form_Value.Published_date} maxDate={new Date()} renderInput={(params)=><TextField variant="standard" size="small" required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"250px"}} {...params}/>} onChange={(value)=>{ setPreview(false);Set_Form_Value({...Form_Value,Published_date:value})}}>
        </DatePicker>
        <DatePicker label="Due date" sx={{width:"250px"}} value={Form_Value.Due_date} minDate={Form_Value.Published_date} renderInput={(params)=><TextField size="small" required={draftSaveButtonRequiredFieldDisable?false:true}  variant="standard" sx={{width:"250px"}} {...params}/>} onChange={(value)=>{ setPreview(false);Set_Form_Value({...Form_Value,Due_date:value})}}>
        </DatePicker>
 
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, border: 1,width:'1250px'}}>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="KO's Details" sx={{backgroundColor:orange[200],color:blueGrey[900],fontWeight:'bold',fontSize:'14px'}} />
          <Tab label="Ship To" sx={{backgroundColor:orange[200],color:blueGrey[900],fontWeight: 'bold',fontSize:'14px'}}/>
          <Tab label="Bill To" sx={{backgroundColor:orange[200],color:blueGrey[900],fontWeight: 'bold',fontSize:'14px'}}/>
        </Tabs>

        <SwipeableViews index={tabIndex} onChangeIndex={handleSwipeChange}>
          {/* Tab 1: KO's Details */}
          <Box>
            <Stack required direction="row" spacing={2} justifyContent="center" sx={{marginTop:'15px'}}>
              <TextField
                variant="standard"
                size="small"
                sx={{ width: "325px" }}
                label="Contracting Officer's Name"
                value={Form_Value.KO_name}
                onChange={(e) => {
                  setPreview(false);
                  Set_Form_Value({ ...Form_Value, KO_name: e.target.value });
                  setKOsNameChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                variant="standard"
                type="email"
                sx={{ width: "325px" }}
                label="Contracting Officer's Email"
                value={Form_Value.KO_email}
                onChange={(e) => {
                  setPreview(false);
                  Set_Form_Value({ ...Form_Value, KO_email: e.target.value });
                  setKOsEmailChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                variant="standard"
                sx={{ width: "325px" }}
                label="Contracting Officer's Phone"
                value={Form_Value.KO_phone}
                onChange={(e) => {
                  setPreview(false);
                  Set_Form_Value({ ...Form_Value, KO_phone: e.target.value });
                  setKOsPhoneChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                variant="standard"
                multiline
                sx={{ width: "325px" }}
                label="Contracting Officer's Address"
                value={Form_Value.KO_address}
                onChange={(e) => {
                  setPreview(false);
                  Set_Form_Value({ ...Form_Value, KO_address: e.target.value });
                  setKOsAddressChecked(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactMailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>

          {/* Tab 2: Ship To */}
          <Box>
            <Stack direction="row" spacing={1} justifyContent="center" sx={{marginTop:'15px'}}>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Customer's Name"
                  value={Form_Value.Customer_Name}
                  disabled={KOsNameChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, Customer_Name: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px', fontSize: '10px' }}
                        checked={KOsNameChecked}
                        onChange={(e) => {
                          setPreview(false);
                          setKOsNameChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Name: Form_Value.KO_name });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Name: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Name"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  type="email"
                  sx={{ width: "300px" }}
                  label="Customer's Email"
                  value={Form_Value.Customer_Email}
                  disabled={KOsEmailChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, Customer_Email: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={KOsEmailChecked}
                        onChange={(e) => {
                          setPreview(false);
                          setKOsEmailChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Email: Form_Value.KO_email });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Email: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Email"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Customer's Phone"
                  value={Form_Value.Customer_Phone}
                  disabled={KOsPhoneChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, Customer_Phone: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={KOsPhoneChecked}
                        sx={{ width: '20px' }}
                        onChange={(e) => {
                          setPreview(false);
                          setKOsPhoneChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Phone: Form_Value.KO_phone });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Phone: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Phone"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  multiline
                  sx={{ width: "300px" }}
                  label="Customer's Address"
                  value={Form_Value.Customer_Address}
                  disabled={KOsAddressChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, Customer_Address: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px', fontSize: '10px' }}
                        checked={KOsAddressChecked}
                        onChange={(e) => {
                          setPreview(false);
                          setKOsAddressChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, Customer_Address: Form_Value.KO_address });
                          } else {
                            Set_Form_Value({ ...Form_Value, Customer_Address: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Address"
                  />
                </FormGroup>
              </Stack>
            </Stack>
          </Box>

          {/* Tab 3: Bill To */}
          <Box>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{marginTop:'15px'}}>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Name"
                  value={Form_Value.BillTo_Name}
                  disabled={BillToKOsNameChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, BillTo_Name: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={BillToKOsNameChecked}
                        onChange={(e) => {
                          setPreview(false);
                          setBillToKOsNameChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Name: Form_Value.KO_name });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Name: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Name"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  type="email"
                  sx={{ width: "300px" }}
                  label="Email"
                  value={Form_Value.BillTo_Email}
                  disabled={BillToKOsEmailChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, BillTo_Email: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={BillToKOsEmailChecked}
                        onChange={(e) => {
                          setPreview(false);
                          setBillToKOsEmailChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Email: Form_Value.KO_email });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Email: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Email"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{ width: "300px" }}
                  label="Phone"
                  value={Form_Value.BillTo_Phone}
                  disabled={BillToKOsPhoneChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, BillTo_Phone: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={BillToKOsPhoneChecked}
                        sx={{ width: '20px' }}
                        onChange={(e) => {
                          setPreview(false);
                          setBillToKOsPhoneChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Phone: Form_Value.KO_phone });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Phone: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Phone"
                  />
                </FormGroup>
              </Stack>
              <Stack direction="column" spacing={1} justifyContent="center">
                <TextField
                  size="small"
                  variant="outlined"
                  multiline
                  sx={{ width: "300px" }}
                  label="Address"
                  value={Form_Value.BillTo_Address}
                  disabled={BillToKOsAddressChecked}
                  onChange={(e) => {
                    setPreview(false);
                    Set_Form_Value({ ...Form_Value, BillTo_Address: e.target.value });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup sx={{ width: "250px" }} style={{ transform: "scale(0.9)" }}>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ width: '20px' }}
                        checked={BillToKOsAddressChecked}
                        onChange={(e) => {
                          setPreview(false);
                          setBillToKOsAddressChecked(e.target.checked);
                          if (e.target.checked) {
                            Set_Form_Value({ ...Form_Value, BillTo_Address: Form_Value.KO_address });
                          } else {
                            Set_Form_Value({ ...Form_Value, BillTo_Address: '' });
                          }
                        }}
                      />
                    }
                    label="Same as KO's Address"
                  />
                </FormGroup>
              </Stack>
            </Stack>
          </Box>
        </SwipeableViews>
      </Paper>
    </Box>

        <Stack direction='row' spacing={2} justifyContent="center">
        <TextField size="small" variant="standard"   sx={{width:"250px"}} label="Organization/Agency's Name" value={Form_Value.Organization_Name} onChange={(e)=>{ setPreview(false);Set_Form_Value({...Form_Value,Organization_Name:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}></TextField>
                <TextField
         size="small"
         variant="standard"
         sx={{ width: "250px" }}
         multiline={true}
         rowsMax={20}
         label="Brief"
         value={Form_Value.Brief}
         onChange={(e) => {
           setPreview(false);
           Set_Form_Value({ ...Form_Value, Brief: e.target.value });
         }}
         InputProps={{
           startAdornment: (
             <InputAdornment position="start">
               <DescriptionIcon />
             </InputAdornment>
           ),
         }}
       />

      
      <TextField size="small"  variant="standard" multiline={true} rowsMax={10} sx={{width:"250px"}} label="Estimated Delivery" value={Form_Value.Estimated_Delivery} onChange={(e)=>
      { setPreview(false);
        Set_Form_Value({...Form_Value,Estimated_Delivery:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
          </TextField>
      <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Payment_Terms">Payment Terms</InputLabel>
        <Select size="small"
          labelId="Payment_Terms"
          id="Payment_Terms-id"
          value={Form_Value.Payment_Terms}
          variant="standard"
          label="Payment Terms"
          onChange={(e)=>{
            setPreview(false);
            Set_Form_Value({...Form_Value,Payment_Terms:e.target.value})}}
          sx={{width:"250px"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"NET30"}>NET30</MenuItem>
          <MenuItem size="small" value={"NET40"}>NET45</MenuItem>
          <MenuItem size="small" value={"NET50"}>NET60</MenuItem>
          <MenuItem size="small" value={"Due On Receipt"}>Due On Receipt</MenuItem>
        </Select>
      </FormControl>
        <TextField size="small" variant="standard" sx={{width:"250px"}} name="Notes" label="Shipping Notes" multiline={true} rowsMax={20} value={Form_Value.Notes} onChange={(e)=>
        { setPreview(false);
          Set_Form_Value({...Form_Value,Notes:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction='row' spacing={2} justifyContent="center">
        <TextField size="small" variant="standard"  sx={{width:"250px"}} label="Shipping Method"  value={Form_Value.Shipping_Method} onChange={(e)=>
        {
          setPreview(false);
          Set_Form_Value({...Form_Value,Shipping_Method:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocalShippingIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Shipping-Terms">Shipping Terms</InputLabel>
        <Select size="small"
          labelId="Shipping-Terms"
          id="Shipping-Terms-id"
          label="Shipping Terms"
          variant="standard"
          onChange={(e)=>{
            setPreview(false);
            Set_Form_Value({...Form_Value,Shipping_Terms:e.target.value})}}
          sx={{width:"250px"}}
          value={Form_Value.Shipping_Terms}>
          <MenuItem  size="small" value={"FOB Origin"}>FOB Origin</MenuItem>
          <MenuItem  size="small" value={"FOB Destination"}>FOB Destination</MenuItem>
        </Select>
      </FormControl>
        <TextField size="small" variant="standard"  sx={{width:"250px"}} multiline={true} rowsMax={20} helperText="Use this field if you have some special rates/notes/info for Purchase Orders deptartment." label="Notes For PO" value={Form_Value.Notes_For_PO} 
        onChange={(e)=>{
          setPreview(false);
          Set_Form_Value({...Form_Value,Notes_For_PO:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
    <  TextField size="small" variant="standard"  sx={{width:"250px"}} multiline={true} rowsMax={20} helperText="30,60,90,120 Days. Default Value is 30 Days" label="Valid For" value={Form_Value.Valid_For} 
        onChange={(e)=>{
          setPreview(false);
          Set_Form_Value({...Form_Value,Valid_For:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
          <TextField size="small" variant="standard" required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"250px"}} label="Sales Lead Name" value={Form_Value.Sales_Head_Name} 
          onChange={(e)=>{
            setPreview(false);
            Set_Form_Value({...Form_Value,Sales_Head_Name:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          )
        }}></TextField>

        </Stack>
        <Stack direction='row' spacing={2} justifyContent="center">
        <TextField size="small" variant="standard" required={draftSaveButtonRequiredFieldDisable?false:true}  type="email" sx={{width:"250px"}} label="Sales Lead Email" value={Form_Value.Sales_Head_Email} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          )
        }}>
        </TextField>
        <TextField size="small" variant="standard" required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"250px"}} label="Sales Lead Phone" value={Form_Value.Sales_Head_Ph} 
        onChange={(e)=>{
          setPreview(false);
          Set_Form_Value({...Form_Value,Sales_Head_Ph:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" variant="standard"  sx={{width:"350px"}} multiline={true} rowsMax={20} helperText="Use this field if you have some special info for KO." label="Notes For KO" value={Form_Value.Notes_For_KO} 
        onChange={(e)=>{
          setPreview(false);
          Set_Form_Value({...Form_Value,Notes_For_KO:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
                <Stack sx={{width:"250px"}} ></Stack>
                <Stack sx={{width:"150px"}} ></Stack>
       
        </Stack>
  {/*--------------------------ORDER DETAILS-----------------------------------------------*/}

        <Stack direction="column" spacing={1} justifyContent="center">
        <Stack direction="row"  justifyContent="center">
        <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"19px", fontWeight:"bold",padding:0}}>Order Details</Typography>

        </Stack>
        <Stack justifyContent="center" direction="row">
        <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px",marginBottom:'20px'}} orientation='horizontal'></Divider>
          </Stack>
        {Form_Value.Contract_Vehicle==="DHS-CBP" &&(
          <Stack direction='column' spacing={2} >
<Stack direction='row' spacing={2} justifyContent="center">
 <FormControl size="small" sx={{width:"250px",marginBottom:"20px"}} >
 <InputLabel id="Pricing-Year">Select Pricing Year</InputLabel>
 <Select size="small"
   required
   labelId="Pricing-Year"
   id="Pricing-Year-id"
   value={pricingYear}
   variant="standard"
   label="Pricing-Year"
   onChange={(e)=>
    { 
      setDHSSKUIDs(DHSSKUsARRAY)
      setPricingYear(e.target.value)
    }}
   sx={{width:"250px"}}>
   <MenuItem size="small" name="Yr1" value={"06/09/23-06/08/24(Year1)"}>06/09/23-06/08/24(Year1)</MenuItem>
   <MenuItem size="small" name="Yr2" value={"06/09/24-06/08/25(Year2)"}>06/09/24-06/08/25(Year2)</MenuItem>
   <MenuItem size="small" name="Yr3" value={"06/09/25-06/08/26(Year3)"}>06/09/25-06/08/26(Year3)</MenuItem>
   <MenuItem size="small" name="Yr4" value={"06/09/26-06/08/27(Year4)"}>06/09/26-06/08/27(Year4)</MenuItem>
   <MenuItem size="small" name="Yr5" value={"06/09/27-06/08/28(Year5)"}>06/09/27-06/08/28(Year5)</MenuItem>
   <MenuItem size="small" name="Yr6" value={"06/09/28-06/08/29(Year6)"}>06/09/28-06/08/29(Year6)</MenuItem>
   <MenuItem size="small" name="Yr7" value={"06/09/28-06/08/30(Year7)"}>06/09/28-06/08/30(Year7)</MenuItem>
 </Select>
</FormControl>
<Button  variant="contained" size='small' onClick={(e)=>{setSearchInput("");handleDHSSKUs(e)}} sx={{bgcolor:orange[800],width:"200px",height:"35px",
  "&:hover": {
    bgcolor: orange[600], // Zoom in effect on hover
  }
}} disabled={pricingYear===''?true:false}>ADD DHS-CBP SKUs</Button>

</Stack>
{/* <Stack>
<Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px",marginBottom:'20px'}} orientation='horizontal'></Divider>
</Stack> */}

</Stack>
         )}

        {Form_Value.Contract_Vehicle==="FBOP" &&(
          <Stack direction='column' spacing={2} >
          <Stack direction='row' spacing={2} justifyContent="center" sx={{}}>
     {/* <img src={FBOP} alt='Slate Image' height="50px" width="50px"></img> */}
<Button   variant="contained" size='small' onClick={(e)=>handleFBOPSKUs(e)} sx={{bgcolor:[orange[800]],width:"200px",height:"35px",
  "&:hover": {
    bgcolor: orange[600], // Zoom in effect on hover
  }
}} disabled={dis}>ADD FBOP SKUs</Button>
</Stack>

{/* <Stack>
<Divider sx={{color:blueGrey[900],bgcolor:orange[900],width:"100%",height:"1px",marginBottom:'20px'}} orientation='horizontal'></Divider>
</Stack> */}
</Stack> )}
<DragDropContext onDragEnd={onDragEnd} >
      <Droppable droppableId="skus">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {New_SKU.map((New_Sku, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Stack direction="column" spacing={1} justifyContent="center">
                      <Stack direction="row" spacing={1} justifyContent="center">
                        <IconButton {...provided.dragHandleProps}>
                        <DragIndicatorIcon sx={{color:orange[900]}} />
                        </IconButton>
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="Vendor_Partner_name"
                          sx={{ width: "150px" }}
                          label="Vendor's Partner Name"
                          value={New_Sku.Vendor_Partner_name}
                          onChange={(e) => HandleSKUChange(e, index)}
                        />
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="SKU_ID"
                          sx={{ width: "150px" }}
                          label="SKU ID"
                          value={New_Sku.SKU_ID}
                          onChange={(e) => HandleSKUChange(e, index)}
                        />
                        <TextField
                          size="small"
                          required
                          sx={{ width: "250px" }}
                          name="SKU_Description"
                          label="SKU Description"
                          multiline
                          rowsMax={20}
                          value={New_Sku.SKU_Description}
                          onChange={(e) => HandleSKUChange(e, index)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          name="QTY"
                          required
                          variant="outlined"
                          sx={{ width: "100px" }}
                          label="QTY"
                          value={String(New_Sku.QTY).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                        />
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="Unit_Of_Measure"
                          sx={{ width: "70px" }}
                          label="UM"
                          value={String(New_Sku.Unit_Of_Measure).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                        />
                        <TextField
                          size="small"
                          name="Aspetto_Unit_Cost"
                          required
                          sx={{ width: "130px" }}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          label="Aspetto's Cost"
                          value={New_Sku.Aspetto_Unit_Cost === "NaN" ? parseFloat(0.00) : String(New_Sku.Aspetto_Unit_Cost).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          name="Margin_Percent"
                          sx={{ width: "110px" }}
                          label="Margin"
                          value={String(New_Sku.Margin_Percent).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <PercentIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          name="Customer_Unit_Cost"
                          required
                          sx={{ width: "130px" }}
                          label="Customer's Cost"
                          value={New_Sku.Customer_Unit_Cost === "NaN" ? parseFloat(0.00) : String(New_Sku.Customer_Unit_Cost).trim()}
                          onChange={(e) => HandleSKUChange(e, index)}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size="small"
                          name="Total_SKU_Cost"
                          required
                          sx={{ width: "180px" }}
                          label="Total SKU Cost"
                          value={New_Sku.Total_SKU_Cost === "NaN" ? parseFloat(0.00) : String(New_Sku.Total_SKU_Cost).trim()}
                          inputProps={{ min: "0", step: "any", inputMode: "numeric" }}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} sx={{ marginLeft: "120px" }} justifyContent="center">
                        <Stack></Stack>
                        {New_SKU.length > 1 && (
                          <IconButton
                            name="Cancel"
                            sx={{
                              borderRadius: '5px',
                              color: 'white',
                              transition: 'transform 0.2s ease-in-out',
                              "&:hover": {
                                transform: 'scale(1.1)',
                              },
                            }}
                            variant='contained'
                            color='warning'
                            onClick={() => HandleRemoveSKU(index)}
                          >
                            <Avatar variant="rounded" sx={{ backgroundColor: red[700], height: '30px', width: '30px', ":hover": { boxShadow: "10px 10px 20px #ccc" } }}>
                              <Tooltip title="Remove SKU"><DisabledByDefaultIcon /></Tooltip>
                            </Avatar>
                          </IconButton>
                        )}
                        {New_SKU.length - 1 === index && (
                          <>
                            <IconButton
                              variant="contained"
                              sx={{
                                borderRadius: '5px',
                                color: 'white',
                                transition: 'transform 0.2s ease-in-out',
                                "&:hover": {
                                  transform: 'scale(1.1)',
                                },
                              }}
                              color="success"
                              onClick={() => HandleAddSKU(index)}
                            >
                              <Avatar variant='rounded' sx={{ bgcolor: green[800], height: '30px', width: '30px' }}>
                                <Tooltip title="Add SKU"><AddBoxIcon /></Tooltip>
                              </Avatar>
                            </IconButton>
                            <IconButton
                              size='small'
                              variant="contained"
                              onClick={(e) => {
                                e?.preventDefault();
                                getAllSKUs(New_Sku.SKU_ID);
                              }}
                              sx={{
                                borderRadius: '5px',
                                color: 'white',
                                transition: 'transform 0.2s ease-in-out',
                                "&:hover": {
                                  transform: 'scale(1.1)',
                                },
                              }}
                              disabled={dis}
                            >
                              <Avatar variant='rounded' sx={{ bgcolor: blue[800], height: '30px', width: '30px' }}>
                                <Tooltip title="Search from Existing SKUs"><SearchIcon /></Tooltip>
                              </Avatar>
                            </IconButton>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
        <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"290px"}}></Stack>
        <FormGroup>
        
      <FormControlLabel size="small" control={<Checkbox size="small" checked={shipp} onChange={(e)=>{
        setShipp(e.target.checked)
        if(e.target.checked===false)
        {
          Set_Form_Value({...Form_Value,Shipping_Cost:parseFloat(0).toFixed(2)})
          const skusl=[...New_SKU];
          let cal=0.00;
          let cal3=0.00;
            skusl.map((sku, index)=>{
            cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
            cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
            })
            cal3=(cal3+parseFloat(0)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
            cal=(cal+parseFloat(0)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
            SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
        }
      }}/>} label="Shipping Cost" />
       </FormGroup>
      <TextField inputProps={{min:"0",step:"any"}} variant="standard" required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"200px"}} size="small"  disabled={!shipp} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Shipping cost" value={Form_Value.Shipping_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}  onChange={(e)=>handleShipping(e)}></TextField>
    
          </Stack>
          
          <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"370px"}}></Stack>
        <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" checked={tax} onChange={(e)=>{
        setTax(e.target.checked);
        if(e.target.checked===false)
        {
          Set_Form_Value({...Form_Value,Tax:parseFloat(0).toFixed(2)})
          const skusl=[...New_SKU];
          let cal=0.00;
          let cal3=0.00;
            skusl.map((sku, index)=>{
            cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
            cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
            })
            cal3=(cal3+parseFloat(0)+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
            cal=(cal+parseFloat(0)+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
            SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
        }
      }}/>} label="Tax" />
       </FormGroup>
        <TextField inputProps={{min:"0",step:"any"}} variant="standard"  required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"200px"}}  size="small" disabled={!tax} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Tax" value={Form_Value.Tax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} type ="number" onChange={(e)=>handleTax(e)}></TextField>
          </Stack>
          {/*------------------------------------------------------------Other Costs--------------------------------------------- */}
          {/* ------------------------------------------------------------------------------------------------------------------ */}
          <Stack direction="row" justifyContent="center">
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={otherCost1} onChange={(e)=>{
          setOtherCost1(e.target.checked)
         if(e.target.checked===false)
         {
           Set_Form_Value({...Form_Value,Other_Cost_1:parseFloat(0).toFixed(2),Other_Cost_Name1:"NA"})
           const skusl=[...New_SKU];
           let cal=0.00;
           let cal3=0.00;
             skusl.map((sku, index)=>{
             cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
             cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
             })
             cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
             cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(0))
             SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
         }
        }}/>} label="Other Cost 1" />
         </FormGroup>
        <TextField  variant="standard" required  sx={{width:"200px"}} size="small" type ="text" disabled={!otherCost1}   value={Form_Value.Other_Cost_Name1} label="Enter Cost Name" onChange={(e)=>{Set_Form_Value({...Form_Value,Other_Cost_Name1:e.target.value})}}></TextField>
                    <TextField inputProps={{min:"0",step:"any"}} variant="standard" required sx={{width:"200px"}} size="small" disabled={!otherCost1} InputProps={{startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),}}  value={Form_Value.Other_Cost_1.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} label="Amount" onChange={(e)=>handleOtherCost1(e)}></TextField>
      
            </Stack>
            <Stack direction="row" justifyContent="center">
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={otherCost2} onChange={(e)=>{
          setOtherCost2(e.target.checked)
         if(e.target.checked===false)
         {
          console.log(Form_Value.Other_Cost_2)
           Set_Form_Value({...Form_Value,Other_Cost_2:parseFloat(0).toFixed(2),Other_Cost_Name2:"NA"})
           const skusl=[...New_SKU];
           let cal=0.00;
           let cal3=0.00;
             skusl.map((sku, index)=>{
             cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
             cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
             })
             cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
             cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_3)+parseFloat(Form_Value.Other_Cost_1))
             SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
         }
        }}/>} label="Other Cost 2" />
         </FormGroup>
        <TextField  variant="standard" required sx={{width:"200px"}} size="small" type ="text" disabled={!otherCost2}  value={Form_Value.Other_Cost_Name2} label="Enter Cost Name" onChange={(e)=>{Set_Form_Value({...Form_Value,Other_Cost_Name2:e.target.value})}}></TextField>
                    <TextField inputProps={{min:"0",step:"any"}} variant="standard" required  sx={{width:"200px"}} size="small" disabled={!otherCost2} InputProps={{startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),}}  value={Form_Value.Other_Cost_2.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} label="Amount" onChange={(e)=>handleOtherCost2(e)}></TextField>
      
            </Stack>
            <Stack direction="row"justifyContent="center">
          <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"200px"}}></Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={otherCost3} onChange={(e)=>{
          setOtherCost3(e.target.checked)
         if(e.target.checked===false)
         {
           Set_Form_Value({...Form_Value,Other_Cost_3:parseFloat(0).toFixed(2),Other_Cost_Name3:"NA"})
           const skusl=[...New_SKU];
           let cal=0.00;
           let cal3=0.00;
             skusl.map((sku, index)=>{
             cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
             cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
             })
             cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_1))
             cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(0)+parseFloat(Form_Value.Other_Cost_1))
             SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
         }
        }}/>} label="Other Cost 3" />
         </FormGroup>
        <TextField  variant="standard" required sx={{width:"200px"}} size="small" type ="text" disabled={!otherCost3} value={Form_Value.Other_Cost_Name3} label="Enter Cost Name" onChange={(e)=>{Set_Form_Value({...Form_Value,Other_Cost_Name3:e.target.value})}}></TextField>
                    <TextField inputProps={{min:"0",step:"any"}} variant="standard" required  sx={{width:"200px"}} size="small"  disabled={!otherCost3} InputProps={{startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),}}  value={Form_Value.Other_Cost_3.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} label="Amount" onChange={(e)=>handleOtherCost3(e)}></TextField>
      
            </Stack>
            
     <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"260px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <TextField size="small" variant="standard" required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"300px"}} label="Total Cost Before Margin" value={TotalCost.Actual_Total_Cost_Before_Margin==="NaN"?parseFloat(0.00):TotalCost.Actual_Total_Cost_Before_Margin.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} ></TextField>
        <TextField size="small" variant="standard" required={draftSaveButtonRequiredFieldDisable?false:true}  sx={{width:"300px"}} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Total Cost After Margin" value={TotalCost.Actual_Total_Cost_After_Margin==="NaN"?parseFloat(0.00):Number(TotalCost.Actual_Total_Cost_After_Margin).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ></TextField>
        </Stack>
          
      </Stack>
        <Stack direction="row" spacing={2} justifyContent="center">
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"220px"}}></Stack><Stack sx={{width:"220px"}}></Stack>
        <FormGroup>
        
      <FormControlLabel size="small" control={<Checkbox size="small" checked={draftSaveButtonRequiredFieldDisable} onChange={(e)=>{
        setDraftSaveButtonRequiredFieldDisable(e.target.checked)
      
      }}/>} label="Save as a Draft & Submit Later?" />
       </FormGroup>
        <Button size='small'  variant="contained" onClick={(e)=>handleSubmitOrSaveDecision(e)} sx={{bgcolor:draftSaveButtonRequiredFieldDisable?[orange[900]]:[indigo[500]],width:"200px",height:"35px"}} disabled={dis}> {draftSaveButtonRequiredFieldDisable?"Save as Draft":"Create Quote"}</Button>
        {draftSaveButtonRequiredFieldDisable!==true &&(<Button size='small' variant="contained" onClick={(e)=>previewQuote(e)} sx={{bgcolor:[orange[800]],width:"200px",height:"35px",":hover":{boxShadow:"10px 10px 20px #ccc",bgcolor:orange[500]}}} disabled={preview}>Pre-view Quote </Button>)}
        <Stack sx={{width:"200px"}}>
          { 
         clicked===true && draftSaveButtonRequiredFieldDisable===false &&
            (<PDFDownloadLink component="button" color="secondary"
            document={<PdfDocument invoicedata={InvoiceData}/>}
            fileName={fileName}>
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : "Download Your Quote"
            }
          </PDFDownloadLink>)
        }
    {preview===true && clicked===false &&
            (<PDFDownloadLink component="button" color="secondary"
            document={<PdfDocument invoicedata={InvoiceData}/>}
            fileName={fileName}>
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : "Preview Your Quote"
            }
          </PDFDownloadLink>)
        }
        </Stack>
        </Stack>
      </Stack>
   
        </Stack>
</>
)
}
export default RFQ_CLIENT_INPUT