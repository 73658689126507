import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Autocomplete,
  MenuItem,
  IconButton,
  Avatar,
  Tooltip,
  Alert, FormControl
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { orange, blueGrey, green, red } from '@mui/material/colors';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AxiosFunction from '../../axiosCustomInstance'; // Replace with actual path
import LoadingDialog from '../Loading';
import UserAuth from '../ProtectedRoute/userAuth';

const GeneralEmailDialog = ({ row,
  DialogBoxTitle,
  DialogBoxTitleID,  
  usersData, 
  onSuccess,
  onError,
  toEmail,loading,setLoading,
  isKOorCustomerGeneralDialogOpen, setIsKOorCustomerGeneralDialogOpen,
  defaultSubject}) => {
  const defaultContent = `Hope you're doing great!`;
  const [attachments, setAttachments] = useState([]);
  const [attachFilesChecked, setAttachFilesChecked] = useState(false);
  const [attachSKUDetailsInTheBody, setAttachSKUDetailsInTheBody] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorAndSuccessDialog, setErrorAndSuccessDialog] = useState(false);
  const [errorDialogMsge, setErrorDialogMsge] = useState('');
  const [successDialogMsge, setSuccessDialogMsge] = useState('');
  const [sendQuotesToEmail, setSendQuotesToEmail] = useState(''); // State to store email address to send quotes
  const {UserName,UserEmail}=UserAuth()
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const [firstName, lastName] = UserName.split(' ').map(capitalize);
   // Default signature content
 const defaultSignature = `
      <p><strong>Thank You</strong><br/>
      <strong>${firstName} ${lastName}</strong><br/>
    Aspetto Quotes & Sales Team<br/>
      <strong>A:</strong> 1691 Emancipation Hwy. Fredericksburg, VA 22401<br/>
      <strong>E:</strong> ${UserEmail}<br/>
      <strong>P:</strong> 540-547-8487<p/>

   <p><strong>DISCLAIMER:</strong> Please do not reply to this email. This is an automatically triggered email and nobody checks its inbox.</p><br/>
        
   <p><strong>CONFIDENTIALITY NOTICE:</strong> This e-mail and/or any files transmitted are proprietary and intended solely for the use of the individual or entity to whom they are addressed. 
      Dissemination of this email to anyone else is strictly prohibited.If you have reason to believe that you have received this e-mail in error,contact us immediately by returning it to the sender and destroy this email as well as any attached files. 
      Please note that any views or opinions presented in this e-mail are solely those of the author and do not necessarily represent those of Aspetto, Inc. or any of its subsidiaries or affiliates. 
      The recipient should check this e-mail and any attachments for the presence of viruses.
      Aspetto, Inc. and its subsidiaries accept no liability for any damage caused by any virus transmitted by this e-mail.</p>`;
   

  const AxiosAPIInstance=AxiosFunction()
  const [emailData, setEmailData] = useState({
    Email_REQ_ID: '',
    RFQRFQID: row?.RFQRFQID,
    Quote_No:'',
    From: 'platform_admin@aspettoinc.com',
    To_Email: toEmail,
    To_Name: '',
    CC: 'quotesandsales@aspettoinc.com',
    Subject: defaultSubject,
    Body: defaultContent,
    Email_SentBy_Name: firstName+' '+lastName,
    Email_SentBy_Email: UserEmail,
    Attachments: attachFilesChecked,
    AttachSKUDataInTheEmailBody: attachSKUDetailsInTheBody,
    AttachmentNames: '',
    Signature:defaultSignature ,
    Latest_Email_Date: '',
    SKU_DATA: []
  });

  // Handler to close various dialogs and reset states
  const handleClose = async (e) => {
    setErrorAndSuccessDialog(false);
    setErrorDialogMsge('');
  };

  const handleAttachmentChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files);
      setAttachments((prevAttachments) => (prevAttachments ? [...prevAttachments, ...newFiles] : [...newFiles]));

      const newFileNames = newFiles.map((file) => file.name).join(' ');
      setEmailData((prevEmailData) => ({
        ...prevEmailData,
        AttachmentNames: prevEmailData.AttachmentNames ? `${prevEmailData.AttachmentNames} ${newFileNames}` : newFileNames
      }));
    }
  };



  const removeAttachment = (indexToRemove) => {
    const updatedAttachments = attachments.filter((_, index) => index !== indexToRemove);
    setAttachments(updatedAttachments);
  };

  const handleSendCustomerOrKOEmail = async (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    const formData = new FormData();

    if (attachments.length > 0) {
      attachments.forEach((attachment) => {
        formData.append('attachments', attachment, attachment.name);
      });
    }

    if (Object.keys(emailData).length > 0) {
      Object.entries(emailData).forEach(([key, value]) => {
        const data = typeof value === 'object' ? JSON.stringify(value) : value;
        formData.append(key, data);
      });
    }

    try {
      const response = await AxiosAPIInstance.post('RFQsSearch/SendCustomerKOEmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setSuccessDialogMsge('Email sent successfully: ' + response.data.message);
      setIsKOorCustomerGeneralDialogOpen(false);
      setErrorStatus(false);
      setAttachFilesChecked(false);
      setAttachSKUDetailsInTheBody(false);
      setAttachments([]);
      setErrorAndSuccessDialog(true);
      setSuccessDialogMsge('Email Sent Successfully')
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = error.response ? JSON.stringify(error.response.data) : 'Network error';
      setErrorDialogMsge('Cannot submit the request: ' + errorMessage);
      setErrorStatus(true);
      setErrorAndSuccessDialog(true);

      if (onError) {
        onError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <LoadingDialog open={loading}/>
    {errorAndSuccessDialog && (
        <Dialog open={errorAndSuccessDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: '400px' }}>
            {!errorStatus ? <Alert variant="filled" severity="success">Success</Alert> : <Alert variant="filled" severity="error">Error</Alert>}
          </DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            <DialogContentText id="alert-dialog-description">
              {!errorStatus ? successDialogMsge : errorDialogMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => handleClose(e)}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
    <Dialog
     key={row}
     maxWidth="lg"
     fullWidth
     open={isKOorCustomerGeneralDialogOpen}
     onClose={() => setIsKOorCustomerGeneralDialogOpen(false)} // Corrected this line
     sx={{ justifyContent: 'stretch' }}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
>
      <DialogTitle id="alert-dialog-title">
        <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontFamily: 'arial' }}>
        {DialogBoxTitle}:<strong>{DialogBoxTitleID}</strong>
  
        </Alert>
      </DialogTitle>
      <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
        <DialogContentText id="alert-dialog-description">
          <Stack sx={{ textAlign: 'center', alignItems: 'center', width: '100%' }}>
            <Stack direction="column" spacing={2} sx={{ width: '100%', marginTop: '20px' }}>
              <TextField
                autoFocus
                margin="dense"
                label="From"
                type="email"
                name="From"
                sx={{ marginTop: '20px', width: '100%' }}
                value={emailData.From}
                disabled
                onChange={(e) => setEmailData({ ...emailData, From: e.target.value })}
              />
              <TextField
                margin="dense"
                label="To"
                type="email"
                name="To_Email"
                sx={{ width: '100%' }}
                value={emailData.To_Email}
                onChange={(e) => setEmailData({ ...emailData, To_Email: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Cc"
                type="text"
                name="CC"
                multiline
                rows={3}
                sx={{ width: '100%' }}
                disabled
                value={emailData.CC || 'quotesandsales@aspettoinc.com'}
                onChange={(e) => setEmailData({ ...emailData, CC: e.target.value })}
              />
              <FormControl sx={{ width: '100%' }}>
                <Autocomplete
                  freeSolo
                  options={usersData?.map((user) => user.User_email)} // Map to user emails
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Add Other Emails to CC"
                      helperText="Start entering user name or email"
                    />
                  )}
                  onInputChange={(e, value) => {
                    setSendQuotesToEmail(value);
                  }}
                  renderOption={(props, option) => {
                    const emailIncluded = emailData.CC && emailData.CC.includes(option);
                    return (
                      <MenuItem {...props} key={option}>
                        {option}
                        {emailIncluded ? (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              const updatedCC = emailData.CC.split(';')
                                .filter((email) => email !== option)
                                .join(';')
                                .replace(/^;|;$/g, '');
                              setEmailData({ ...emailData, CC: updatedCC });
                              setSendQuotesToEmail('');
                            }}
                          >
                            <Avatar variant='rounded' sx={{ bgcolor: red[800], marginLeft: "10px", width: "20px", height: "20px" }}>
                              <Tooltip title='Remove'>
                                <CloseIcon sx={{ color: 'whitesmoke' }} />
                              </Tooltip>
                            </Avatar>
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              const updatedCC = emailData.CC
                                ? `${emailData.CC};${option}`
                                : `quotesandsales@aspettoinc.com;${option}`;
                              setEmailData({ ...emailData, CC: updatedCC });
                              setSendQuotesToEmail('');
                            }}
                          >
                            <Avatar variant='rounded' sx={{ bgcolor: green[800], marginLeft: "10px", width: "20px", height: "20px" }}>
                              <Tooltip title='Add'>
                                <AddIcon sx={{ color: 'whitesmoke' }} />
                              </Tooltip>
                            </Avatar>
                          </IconButton>
                        )}
                      </MenuItem>
                    );
                  }}
                />
              </FormControl>


            <TextField
              margin="dense"
              label="Subject"
              name="Subject"
              sx={{ width: '100%' }}
              value={emailData.Subject}
              onChange={(e) => setEmailData({ ...emailData, Subject: e?.target.value })}
            ></TextField>
              <ReactQuill
                theme="snow"
                value={emailData.Body}
                onChange={(value) => setEmailData({ ...emailData, Body: value })}
                placeholder="Body"
                style={{ marginTop: '10px' }}
              />
              <FormGroup>
                <FormControlLabel
                sx={{
                    color: orange[900],
                    '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                          },
                   }}
                  size="small"
                  control={
                    <Checkbox
                      size="small"
                      checked={attachFilesChecked}
                      onChange={(e) => {
                        setAttachFilesChecked(e.target.checked);
                        setEmailData({ ...emailData, Attachments: e.target.checked });
                      }}
                    />
                  }
                  label="Attach Files"
                />
              </FormGroup>
              {attachFilesChecked && (
                <>
                  <input
                    accept="image/*, .pdf, .doc, .docx"
                    style={{ display: 'none' }}
                    id="attachment-input"
                    multiple
                    type="file"
                    onChange={handleAttachmentChange}
                  />
                  <label
                    htmlFor="attachment-input"
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}
                  >
                    <AttachFileIcon />
                    Attach Files
                  </label>
                  <ul>
                    {attachments?.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton onClick={() => removeAttachment(index)} style={{ marginLeft: '10px' }}>
                          <CloseIcon />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
             
              <ReactQuill
                theme="snow"
                value={emailData.Signature}
                onChange={(value) => setEmailData({ ...emailData, Signature: value })}
                placeholder="Signature"
                style={{ marginTop: '10px' }}
              />
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          size="small"
          variant="contained"
          sx={{
            bgcolor: blueGrey[900],
            color: 'whitesmoke',
            ':hover': { boxShadow: '10px 10px 20px #ccc' },
          }}
          onClick={handleSendCustomerOrKOEmail}
        >
          Send
        </Button>
        <Button
          name="Cancel"
          variant="outlined"
          size="small"
          color="warning"
          sx={{ ':hover': { boxShadow: '10px 10px 20px #ccc' } }}
          onClick={(e) => {
            setIsKOorCustomerGeneralDialogOpen(false)
            setEmailData({ ...emailData, Attachments: false, AttachSKUDataInTheEmailBody: false });
            setAttachments([]);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default GeneralEmailDialog;
