import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider, Link} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import { green, blueGrey,red,blue, grey,cyan } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import { useNavigate,useSearchParams } from "react-router-dom";
import AxiosFunction from "../../axiosCustomInstance";
import { orange,indigo } from '@mui/material/colors';
import Login_Bck from "../Images/Login.png"
import Login_Im from "../Images/Aspetto_Login.png";
import cbpseal from "../Images/cbpseal.png"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ScaleIcon from '@mui/icons-material/Scale';
import HeightIcon from '@mui/icons-material/Height';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import jwtDecode  from 'jwt-decode';
import { AxiosGlobalAPIInstance } from "../../axiosGlobalInstance";
import ExcelUploader from "./ExcelUploaderExternal";
import { bgcolor, color, styled, ThemeProvider } from '@mui/system';
import { Twitter, Facebook, Instagram,LinkedIn, Fullscreen  } from '@mui/icons-material';
import Logo from "../Images/logo.png"
import ExpiryTimeTicker from './Timeticker'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DHSCustomerQuoteGenerator from './DHSCustomerQuoteGeneratorExternal'
import LoadingDialog from '../Loading'

const theme = createTheme({
  palette: {
      primary: {
          main: blueGrey[900],
      },
      secondary: {
          main: blueGrey[300],
      },
  },
});

// Define styles using the styled utility
const Footer = styled('footer')(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: '12px', // Adjust the font size as needed
  color: 'gray',   // Adjust the color as needed
  borderTop: '0px solid #ccc', // Add a border at the top for separation
}));
const SocialIcons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function  DHSCUstomerInputPortal()
{
// Get current date
const PD = new Date();

// Create Axios instance using custom Axios function
const AxiosAPIInstance = AxiosFunction();

// Extract token from search parameters
const [searchParams] = useSearchParams();
const token = String(searchParams.get('Temp')).trim();

// Decode JWT token to extract user information
const decoded = jwtDecode(token);
const { UserName, UserEmail } = decoded?.UserInfo;

// State variables to track user actions
const [newOrderClick, setNewOrderClick] = useState(false);
const [uploadExcelClick, setUploadExcelClick] = useState(false);
const [generateQuoteClick, setGenerateQuoteClick] = useState(false);

// State variable to hold form values
const [Form_Value, Set_Form_Value] = useState({
    REQ_ID: "",
    Customer_Name: UserName,
    Customer_Email: UserEmail,
    User_ID: "",
    Location: "",
    Contract_Number: "70B06C23D00000002",
    Delivery_Order_Number: "",
    Refresh_Kit: false,
    CLIN: "",
    Order_Number: "",
    Armor_Gender: "",
    Order_Type: "",
    Height_ft_in: "",
    Weight_lbs: "",
    Chest_in: "",
    Waist_in: "",
    Front_Stand_in: "",
    Front_Sit_in: "",
    Back_in: "",
    Bra_Size: "",
    Cup_Size: "",
    Tip_Apex_to_Tip_Apex_in: "",
    Tip_Apex_to_Belt_in: "",
    Tip_Apex_to_Clavicle_Shoulder_in: "",
    Tip_Apex_to_Side_in: "",
    Side_Panel_Size: "",
    Cumberbund_Size: "",
    Front_Panel_Size: "",
    Back_Panel_Size: "",
    Base_Carrier_Size: "",
    Color: "",
    Placard: "",
    Created_Date: `${Number(PD.getUTCMonth()) + 1}-${PD.getUTCDate()}-${PD.getUTCFullYear()}`,
    Last_Updated_Date: `${Number(PD.getUTCMonth()) + 1}-${PD.getUTCDate()}-${PD.getUTCFullYear()}`,
    Created_By: "TBD",
    Version_No: parseInt(0),
    Temp_Token: token,
    Excel_Sheet_Upload: false,
    File_Name: 'NA',
    Barcode_Value: 'NA',
    Updating_Comments: "Requested"
});


// State variable to control the disabled state of components
const [dis, setDis] = useState(false);

// Hook to navigate between routes
const navigate = useNavigate();

// State variable to hold error messages
const [dHSMssge, setDHSMssge] = useState('');

// State variables to control visibility of success and error dialogs
const [successDialog, setSuccessDialog] = useState(false);
const [errorDialog, setErrorDialog] = useState(false);

// State variable to control the visibility of a dialog
const [open, setOpen] = useState(false);

// State variable to indicate loading state
const [loading, setLoading] = useState(false);

// State variable to hold status code for error handling
const [statusCode, setStatusCode] = useState(false);

// State variable to hold token
const [token1, setToken] = useState("");

// Function to handle closing dialogs
const handleClose = (e) => {
    // If error dialog is true and specific error messages are displayed, navigate to specific route
    if (errorDialog === true && (dHSMssge === "Sorry! Your temporary link got expired" || dHSMssge === 'It seems like you have requested a new link before your current link got expired.Please use your newest link.')) {
        navigate('/', { replace: true });
        console.error("Sorry! Your temporary link has expired. Please request a new link.");
    }
    // Reset state variables
    setDHSMssge("");
    setOpen(false);
    setErrorDialog(false);
    setSuccessDialog(false);
    setErrorDialog(false);
};


// useEffect hook to handle DHS page load logic
React.useEffect(() => {
  // Set loading state to true initially
  setLoading(true);

  // Function to handle DHS page load
  const DHSPageLoad = async () => {
      try {
          // Extract temporary token from search parameters
          const tempToken = String(searchParams.get("Temp")).trim();
          console.log(tempToken)

          // Check if the token is valid and not expired
          if (tempToken !== "" && tempToken !== null && tempToken !== " " && tempToken !== undefined) {
              // Set the token state
              setToken(tempToken);
              // Decode the token
              const decoded = jwtDecode(tempToken);
              
              // Check if the token is expired
              const isTokenExpired = decoded.exp < Math.floor(Date.now() / 1000);

              if (isTokenExpired) {
                  // Display error message if the token is expired
                  setLoading(false);
                  setDHSMssge("Sorry! Your temporary link got expired");
                  setErrorDialog(true);
                  setSuccessDialog(false);
                  return;
              }

              // Extract user information from the token
              const { UserName, UserEmail } = decoded?.UserInfo;

              try {
                  // Send a GET request to retrieve customer data
                  const result = await AxiosGlobalAPIInstance.get("/DHSCustomer/Customer", {
                      params: { Temptoken: tempToken, Customer_Email: UserEmail },
                  });

                  setLoading(false);
                  // Additional logic if needed after a successful request
              } catch (err) {
                  // Handle errors from the GET request
                  setLoading(false);
                  setDHSMssge(err?.response?.data);
                  setErrorDialog(true);
                  setSuccessDialog(false);
              }
          } else {
              // Display error message if the token is invalid or missing
              setLoading(false);
              setDHSMssge("Unauthorized");
              setErrorDialog(true);
              setSuccessDialog(false);
          }
      } catch {
          // Handle unexpected errors
          setLoading(false);
          setDHSMssge("Unauthorized");
          setErrorDialog(true);
          setSuccessDialog(false);
      }
  };

  // Call the DHSPageLoad function
  DHSPageLoad();
}, [searchParams]);



// Function to handle form submission
const HandleSubmit = async (event) => {
  // Set loading state to true and disable form
  setLoading(true);
  setDis(true);
  event.preventDefault();

  try {
      // Send a POST request to create a new order
      await AxiosGlobalAPIInstance.post("/DHSCustomer/CreateNewOrder", Form_Value).then(
          async (response) => {
              // If successful response, update state variables and reset form values
              setLoading(false);
              setSuccessDialog(true);
              setErrorDialog(false);
              setDHSMssge(response?.data);
              setDis(false);
              // Reset form values
              Set_Form_Value({
                  User_ID: "",
                  REQ_ID: "",
                  Location: "",
                  Contract_Number: "70B06C23D00000002",
                  Delivery_Order_Number: "",
                  Refresh_Kit: false,
                  CLIN: "",
                  Order_Number: "",
                  Armor_Gender: "",
                  Order_Type: "",
                  Height_ft_in: "",
                  Weight_lbs: "",
                  Chest_in: "",
                  Waist_in: "",
                  Front_Stand_in: "",
                  Front_Sit_in: "",
                  Back_in: "",
                  Bra_Size: "",
                  Cup_Size: "",
                  Tip_Apex_to_Tip_Apex_in: "",
                  Tip_Apex_to_Belt_in: "",
                  Tip_Apex_to_Clavicle_Shoulder_in: "",
                  Tip_Apex_to_Side_in: "",
                  Color: "",
                  Placard: "",
                  Created_Date: `${Number(PD.getUTCMonth()) + 1}-${PD.getUTCDate()}-${PD.getUTCFullYear()}`,
                  Last_Updated_Date: `${Number(PD.getUTCMonth()) + 1}-${PD.getUTCDate()}-${PD.getUTCFullYear()}`,
                  Created_By: "TBD",
                  Version_No: parseInt(0),
                  File_Name: 'NA',
                  Barcode_Value: "NA",
                  Updating_Comments: "Requested"
              });
          }
      ).catch((error) => {
          // If error response, handle the error and update state variables
          setLoading(false);
          if (error?.response.status === 403) {
              setStatusCode(true);
          }
          setLoading(false);
          setSuccessDialog(false);
          setErrorDialog(true);
          setDHSMssge(error.response?.data);
          setDis(false);
      });
  } catch (error) {
      // Handle unexpected errors
      setLoading(false);
      setSuccessDialog(false);
      setErrorDialog(true);
      setDHSMssge("Cannot submit the request");
      setDis(false);
  }
};

return(
  <>
<LoadingDialog open={loading} />
 <ThemeProvider theme={theme}>
 <Footer>
    {open===true&&(<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to exit this page?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the changes will be lost if you decide to exit this page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={handleClose} href="/Dashboard">Yes</Button>
          <Button name="No" onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          }
          }>OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}}>OK</Button>
        </DialogActions>
      </Dialog>)}
  <Stack component="form" onSubmit={HandleSubmit} sx={{marginLeft:"20px",padding:"0px"}}>
  <Stack direction="row" spacing={3} sx={{marginTop:"0px"}} justifyContent="center">
 <Box component="img" src={cbpseal} sx={{height:"80px",width:"80px",marginLeft:"300px"}}></Box>
 <Stack><Box component="img" src={Login_Im} sx={{height:"60px",width:"180px",marginTop:'20px'}}></Box>
 </Stack>
 <Typography variant="body2" style={{ color: 'black', textAlign: 'center', width: '650px',fontSize:"12px"}}>
  <strong>NOTE:</strong><br />
  This page is time-sensitive and will remain active for 24 hours. After this period, the link will expire, and you will need to request a new one. Once you submit this form, an email notification containing the provided information will be sent to our dedicated Aspetto's DHS-CBP Sales team at {' '}
  <a href="mailto:DHS_orders@aspettoinc.com" style={{ color: 'blue' }}><strong>DHS_orders@aspettoinc.com</strong></a>. We will start working on your order as soon as we receive the email.
</Typography>
       </Stack>
       <Stack>
       <Divider sx={{color:blue[900],bgcolor:blue[900],height:"2px", width:"inherit"}}></Divider>
 {/* <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"20px", color:'black', fontWeight:"bold"}} > DHS-CBP Time Sensitive Web Page</Typography> */}
 <br></br>
 <ExpiryTimeTicker />

 </Stack>
 <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={generateQuoteClick} onChange={(e)=>{
         setGenerateQuoteClick(e.target.checked)
         if(e.target.checked===true)
         {
          setNewOrderClick(false)
          setUploadExcelClick(false)
          Set_Form_Value({...Form_Value,Excel_Sheet_Upload:true})
         
         }
        }}/>} label="Generate Quote" />
         </FormGroup>
         <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={uploadExcelClick} onChange={(e)=>{
         setUploadExcelClick(e.target.checked)
         if(e.target.checked===true)
         {
          setNewOrderClick(false)
          setGenerateQuoteClick(false)
          Set_Form_Value({...Form_Value,Excel_Sheet_Upload:true})
         
         }
        }}/>} label="Upload File" />
         </FormGroup>
       <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={newOrderClick}    onChange={(e)=>{
         setNewOrderClick(e.target.checked)
         if(e.target.checked===true)
         {
       
          setUploadExcelClick(false)
          setGenerateQuoteClick(false)
          Set_Form_Value({...Form_Value,Excel_Sheet_Upload:false})
         }
        }}/>} label="Submit Size info Individually" />
         </FormGroup>


         {uploadExcelClick===true&&(<>
         
      <ExcelUploader/>

         </>)}
         <br></br>
         {generateQuoteClick===true&&(<>
         
         <DHSCustomerQuoteGenerator UserDetails={Form_Value.Customer_Email+"E-N"+Form_Value.Customer_Name+'T-T'+token}/>
   
            </>)}
         <br></br>

        {newOrderClick===true &&(<>  
 <Stack direction="column" spacing={1} sx={{marginTop:"0px"}}>

 <Stack direction="row" spacing={4}>
        {/* <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Expires In" value={expTime} required={true}></TextField> */}
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Contract Number" value={Form_Value.Contract_Number} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Contract_Number:e.target.value})}></TextField>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Delivery Order Number" helperText="" value={Form_Value.Delivery_Order_Number} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Delivery_Order_Number:String(e.target.value).trim().toUpperCase()})}></TextField>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="CLIN" value={Form_Value.CLIN} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,CLIN:e.target.value})}></TextField>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Order Number" helperText="" value={Form_Value.Order_Number} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Order_Number:String(e.target.value).trim().toUpperCase()})}></TextField>
        </Stack>
        <Stack>
        <FormGroup>
        <FormControlLabel size="small" control={<Checkbox size="small" checked={Form_Value.Refresh_Kit} onChange={(e)=>{
         Set_Form_Value({...Form_Value,Refresh_Kit:e.target.checked})
        }}/>} label="Refresh Kit" />
         </FormGroup>
        </Stack>
 <Stack>
 <Divider sx={{color:red[900],bgcolor:red[900],height:"2px", width:"1300px"}}></Divider>
 </Stack>
 <Stack></Stack>
 <FormControl size="small" sx={{width:"300px"}}>
        <InputLabel id="Order-Type">Order Type</InputLabel>
        <Select size="small"
          labelId="Order-Type"
          variant="standard"
          id="Order-Type-id"
          value={Form_Value.Order_Type}
          required={true}
          label="Order-Type"
          onChange={(e)=>{
            Set_Form_Value({...Form_Value,Order_Type:e.target.value})
          
          }}
          sx={{width:"300px"}}>
          <MenuItem size="small" name="SSO" value={"Scalable System Order"}>Scalable System Order</MenuItem>
          <MenuItem size="small" name="TSO" value={"Traditional System Order"}>Traditional System Order</MenuItem>
        </Select>
      </FormControl>
 {Form_Value.Order_Type==='Traditional System Order' &&(<>
  <Stack></Stack>
        <Stack direction="row" spacing={4}>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="User ID" value={Form_Value.User_ID} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,User_ID:e.target.value})}
           InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}></TextField>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Location" helperText="" value={Form_Value.Location} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Location:String(e.target.value).trim().toUpperCase()})}
         InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonPinCircleIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <FormControl size="small" sx={{width:"300px"}}>
        <InputLabel id="Armor-Gender">Armor Gender</InputLabel>
        <Select size="small"
          labelId="Armor-Gender"
          variant="standard"
          id="Armor-Gender-id"
          value={Form_Value.Armor_Gender}
          required={true}
          label="Armor Gender"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Armor_Gender:e.target.value})}}
          sx={{width:"300px"}}>
          <MenuItem size="small" name="Ma" value={"Male"}>Male</MenuItem>
          <MenuItem size="small" name="Fe" value={"Female"}>Female</MenuItem>
          <MenuItem size="small" name="Ot" value={"Others"}>Others</MenuItem>
        </Select>
      </FormControl>
      <TextField variant="standard"  size="small" type="text" sx={{width:"300px"}} label="Height(ft-in)" value={Form_Value.Height_ft_in} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Height_ft_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HeightIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={4}>
         <TextField variant="standard"  size="small" type="text" sx={{width:"300px"}} label="Weight(lbs)" value={Form_Value.Weight_lbs} required={true}   onChange={(e)=>Set_Form_Value({...Form_Value,Weight_lbs:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ScaleIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Chest(inches)" value={Form_Value.Chest_in} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Chest_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FitnessCenterIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField variant="standard" size="small" type="text" sx={{width:"300px"}} label="Waist(inches)"  value={Form_Value.Waist_in} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Waist_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField variant="standard"  size="small" sx={{width:"300px"}} type="text"  label="Front Stand(inches)" value={Form_Value.Front_Stand_in} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Front_Stand_in:e.target.value})} inputProps={{min:"0"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}></TextField>
        </Stack>
        <Stack direction="row" spacing={4} >
        <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Front Sit(inches)" value={Form_Value.Front_Sit_in} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Front_Sit_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}></TextField>
                  <TextField  variant="standard" size="small" sx={{width:"300px"}}  type="text" label="Back(inches)" required={true}  value={Form_Value.Back_in} onChange={(e)=>Set_Form_Value({...Form_Value,Back_in:e.target.value})}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    ),
                  }}></TextField>
          <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Color" value={Form_Value.Color} onChange={(e)=>Set_Form_Value({...Form_Value,Color:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PaletteIcon />
            </InputAdornment>
          ),
        }}></TextField>
              <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Placard" required={true}  value={Form_Value.Placard} onChange={(e)=>Set_Form_Value({...Form_Value,Placard:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={4}>
    </Stack>
    {Form_Value.Armor_Gender==="Female" && (<>
      <Stack>

 </Stack>
 <Stack>
 <Divider sx={{color:red[900],bgcolor:red[900],height:"2px", width:"1300px"}}></Divider>
 </Stack>
<Stack direction="row" spacing={4}>
<TextField  variant="standard" size="small" sx={{width:"300px"}} type="text" label="Bra Size" required={true}  value={Form_Value.Bra_Size} onChange={(e)=>Set_Form_Value({...Form_Value,Bra_Size:e.target.value})}
 InputProps={{
  startAdornment: (
    <InputAdornment position="start">
    </InputAdornment>
  ),
}}></TextField>
<TextField  variant="standard" size="small" sx={{width:"300px"}}  type="text" label="Cup Size" required={true}  value={Form_Value.Cup_Size} onChange={(e)=>Set_Form_Value({...Form_Value,Cup_Size:e.target.value})}
 InputProps={{
  startAdornment: (
    <InputAdornment position="start">
    </InputAdornment>
  ),
}}></TextField>
<TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Tip(Apex) to Tip(Apex) (inches)" required={true}  value={Form_Value.Tip_Apex_to_Tip_Apex_in} onChange={(e)=>Set_Form_Value({...Form_Value,Tip_Apex_to_Tip_Apex_in:e.target.value})} InputProps={{
startAdornment: (
  <InputAdornment position="start">
  </InputAdornment>
),
}}></TextField>
</Stack>
<Stack direction="row" spacing={4}>
        <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Tip(Apex) to Belt (inches)" required={true} value={Form_Value.Tip_Apex_to_Belt_in} onChange={(e)=>Set_Form_Value({...Form_Value,Tip_Apex_to_Belt_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}></TextField>
          <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Tip(Apex) to Clavicle(Shoulder)(inches)" required={true}  value={Form_Value.Tip_Apex_to_Clavicle_Shoulder_in} onChange={(e)=>Set_Form_Value({...Form_Value,Tip_Apex_to_Clavicle_Shoulder_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}></TextField>
           <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Tip(Apex) to Side(inches)" required={true} value={Form_Value.Tip_Apex_to_Side_in} onChange={(e)=>Set_Form_Value({...Form_Value,Tip_Apex_to_Side_in:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}></TextField>
        </Stack >
    </>

    )}
 </>)}
 {Form_Value.Order_Type==='Scalable System Order' &&(<>
  <Stack></Stack>
        <Stack direction="row" spacing={4}>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="User ID" value={Form_Value.User_ID} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,User_ID:e.target.value})}
           InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}></TextField>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Location" helperText="" value={Form_Value.Location} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Location:String(e.target.value).trim().toUpperCase()})}
         InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonPinCircleIcon />
            </InputAdornment>
          ),
        }}></TextField>
                <TextField variant="standard"  size="small" type="text" sx={{width:"300px"}} label="Base Carrier Size" value={Form_Value.Base_Carrier_Size} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Base_Carrier_Size:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HeightIcon />
            </InputAdornment>
          ),
        }}></TextField>
                 <TextField variant="standard"  size="small" type="text" sx={{width:"300px"}} label="Front Panel Size(inches)" value={Form_Value.Front_Panel_Size} required={true}   onChange={(e)=>Set_Form_Value({...Form_Value,Front_Panel_Size:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
               <HeightIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        </Stack>
        <Stack direction="row" spacing={4}>
        <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Back Panel Size(inches)" value={Form_Value.Back_Panel_Size} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Back_Panel_Size:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <HeightIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField variant="standard" size="small" type="text" sx={{width:"300px"}} label="Cumberbund Size"  value={Form_Value.Cumberbund_Size} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Cumberbund_Size:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
             <HeightIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField variant="standard"  size="small" sx={{width:"300px"}} type="text"  label="Side Panel Size" value={Form_Value.Side_Panel_Size} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Side_Panel_Size:e.target.value})} inputProps={{min:"0"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
          }}></TextField>
          <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Color" value={Form_Value.Color} onChange={(e)=>Set_Form_Value({...Form_Value,Color:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PaletteIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={4} >
              <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Placard" required={true}  value={Form_Value.Placard} onChange={(e)=>Set_Form_Value({...Form_Value,Placard:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={4}>

    </Stack>
 </>)}
 <Stack direction="row" spacing={4}>
          </Stack>
          <Stack direction="row" spacing={4}>
          
          </Stack>
          <Stack direction="row" spacing={4}>
          
          </Stack>
        <Stack direction="row" spacing={4}>
        <Button  sx={{width:"300px",height:"30px",bgcolor:[indigo[500]]}}  variant="contained" type="submit" disabled={dis}>Submit</Button>
        <Stack></Stack>
          </Stack>
          <Stack direction="row" spacing={4}>
          
          </Stack>
        </Stack> 
        </>)}
      
        <Stack direction="row" spacing={4}>
          <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"20px", color:'black', fontWeight:"bold"}} >Customer Details:</Typography>
          </Stack>
<br></br>
        <Stack direction="row" spacing={4}>
 <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Customer Name" required={true}  value={Form_Value.Customer_Name}  onChange={(e)=>Set_Form_Value({...Form_Value,Placard:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),
          readOnly:true
        }}></TextField>
              <TextField variant="standard" size="small" sx={{width:"300px"}} type="text" label="Customer Email" required={true}  value={Form_Value.Customer_Email} onChange={(e)=>Set_Form_Value({...Form_Value,Placard:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
            </InputAdornment>
          ),  readOnly:true
        }}></TextField>
     
           </Stack>
</Stack>
<br></br>
<Stack direction="row" spacing={1} sx={{bgcolor:blueGrey[900],color:"white",padding:1,fontSize:"12px",height:"100px",}}>
  <Stack direction="column" spacing={0.1} sx={{ marginLeft: '20px', alignItems: 'flex-start' }}>
<IconButton size='large'  color='inherit' sx={{}} >
     <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
    </IconButton>
<p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
<p>Inc. 5000 America's Fastest Growing Private Companies</p>
<p>ISO certified ISO 9001:2015</p>
{/* <p>1691 Emancipation Hwy.</p>
<p>Fredericksburg, VA 22401 USA</p> */}
</Stack>
<Stack direction="column" spacing={0.1} sx={{ marginLeft: '20px', alignItems: 'flex-start' }} >
<p></p>
<p></p>
<p></p>
<p>Let's Talk:</p>
<p>Phone: 540-547-8487</p>
<p>Fax: 540-300-2669</p>
<p>E: DHS_orders@aspettoinc.com</p>
<Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
</Stack>
<Stack sx={{width:"830px"}}></Stack>
<Stack direction="column" spacing={0.1}>
<p>SOCIAL MEDIA</p>
          <Stack direction="row" spacing={.5} justifyContent="center" >
          <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
        < Twitter color="white" sx={{ backgroundColor: "white",color:cyan[400] }} />
      </IconButton>
      <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
        <Facebook color="white" sx={{ backgroundColor: "white",color:blue[800] }} />
      </IconButton>
      <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
        <Instagram color="white" sx={{ backgroundColor: "white" ,color:red[800] }} />
      </IconButton>
      <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
        <LinkedIn sx={{ backgroundColor: "white",color:blueGrey[600]  }} />
      </IconButton>
    </Stack>
</Stack>
          {/* <p>Privacy Policy | Terms of Service</p>
          <p>Last Updated: {new Date().toLocaleDateString()}</p> */}
</Stack>

</Footer>
</ThemeProvider>
</>
)


}
export default DHSCUstomerInputPortal