import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Autocomplete,
  MenuItem,
  IconButton,
  Avatar,
  Tooltip,
  Alert,
  FormControl
} from '@mui/material'; // Importing Material-UI components for UI rendering
import AttachFileIcon from '@mui/icons-material/AttachFile'; // Attach file icon
import CloseIcon from '@mui/icons-material/Close'; // Close icon
import AddIcon from '@mui/icons-material/Add'; // Add icon
import { orange, blueGrey, green, red } from '@mui/material/colors'; // Color imports
import ReactQuill from 'react-quill'; // Importing ReactQuill for rich text editor
import 'react-quill/dist/quill.snow.css'; // Quill editor styles
import AxiosFunction from '../../axiosCustomInstance'; // Custom Axios instance import
import LoadingDialog from '../Loading'; // Custom loading dialog component

// EmailDialog component definition with props destructuring
const EmailDialog = ({ 
  row, // Current data row
  RFQID, // Request For Quote ID
  userName, // Current user's name
  userEmail, // Current user's email
  usersData, // List of all users data
  onSuccess, // Callback function on success
  onError, // Callback function on error
  toEmail, // Email to send the quotes to
  skuData, // SKU (Stock Keeping Unit) details
  loading, // Loading state
  setLoading, // Function to set loading state
  isKOorCustomerDialogOpen, // Flag to open/close the dialog
  setIsKOorCustomerDialogOpen, // Function to set dialog open/close state
  defaultSubject, // Default subject line for the email
  Quote_No // Quote number
}) => {

  // Default content for the email body
  const defaultContent = `Hope you're doing great!
  Just wanted to drop a quick note to let you know that we've got those quotes you asked for. Check 'em out in the attachment!
  We've made sure they're exactly what you need based on our recent chats. Any questions or tweaks you want to make, just hit me up. Always here to help!
  Thanks for considering Aspetto. Can't wait to work with you!
  Cheers!`;

  // State for managing attachments in the email
  const [attachments, setAttachments] = useState([]);
  // State for managing checkbox to attach files
  const [attachFilesChecked, setAttachFilesChecked] = useState(false);
  // State for managing checkbox to attach SKU details in the email body
  const [attachSKUDetailsInTheBody, setAttachSKUDetailsInTheBody] = useState(false);
  // State to store the total cost of all SKUs
  const [allSKUsCost, setAllSKUsCost] = useState('');
  // Error and success handling states
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorAndSuccessDialog, setErrorAndSuccessDialog] = useState(false);
  const [errorDialogMsge, setErrorDialogMsge] = useState('');
  const [successDialogMsge, setSuccessDialogMsge] = useState('');
  // State for storing the email address entered by the user
  const [sendQuotesToEmail, setSendQuotesToEmail] = useState('');

  // Helper function to capitalize the first letter of the user's name
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // Split the userName into first and last names
  const [firstName, lastName] = userName.split(' ').map(capitalize);

  // Default email signature content
  const defaultSignature = `
    <p><strong>Thank You</strong><br/>
    <strong>${firstName} ${lastName}</strong><br/>
    Aspetto Quotes & Sales Team<br/>
    <strong>A:</strong> 1691 Emancipation Hwy. Fredericksburg, VA 22401<br/>
    <strong>E:</strong> ${userEmail}<br/>
    <strong>P:</strong> 540-547-8487<p/>
    <p><strong>DISCLAIMER:</strong> Please do not reply to this email. This is an automatically triggered email and nobody checks its inbox.</p><br/>
    <p><strong>CONFIDENTIALITY NOTICE:</strong> This e-mail and/or any files transmitted are proprietary and intended solely for the use of the individual or entity to whom they are addressed. 
    Dissemination of this email to anyone else is strictly prohibited. If you have reason to believe that you have received this e-mail in error, contact us immediately by returning it to the sender and destroy this email as well as any attached files. 
    Please note that any views or opinions presented in this e-mail are solely those of the author and do not necessarily represent those of Aspetto, Inc. or any of its subsidiaries or affiliates. 
    The recipient should check this e-mail and any attachments for the presence of viruses. Aspetto, Inc. and its subsidiaries accept no liability for any damage caused by any virus transmitted by this e-mail.</p>`;

  // Axios instance to make API requests
  const AxiosAPIInstance = AxiosFunction();

  // Email data state, initialized with default values
  const [emailData, setEmailData] = useState({
    Email_REQ_ID: '',
    RFQRFQID: RFQID,
    Quote_No: Quote_No,
    From: 'platform_admin@aspettoinc.com',
    To_Email: toEmail,
    To_Name: '',
    CC: 'quotesandsales@aspettoinc.com',
    Subject: defaultSubject,
    Body: defaultContent,
    Email_SentBy_Name: firstName + ' ' + lastName,
    Email_SentBy_Email: userEmail,
    Attachments: attachFilesChecked,
    AttachSKUDataInTheEmailBody: attachSKUDetailsInTheBody,
    AttachmentNames: '',
    Signature: defaultSignature,
    Latest_Email_Date: '',
    SKU_DATA: skuData
  });

  // Function to handle closing the error/success dialogs
  const handleClose = async (e) => {
    setErrorAndSuccessDialog(false);
    setErrorDialogMsge('');
  };

  // Function to handle attachment file selection
  const handleAttachmentChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files);
      setAttachments((prevAttachments) => (prevAttachments ? [...prevAttachments, ...newFiles] : [...newFiles]));
      
      // Join new filenames into a string
      const newFileNames = newFiles.map((file) => file.name).join(' ');
      setEmailData((prevEmailData) => ({
        ...prevEmailData,
        AttachmentNames: prevEmailData.AttachmentNames ? `${prevEmailData.AttachmentNames} ${newFileNames}` : newFileNames
      }));
    }
  };

  // Function to handle changes to SKU details checkbox
  const handleAttachSKUDetailsCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setAttachSKUDetailsInTheBody(isChecked);
    setEmailData((prevData) => ({
      ...prevData,
      AttachSKUDataInTheEmailBody: isChecked
    }));
  };

  // Function to calculate and attach SKU details in the email body
  const handleAttachSKUDetailsInTheEMailBody = async (e) => {
    if (e.target.checked === true) {
      if (Array.isArray(emailData.SKU_DATA)) {
        // Calculate total SKU cost
        const totalSKU = emailData.SKU_DATA.reduce((total, current) => total + Number(current.Total_SKU_Cost), 0);
        setAllSKUsCost(totalSKU.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      } else {
        console.error('SKU_DATA is not an array:');
        setErrorAndSuccessDialog('true');
        setErrorDialogMsge('SKU_DATA is not an array:');
        setAllSKUsCost('0.00');
      }
    }
  };

  // Function to remove an attachment from the list
  const removeAttachment = (indexToRemove) => {
    const updatedAttachments = attachments.filter((_, index) => index !== indexToRemove);
    setAttachments(updatedAttachments);
  };

  // Function to handle sending the email
  const handleSendCustomerOrKOEmail = async (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    const formData = new FormData();

    // Append attachments to form data
    if (attachments.length > 0) {
      attachments.forEach((attachment) => {
        formData.append('attachments', attachment, attachment.name);
      });
    }

    // Append email data to form data
    if (Object.keys(emailData).length > 0) {
      Object.entries(emailData).forEach(([key, value]) => {
        const data = typeof value === 'object' ? JSON.stringify(value) : value;
        formData.append(key, data);
      });
    }

    try {
      // API request to send the email
      const response = await AxiosAPIInstance.post('RFQsSearch/SendCustomerKOEmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Show success dialog
      setSuccessDialogMsge('Email sent successfully: ' + response.data.message);
      setIsKOorCustomerDialogOpen(false);
      setErrorStatus(false);
      setAttachFilesChecked(false);
      setAttachSKUDetailsInTheBody(false);
      setAttachments([]);
      setErrorAndSuccessDialog(true);
      setSuccessDialogMsge('Email Sent Successfully');
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      // Handle and show error dialog
      const errorMessage = error.response ? JSON.stringify(error.response.data) : 'Network error';
      setErrorDialogMsge('Cannot submit the request: ' + errorMessage);
      setErrorStatus(true);
      setErrorAndSuccessDialog(true);

      if (onError) {
        onError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Loading dialog while email is being sent */}
      <LoadingDialog open={loading} />
      
      {/* Error/Success dialog */}
      {errorAndSuccessDialog && (
        <Dialog open={errorAndSuccessDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: '400px' }}>
            {/* Show success or error message */}
            {!errorStatus ? <Alert variant="filled" severity="success">Success</Alert> : <Alert variant="filled" severity="error">Error</Alert>}
          </DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            <DialogContentText id="alert-dialog-description">
              {!errorStatus ? successDialogMsge : errorDialogMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => handleClose(e)}>OK</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Main email dialog */}
      <Dialog
        key={row}
        maxWidth="lg"
        fullWidth
        open={isKOorCustomerDialogOpen}
        onClose={() => setIsKOorCustomerDialogOpen(false)} // Function to close the dialog
        sx={{ justifyContent: 'stretch' }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* Dialog title displaying the quote number */}
        <DialogTitle id="alert-dialog-title">
          <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontFamily: 'arial' }}>
            Quote Number: <strong>{Quote_No}</strong>
          </Alert>
        </DialogTitle>

        {/* Dialog content */}
        <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
          <DialogContentText id="alert-dialog-description">
            <Stack sx={{ textAlign: 'center', alignItems: 'center', width: '100%' }}>
              <Stack direction="column" spacing={2} sx={{ width: '100%', marginTop: '20px' }}>
                {/* From email address field */}
                <TextField
                  autoFocus
                  margin="dense"
                  label="From"
                  type="email"
                  name="From"
                  sx={{ marginTop: '20px', width: '100%' }}
                  value={emailData.From}
                  disabled
                  onChange={(e) => setEmailData({ ...emailData, From: e.target.value })}
                />

                {/* To email address field */}
                <TextField
                  margin="dense"
                  label="To"
                  type="email"
                  name="To_Email"
                  sx={{ width: '100%' }}
                  value={emailData.To_Email}
                  onChange={(e) => setEmailData({ ...emailData, To_Email: e.target.value })}
                />

                {/* CC email field */}
                <TextField
                  margin="dense"
                  label="Cc"
                  type="text"
                  name="CC"
                  multiline
                  rows={3}
                  sx={{ width: '100%' }}
                  disabled
                  value={emailData.CC || 'quotesandsales@aspettoinc.com'}
                  onChange={(e) => setEmailData({ ...emailData, CC: e.target.value })}
                />

                {/* Autocomplete for adding CC emails */}
                <FormControl sx={{ width: '100%' }}>
                  <Autocomplete
                    freeSolo
                    options={usersData?.map((user) => user.User_email)} // Populate with user emails
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Add Other Emails to CC"
                        helperText="Start entering user name or email"
                      />
                    )}
                    onInputChange={(e, value) => {
                      setSendQuotesToEmail(value);
                    }}
                    renderOption={(props, option) => {
                      const emailIncluded = emailData.CC && emailData.CC.includes(option);
                      return (
                        <MenuItem {...props} key={option}>
                          {option}
                          {emailIncluded ? (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                const updatedCC = emailData.CC.split(';')
                                  .filter((email) => email !== option)
                                  .join(';')
                                  .replace(/^;|;$/g, '');
                                setEmailData({ ...emailData, CC: updatedCC });
                                setSendQuotesToEmail('');
                              }}
                            >
                              <Avatar variant='rounded' sx={{ bgcolor: red[800], marginLeft: "10px", width: "20px", height: "20px" }}>
                                <Tooltip title='Remove'>
                                  <CloseIcon sx={{ color: 'whitesmoke' }} />
                                </Tooltip>
                              </Avatar>
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                const updatedCC = emailData.CC
                                  ? `${emailData.CC};${option}`
                                  : `quotesandsales@aspettoinc.com;${option}`;
                                setEmailData({ ...emailData, CC: updatedCC });
                                setSendQuotesToEmail('');
                              }}
                            >
                              <Avatar variant='rounded' sx={{ bgcolor: green[800], marginLeft: "10px", width: "20px", height: "20px" }}>
                                <Tooltip title='Add'>
                                  <AddIcon sx={{ color: 'whitesmoke' }} />
                                </Tooltip>
                              </Avatar>
                            </IconButton>
                          )}
                        </MenuItem>
                      );
                    }}
                  />
                </FormControl>

                {/* Subject field */}
                <TextField
                  margin="dense"
                  label="Subject"
                  name="Subject"
                  sx={{ width: '100%' }}
                  value={emailData.Subject}
                  onChange={(e) => setEmailData({ ...emailData, Subject: e.target.value })}
                ></TextField>

                {/* ReactQuill editor for email body */}
                <ReactQuill
                  theme="snow"
                  value={emailData.Body}
                  onChange={(value) => setEmailData({ ...emailData, Body: value })}
                  placeholder="Body"
                  style={{ marginTop: '10px' }}
                />

                {/* Attach files checkbox */}
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    size="small"
                    control={
                      <Checkbox
                        size="small"
                        checked={attachFilesChecked}
                        onChange={(e) => {
                          setAttachFilesChecked(e.target.checked);
                          setEmailData({ ...emailData, Attachments: e.target.checked });
                        }}
                      />
                    }
                    label="Attach Files"
                  />
                </FormGroup>

                {/* File attachment input and display */}
                {attachFilesChecked && (
                  <>
                    <input
                      accept="image/*, .pdf, .doc, .docx"
                      style={{ display: 'none' }}
                      id="attachment-input"
                      multiple
                      type="file"
                      onChange={handleAttachmentChange}
                    />
                    <label
                      htmlFor="attachment-input"
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}
                    >
                      <AttachFileIcon />
                      Attach Files
                    </label>
                    <ul>
                      {attachments?.map((file, index) => (
                        <li key={index}>
                          {file.name}
                          <IconButton onClick={() => removeAttachment(index)} style={{ marginLeft: '10px' }}>
                            <CloseIcon />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {/* Attach SKU details checkbox */}
                <FormGroup>
                  <FormControlLabel
                    size="small"
                    sx={{
                      color: orange[900],
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={attachSKUDetailsInTheBody}
                        onChange={async (e) => {
                          handleAttachSKUDetailsCheckboxChange(e);
                          handleAttachSKUDetailsInTheEMailBody(e);
                        }}
                      />
                    }
                    label="Attach SKU Details in the Email Body"
                  />
                </FormGroup>

                {/* SKU details table if checkbox is selected */}
                {attachSKUDetailsInTheBody && (
                  <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          Vendor/Partner
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          SKU ID
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          SKU Desc
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          QTY
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          Unit Cost($)
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          Total Cost($)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Rendering each SKU's details */}
                      {emailData.SKU_DATA.map((P, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                            {P.Vendor_Partner_name}
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                            {P.SKU_ID}
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                            {P.SKU_Description}
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                            {P.QTY}
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                            {Number(P.Customer_Unit_Cost).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                            {Number(P.Total_SKU_Cost).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      ))}

                      {/* Row to display subtotal of all SKUs */}
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            border: 1,
                            borderColor: 'text.primary',
                            color: 'black',
                            backgroundColor: orange[200],
                            fontFamily: 'Verdana (sans-serif)',
                            fontSize: '11px',
                            fontWeight: 'bold',
                          }}
                          align="center"
                        >
                          SUB TOTAL
                        </TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                          ${allSKUsCost}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}

                {/* ReactQuill editor for email signature */}
                <ReactQuill
                  theme="snow"
                  value={emailData.Signature}
                  onChange={(value) => setEmailData({ ...emailData, Signature: value })}
                  placeholder="Signature"
                  style={{ marginTop: '10px' }}
                />
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>

        {/* Dialog actions for Send and Cancel buttons */}
        <DialogActions sx={{ padding: 2 }}>
          <Button
            size="small"
            variant="contained"
            sx={{
              bgcolor: blueGrey[900],
              color: 'whitesmoke',
              ':hover': { boxShadow: '10px 10px 20px #ccc' },
            }}
            onClick={handleSendCustomerOrKOEmail}
          >
            Send
          </Button>
          <Button
            name="Cancel"
            variant="outlined"
            size="small"
            color="warning"
            sx={{ ':hover': { boxShadow: '10px 10px 20px #ccc' } }}
            onClick={(e) => {
              setIsKOorCustomerDialogOpen(false);
              setEmailData({ ...emailData, Attachments: false, AttachSKUDataInTheEmailBody: false });
              setAttachments([]);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailDialog;
